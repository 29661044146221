/* eslint-disable prettier/prettier */
import { DatePicker, Form, Modal, Switch, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Format } from '../../../helper';
import { Cost, Eba, Investment } from '../../../service';
import {
  CustomButton,
  CustomInput,
  CustomInputNumber,
  CustomNotification,
  Select,
} from '../../_shared';
import { BsTrash } from 'react-icons/bs';
import rules from './rulesBeiSet';
import { Constant } from '../../../const';
import { setIsBulkUpdating } from '../../../store/cost';

export default function CostDetailForm({
  tax,
  open,
  isUpdate,
  type,
  onClose,
  costId,
  ebaId,
  isBei,
  onCloseParent,
  sourceClass,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [amounts, setAmounts] = useState([]);
  const [afterTaxPercentage, setAfterTaxPercentage] = useState([]);
  const [balance, setBalance] = useState([]);
  const [finalAmount, setFinalAmount] = useState([]);
  const [range, setRange] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [initialValues, setInitialValues] = useState([]);
  const [renderer, setRerender] = useState(false);
  const [searchParams] = useSearchParams();
  const [firstPaymentDate, setFirstPaymentDate] = useState('');
  const [endDateEba, setEndDateEba] = useState('');
  const period = searchParams.get('period');
  const [source, setSource] = useState([]);
  const [isBulk, setIsBulk] = useState(false);
  const [isSwitchPeriod, setIsSwitchPeriod] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [basic, setBasic] = useState(['']);

  const intervals = [
    {
      value: 1,
      label: 'Monthly',
    },
    {
      value: 3,
      label: 'Quarterly',
    },
    {
      value: 12,
      label: 'Yearly',
    },
    {
      value: 'custom',
      label: 'Custom',
    },
  ];

  const handleClose = () => {
    if (!isUpdate) {
      dispatch(Cost.deleteCost(costId));
    }
    form.resetFields();
    onCloseParent();
    onClose();
  };

  const resetForm = () => {
    form.resetFields();
    onCloseParent();
    onClose();
  };

  useEffect(() => {
    if (open) {
      let startDate = '';
      dispatch(Eba.getDetailEba(ebaId))
        .unwrap()
        .then((res) => {
          startDate = dayjs(res?.data?.start_date);
          const endDate = dayjs(res?.data?.end_date);
          const diff = endDate.diff(startDate, 'month');
          setFirstPaymentDate(startDate);
          setRange(diff);
          setEndDateEba(endDate);
        });
      if ([1, 2].includes(type)) {
        const tempSource = [];
        dispatch(Cost.getListAum({ id: ebaId, limit: 10000, offset: 0 }))
          .unwrap()
          .then((res) => {
            setSource([
              ...res.data.map((e) => {
                return {
                  value: e?.aum_type?.id === 0 ? 'G-' + e?.id62 : 'B-' + e?.id62,
                  label: e.name,
                  aum_type: e?.aum_type,
                };
              }),
              {
                value: '',
                label: 'Semua',
              },
            ]);

            tempSource.push(
              ...res.data.map((e) => {
                return {
                  value: e?.aum_type?.id === 0 ? 'G-' + e?.id62 : 'B-' + e?.id62,
                  label: e.name,
                  aum_type: e?.aum_type,
                };
              }),
              {
                value: '',
                label: 'Semua',
              }
            );
            const intervalDates = [];

            for (const item of basic) {
              if (item === '') {
                for (const grade of tempSource) {
                  if (grade?.aum_type?.id === 0) {
                    dispatch(Investment.getClassByID({ id: grade?.value?.split('-')[1] }))
                      .unwrap()
                      .then((res) => {
                        intervalDates.push(
                          dayjs(dayjs(startDate).get('date') + ' ' + res?.data?.end_date)
                        );
                        let setterDate = intervalDates[0];
                        const dates = [];
                        for (const intervalDate of intervalDates) {
                          if (intervalDate > setterDate) {
                            setterDate = intervalDate;
                          }

                          dates.push(intervalDate);
                        }

                        const diff = setterDate.diff(startDate, 'months');
                        setRange(diff);
                        setEndDateEba(setterDate);
                      });
                  }
                }
              }
            }
          });
      }
      if (isUpdate) {
        dispatch(Cost.getCostDetail({ id: ebaId, cost: costId, limit: 0, offset: 0 }))
          .unwrap()
          .then((res) => {
            const value = res?.data?.map((item) => {
              return {
                ...item,
                id62: item?.id62,
                period: item?.period,
                percentage: item?.percentage,
                is_prorate: false,
                prorate: '',
                amount: item?.amount,
                is_paid: item?.is_paid,
                period_date: dayjs(startDate).add(
                  item?.period,
                  item?.period === 1 ? 'month' : 'months'
                ),
              };
            });
            form.setFieldValue('fields', value);
            setInitialValues(value);
            setAmounts(res?.data?.map((item) => item.amount));
            setFinalAmount(res?.data?.map((item) => item.final_amount));
            setRerender(true);
          });
      } else {
        setRerender(true);
      }
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      const intervalDates = [];

      for (const item of basic) {
        if (item === '') {
          for (const grade of source) {
            if (grade?.aum_type?.id === 0) {
              dispatch(Investment.getClassByID({ id: grade?.value?.split('-')[1] }))
                .unwrap()
                .then((res) => {
                  intervalDates.push(
                    dayjs(dayjs(firstPaymentDate).get('date') + ' ' + res?.data?.end_date)
                  );
                  let setterDate = intervalDates[0];
                  const dates = [];
                  for (const intervalDate of intervalDates) {
                    if (intervalDate > setterDate) {
                      setterDate = intervalDate;
                    }

                    dates.push(intervalDate);
                  }

                  const diff = setterDate.diff(firstPaymentDate, 'months');
                  setRange(diff);
                  setEndDateEba(setterDate);
                });
            }
          }
        } else {
          const isClass = source.find((e) => e.value === item);

          if (isClass?.aum_type?.id === 0) {
            dispatch(Investment.getClassByID({ id: isClass?.value?.split('-')[1] }))
              .unwrap()
              .then((res) => {
                intervalDates.push(
                  dayjs(dayjs(firstPaymentDate).get('date') + ' ' + res?.data?.end_date)
                );

                let setterDate = intervalDates[0];

                for (const intervalDate of intervalDates) {
                  if (intervalDate > setterDate) {
                    setterDate = intervalDate;
                  }
                }

                const diff = setterDate.diff(firstPaymentDate, 'months');

                setRange(diff);
                setEndDateEba(setterDate);
              });
          } else {
            const hasClass = basic.includes(
              source.filter((e) => e?.aum_type?.id === 0).map((e) => e.value)
            );
            if (!hasClass) {
              dispatch(Eba.getDetailEba(ebaId))
                .unwrap()
                .then((res) => {
                  const startDate = dayjs(res?.data?.start_date);
                  const endDate = dayjs(res?.data?.end_date);
                  const diff = endDate.diff(startDate, 'months');
                  setFirstPaymentDate(startDate);
                  setRange(diff);
                  setEndDateEba(endDate);
                });
            }
          }
        }
      }
    }
  }, [open, basic]);

  const generatePeriod = async (value, percent, addRow, removeRow) => {
    if (isNaN(+value) || +value < 1) {
      CustomNotification({
        type: Constant.WARNING,
        message: 'Gagal Generate Data',
      });
      return;
    }
    setLoading(true);
    if (value > range) {
      Modal.error({
        title: 'Interval tidak boleh melebih period eba.',
        content: 'Interval tidak boleh melebih period eba.',
        okButtonProps: {
          className: 'custom-button custom-button-primary',
          style: { height: '48px' },
        },
      });
    } else {
      await dispatch(Cost.getCostByID(costId))
        .unwrap()
        .then(async (res) => {
          const req = {
            type_id62: res?.data?.type?.type_id62,
            interval: value,
          };
          await dispatch(Cost.updateCost({ id: costId, payload: req })).unwrap();
        })
        .catch(() => {
          setLoading(true);
        });
      setAmounts([]);
      setFinalAmount([]);
      // eslint-disable-next-line prettier/prettier
      const exists =
        typeof form.getFieldValue(['fields']) === 'undefined'
          ? 0
          : form.getFieldValue(['fields']).length;
      const indexes = [];
      for (let i = 0; i < exists; i++) {
        indexes.push(i);
      }

      removeRow(indexes);

      let allRanges = range - (range % parseInt(value));
      let stack = 1;
      const details = [];
      while (allRanges > 0) {
        const currentMonth = dayjs(firstPaymentDate).get('month');

        const periodBei = dayjs(firstPaymentDate)
          .add(11 - currentMonth, 'months')
          .add(stack, 'year');

        const periodDate = dayjs(firstPaymentDate).add(
          stack * parseInt(value),
          stack * parseInt(value) === 1 ? 'month' : 'months'
        );

        const data = {
          period: stack * parseInt(value),
          percentage: percent,
          is_prorate: false,
          period_date: type === 2 ? periodBei : periodDate,
        };

        details.push(data);

        addRow(data);
        stack++;

        allRanges -= parseInt(value);
      }

      const payload = {
        cost_id62: costId,
        detail_cost: details,
      };

      const grades = source.filter(
        (item) => form.getFieldValue('source')?.includes(item.value) && item?.aum_type?.id === 0
      );

      const buffers = source.filter(
        (item) => form.getFieldValue('source')?.includes(item.value) && item?.aum_type?.id === 1
      );

      if (type === 1 || type === 2) {
        if (buffers.length > 0) {
          payload.buffer_id62 = buffers[0]?.value?.split('-')[1];
        }

        if (grades.length > 0) {
          payload.grade_id62 = grades.map((item) => item.value?.split('-')[1]).join(',');
        }
        form.setFields([{ name: 'source', value: form.getFieldValue('source') }]);
      }

      if (type !== 0) {
        dispatch(Cost.calculateAmount(payload))
          .unwrap()
          .then((res) => {
            setAmounts(res?.data?.data.map((item) => item.amount));
            setAfterTaxPercentage(res?.data?.data.map((item) => item.after_tax_percentage));
            setBalance(res?.data?.data.map((item) => item.multiplier_amount));

            if (res.data.message === 'COUPON') {
              setFinalAmount(
                res?.data?.data.map((it) => {
                  return it.amount + (it.amount * it.percentage) / 100;
                })
              );
            } else {
              setFinalAmount(res?.data?.data.map((item) => item.final_amount));
            }

            if (isBei) {
              form.setFields([
                {
                  name: 'fields',
                  value: details.map((e, i) => {
                    return {
                      ...e,
                      percentage: res?.data?.data[i]?.percentage,
                      prorate: '',
                    };
                  }),
                },
              ]);
            }
          });
      }
    }
    setLoading(false);
  };

  const onCalculate = debounce((value, index, typeCalculate = 'percent') => {
    if (value && type !== 0) {
      setLoading(true);
      const item = form.getFieldValue('fields');
      const details = [...item];
      // const prorate = item[index]?.prorate;
      const isProrate = item[index]?.is_prorate;

      if (typeCalculate === 'percent') {
        // input percent based on value
        if (value > range) return;

        details[index] = {
          period: item[index].period,
          percentage: value,
        };
      }

      if (typeCalculate === 'period') {
        // input period based on value
        if (value > range) return;
        details[index] = {
          period: Number(value),
          percentage: item[index].percentage,
        };
      }

      if (isProrate) {
        // is prorate true
        // TO BE Confirm
        // details.ls[index] = {
        //   ...details[index],
        //   prorate: Number(prorate),
        // };
        console.log('prorate');
      } else {
        delete details[index]?.prorate;
      }

      const payload = {
        cost_id62: costId,
        detail_cost: details.map((itm) => {
          const data = {
            period: itm?.period,
            percentage: itm?.percentage,
          };

          if (itm?.prorate !== '') {
            data.prorate = itm?.prorate;
          }

          return data;
        }),
      };

      const grades = source.filter(
        (itm) =>
          form.getFieldValue('source')?.includes(itm.value.split('-')[1]) && itm?.aum_type?.id === 0
      );

      const buffers = source.filter(
        (itm) =>
          form.getFieldValue('source')?.includes(itm.value.split('-')[1]) && itm?.aum_type?.id === 1
      );

      if (type === 1 || type === 2) {
        if (buffers.length > 0) {
          payload.buffer_id62 = buffers[0]?.value;
        }

        if (grades.length > 0) {
          payload.grade_id62 = grades.map((itm) => itm.value).join(',');
        }

        form.setFields([{ name: 'source', value: form.getFieldValue('source') }]);
      }

      dispatch(Cost.calculateAmount(payload))
        .unwrap()
        .then((res) => {
          setAmounts(res?.data?.data.map((it) => it.amount));
          if (res.data.message === 'COUPON') {
            setFinalAmount(
              res?.data?.data.map((it) => {
                return it.amount + (it.amount * it.percentage) / 100;
              })
            );
          } else {
            setFinalAmount(res?.data?.data.map((it) => it.final_amount));
          }
          form.setFields([
            {
              name: ['fields', index, 'amount'],
              value: res?.data?.data[index].amount,
            },
            // {
            //   name: ['fields', index, 'final_amount']
            // }
          ]);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (value && type === 0) {
      // fixed changed
      let taxValue = parseFloat(tax || '0') / 100;
      if (isUpdate) {
        taxValue = parseFloat(form.getFieldValue('fields')[index]?.tax || '0') / 100;
      }
      const finalAmounts = form.getFieldValue('fields').map((item, idx) => {
        if (idx === index) {
          return item.amount + item.amount * taxValue;
        } else {
          return finalAmount[idx];
        }
      });
      setFinalAmount(finalAmounts);
    }
  }, 800);

  const onSubmit = async (e) => {
    if (loadingSubmit) return;
    if (!isUpdate) {
      const payload = {
        cost_id62: costId,
        detail_cost: e.fields.map((f, i) => {
          console.log(f);
          const data = {
            period: f?.period,
            percentage: type === 0 ? null : f?.percentage,
            amount: type === 0 ? f?.amount : parseInt(amounts[i]).toString(),
            after_tax_percentage: afterTaxPercentage && afterTaxPercentage[i] ? afterTaxPercentage[i]: 0,
            balance: balance && balance[i] ? balance[i] : 0,
          };

          if (f.prorate !== '') {
            data.prorate = f.prorate;
          }
          

          return data;
        }),
      };

      setLoadingSubmit(true);
      await dispatch(Cost.createCostDetail(payload))
        .unwrap()
        .then(() => {
          dispatch(Cost.getCostDashboard({ id: ebaId, limit: 5, offset: 0, page: 1 }));
          resetForm();
          setLoadingSubmit(false);
        })
        .catch(() => {
          setLoadingSubmit(false);
        });
    } else {
      setLoadingSubmit(true);
      setIsBulkUpdating(false);
      for (let i = 0; i < e.fields.length; i++) {
        const payload = {
          percentage: type === 0 ? null : e?.fields[i]?.percentage,
          amount: type === 0 ? e?.fields[i]?.amount : parseInt(amounts[i]).toString(),
          tax: e?.fields[i]?.tax,
        };
        dispatch(Cost.updateCostDetail({ id: e?.fields[i]?.id62, payload }));
      }
      await dispatch(
        Cost.getCostDetailByMonth({ id: ebaId, period: period, limit: 5, offset: 0, page: 1 })
      )
        .unwrap()
        .then(() => {
          setLoadingSubmit(false);
          handleClose();
          resetForm();
        })
        .catch(() => {
          setLoadingSubmit(false);
        });
    }
  };

  const handlePeriodChange = (e, i) => {
    const value = e.target.value || 0;
    form.setFields([
      {
        name: ['fields', i, 'period_date'],
        value: dayjs(firstPaymentDate).add(
          parseInt(value),
          parseInt(value) === 1 ? 'month' : 'months'
        ),
      },
    ]);
    onCalculate(value, i, 'period');
  };

  const handleDateChange = (e, i) => {
    const current = dayjs(e);
    const perd = current.diff(dayjs(firstPaymentDate), 'month');

    form.setFields([
      {
        name: ['fields', i, 'period'],
        value: perd,
      },
    ]);
    onCalculate(perd, i, 'period');
  };

  const onDelete = (callback) => {
    callback();
  };

  const bulkUpdate = () => {
    // eslint-disable-next-line prettier/prettier
    const endEba =
      typeof form.getFieldValue('period_end') !== 'undefined'
        ? form.getFieldValue('period_end')
        : 0;
    if (endEba > range) {
      Modal.error({
        title: 'Interval tidak boleh melebih period eba.',
        content: 'Interval tidak boleh melebih period eba.',
        okButtonProps: {
          className: 'custom-button custom-button-primary',
          style: { height: '48px' },
        },
      });
    }
    const hasPeriodStart =
      typeof form.getFieldValue('period_start') !== 'undefined' &&
      form.getFieldValue('period_start') !== '';

    const hasPeriodEnd =
      typeof form.getFieldValue('period_end') !== 'undefined' &&
      form.getFieldValue('period_end') !== '';

    const hasAmount =
      typeof form.getFieldValue('bulk_percent') !== 'undefined' &&
      form.getFieldValue('bulk_percent') !== '';
    if (hasPeriodStart && hasPeriodEnd && hasAmount) {
      for (
        let i = parseInt(form.getFieldValue('period_start')) - 1;
        i <= parseInt(form.getFieldValue('period_end')) - 1;
        i++
      ) {
        if (type === 0) {
          form.setFields([
            {
              name: ['fields', i, 'amount'],
              value: form.getFieldValue('bulk_percent'),
            },
          ]);
        } else {
          form.setFields([
            {
              name: ['fields', i, 'percentage'],
              value: form.getFieldValue('bulk_percent'),
            },
          ]);
          onCalculate(form.getFieldValue('bulk_percent'), i, 'prorate');
        }
      }

      if (type === 0) {
        const taxValue = parseFloat(tax || '0') / 100;
        const finalAmounts = form
          .getFieldValue('fields')
          .map((item) => item.amount + item.amount * taxValue);
        setFinalAmount(finalAmounts);
      }
    }
  };

  // TO BE Confirm
  // const handleProrateChange = (i) => {
  //   const item = form.getFieldValue('fields');
  //   const details = [...item];
  //   details[i].is_prorate = !details[i].is_prorate;
  //   form.setFields([
  //     {
  //       name: 'fields',
  //       value: details,
  //     },
  //   ]);
  //   onCalculate('value', i, 'prorate');
  // };

  const setIntervals = (e) => {
    if (e === 'custom') {
      form.setFieldValue('interval', e);
      setIsCustom(true);
    } else {
      form.setFieldValue('interval', e);
      setIsCustom(false);
    }
  };

  const disabledPeriodDate = (current, index) => {
    const fields = form.getFieldValue('fields');
    const prevDate = fields[index - 1]?.period_date;
    const nextDate = fields[index + 1]?.period_date;
    return (current && current < prevDate) || current > nextDate;
  };

  const onChangeTax = debounce((taxs, index) => {
    const payloadTax = {
      tax: Number(taxs),
    };

    const fields = form.getFieldValue('fields');
    const idField = fields[index]?.id62;

    dispatch(Cost.updateCostTax({ id: idField, payload: payloadTax }))
      .unwrap()
      .then((res) => {
        const newInit = initialValues.map((init) => {
          let dataInit = { ...init };
          const newData = res.data.filter((e) => e.cost_detail_id62 === init.id62);
          if (newData.length > 0) {
            dataInit = { ...dataInit, ...newData[0] };
          }
          return dataInit;
        });
        form.setFieldValue('fields', newInit);
        setInitialValues(newInit);
        setAmounts(newInit?.map((item) => item.amount));
        setFinalAmount(newInit?.map((item) => item.final_amount));

        setRerender(true);
        CustomNotification({
          type: Constant.SUCCESS,
          message: 'Success Update Tax',
        });
      })
      .catch((err) => {
        CustomNotification({
          type: Constant.ERROR,
          message: 'Failed Update Tax',
          description: err?.message?.id,
        });
      });
  }, 1000);

  return (
    <Modal
      title={<h3 className="text-xl font-bold">Detail Biaya</h3>}
      open={open}
      onCancel={() => handleClose()}
      centered
      footer={[
        <CustomButton key="back" type="primary-ghost" onClick={() => handleClose()}>
          Batal
        </CustomButton>,
        <CustomButton
          key="submit"
          type="primary"
          form="form-detail"
          htmlType="submit"
          loading={loadingSubmit}>
          Simpan
        </CustomButton>,
      ]}
      width={'100%'}
      destroyOnClose={true}>
      {renderer && (
        <Form
          id="form-detail"
          layout="vertical"
          form={form}
          requiredMark={false}
          onFinish={onSubmit}>
          <Form.List name="fields">
            {(fields, { add, remove }) => {
              return (
                <>
                  {!isUpdate && (
                    <>
                      {[1, 2].includes(type) && sourceClass !== 'COLLECTION' && (
                        <Form.Item className="w-full" label="Dasar Perhitungan">
                          <Select
                            placeholder="Pilih Dasar Perhitungan"
                            className="py-[12px] px-[18px]"
                            mode="multiple"
                            onChange={(e) => {
                              if (e.includes('')) {
                                form.setFieldValue('source', ['']);
                                setBasic(['']);
                              } else {
                                form.setFieldValue('source', e);
                                setBasic(e);
                              }
                            }}
                            value={basic}
                            options={source}
                          />
                        </Form.Item>
                      )}
                      <div className="flex justify-between items-center gap-5">
                        <Form.Item className="w-full" label="Interval">
                          <Select
                            placeholder="Pilih Interval"
                            className="py-[12px] px-[18px] h-full"
                            onChange={setIntervals}
                            options={intervals}
                          />
                        </Form.Item>
                        {isCustom && (
                          <Form.Item
                            label="Interval"
                            className="w-full"
                            rules={[
                              {
                                required: true,
                                message: 'Field tidak boleh kosong.',
                              },
                            ]}>
                            <CustomInput
                              onChange={(e) => {
                                form.setFieldValue('interval', e.target.value);
                              }}
                              type="text"
                              className="py-[18px] px-[24px]"
                            />
                          </Form.Item>
                        )}
                        {type === 1 && (
                          <Form.Item
                            label="Persentase per pembayaran (%)"
                            className="w-full"
                            rules={[
                              {
                                required: true,
                                message: 'Field tidak boleh kosong.',
                              },
                            ]}>
                            <CustomInput
                              onChange={(e) => {
                                form.setFieldValue('percentage', e.target.value);
                              }}
                              type="text"
                              className="py-[18px] px-[24px]"
                            />
                          </Form.Item>
                        )}
                        <CustomButton
                          type="primary"
                          loading={loading}
                          onClick={() =>
                            generatePeriod(
                              form.getFieldValue('interval'),
                              form.getFieldValue('percentage') || 0,
                              add,
                              remove
                            )
                          }>
                          Generate
                        </CustomButton>
                      </div>
                      <div className="flex space-x-5 my-5">
                        <div className="flex space-x-3 ">
                          <Typography.Paragraph className="text-base">
                            Ubah Semua {type === 0 ? 'Nominal' : 'Persentase'}
                          </Typography.Paragraph>
                          <Switch onChange={() => setIsBulk(!isBulk)} />
                        </div>
                        <div className="flex space-x-3 mb-3">
                          <Typography.Paragraph className="text-base">
                            Ganti Masukkan Periode
                          </Typography.Paragraph>
                          <Tooltip
                            title={
                              isSwitchPeriod
                                ? 'Ganti ke masukkan periode'
                                : 'Ganti ke masukkan tanggal'
                            }>
                            <Switch onChange={() => setIsSwitchPeriod(!isSwitchPeriod)} />
                          </Tooltip>
                        </div>
                      </div>
                      {isBulk && (
                        <div className="flex justify-between items-center gap-5">
                          <Form.Item label="Awal Periode" className="w-full">
                            <CustomInput
                              onChange={(e) => {
                                form.setFieldValue('period_start', e.target.value);
                              }}
                              type="text"
                              className="py-[18px] px-[24px]"
                            />
                          </Form.Item>
                          <Form.Item label="Akhir Periode" className="w-full">
                            <CustomInput
                              onChange={(e) => {
                                if (e.target.value > range) {
                                  Modal.error({
                                    title: 'Interval tidak boleh melebih period eba.',
                                    content: 'Interval tidak boleh melebih period eba.',
                                    okButtonProps: {
                                      className: 'custom-button custom-button-primary',
                                      style: { height: '48px' },
                                    },
                                  });
                                }
                                form.setFieldValue('period_end', e.target.value);
                              }}
                              type="text"
                              className="py-[18px] px-[24px]"
                            />
                          </Form.Item>
                          {type === 0 && (
                            <Form.Item
                              label="Nominal (Rp)"
                              className="w-full"
                              rules={[
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.reject();
                                    }
                                    if (isNaN(value)) {
                                      return Promise.reject('The input is not a number');
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}>
                              <CustomInputNumber
                                className="py-[14px] px-[20px] w-full"
                                onChange={(value) => {
                                  form.setFieldValue('bulk_percent', value);
                                }}
                                formatter={(value) =>
                                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }
                                maxLength={19}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              />
                            </Form.Item>
                          )}
                          {type !== 0 && (
                            <Form.Item label="Persentase Pertahun (%)" className="w-full">
                              <CustomInput
                                onChange={(e) => {
                                  form.setFieldValue('bulk_percent', e.target.value);
                                }}
                                type="text"
                                className="py-[18px] px-[24px]"
                              />
                            </Form.Item>
                          )}
                          <CustomButton
                            type="primary"
                            loading={loading}
                            onClick={() => bulkUpdate()}>
                            Set
                          </CustomButton>
                        </div>
                      )}
                    </>
                  )}

                  <div className="flex flex-column items-center gap-3 mb-5">
                    <div className="flex items-center justify-evenly w-full gap-x-2.5">
                      {/* <div style={{ width: isBei ? '10%' : '20%' }}>
                        <Typography.Paragraph className="text-base text-center w-full">
                          Pembayaran
                        </Typography.Paragraph>
                      </div>
                      <div style={{ width: isBei ? '10%' : '20%' }}>
                        <Typography.Paragraph className="text-base text-center w-full">
                          Periode
                        </Typography.Paragraph>
                      </div>
                      <div style={{ width: isBei ? '15%' : '20%' }}>
                        <Typography.Paragraph className="text-base text-center w-full">
                          Tanggal
                        </Typography.Paragraph>
                      </div>
                      {isBei && (
                        <div style={{ width: isBei ? '35%' : '30%' }}>
                          <Typography.Paragraph className="text-base text-right w-full">
                            Periode Prorate
                          </Typography.Paragraph>
                        </div>
                      )}
                      <div style={{ width: isBei ? '15%' : '30%' }}>
                        <Typography.Paragraph className="text-base text-center w-full">
                          Nominal (Rp)
                        </Typography.Paragraph>
                      </div> */}
                      <div style={{ width: '20%' }}>
                        <Typography.Paragraph className="text-base text-center w-full">
                          Periode Pembayaran
                        </Typography.Paragraph>
                      </div>
                      <div style={{ width: '20%' }}>
                        <Typography.Paragraph className="text-base text-center w-full">
                          Bulan Ke-
                        </Typography.Paragraph>
                      </div>
                      <div style={{ width: '20%' }}>
                        <Typography.Paragraph className="text-base text-center w-full">
                          Tanggal
                        </Typography.Paragraph>
                      </div>
                      {/* {isBei && (
                        <div style={{ width: '30%' }}>
                          <Typography.Paragraph className="text-base text-right w-full">
                            Periode Prorate
                          </Typography.Paragraph>
                        </div>
                      )} */}
                      <div
                        style={{ width: type === 0 ? '35%' : '50%' }}
                        className="flex items-center">
                        {type !== 0 && (
                          <Typography.Paragraph className="flex-1 text-base text-center w-full">
                            Persen per-Pembayaran
                          </Typography.Paragraph>
                        )}
                        <Typography.Paragraph className="flex-1 text-base text-center w-full">
                          Nominal (Rp)
                        </Typography.Paragraph>
                        <Typography.Paragraph className="flex-1 text-base text-center w-full">
                          Nominal + Pajak (Rp)
                        </Typography.Paragraph>
                      </div>
                      {isUpdate && (
                        <>
                          {/* <div style={{ width: '20%' }}>
                            <Typography.Paragraph className="text-base text-center w-full">
                              Nominal + Pajak (Rp)
                            </Typography.Paragraph>
                          </div> */}
                          <div style={{ width: '10%' }}>
                            <Typography.Paragraph className="text-base text-center w-full">
                              Pajak (%)
                            </Typography.Paragraph>
                          </div>
                        </>
                      )}
                      <div style={{ width: '10%' }}>
                        <Typography.Paragraph className="text-base text-center w-full">
                          Aksi
                        </Typography.Paragraph>
                      </div>
                    </div>
                  </div>
                  {fields.map((field, index) => (
                    <div key={field?.key} className="flex flex-column items-center gap-3 mb-5">
                      <div className="flex items-start justify-evenly w-full gap-x-2.5">
                        {/* <Typography.Paragraph
                          className={`text-base text-center py-[18px] px-[24px]`}
                          style={{ width: isBei ? '10%' : '20%' }}>
                          {index + 1}
                        </Typography.Paragraph>
                        <div className={`items-center`} style={{ width: isBei ? '10%' : '20%' }}>
                          <Form.Item
                            className="text-center"
                            name={[index, 'period']}
                            validateFirst
                            dependencies={[['fields', field.key, 'period_date']]}
                            rules={rules.period(range, index)}>
                            <CustomInput
                              type="text"
                              onChange={(e) => handlePeriodChange(e, index)}
                              className="py-[18px] px-[24px] text-center"
                              disabled={isUpdate || isSwitchPeriod}
                            />
                          </Form.Item>
                        </div>
                        <div style={{ width: isBei ? '15%' : '20%' }}>
                          <Form.Item
                            className="w-full"
                            name={[index, 'period_date']}
                            validateFirst
                            dependencies={[['fields', field.key, 'period']]}
                            rules={rules.periodDate(range, endDateEba, index)}>
                            <DatePicker
                              className="custom-input rounded py-[18px] bg-white px-[12px] w-full"
                              placeholder="YYYY-MM-DD"
                              picker="date"
                              disabledDate={(c) => disabledPeriodDate(c, index)}
                              disabled={!isSwitchPeriod}
                              onChange={(_, e) => handleDateChange(e, index)}
                            />
                          </Form.Item>
                        </div> */}
                        <Typography.Paragraph
                          className={`text-base text-center py-[18px] px-[24px]`}
                          style={{ width: '20%' }}>
                          {index + 1}
                        </Typography.Paragraph>
                        <div className={`items-center`} style={{ width: '20%' }}>
                          <Form.Item
                            className="text-center"
                            name={[index, 'period']}
                            validateFirst
                            dependencies={[['fields', field.key, 'period_date']]}
                            rules={rules.period(range, index)}>
                            <CustomInput
                              type="text"
                              onChange={(e) => handlePeriodChange(e, index)}
                              className="py-[18px] px-[24px] text-center"
                              disabled={isUpdate || isSwitchPeriod}
                            />
                          </Form.Item>
                        </div>
                        <div style={{ width: '20%' }}>
                          <Form.Item
                            className="w-full"
                            name={[index, 'period_date']}
                            validateFirst
                            dependencies={[['fields', field.key, 'period']]}
                            rules={rules.periodDate(range, endDateEba, index)}>
                            <DatePicker
                              className="custom-input rounded py-[18px] bg-white px-[12px] w-full"
                              placeholder="YYYY-MM-DD"
                              picker="date"
                              disabledDate={(c) => disabledPeriodDate(c, index)}
                              disabled={!isSwitchPeriod}
                              onChange={(_, e) => handleDateChange(e, index)}
                            />
                          </Form.Item>
                        </div>
                        {/* <div
                          className={
                            // eslint-disable-next-line prettier/prettier
                            `flex justify-start items-center ${type === 0 ? '' : 'gap-5'}`
                          }
                          style={{ width: isBei ? '55%' : '30%' }}>
                          {type !== 0 && (
                            <>
                              <Form.Item
                                className="text-center w-[25%]"
                                name={[index, 'percentage']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Field tidak boleh kosong.',
                                  },
                                  () => ({
                                    validator(_, value) {
                                      if (!Number(value) && value === 0) {
                                        return Promise.reject(new Error(`Hanya masukkan angka`));
                                      }
                                      if (value > 100) {
                                        return Promise.reject(new Error(`Maksimal 100%`));
                                      }
                                      if (value < 0.01) {
                                        return Promise.reject(new Error(`Minimal 0.01%`));
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}>
                                <CustomInput
                                  type="text"
                                  {...(initialValues[index]?.is_paid && { disabled: true })}
                                  className="py-[18px] px-[10px]"
                                  suffix={<span>%</span>}
                                  onChange={(e) => onCalculate(e.target.value, index)}
                                />
                              </Form.Item>
                              {isBei && (
                                <>
                                  <div
                                    className="flex space-x-3 mb-3"
                                    style={{ marginTop: '-12px' }}>
                                    <Typography.Paragraph className="text-base">
                                      Prorate
                                    </Typography.Paragraph>
                                    <Switch
                                      checked={form.getFieldValue('fields')[index]?.is_prorate}
                                      onChange={() => handleProrateChange(index)}
                                    />
                                  </div>
                                  <Form.Item
                                    className="text-center w-[25%]"
                                    name={[index, 'prorate']}>
                                    <CustomInputNumber
                                      className="py-[14px] px-[12px] w-full"
                                      {...(!form.getFieldValue('fields')[index]?.is_prorate && {
                                        disabled: true,
                                      })}
                                      formatter={(value) =>
                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                      }
                                      maxLength={19}
                                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                      onChange={(e) => onCalculate(e, index, 'prorate')}
                                    />
                                  </Form.Item>
                                </>
                              )}
                              <Typography.Paragraph
                                className="text-base font-bold"
                                style={{ marginTop: '-25px', textAlign: 'right' }}>
                                Rp. {loading ? 0 : Format.numberFormat(Math.round(amounts[index]))}
                              </Typography.Paragraph>
                            </>
                          )}
                          {type === 0 && (
                            <Form.Item
                              className="text-center w-full"
                              name={[index, 'amount']}
                              validateFirst
                              rules={[
                                {
                                  required: true,
                                  message: 'Field tidak boleh kosong.',
                                },
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.reject();
                                    }
                                    if (isNaN(value)) {
                                      return Promise.reject('The input is not a number');
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}>
                              <CustomInputNumber
                                className="py-[14px] px-[13px] w-full"
                                {...(initialValues[index]?.is_paid && { disabled: true })}
                                formatter={(value) =>
                                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }
                                maxLength={19}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              />
                            </Form.Item>
                          )}
                        </div> */}
                        <div
                          className={
                            // eslint-disable-next-line prettier/prettier
                            `flex justify-start items-center ${type === 0 ? '' : 'gap-5'}`
                          }
                          style={{ width: type === 0 ? '30%' : '35%' }}>
                          {type !== 0 && (
                            <>
                              <Form.Item
                                className="flex-1 text-center "
                                name={[index, 'percentage']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Field tidak boleh kosong.',
                                  },
                                  () => ({
                                    validator(_, value) {
                                      if (!Number(value) && value === 0) {
                                        return Promise.reject(new Error(`Hanya masukkan angka`));
                                      }
                                      if (value > 100) {
                                        return Promise.reject(new Error(`Maksimal 100%`));
                                      }
                                      if (value < 0.01) {
                                        return Promise.reject(new Error(`Minimal 0.01%`));
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}>
                                {/* Persen per pembayaran */}
                                <CustomInputNumber
                                  className="py-[14px] px-[13px]"
                                  {...(initialValues[index]?.is_paid && { disabled: true })}
                                  formatter={(value) =>
                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  }
                                  maxLength={19}
                                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                  onChange={(e) => onCalculate(e, index)}
                                  suffix={<span>%</span>}
                                />
                              </Form.Item>
                            </>
                          )}
                          {type !== 0 && (
                            <Typography.Paragraph
                              className="flex-1 text-base font-bold"
                              style={{ marginTop: '-25px', textAlign: 'right' }}>
                              {/* nominal */}
                              Rp. {loading ? 0 : Format.numberFormat(Math.round(amounts[index]))}
                            </Typography.Paragraph>
                          )}
                          {type === 0 && (
                            <Form.Item
                              className="text-center w-full"
                              name={[index, 'amount']}
                              shouldUpdate
                              validateFirst
                              rules={[
                                {
                                  required: true,
                                  message: 'Field tidak boleh kosong.',
                                },
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.reject();
                                    }
                                    if (isNaN(value)) {
                                      return Promise.reject('The input is not a number');
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}>
                              <CustomInputNumber
                                className="py-[14px] px-[13px] w-full"
                                {...(initialValues[index]?.is_paid && { disabled: true })}
                                formatter={(value) =>
                                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }
                                maxLength={19}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(e) => onCalculate(e, index)}
                              />
                            </Form.Item>
                          )}
                        </div>
                        <div className="w-[20%]">
                          {/* nominal + pajak */}
                          <Typography.Paragraph className="text-base font-bold text-center mt-5">
                            Rp. {loading ? 0 : Format.numberFormat(Math.round(finalAmount[index]))}
                          </Typography.Paragraph>
                        </div>
                        {isUpdate && (
                          <div className="w-[10%]">
                            <Form.Item
                              className="text-center w-full"
                              name={[index, 'tax']}
                              shouldUpdate>
                              <CustomInputNumber
                                className="py-[14px] px-[13px]"
                                {...(initialValues[index]?.is_paid && { disabled: true })}
                                formatter={(value) =>
                                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }
                                maxLength={19}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(e) => onChangeTax(e, index)}
                                suffix={<span>%</span>}
                              />
                              {/* <CustomInput
                                type="text"
                                {...(initialValues[index]?.is_paid && { disabled: true })}
                                className="py-[18px] px-[10px]"
                                suffix={<span>%</span>}
                                onChange={(e) => onChangeTax(e.target.value, index)}
                              /> */}
                            </Form.Item>
                          </div>
                        )}
                        <BsTrash
                          {...(!initialValues[index]?.is_paid && {
                            onClick: () => {
                              isUpdate ? onDelete(remove(index)) : remove(index);
                            },
                          })}
                          className="cursor-pointer"
                          style={{
                            marginTop: '24px',
                            visibility: !initialValues[index]?.is_paid ? 'visible' : 'hidden',
                            width: '10%',
                          }}
                        />
                      </div>
                    </div>
                  ))}

                  <CustomButton type="primary" className="w-full" onClick={add}>
                    Input
                  </CustomButton>
                </>
              );
            }}
          </Form.List>
        </Form>
      )}
    </Modal>
  );
}
