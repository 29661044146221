import React from 'react';
import CustomButton from '../../_shared/custom-button';

const UpdateAction = ({ onClickDetail, record }) => {
  return (
    <div className="flex justify-center space-x-3">
      <CustomButton type="primary-ghost" onClick={() => onClickDetail(record)}>
        Lihat Detail
      </CustomButton>
    </div>
  );
};

export default UpdateAction;
