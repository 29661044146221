import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import constants from '../../const/constants';
import { UserLog } from '../../service';
import { Constant } from '../../const';

const initialState = {
  items: [],
  itemDetails: [],
  logCashflow: [],
  itemTotal: 0,
  paginationSync: {
    page: 1,
  },
  itemDetailTotal: 0,
  loading: false,
  loadingDetail: false,
  paginationSyncDetail: {
    page: 1,
  },
  download: {},
  loadingDownload: false,
};

export const userSlice = createSlice({
  name: 'userLog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // user log list
    builder.addCase(UserLog.userLogList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(UserLog.userLogList.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload?.data?.results;
      state.itemTotal = action.payload?.data?.count;
      state.paginationSync.page = action?.meta?.arg?.page;
    });
    builder.addCase(UserLog.userLogList.rejected, (state, action) => {
      CustomNotification({
        type: constants.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
      state.items = [];
      state.paginationSync.page = 1;
    });
    // user log detail list
    builder.addCase(UserLog.userLogDetailList.pending, (state) => {
      state.loadingDetail = true;
    });
    builder.addCase(UserLog.userLogDetailList.fulfilled, (state, action) => {
      state.loadingDetail = false;
      state.itemDetails = action.payload?.data?.results;
      state.itemDetailTotal = action.payload?.data?.count;
      state.paginationSyncDetail.page = action?.meta?.arg?.page;
    });
    builder.addCase(UserLog.userLogDetailList.rejected, (state, action) => {
      CustomNotification({
        type: constants.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loadingDetail = false;
      state.itemDetails = [];
      state.paginationSyncDetail.page = 1;
    });
    // user log log-cashflow
    builder.addCase(UserLog.userLogCashflow.pending, (state) => {
      state.loadingDetail = true;
    });
    builder.addCase(UserLog.userLogCashflow.fulfilled, (state, action) => {
      state.loadingDetail = false;
      state.logCashflow = action.payload?.data?.results;
      state.paginationSyncDetail.page = action?.meta?.arg?.page;
    });
    builder.addCase(UserLog.userLogCashflow.rejected, (state, action) => {
      CustomNotification({
        type: constants.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loadingDetail = false;
      state.logCashflow = [];
      state.paginationSyncDetail.page = 1;
    });

    // Download
    builder.addCase(UserLog.downloadLogbook.pending, (state) => {
      state.loadingDownload = true;
    });
    builder.addCase(UserLog.downloadLogbook.fulfilled, (state, action) => {
      state.loadingDownload = false;
      state.download = action.payload.data;
    });
    builder.addCase(UserLog.downloadLogbook.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Download Data',
        description: action?.payload?.message?.id,
      });
      state.loadingDownload = false;
    });
  },
});

export default userSlice.reducer;
