import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const getListEba = createAsyncThunk('get/getListEba', async (body, { rejectWithValue }) => {
  const { limit = 10, offset = 0, status_value, search, is_simulation } = body;
  try {
    return await API.get({
      // eslint-disable-next-line prettier/prettier
      url: `eba/?limit=${limit}&offset=${offset}&status_value=${
        status_value || ''
      }&search=${search}&is_simulation=${is_simulation || 'False'}`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getDetailEba = createAsyncThunk('get/getDetailEba', async (id, { rejectWithValue }) => {
  try {
    return await API.get({ url: `eba/${id}/` });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createEba = createAsyncThunk('get/createEba', async (payload, { rejectWithValue }) => {
  try {
    return await API.post({ url: `eba/`, payload });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const editEba = createAsyncThunk('put/editEba', async ({ payload, id }, { rejectWithValue }) => {
  try {
    return await API.put({ url: `eba/${id}/`, payload });
  } catch (error) {
    return rejectWithValue(error);
  }
});
const deleteEba = createAsyncThunk('delete/deleteEba', async ({ id62 }, { rejectWithValue }) => {
  try {
    return await API.deleteRequest({ url: `eba/${id62}/` });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const AdminApproval = createAsyncThunk('post/approval', async (id, { rejectWithValue }) => {
  try {
    return await API.get({ url: `eba/${id}/approval/` });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getDomainEmail = createAsyncThunk(
  'get/getDomainEmail',
  async (ebaId, { rejectWithValue }) => {
    try {
      return await API.get({ url: `eba/domain/?eba_id62=${ebaId}` });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getEbaDomainEmail = createAsyncThunk(
  'get/getEbaDomainEmail',
  async (email, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `eba/by-domain/?is_simulation=false&status_value=0&domain_email=${email}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getSummaryEba = createAsyncThunk(
  'get/getSummaryEba',
  async ({ ebaId, is_simulation, startDate, endDate }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `eba/summary/?eba_id62=${ebaId}&start_date=${startDate}&end_date=${endDate}&is_simulation=${
          is_simulation || false
        }`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default {
  getListEba,
  getDetailEba,
  createEba,
  editEba,
  AdminApproval,
  deleteEba,
  getDomainEmail,
  getEbaDomainEmail,
  getSummaryEba,
};
