import React, { useEffect } from 'react';
import { CustomTable, Page } from '../../components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Typography, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import usePage from '../../hooks/usePage';
import { Cost } from '../../service';
import { Util } from '../../helper';
import { costSummary } from './components/tableConfig';
import { CustomButton } from '../../components/_shared';
import { ExclamationCircleFilled } from '@ant-design/icons';

const { confirm } = Modal;

export default function CostSummary() {
  const { isProduct } = usePage();
  const { isSupervisor, isOriginator } = Util.role();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  const isSimulation = searchParams.get('is_simulation');
  const ebaName = searchParams.get('eba-name');
  const page = searchParams.get('page') || 'management-product';

  const { items, itemTotal, loading } = useSelector((state) => state.cost);

  const breadcrumbs = [
    {
      name: `${isSimulation === 'true' ? 'Simulasi Produk' : 'Management Produk'}`,
      href: '/management-product',
    },
    {
      name: 'Draft Produk',
      href: '/management-product/?status=1',
      isHide: isProduct || isSupervisor,
    },
    { name: 'Management Asset', href: null, isHide: !isProduct },
    { name: 'Biaya Berjalan', href: null },
  ];

  const paginationChange = (page, pageSize) => {
    fetchData({ limit: 5, offset: (page - 1) * pageSize, page: page });
  };

  const fetchData = ({ limit, offset, page = 1 }) => {
    dispatch(Cost.getCostSummary({ id: ebaId, limit, offset, page }));
  };

  const showConfirm = (id) => {
    confirm({
      title: (
        <h3 className="text-xl font-bold">
          Apakah Anda yakin ingin menghapus biaya ini? biaya yang sudah dibayar tidak akan terhapus
        </h3>
      ),
      icon: <ExclamationCircleFilled />,
      centered: true,
      width: '30%',
      okText: 'Hapus',
      okButtonProps: {
        className: 'custom-button custom-button-primary',
        style: { height: '48px' },
      },
      cancelText: 'Batal',
      cancelButtonProps: {
        className: 'custom-button custom-button-secondary',
        style: { height: '48px' },
      },
      onOk() {
        dispatch(Cost.deleteCost(id))
          .unwrap()
          .then(() => {
            fetchData({ limit: 5, offset: 0 });
          });

        Modal.destroyAll();
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  useEffect(() => {
    fetchData({ limit: 5, offset: 0 });
  }, [ebaId]);

  return (
    <Page title="Biaya Berjalan" breadcrumbs={breadcrumbs}>
      <Typography.Paragraph className="font-bold text-3xl">Cost Summary</Typography.Paragraph>
      <Typography.Paragraph className="font-bold text-xl mt-2">
        {ebaName ? ebaName : null}
      </Typography.Paragraph>
      <div className="mb-5 mt-5">
        <CustomTable.TableGlobal
          dataSource={items}
          columns={costSummary(showConfirm)}
          loading={loading}
          pagination={{
            pageSize: 5,
            onChange: paginationChange,
            showSizeChanger: false,
            total: itemTotal,
          }}
        />
      </div>
      {!isOriginator && (
        <div className="flex flex-row-reverse items-center gap-5">
          <CustomButton
            type="primary-ghost"
            onClick={() =>
              navigate(
                `/management-product/cost?page=${page}&eba-name=${ebaName}&eba-id=${ebaId}&status=${status}`
              )
            }>
            Kembali
          </CustomButton>
        </div>
      )}
    </Page>
  );
}
