import React from 'react';
import { AiOutlineTeam } from 'react-icons/ai';
import { ImUsers } from 'react-icons/im';
import { IoPeopleCircleSharp } from 'react-icons/io5';
import {
  MdAttachMoney,
  MdBook,
  MdCollectionsBookmark,
  MdDashboard,
  MdOutlineBusiness,
  MdOutlineMoney,
  MdOutlineSwapCalls,
  MdStackedLineChart,
  MdTableChart,
} from 'react-icons/md';
import { Constant, Menu, PageContent } from '../../const';
import { Util } from '../../helper';
import { useAuth } from '../../hooks/useAuth';
import { useSearchParams } from 'react-router-dom';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const ListMenu = (keySelected, page) => {
  const { user } = useAuth();
  const { isOriginator } = Util.role();
  const [searchParam] = useSearchParams();
  const isSimulation = searchParam.get('is_simulation');

  const isClickedMenu = (key) => {
    if (typeof key === typeof []) {
      return key.includes(keySelected) ? '#E70A09' : '#AFAFAF';
    }
    return keySelected === key ? '#E70A09' : '#AFAFAF';
  };

  const originatorMenu = [
    getItem(
      Menu.LABEL.ASSETS,
      Menu.KEY.MANAGEMENT_PRODUCT,
      <MdCollectionsBookmark size={16} color={isClickedMenu(Menu.KEY.MANAGEMENT_PRODUCT)} />
    ),
  ];

  const adminMenu = [
    getItem(
      Menu.LABEL.MANAGEMENT_PRODUCT,
      Menu.KEY.MANAGEMENT_PRODUCT,
      <MdTableChart size={16} color={isClickedMenu(Menu.KEY.MANAGEMENT_PRODUCT)} />
    ),
    getItem(
      Menu.LABEL.SIMULATION,
      Menu.KEY.SIMULATION,
      <MdOutlineSwapCalls size={16} color={isClickedMenu(Menu.KEY.SIMULATION)} />
    ),
    getItem(
      Menu.LABEL.MANAGEMENT_USER,
      Menu.KEY.MANAGEMENT_USER,
      <ImUsers size={16} color={isClickedMenu(Menu.KEY.MANAGEMENT_USER)} />
    ),
  ];

  const adminManagementProduct = [
    getItem(
      isSimulation === 'true' ? Menu.LABEL.SIMULATION : Menu.LABEL.EBA_INFO,
      Menu.KEY.EBA_INFO,
      <MdDashboard size={16} color={isClickedMenu(Menu.KEY.EBA_INFO)} />
    ),
    getItem(
      Menu.LABEL.UPLOAD_ASSETS,
      Menu.KEY.UPLOAD_ASSETS,
      <MdCollectionsBookmark size={16} color={isClickedMenu(Menu.KEY.UPLOAD_ASSETS)} />
    ),
    getItem(
      Menu.LABEL.COST_EBA,
      Menu.KEY.COST_EBA,
      <MdOutlineMoney
        size={16}
        color={isClickedMenu([
          Menu.KEY.EQUITY_BUFFER,
          Menu.KEY.ASSET_CLASS,
          Menu.KEY.COST,
          Menu.KEY.RE_INVESTMENT,
        ])}
      />,
      [
        getItem(
          Menu.LABEL.ASSET_CLASS,
          Menu.KEY.ASSET_CLASS,
          <MdBook size={16} color={isClickedMenu(Menu.KEY.ASSET_CLASS)} />
        ),
        getItem(
          Menu.LABEL.EQUITY_BUFFER,
          Menu.KEY.EQUITY_BUFFER,
          <MdStackedLineChart size={16} color={isClickedMenu(Menu.KEY.EQUITY_BUFFER)} />
        ),
        getItem(
          Menu.LABEL.COST,
          Menu.KEY.COST,
          <MdAttachMoney size={16} color={isClickedMenu(Menu.KEY.COST)} />
        ),
        getItem(
          Menu.LABEL.RE_INVESTMENT,
          Menu.KEY.RE_INVESTMENT,
          <MdOutlineBusiness size={16} color={isClickedMenu(Menu.KEY.RE_INVESTMENT)} />
        ),
      ]
    ),
  ];

  const adminProduct = [
    getItem(
      Menu.LABEL.ASSETS,
      Menu.KEY.ASSETS,
      <MdCollectionsBookmark
        size={16}
        color={isClickedMenu([Menu.KEY.ASSETS, Menu.KEY.POOL_OF_ASSET])}
      />
    ),
    getItem(
      Menu.LABEL.COST_EBA,
      Menu.KEY.COST_EBA,
      <MdOutlineMoney size={16} color={isClickedMenu(Menu.KEY.COST_EBA)} />
    ),
    getItem(
      Menu.LABEL.MANAGEMENT_ASSETS,
      Menu.KEY.MANAGEMENT_ASSETS,
      <IoPeopleCircleSharp
        size={16}
        color={isClickedMenu([
          Menu.KEY.EBA_INFO,
          Menu.KEY.UPLOAD_ASSETS,
          Menu.KEY.EQUITY_BUFFER,
          Menu.KEY.ASSET_CLASS,
          Menu.KEY.COST,
          Menu.KEY.RE_INVESTMENT,
        ])}
      />,
      [
        getItem(
          isSimulation ? Menu.LABEL.SIMULATION : Menu.LABEL.EBA_INFO,
          Menu.KEY.EBA_INFO,
          <MdDashboard size={16} color={isClickedMenu(Menu.KEY.EBA_INFO)} />
        ),
        getItem(
          Menu.LABEL.UPLOAD_ASSETS,
          Menu.KEY.UPLOAD_ASSETS,
          <MdCollectionsBookmark size={16} color={isClickedMenu(Menu.KEY.UPLOAD_ASSETS)} />
        ),
        getItem(
          Menu.LABEL.ASSET_CLASS,
          Menu.KEY.ASSET_CLASS,
          <MdBook size={16} color={isClickedMenu(Menu.KEY.ASSET_CLASS)} />
        ),
        getItem(
          Menu.LABEL.EQUITY_BUFFER,
          Menu.KEY.EQUITY_BUFFER,
          <MdStackedLineChart size={16} color={isClickedMenu(Menu.KEY.EQUITY_BUFFER)} />
        ),

        getItem(
          Menu.LABEL.COST,
          Menu.KEY.COST,
          <MdAttachMoney size={16} color={isClickedMenu(Menu.KEY.COST)} />
        ),
        getItem(
          Menu.LABEL.RE_INVESTMENT,
          Menu.KEY.RE_INVESTMENT,
          <MdOutlineBusiness size={16} color={isClickedMenu(Menu.KEY.RE_INVESTMENT)} />
        ),
      ]
    ),
    getItem(
      Menu.LABEL.REPORTS,
      Menu.KEY.REPORTS,
      <AiOutlineTeam
        size={16}
        color={isClickedMenu([
          Menu.KEY.REPORTS,
          Menu.KEY.REPORT_INTERNAL,
          Menu.KEY.REPORT_EXTERNAL,
        ])}
      />
    ),
    getItem(
      Menu.LABEL.LOGBOOK,
      Menu.KEY.LOGBOOK,
      <MdBook size={16} color={isClickedMenu(Menu.KEY.LOGBOOK)} />
    ),
  ];

  const menuShow = () => {
    const role = user?.role;
    let menu = [];

    // default main menu sidebar
    switch (role) {
      case Constant.ADMIN:
      case Constant.SUPERVISOR:
        menu = adminMenu;
        break;
      default:
        menu = originatorMenu;
        break;
    }

    // is there page params
    // then execute show sub menu sidebar
    if (page) {
      if (!isOriginator) {
        switch (page) {
          case PageContent.PRODUCT:
            menu = adminProduct;
            break;
          case PageContent.MANAGEMENT_PRODUCT:
            menu = adminManagementProduct;
            break;
          default:
            break;
        }
      }

      return menu;
    }

    return menu;
  };

  return menuShow();
};

export default ListMenu;
