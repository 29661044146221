import { Form, Modal, Tooltip, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { IoMdSettings } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { ManagementProductComponent } from '../..';
import { Cost } from '../../../service';
import { CustomButton, CustomInput, Select } from '../../_shared';
import { TiWarningOutline } from 'react-icons/ti';

export default function CostForm({ open, onClose, isUpdate = false, ebaId, record }) {
  const dispatch = useDispatch();
  const [costType, setCostType] = useState([]);
  const [rerender, setRerender] = useState(false);
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState([
    {
      id62: '',
      name: '',
      type: '',
      tax: '',
    },
  ]);
  const [costId, setCostId] = useState('');
  const [type, setType] = useState(0);
  const [source, setSource] = useState('');
  const [detail, setDetail] = useState(false);
  const [isAumBei, setIsAumBei] = useState(false);
  const [tax, setTax] = useState('');

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (open) {
      dispatch(Cost.getCostType({ limit: 10000, ebaId }))
        .unwrap()
        .then((res) => {
          if (res.data?.length > 0) {
            setCostType(
              res.data?.map((e) => {
                return {
                  value: e?.id62,
                  label: e?.name,
                  enum: e?.type?.value,
                  is_bei_param: e?.is_params,
                };
              })
            );
          } else {
            setCostType([]);
          }
        });
      if (isUpdate) {
        setRerender(false);
        dispatch(Cost.getCostByID(record?.cost?.id62))
          .unwrap()
          .then((res) => {
            setType(res?.data?.type?.type?.value);
            setInitialValues([
              {
                id62: res?.data?.id62,
                name: res?.data?.name,
                type: res?.data?.type?.id62,
                tax: parseInt(res?.data?.tax).toString(),
              },
            ]);
            setCostId(res?.data?.id62);
            setRerender(true);
          });
      } else {
        setRerender(true);
      }
    }
  }, [open]);

  const onSubmit = useCallback(
    async (e) => {
      form.validateFields().then(() => {
        for (let i = 0; i < e?.fields.length; i++) {
          if (!isUpdate) {
            const payload = {
              eba_id62: ebaId,
              name: e.fields[i].name,
              type_id62: e.fields[i].type,
              // start_date: dayjs(e?.fields[i].start_date?.$d).format('YYYY-MM-DD'),
              tax: e.fields[i].tax,
            };

            setTax(e.fields[i].tax);
            dispatch(Cost.createCost(payload))
              .unwrap()
              .then((res) => {
                setCostId(res?.data?.id62);
              });
          } else {
            const payload = {
              name: e.fields[i].name,
              type_id62: e.fields[i].type,
              tax: e.fields[i].tax,
            };

            dispatch(Cost.updateCost({ id: e.fields[i].id62, payload }));
          }
        }

        setDetail(true);
      });
    },
    [form]
  );

  return (
    <Modal
      title={<h3 className="text-xl font-bold">Form {isUpdate ? 'Edit Biaya' : 'Input Biaya'}</h3>}
      open={open}
      onCancel={() => handleClose()}
      centered
      footer={[
        <CustomButton key="back" type="secondary" onClick={() => handleClose()}>
          Cancel
        </CustomButton>,
        <CustomButton
          key="submit"
          type="primary"
          {...(isUpdate && { form: 'myForm', htmlType: 'submit' })}
          {...(!isUpdate && { style: { display: 'none' } })}>
          Simpan
        </CustomButton>,
      ]}
      width={'70%'}
      destroyOnClose={true}>
      {rerender && (
        <Form
          id="myForm"
          layout="vertical"
          initialValues={{ fields: initialValues }}
          requiredMark={false}
          form={form}
          onFinish={onSubmit}>
          <Form.List name="fields">
            {(fields) => {
              return (
                <>
                  <div className="flex flex-column items-center gap-3 mb-5">
                    <div className="flex items-center justify-evenly w-full gap-3">
                      <Typography.Paragraph
                        className="text-base text-center"
                        style={{ width: '25%' }}>
                        Nama Biaya
                      </Typography.Paragraph>
                      <Typography.Paragraph
                        className="text-base text-center"
                        style={{ width: '25%' }}>
                        Dasar Perhitungan
                      </Typography.Paragraph>
                      <Typography.Paragraph
                        className="text-base text-center"
                        style={{ width: '25%' }}>
                        Pajak
                      </Typography.Paragraph>
                      <Typography.Paragraph
                        className="text-base text-center"
                        style={{ width: '25%' }}>
                        Aksi
                      </Typography.Paragraph>
                    </div>
                  </div>
                  {fields.map((field, index) => (
                    <div key={field.key} className="flex flex-column items-center gap-3 mb-5">
                      <div className="flex items-baseline justify-evenly w-full gap-3">
                        <Form.Item
                          className="text-center"
                          name={[index, 'name']}
                          style={{ width: '25%' }}
                          rules={[
                            {
                              required: true,
                              message: 'Field tidak boleh kosong',
                            },
                          ]}>
                          <CustomInput type="text" className="py-[18px] px-[24px]" />
                        </Form.Item>
                        <Form.Item
                          className="text-center"
                          style={{ width: '25%' }}
                          name={[index, 'type']}
                          rules={[
                            {
                              required: true,
                              message: 'Field tidak boleh kosong',
                            },
                            () => ({
                              validator(_, v) {
                                const bei = costType.find((item) => item.label === 'AUM BEI');

                                if (!bei?.is_bei_param && v === bei?.value) {
                                  return Promise.reject(
                                    new Error(`Anda belum atur parameter untuk BEI`)
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}>
                          <Select
                            {...(isUpdate && { disabled: true })}
                            className="py-[12px] px-[18px] h-full"
                            options={costType}
                            onChange={(value) => {
                              setIsAumBei(
                                !costType
                                  .find((item) => item.value === value)
                                  ?.label.includes('FIXED')
                              );
                              setSource(costType.find((item) => item.value === value)?.label);
                              setType(costType.find((item) => item.value === value)?.enum);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          className="text-center"
                          style={{ width: '25%' }}
                          name={[index, 'tax']}
                          rules={[
                            {
                              required: true,
                              message: 'Field tidak boleh kosong',
                            },
                          ]}>
                          <CustomInput
                            type="text"
                            className="py-[18px] px-[24px] text-center"
                            suffix={<span>%</span>}
                          />
                        </Form.Item>
                        <div className="flex gap-2 justify-center" style={{ width: '25%' }}>
                          {!isUpdate && (
                            <div style={{ position: 'absolute' }}>
                              <TiWarningOutline
                                size={18}
                                className="absolute top-[-14px] left-[-14px] "
                                color="#fdcc00"
                              />
                            </div>
                          )}
                          <Tooltip
                            title="Anda belum mengatur detail cost"
                            placement="topLeft"
                            className="relative">
                            <IoMdSettings
                              style={{ cursor: 'pointer' }}
                              onClick={() => form.submit()}
                            />
                          </Tooltip>
                          {/* <BiTrash style={{ cursor: 'pointer' }} onClick={() => remove(index)} /> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      )}
      <ManagementProductComponent.CostDetailForm
        tax={tax}
        open={detail}
        costId={costId}
        ebaId={ebaId}
        isUpdate={isUpdate}
        type={type}
        sourceClass={source}
        isBei={isAumBei}
        onClose={() => setDetail(false)}
        onCloseParent={() => handleClose()}
      />
    </Modal>
  );
}
