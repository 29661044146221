import React, { useState, useEffect } from 'react';
import { Form, Select, DatePicker } from 'antd';
import { CustomButton, CustomInputNumber } from '../../_shared';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Investment } from '../../../service';
import { Report } from '../../../service';
import dayjs from 'dayjs';

const AddInternalReport = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id') || null;
  const [ClassData, setClassData] = useState([]);

  useEffect(() => {
    dispatch(Investment.getClass({ ebaId: ebaId }))
      .unwrap()
      .then((ress) => setClassData(ress.data));
  }, []);

  const initialValue = {
    eba_class: '',
    ibpa: '',
    date: '',
  };

  const CreateInternalReport = (e) => {
    const payload = {
      eba_id62: ebaId,
      eba_class: e.eba_class,
      ibpa: e.ibpa,
      date: dayjs(e.date).format('YYYY-MM-DD'),
    };
    dispatch(Report.CreateInternalReport(payload));
  };

  return (
    <>
      <Form
        form={form}
        requiredMark={false}
        initialValues={initialValue}
        onFinish={CreateInternalReport}
        layout="vertical"
        className="mt-3">
        <Form.Item
          label="Pilih Tanggal"
          name="date"
          rules={[
            {
              required: true,
              message: 'Field tidak boleh kosong',
            },
          ]}>
          <DatePicker placeholder="Pilih Tanggal" />
        </Form.Item>
        <Form.Item
          label="Pilih Senior Tranches"
          name="eba_class"
          rules={[
            {
              required: true,
              message: 'Field tidak boleh kosong',
            },
          ]}>
          <Select
            placeholder="Pilih Senior Tranches"
            options={ClassData.map((item) => ({
              label: item.name,
              value: item.name,
            }))}
            className="flex items-center bg-white border h-[48px] rounded-lg"
          />
        </Form.Item>
        <Form.Item
          label="Harga Pasar per Senior Tranches (IBPA) (Dalam Persen %)"
          name="ibpa"
          rules={[
            {
              required: true,
              message: 'Field tidak boleh kosong',
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject();
                }
                if (isNaN(value)) {
                  return Promise.reject('The input is not a number');
                }
                return Promise.resolve();
              },
            }),
          ]}>
          <CustomInputNumber
            placeholder="Masukkan harga per senior tranche"
            className="py-2 w-full"
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <div className="flex justify-end">
          <CustomButton type="primary" htmlType="submit">
            Input Reporting
          </CustomButton>
        </div>
      </Form>
    </>
  );
};

export default AddInternalReport;
