import { StatusColor } from '../../components/_shared';
import CustomButton from '../../components/_shared/custom-button';

const columnsFeedbackAcquisition = [
  {
    title: 'Nama Produk',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: 180,
    className: 'font-bold',
  },
  {
    title: 'Feedback',
    dataIndex: 'feedback',
    key: 'feedback',
    align: 'center',
    className: 'font-bold',
    width: 280,
    render: (record) => record || '-',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: 180,
    render: (record) => <StatusColor status={record} />,
  },
  {
    title: 'Aksi',
    key: 'action',
    align: 'center',
    width: 100,
    render: (_, record) => {
      return (
        record?.status?.value !== 0 && <CustomButton type="primary-ghost">Perbaiki</CustomButton>
      );
    },
  },
];

// TODO: delete dummy
const dummyFeedback = [
  {
    name: 'KIK EBA X',
    feedback: 'Data yang diajukan sudah sangat baik dan memenuhi kriteria akusisi data',
    status: { value: 0, text: 'Diterima' },
  },
  {
    name: 'KIK EBA Z',
    feedback: 'Data yang diajukan kurang memenuhi syarat kriteria akusisi data',
    status: { value: 3, text: 'Ditolak' },
  },
];

export { columnsFeedbackAcquisition, dummyFeedback };
