import React, { useState } from 'react';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Eba } from '../../../service';
import DeleteConfirmationModal from '../../_shared/modal-delete-confirm';

export default function DraftAction({ item }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Open, setOpen] = useState(false);

  const onClickDraftEba = () => {
    navigate(
      `/management-product/eba-info?page=management-product&eba-id=${item?.id62}&eba-name=${item.name}&status=${item?.status?.value}`
    );
  };
  const onDelete = () => {
    dispatch(Eba.deleteEba({ id62: item.id62 })).then(() => {
      dispatch(Eba.getListEba({ status_value: 1, search: '' }));
      setOpen(false);
    });
  };
  return (
    <div className="flex gap-5 items-center justify-center">
      <BiPencil className="cursor-pointer text-grey" onClick={onClickDraftEba} />
      <BiTrash className="cursor-pointer text-grey" onClick={() => setOpen(!Open)} />
      <DeleteConfirmationModal open={Open} onClose={() => setOpen(false)} onConfirm={onDelete} />
    </div>
  );
}
