import { Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { Date as Dates } from '../../../helper';

const UpdatedTime = ({ updated = Date.now() }) => {
  if (!updated) return;
  const date = typeof updated.split === 'function' ? updated?.split('.')[0] : updated;
  return (
    <Typography.Paragraph className="text-grey mt-3">
      Diperbarui &nbsp;
      {Dates.parseTimeAgo(moment(date).format('YYYY-MM-DD HH:mm:ss'))}
    </Typography.Paragraph>
  );
};

export default UpdatedTime;
