import TableInformasiTagihan from './table-informasi-tagihan';
import TableInformasiPembayaran from './table-informasi-pembayaran';
import TableInformasiUmum from './table-informasi-umum';
import TableInvestor from './table-investor';
import TableReportInternal from './table-report-internal';
import TableReportProfile from './table-profile';
import AddInternalReport from './add-internal-report';
import LogHistoryInternal from './table-log-history-internal';
import AddExternalReport from './add-external-report';
import LogHistoryExternal from './table-log-history-external';

export default {
  TableReportInternal,
  TableInformasiUmum,
  TableInformasiTagihan,
  TableInformasiPembayaran,
  TableInvestor,
  TableReportProfile,
  AddInternalReport,
  AddExternalReport,
  LogHistoryInternal,
  LogHistoryExternal,
};
