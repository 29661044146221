import { Modal } from 'antd';
import React from 'react';
import { logRatingColumn } from '../../../screens/management-product/components/tableConfig';
import { CustomButton, SimpleTable } from '../../_shared';

export default function AssetClassLogRating({ open, onClose, AssetClassRating }) {
  return (
    <Modal
      title={<h3 className="text-xl font-bold">Rating Information</h3>}
      open={open}
      onCancel={() => onClose()}
      centered
      footer={[
        <CustomButton key="back" type="primary" onClick={() => onClose()}>
          Tutup
        </CustomButton>,
      ]}
      width={'50%'}>
      <SimpleTable headers={logRatingColumn} className="radius">
        {AssetClassRating.length > 0 &&
          AssetClassRating.map((item, i) => (
            <tr key={i} style={{ backgroundColor: '#fff' }}>
              <td className="border text-center">{item?.name}</td>
              <td className="border text-center">{item?.date}</td>
              <td className="border text-center">{item?.maker}</td>
            </tr>
          ))}
        {AssetClassRating.length == 0 && (
          <tr style={{ backgroundColor: '#fff' }}>
            <td colSpan={3} className="border text-center p-4">
              record not found
            </td>
          </tr>
        )}
      </SimpleTable>
    </Modal>
  );
}
