import { DatePicker, Form, Modal, Switch, Typography } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { Constant } from '../../../const';
import { Investment } from '../../../service';
import {
  CustomButton,
  CustomInput,
  CustomInputNumber,
  CustomNotification,
  CustomPopconfirm,
  Select,
} from '../../_shared';
import rulesForm from './rulesFormItem';
import locale from 'antd/es/date-picker/locale/id_ID';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
dayjs.locale('id');

export default function AssetClassSet({ open, onClose, ebaId, gradeId, isEdit, isProduct }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const intervals = [
    {
      value: 1,
      label: 'Bulanan',
    },
    {
      value: 3,
      label: 'Per Quartal',
    },
    {
      value: 12,
      label: 'Per Tahun',
    },
    {
      value: 'custom',
      label: 'Custom',
    },
  ];

  const { coupon, classPeriodDiff, loading, classDetail } = useSelector(
    (state) => state.investment
  );

  const [inter, setInter] = useState(0);
  const [customInter, setCustomInter] = useState([]);
  const [isPersentase, setPersentase] = useState(false);
  const [bulkPercent, setBulkPercentage] = useState({ startPeriod: 0, endPeriod: 0, percent: 0 });
  const [errorBulk, setErrorBulk] = useState('');

  const periodDate = (value) => {
    const startDate = classDetail?.start_date;
    return dayjs(startDate).add(parseInt(value), parseInt(value) === 1 ? 'month' : 'months');
  };

  useEffect(() => {
    if (open) {
      const coupons = coupon?.map((res) => ({
        ...res,
        period_date: periodDate(res.period),
      }));
      form.setFieldValue('fields', coupons);
    }
  }, [open]);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const onSubmit = async (e) => {
    let coupons = [];
    const interval = inter === 'custom' ? customInter : inter;
    if (isNaN(+interval) || +interval < 1) {
      CustomNotification({
        type: Constant.WARNING,
        message: 'Gagal Generate Data',
      });
      return;
    }
    for (let i = 0; i < e?.fields.length; i++) {
      coupons.push({
        interval: interval,
        period: e?.fields[i].period,
        payment: i + 1,
        percentage: parseFloat(e?.fields[i].percentage),
      });
    }

    const payload = {
      grade_id62: gradeId,
      coupon: coupons,
    };

    if (isEdit) {
      await dispatch(Investment.updateCoupon(payload));
    } else {
      await dispatch(Investment.createCoupon(payload));
    }

    await dispatch(Investment.getCoupon({ ebaId: ebaId, gradeId: gradeId }));
    dispatch(Investment.getCouponSummary({ gradeId: gradeId }));
    handleClose();
  };

  const generateList = (addRow, removeRow) => {
    if (classPeriodDiff === 0) {
      CustomNotification({
        type: Constant.WARNING,
        message: 'Jangka waktu senior tranche kurang dari 1 bulan',
      });
      return;
    }
    const exists =
      typeof form.getFieldValue(['fields']) === 'undefined'
        ? 0
        : form.getFieldValue(['fields']).length;
    const indexes = [];
    for (let i = 0; i < exists; i++) {
      indexes.push(i);
    }

    removeRow(indexes);

    const interval = inter === 'custom' ? customInter : inter;
    if (isNaN(+interval) || +interval < 1) {
      CustomNotification({
        type: Constant.WARNING,
        message: 'Gagal Generate Data',
      });
      return;
    }
    let intervalLength = Math.ceil(classPeriodDiff / interval);
    let percent = (100 / intervalLength).toFixed(2);
    let isHundred = 0;
    let period = 0;
    let stack = 1;
    while (intervalLength > 0) {
      period = stack * interval;
      isHundred += Number(percent);
      const lastPeriod = intervalLength === 1;

      if (lastPeriod) {
        percent = (Number(percent) + (100 - isHundred)).toFixed(2);
        isHundred = 100;
      }
      if (period > classPeriodDiff) {
        period = classPeriodDiff;
      }

      addRow({ period: period, percentage: percent, period_date: periodDate(period) });
      stack++;
      intervalLength -= 1;
    }
    CustomNotification({
      type: Constant.SUCCESS,
      message: 'Sukses Generate Data',
    });
  };

  const onChangePersentase = (e) => {
    setPersentase(e);
  };

  const onChangeBulk = (e, f) => {
    setBulkPercentage((prev) => ({ ...prev, [f]: e }));
  };

  const isPassValidateBulkPercent = () => {
    if (!bulkPercent.endPeriod || !bulkPercent.startPeriod || !bulkPercent.percent) {
      setErrorBulk('Salah satu field tidak boleh kosong');
      return false;
    }

    return true;
  };

  const setBulkPercent = () => {
    if (!isPassValidateBulkPercent()) {
      setTimeout(() => {
        setErrorBulk('');
      }, 3000);
      return;
    }
    const copyField = [];
    const fields = form.getFieldValue(['fields']);
    fields.map((res, i) => {
      if (i + 1 >= bulkPercent.startPeriod && i + 1 <= bulkPercent.endPeriod) {
        copyField.push({ ...res, percentage: bulkPercent.percent });
      } else {
        copyField.push(res);
      }
    });
    form.setFieldValue('fields', copyField);
    CustomNotification({
      type: Constant.SUCCESS,
      message: 'Sukses Atur Persentase',
    });
  };

  const handlePeriodChange = (e, i) => {
    const value = e.target.value || 0;
    form.setFields([
      {
        name: ['fields', i, 'period_date'],
        value: periodDate(value),
      },
    ]);
  };

  return (
    <Modal
      title={
        <h3 className="text-xl font-bold">
          Pengaturan Periode Pembayaran Kupon Senior Tranches {classDetail?.name} dan Peluruhan
          Pokok
        </h3>
      }
      open={open}
      onCancel={handleClose}
      centered
      destroyOnClose={true}
      footer={[
        <div className="py-5" key="footer">
          <CustomButton key="back" type="primary-ghost" onClick={() => handleClose()}>
            Batal
          </CustomButton>
          <CustomButton
            key="submit"
            type="primary"
            form="myForm"
            htmlType="submit"
            disabled={isProduct}
            loading={loading}>
            Simpan
          </CustomButton>
        </div>,
      ]}
      width={'70%'}
      style={{ height: 800 }}>
      <Form
        id="myForm"
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={onSubmit}
        initialValues={{ fields: [] }}>
        <Form.List name="fields">
          {(fields, { add, remove }) => {
            return (
              <>
                <Form.Item
                  label={<label className="font-semibold text-base ">Interval</label>}
                  className="mb-6">
                  <div className="flex flex-row items-center gap-3">
                    <Select
                      value={isEdit ? Number(coupon[0].interval) : inter}
                      placeholder="Pilih Interval"
                      className="flex-1 p-[10px] h-[52px]"
                      options={intervals}
                      disabled={isEdit}
                      onChange={(v) => setInter(v)}
                    />
                    {inter === 'custom' && (
                      <CustomInputNumber
                        className="flex-1 p-[10px]"
                        onChange={(v) => setCustomInter(v)}
                        placeholder="Interval"
                      />
                    )}
                    {!isEdit && (
                      <CustomButton type="primary" onClick={() => generateList(add, remove)}>
                        Generate
                      </CustomButton>
                    )}
                  </div>
                </Form.Item>
                <div className="flex space-x-3 mb-3">
                  <Typography.Paragraph className="text-base">
                    Ubah Semua Persentase
                  </Typography.Paragraph>
                  <Switch onChange={onChangePersentase} />
                </div>
                {isPersentase && (
                  <Fragment>
                    <div className="flex w-full justify-between items-center space-x-3">
                      <Form.Item label="Pembayaran ke">
                        <CustomInputNumber
                          onChange={(e) => onChangeBulk(e, 'startPeriod')}
                          className="w-full py-[14px] px-[12px]"
                          min={1}
                        />
                      </Form.Item>
                      <Form.Item label="Sampai Pembayaran ke">
                        <CustomInputNumber
                          onChange={(e) => onChangeBulk(e, 'endPeriod')}
                          className="w-full py-[14px] px-[12px]"
                          min={1}
                        />
                      </Form.Item>
                      <Form.Item label="Persentase">
                        <CustomInputNumber
                          onChange={(e) => onChangeBulk(e, 'percent')}
                          className="w-full py-[14px] px-[12px]"
                          max={100}
                          min={0}
                        />
                      </Form.Item>
                      <CustomButton
                        type="primary"
                        height="48px"
                        onClick={setBulkPercent}
                        disabled={isProduct}>
                        Atur
                      </CustomButton>
                    </div>
                    {errorBulk && (
                      <Typography.Paragraph className="text-primary text-xs">
                        {errorBulk}
                      </Typography.Paragraph>
                    )}
                  </Fragment>
                )}
                <div className="flex flex-column items-center gap-3 mb-5">
                  <div className="flex items-center justify-evenly w-full">
                    <Typography.Paragraph className="text-base text-center w-1/4">
                      Pembayaran ke
                    </Typography.Paragraph>
                    <Typography.Paragraph className="text-base text-center w-1/4">
                      Periode
                    </Typography.Paragraph>
                    <Typography.Paragraph className="text-base text-center w-1/4">
                      Bulan Periode
                    </Typography.Paragraph>
                    <Typography.Paragraph className="text-base text-center w-1/4">
                      Persentase Peluruhan Pokok
                    </Typography.Paragraph>
                    {!isEdit && (
                      <Typography.Paragraph className="text-base text-center w-1/4">
                        Aksi
                      </Typography.Paragraph>
                    )}
                  </div>
                </div>
                <div className="max-h-[460px] overflow-y-scroll custom-scrollbar pr-1">
                  {fields.map((field, index) => (
                    <div
                      key={field.key}
                      className="flex items-baseline justify-evenly w-full gap-3 mb-4">
                      <Typography.Paragraph className="text-base text-center w-1/4">
                        {index + 1}
                      </Typography.Paragraph>
                      <Form.Item
                        className="text-center w-1/4"
                        name={[index, 'period']}
                        validateFirst
                        rules={rulesForm.rulesAssetPeriod({
                          interval: inter === 'custom' ? customInter : inter,
                          periodDiff: classPeriodDiff,
                          index: index,
                        })}>
                        <CustomInput
                          disabled={isEdit && coupon[index].is_paid}
                          className="py-[18px] px-[24px]"
                          onChange={(e) => handlePeriodChange(e, index)}
                        />
                      </Form.Item>
                      <Form.Item
                        className="text-center w-1/4"
                        name={[index, 'period_date']}
                        validateFirst
                        dependencies={[['fields', field.key, 'period']]}>
                        <DatePicker
                          className="custom-input rounded py-[18px] bg-white px-[12px] w-full"
                          placeholder="MMMM YYYY"
                          picker="month"
                          format="MMMM YYYY"
                          locale={locale}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item
                        className="text-center w-1/4"
                        name={[index, 'percentage']}
                        validateFirst
                        dependencies={[['fields', field.key, 'percentage']]}
                        rules={rulesForm.rulesAssetPercent}>
                        <CustomInput
                          disabled={isEdit && coupon[index].is_paid}
                          suffix={<span>%</span>}
                          className="py-[18px] px-[24px]"
                        />
                      </Form.Item>
                      <CustomPopconfirm
                        placement="topRight"
                        title="Hapus Periode"
                        description="Anda yakin akan hapus periode ini?"
                        onConfirm={() => remove(field.name)}>
                        {!isEdit && <BsTrash className="cursor-pointer w-1/4" />}
                      </CustomPopconfirm>
                    </div>
                  ))}
                </div>

                <div className="mt-2 pb-5">
                  <CustomButton
                    type="primary-ghost"
                    disabled={isEdit}
                    className="group justify-center items-center flex w-full space-x-2"
                    onClick={() => add()}
                    icon={
                      <BsPlusCircleFill
                        size={20}
                        className={
                          coupon?.length === 0 || isEdit
                            ? ''
                            : 'text-primary group-hover:text-white'
                        }
                      />
                    }>
                    Input Pembayaran
                  </CustomButton>
                </div>
              </>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
}
