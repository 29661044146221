import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CustomTable, ManagementUserComponent } from '..';
import { CustomButton } from '../_shared';
import { Util } from '../../helper';

function UserData({ items, total, columns, loading, fetchData, group, paginationSync }) {
  const [open, setOpen] = useState(false);
  const { isSupervisor } = Util.role();

  const paginationChange = (page, pageSize) => {
    fetchData({ limit: 5, offset: (page - 1) * pageSize, group, page });
  };

  return (
    <>
      <div className="flex justify-between items-center gap-5 my-6">
        <h4 className="text-base font-bold">
          Dibawah merupakan informasi mengenai management user
        </h4>
        {isSupervisor ? (
          <></>
        ) : (
          <CustomButton type="primary" onClick={() => setOpen(true)}>
            Input User
          </CustomButton>
        )}
      </div>
      <CustomTable.TableGlobal
        dataSource={items}
        columns={columns}
        loading={loading}
        pagination={{
          pageSize: 5,
          onChange: paginationChange,
          current: paginationSync?.page,
          total: total,
          showSizeChanger: false,
        }}
      />
      <ManagementUserComponent.UserForm
        title="Input User"
        open={open}
        values={{}}
        isUpdate={false}
        group={group}
        onClose={() => setOpen(false)}
      />
    </>
  );
}

UserData.propTypes = {
  items: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchData: PropTypes.func.isRequired,
  group: PropTypes.string,
};

export default UserData;
