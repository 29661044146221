import { Form, Modal, Result, Typography } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Constant } from '../../const';
import { Login } from '../../service';
import Page from '../page-wrapper';
import { CustomButton, CustomInput, CustomNotification } from '../_shared';

const FormEmailVerification = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { loadingForgot } = useSelector((state) => state.login);

  const [isOpenModal, setOpenModal] = useState(false);

  const sendConfirmationEmail = (value) => {
    dispatch(Login.sendEmailVerification(value.email))
      .unwrap()
      .then(() => {
        setOpenModal(true);
      })
      .catch(() => {
        CustomNotification({ type: Constant.ERROR, message: 'Gagal kirim OTP' });
      });
  };

  const closeModal = () => {
    setOpenModal(false);
    navigate('?section=otp-input');
  };

  const backToLogin = () => {
    navigate('/sign-in');
  };

  return (
    <Page title="Email verification">
      <div className="w-[460px] flex flex-col px-10">
        <Typography.Paragraph className="font-bold text-[40px]">
          Forgot Password
        </Typography.Paragraph>
        <Typography.Paragraph>
          We need to sent verification code to your email to retrieve your password
        </Typography.Paragraph>
        <Form layout="vertical" requiredMark={false} onFinish={sendConfirmationEmail}>
          <Form.Item
            label="Email"
            name="email"
            className="mt-16"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ]}>
            <CustomInput
              placeholder="yourmail@mail.com"
              type="email"
              className="mt-2 py-[18px] px-[24px]"
            />
          </Form.Item>

          <Form.Item
            label="Confirm Email"
            name="confirmEmail"
            className="mb-1"
            hasFeedback
            dependencies={['email']}
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('email') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two email that you entered do not match!'));
                },
              }),
            ]}>
            <CustomInput
              placeholder="yourmail@mail.com"
              type="email"
              className="mt-2 py-[18px] px-[24px]"
            />
          </Form.Item>
          <div className="flex justify-end">
            <CustomButton type="link" className="self-end text-sm" onClick={backToLogin}>
              Kembali
            </CustomButton>
          </div>

          <CustomButton
            type="primary"
            block
            className="mt-20"
            htmlType="submit"
            loading={loadingForgot}>
            Send Verification Code
          </CustomButton>

          <Modal open={isOpenModal} footer={false} closable={false} centered>
            <Result
              status="success"
              title="Kode OTP Telah Dikirim"
              subTitle="Silahkan periksa email Anda"
              extra={[
                <CustomButton key={1} type="primary" className="px-8" onClick={closeModal}>
                  Tutup
                </CustomButton>,
              ]}
            />
          </Modal>
        </Form>
      </div>
    </Page>
  );
};

export default FormEmailVerification;
