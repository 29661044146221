import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomTable } from '../..';
import { Aquisition } from '../../../service';
import { failedColumns } from '../tableConfig';

const FailedInsepection = ({ fileId }) => {
  const dispatch = useDispatch();

  const [submission, setSubmission] = useState({});
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({ limit: 10, offset: 0 });

  useEffect(() => {
    setLoading(true);
    dispatch(
      Aquisition.aquisitionSubmission({
        isPass: 'False',
        fileId: fileId,
        limit: page.limit,
        offset: page.offset,
      })
    )
      .unwrap()
      .then(({ data }) => {
        setSubmission(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page]);

  const paginationChange = (pages, pageSize) => {
    setCurrent(pages);
    setPage({ ...page, offset: (pages - 1) * pageSize });
  };

  return (
    <CustomTable.TableGlobal
      dataSource={submission?.results}
      columns={failedColumns}
      loading={loading}
      pagination={{
        pageSize: 10,
        onChange: paginationChange,
        current,
        total: submission?.count,
        showSizeChanger: false,
      }}
    />
  );
};

export default FailedInsepection;
