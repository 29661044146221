import { SearchOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomButton, CustomInput, Page } from '../../components';
import Table from '../../components/table';
import { Constant } from '../../const';
import { useAuth } from '../../hooks/useAuth';
import { Eba } from '../../service';
import { columnsListProduct } from './components/tableConfig';

const SimulationProduct = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.eba);

  const [page, setPage] = useState({ limit: 10, offset: 0, current: 1 });
  const [searchItem, setSearchItem] = useState('');
  const isOriginator = user.role === Constant.ORIGINATOR;
  const isAdmin = user.role === Constant.ADMIN;

  useEffect(() => {
    const payload = {
      limit: page.limit,
      offset: page.offset,
      is_simulation: 'True',
      search: searchItem,
    };
    dispatch(Eba.getListEba(payload));
  }, [page, searchItem]);

  const onClickEba = (data) => {
    navigate(
      `/management-product/eba-info?page=management-product&eba-name=${data.name}&eba-id=${data.id62}&is_simulation=true`
    );
  };

  const onClickAddProduct = () => {
    navigate('/management-product/eba-info?page=management-product&is_simulation=true');
  };

  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
  };

  const ShowTable = useCallback(() => {
    let columns = columnsListProduct(onClickEba);
    let data = list?.results;
    let count = list?.count;

    return (
      <Table.TableGlobal
        columns={columns}
        dataSource={data}
        totalData={count}
        onChangePagination={paginationChange}
        current={page.current}
        loading={loading}
      />
    );
  }, [list?.results]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setSearchItem(encodeURIComponent(e.target.value));
    }
  };

  return (
    <Page title="Simulasi Produk">
      <div className="flex justify-between items-center my-6">
        <Typography.Paragraph className="font-bold text-3xl">
          {isOriginator ? 'Asset' : 'Simulasi Produk'}
        </Typography.Paragraph>
        {isAdmin && (
          <CustomButton
            type="primary"
            className="
            group
            justify-center
            items-center
            flex
            space-x-2"
            onClick={onClickAddProduct}>
            <BsPlusCircleFill size={20} className="text-white group-hover:text-primary" />
            Buat Simulasi Baru
          </CustomButton>
        )}
      </div>
      <CustomInput
        prefix={<SearchOutlined />}
        className="my-5 p-3 max-w-70vh"
        placeholder="Search Product"
        onKeyDown={handleKeyDown}
      />
      <ShowTable />
    </Page>
  );
};

export default SimulationProduct;
