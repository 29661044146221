import React, { useState } from 'react';
import { Typography, Modal } from 'antd';
import { CustomInput, CustomButton } from '../../_shared';
import { Approval } from '../../../service';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function ApproveDeclineModal({
  open,
  onClose,
  type,
  ebaId,
  page,
  navigate,
  onApproved,
}) {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [note, setNote] = useState('');

  const onSubmit = () => {
    const payload = {
      eba_id62: ebaId,
      page: page,
      description: note,
      approval: type === 'approve',
    };
    dispatch(Approval.SpvApproval(payload)).then(() => {
      page === 'Reinvestment' ? onApproved() : Navigate(navigate);
    });
    onClose();
  };

  return (
    <Modal
      open={open}
      footer={null}
      onCancel={() => {
        onClose();
      }}
      destroyOnClose={true}
      centered
      width={'486px'}>
      <div className="flex flex-col p-6 gap-6 justify-center items-center">
        <img width={80} src="/icons/popup/warningPopup.svg" alt="warning" />
        <Typography.Paragraph className="font-bold text-base text-center w-[354px]">
          {type === 'approve'
            ? 'Apakah anda yakin ingin menyetujui permintaan produk ini?'
            : 'Apakah anda yakin tidak menyetujui permintaan produk ini?'}
        </Typography.Paragraph>
        {type === 'approve' ? (
          <></>
        ) : (
          <CustomInput
            className="w-[369px] h-[96px] resize-none flex justify-start items-start"
            required
            placeholder="Masukan catatan"
            onChange={(e) => setNote(e.target.value)}
          />
        )}
        <div className="flex flex-row-reverse gap-5 mt-5">
          <CustomButton
            onClick={() => onSubmit()}
            type="primary"
            disabled={type === 'approve' ? false : note.length === 0}>
            {type === 'approve' ? 'Setuju' : 'Tolak'}
          </CustomButton>
          <CustomButton onClick={() => onClose()} type="primary-ghost">
            Batalkan
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
}
