import { Form, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Transaction } from '../../../service';
import Buffer from '../../../service/api/buffer';
import { CustomButton, CustomInput, CustomNotification } from '../../_shared';

export default function EquityBufferForm({ open, onClose, isUpdate, data }) {
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldValue('description', data?.module?.desc);
  }, []);

  const handleModalClose = () => {
    form.resetFields();
    onClose();
  };

  const onSubmit = async (e) => {
    const payload = {
      eba_id62: ebaId,
      percent: e.percentage,
      description: e.description,
    };
    setLoading(true);
    if (isUpdate) {
      await dispatch(Buffer.editBuffer(payload))
        .unwrap()
        .then((res) => {
          CustomNotification({
            type: 'success',
            message: 'Success Edit Data',
            description: res.message?.id,
          });
          handleModalClose();
          setLoading(false);
        });
    } else {
      await dispatch(Buffer.createBuffer(payload)).then(() => {
        handleModalClose();
        setLoading(false);
      });
    }
    setTimeout(() => {
      dispatch(Buffer.bufferDetail({ ebaId: ebaId, limit: 5, offset: 0, page: 1 }));
      dispatch(Transaction.getTransaction({ ebaId: ebaId, limit: 5, offset: 0, page: 1 }));
    }, 1000);
    setLoading(false);
  };

  return (
    <Modal
      title={
        <h3 className="text-xl font-bold">
          {isUpdate ? 'Edit Equity Tranche' : 'Input Equity Tranche'}
        </h3>
      }
      open={open}
      onCancel={() => handleModalClose()}
      centered
      footer={[
        <CustomButton key="back" type="secondary" onClick={() => handleModalClose()}>
          Batal
        </CustomButton>,
        <CustomButton key="submit" type="primary" form="myForm" htmlType="submit" loading={loading}>
          Simpan
        </CustomButton>,
      ]}
      width={'50%'}
      destroyOnClose={true}>
      <Form id="myForm" layout="vertical" requiredMark={false} form={form} onFinish={onSubmit}>
        <Form.Item
          label="Persentase"
          name="percentage"
          validateFirst
          rules={[
            {
              required: true,
              message: 'Field tidak boleh kosong',
            },
            () => ({
              validator(_, value) {
                if (isNaN(value)) {
                  return Promise.reject('Hanya masukkan angka');
                }
                if (value <= 0.01) {
                  return Promise.reject('Kurang dari 0.01%');
                }
                if (value > 90) {
                  return Promise.reject('lebih dari 90%');
                }
                return Promise.resolve();
              },
            }),
          ]}>
          <CustomInput
            placeholder="Masukan persentasi"
            type="text"
            className="py-[18px] px-[24px]"
            suffix={<span>%</span>}
          />
        </Form.Item>
        <Form.Item
          label="Deskripsi"
          name="description"
          rules={[
            {
              required: true,
              message: 'Field tidak boleh kosong',
            },
          ]}>
          <CustomInput
            placeholder="Tuliskan deskripsi"
            type="text"
            className="py-[18px] px-[24px]"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
