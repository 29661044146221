import { Pagination } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { ManagementProductComponent } from '../..';
import { Util } from '../../../helper';
import { Eba, Revenue } from '../../../service';
import { CustomButton, SimpleTable } from '../../_shared';

function EarningPercentage({ column, ebaId, items, isProduct }) {
  const { isAdmin } = Util.role();

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    dispatch(Eba.getDetailEba(ebaId));
    dispatch(Revenue.revenuePeriod({ ebaId: ebaId, limit: 5, offset: 0 }))
      .unwrap()
      .then((res) => {
        setTotal(res.data.count);
      });
  }, [ebaId]);

  const paginationChange = (e) => {
    dispatch(Revenue.revenuePeriod({ ebaId: ebaId, limit: 5, offset: (e - 1) * 5 }));
    setCurrent(e);
  };
  return (
    <>
      <SimpleTable headers={column} className="radius">
        {items.length > 0 &&
          items.map((item) => (
            <tr key={item?.id62} style={{ backgroundColor: '#fff' }}>
              <td className="border text-center">{item?.value}</td>
              <td className="border text-center">{item?.period_start}</td>
              <td className="border text-center">{item?.period_end}</td>
              {!isProduct && isAdmin && (
                <td className="border text-center">
                  <ManagementProductComponent.EarningPercentageAction
                    item={item}
                    setCurrent={setCurrent}
                    setTotal={setTotal}
                  />
                </td>
              )}
            </tr>
          ))}
        {items.length == 0 && (
          <tr style={{ backgroundColor: '#fff' }}>
            <td colSpan="4" className="border text-center p-4">
              record not found
            </td>
          </tr>
        )}
      </SimpleTable>
      <Pagination
        current={current}
        pageSize={5}
        total={total}
        onChange={(e) => paginationChange(e)}
        className="flex justify-end mt-4"
      />
      {isAdmin && !isProduct && (
        <CustomButton
          type="primary-ghost"
          className="w-full mt-5 group justify-center items-center flex space-x-2"
          onClick={() => setOpen(true)}>
          <BsPlusCircleFill size={20} className="text-primary group-hover:text-white" />
          Input Earning Percentage
        </CustomButton>
      )}
      <ManagementProductComponent.EarningPercentageForm
        open={open}
        onClose={() => setOpen(false)}
        ebaId={ebaId}
        setCurrent={setCurrent}
        setTotal={setTotal}
      />
    </>
  );
}

EarningPercentage.propTypes = {
  column: PropTypes.array.isRequired,
  ebaId: PropTypes.string.isRequired,
};

export default EarningPercentage;
