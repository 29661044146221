import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const userLogList = createAsyncThunk(
  'get/userLogList',
  async ({ search, limit, offset, timestamp_from, timestamp_to }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `user-log/?search=${search}&limit=${limit}&offset=${offset}&timestamp_from=${timestamp_from}&timestamp_to=${timestamp_to}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userLogDetailList = createAsyncThunk(
  'get/userLogDetailList',
  async ({ id, limit, offset }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `user-log/log-details/?user_id62=${id || ''}&limit=${limit}&offset=${offset}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userLogCashflow = createAsyncThunk(
  'get/userLogCashflow',
  async ({ ebaId, limit, offset, period_date }, { rejectWithValue }) => {
    try {
      return await API.get({
        // eslint-disable-next-line prettier/prettier
        url: `user-log/log-cashflow/?eba_id62=${ebaId}&period_date=${period_date}${limit !== undefined ? `&limit=${limit}` : ''}${offset !== undefined ? `&offset=${offset}` : ''}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const downloadLogbook = createAsyncThunk(
  'get/download',
  async ({ ebaId, ebaName }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `user-log/export-log-cashflow/?eba_id62=${ebaId}`,
        isDownload: true,
        fileName: `Logbook_${ebaName}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default {
  userLogList,
  userLogDetailList,
  userLogCashflow,
  downloadLogbook,
};
