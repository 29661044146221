import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const userList = createAsyncThunk(
  'get/userList',
  async ({ limit, offset, group }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `user/?limit=${limit}&offset=${offset}&group=${group}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userCreate = createAsyncThunk('post/userCreate', async (payload, { rejectWithValue }) => {
  try {
    return await API.post({
      url: `user/`,
      payload,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const userUpdate = createAsyncThunk(
  'put/userCreate',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      return await API.put({
        url: `user/${id}/`,
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userDetail = createAsyncThunk('get/userDetail', async ({ id }, { rejectWithValue }) => {
  try {
    return await API.get({
      url: `user/${id}/`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const userProfile = createAsyncThunk('get/userProfile', async ({ id }, { rejectWithValue }) => {
  try {
    return await API.get({
      url: `user/${id}/`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const userDelete = createAsyncThunk('delete/userDelete', async ({ id }, { rejectWithValue }) => {
  try {
    return await API.deleteRequest({
      url: `user/${id}/`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

export default {
  userList,
  userCreate,
  userUpdate,
  userDetail,
  userDelete,
  userProfile,
};
