/* eslint-disable no-unused-vars */
import { Alert, Popconfirm, Progress, Spin, Typography } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import md5 from 'md5';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { CustomTable } from '../..';
import { Constant, Images } from '../../../const';
import { Date, Format, Util } from '../../../helper';
import usePage from '../../../hooks/usePage';
import { Aquisition, Reference } from '../../../service';
import { setFileBase64, setFileUpload } from '../../../store/reference';
import { CustomButton, CustomMessage, CustomNotification } from '../../_shared';
import { MdFileDownload } from 'react-icons/md';

function UploadAsset({ columns, setLoadingUpload, setPercentProcess, items }) {
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');

  const { isAdmin } = Util.role();
  const { isProduct } = usePage();

  const dispatch = useDispatch();

  const [fileList, setFileList] = useState([]);
  const [loadingSend, setLoadingSend] = useState(false);
  const [payloadDone, setPayloadDone] = useState({ file_name: '', chunk_count: 0, checksum: '' });
  const [progressUpload, setProgressUpload] = useState(0);
  const [hasUpload, setHasUpload] = useState(false);
  const [lookDetail, setLookDetail] = useState(false);
  const [getData, setGetData] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isRefetch, setRefetch] = useState(false);
  const [error, setError] = useState({ isError: false, notes: '' });

  const {
    summaryList,
    loading,
    paginationSync,
    itemTotal: total,
  } = useSelector((state) => state.aquisition);

  const fetchData = ({ limit, offset, page }) => {
    dispatch(Aquisition.aquisitionSummaryList(ebaId))
      .unwrap()
      .then(({ data }) => {
        if (data?.status === 1) {
          // if data still process
          const percent = (data?.count / data?.count_total) * 100;
          setPercentProcess(percent.toFixed(1) + '%');
          setError({ notes: '', isError: false });

          setLoadingUpload(true);
          setTimeout(() => {
            setRefetch(!isRefetch);
            setHasUpload(true);
          }, 3000);
        } else if (data?.status === 2) {
          // there is error when upload data
          setError({ notes: data?.notes, isError: true });
        } else {
          setError({ notes: '', isError: false });
          setGetData(false);
          setLoadingUpload(false);

          dispatch(Aquisition.aquisitionList({ limit, offset, ebaId: ebaId, page }))
            .unwrap()
            .then(({ data }) => {
              if (data?.count > 0) {
                setHasUpload(true);
              }
            });
        }
      })
      .catch(() => setGetData(false));
  };

  useEffect(() => {
    fetchData({ limit: 10, offset: 0, page: 1 });
  }, [ebaId, isRefetch]);

  const fileExt = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/wps-office.xlsx',
    'text/plain',
  ];

  const initUpload = async (name) => {
    await dispatch(
      Reference.chunkUpload({ payload: { file_name: name }, params: '?is_init=true' })
    );
  };

  const chunkUpload = async ({ base64, fileSize, chunkSize, onProgress, file, chunkCount }) => {
    let chunkDone = 0;
    for (let i = 0; i < fileSize; i += chunkSize) {
      const chunk = base64.slice(i, i + chunkSize);

      const payload = {
        file_name: file.name,
        chunk: chunk,
        chunk_no: i / chunkSize,
      };

      // DO: chunk upload
      await dispatch(Reference.chunkUpload({ payload: payload }));
      chunkDone++;
      const percent = Math.round((chunkDone / chunkCount) * 100).toFixed(0);
      onProgress({
        percent: percent,
      });
      setProgressUpload(percent);
    }
  };

  const onRemoveFile = () => {
    setFileList([]);
    setHasUpload(false);
    setLookDetail(false);
  };

  const deleteSummary = async () => {
    setLoadingDelete(true);
    await dispatch(Aquisition.aquisitionSummaryDelete(summaryList?.file?.id62))
      .unwrap()
      .then(() => {
        setFileList([]);
        setHasUpload(false);
        setLookDetail(false);
        setLoadingDelete(false);
      })
      .catch(() => {
        setLoadingDelete(false);
      });
    fetchData({ limit: 10, offset: 0, page: 1 });
  };

  const convertToBase64 = async (file) => {
    let fileSize = 0;
    let base64 = '';
    await Format.getBase64(file)
      .then((res) => {
        fileSize = res?.length;
        base64 = res;
        dispatch(setFileBase64(res));
      })
      .catch(() => {
        CustomMessage({ type: Constant.ERROR, messages: `Failed convert base64. Try again` });
      });

    return { fileSize, base64 };
  };

  // PROPS UPLOAD
  const propsUpload = {
    name: 'file',
    multiple: true,
    accept: fileExt.toString(),
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${percent}%`,
    },
    onRemove: onRemoveFile,
    beforeUpload: (file, listFile) => {
      let isPassed;
      isPassed = Util.validateFileType(file, fileExt);
      if (!isPassed) {
        CustomNotification({
          type: Constant.ERROR,
          message: 'Format file tidak sesuai',
          description: 'Format file yang diterima adalah xlsx, xls, dan txt',
        });
        return isPassed;
      }

      if (listFile.length > 1) {
        CustomNotification({
          type: Constant.ERROR,
          message: 'Pilih File',
          description: 'Hanya 1 file yang dapat diupload ',
        });
        return false;
      }

      setFileList(listFile);

      return isPassed;
    },
    customRequest: async ({ file, onSuccess, onProgress }) => {
      const { name } = file;
      const chunkSize = 16384 * 2;

      // DO: set file to redux
      dispatch(setFileUpload(file));

      // DO: set loading upload
      setLoadingSend(true);

      // DO: convert to base64
      const { fileSize, base64 } = await convertToBase64(file);
      const chunkCount = parseInt(fileSize / chunkSize) + 1;

      // DO: set payload for sent when status done
      setPayloadDone({
        file_name: name,
        checksum: md5(base64),
        chunk_count: chunkCount,
      });

      // DO: init upload
      await initUpload(name);

      //DO: chunk upload
      await chunkUpload({ base64, fileSize, chunkSize, onProgress, file, chunkCount });
      onSuccess(file);
    },

    onChange: (info) => {
      const { status } = info.file;

      if (status === 'done') {
        dispatch(Reference.chunkUpload({ payload: payloadDone, params: '?is_checksum=true' }))
          .unwrap()
          .then((res) => {
            uploadAcquisition(res.data.file_id62);
          })
          .catch(() => {
            CustomMessage({
              type: Constant.ERROR,
              messages: `${info.file.name} file gagal diunggah.`,
            });
            setLoadingSend(false);
            setProgressUpload(0);
          });
      } else if (status === 'error') {
        CustomMessage({ type: Constant.ERROR, messages: `${info.file.name} file upload failed.` });
      }
    },
  };

  // const downloadTemplate = () => {
  //   dispatch(Aquisition.aquisitionDownloadFormatExcel());
  // };

  const uploadAcquisition = (fileId62) => {
    const body = {
      eba_id62: ebaId,
      file_id62: fileId62,
    };
    setLoadingUpload(true);

    dispatch(Aquisition.acquisitionUpload(body))
      .unwrap()
      .then(() => {
        CustomNotification({
          type: Constant.SUCCESS,
          message: 'Sukses Upload',
          description: 'File sedang diproses',
        });
        setTimeout(() => {
          fetchData({ limit: 10, offset: 0, page: 1 });
          // Util.scrollToElement('table-kreditur');
        }, 5000);
        setLoadingSend(false);
        setHasUpload(true);
        setGetData(true);
      })
      .catch((err) => {
        CustomNotification({
          type: Constant.ERROR,
          message: 'Gagal Upload Data',
          description: Util.errorHandle(err),
        });

        setLoadingSend(false);
        setFileList([]);
        setProgressUpload(0);
        setLoadingUpload(false);
        setHasUpload(false);
      });
  };

  const paginationChange = (page, pageSize) => {
    fetchData({ limit: 10, offset: (page - 1) * pageSize, page });
  };

  const exportExcel = () => {
    dispatch(Aquisition.acquisitionExportExcel(ebaId));
  };

  const downloadExample = () => {
    dispatch(Aquisition.aquisitionAssetDownloadFormatExcel());
  };

  return (
    <Spin spinning={loading}>
      {error?.isError && (
        <Alert message="File Error" description={error.notes} type="error" showIcon />
      )}
      {isAdmin && !isProduct && !hasUpload && !loading && (
        <div>
          <div className="flex flex-row-reverse">
            <CustomButton
              type="primary-ghost"
              height="32px"
              onClick={downloadExample}
              className=" group flex justify-center items-center space-x-2">
              Unduh Format Excel
              <MdFileDownload size={20} className="text-primary group-hover:text-white" />
            </CustomButton>
          </div>
          <Dragger {...propsUpload} disabled={loadingSend} fileList={fileList} maxCount={1}>
            {loadingSend ? (
              <Progress
                type="circle"
                percent={progressUpload}
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
              />
            ) : (
              <>
                <div className="ant-upload-drag-icon">
                  <img src={Images.DOCUMENT} alt="upload icon" className="m-auto" />
                </div>
                <p className="ant-upload-hint text-xl">Drag & drop your document here or</p>
                <CustomButton type="primary" className="mt-3">
                  Browse
                </CustomButton>
              </>
            )}
          </Dragger>
        </div>
      )}

      {!isProduct && isAdmin && hasUpload && !getData && (
        <div className="flex justify-between items-center gap-5 my-6 mmm">
          <Typography.Paragraph className="font-bold text-base">
            Kredit Pensiunan Aktif
          </Typography.Paragraph>
          <CustomButton type="primary-ghost" onClick={exportExcel} loading={loading}>
            Export Excel
          </CustomButton>
          {/* <CustomButton
              type="primary-ghost"
              onClick={() => setLookDetail(!lookDetail)}
              className="group justify-center items-center flex space-x-2">
              <AiFillEye size={20} className="text-primary group-hover:text-white" />
              Lihat Detail
            </CustomButton> */}
        </div>
      )}

      {hasUpload && !getData && (
        <>
          {isProduct && (
            <div className="flex justify-between items-center gap-5 my-6 mmm">
              <Typography.Paragraph className="font-bold text-base">
                Kredit Pensiunan Aktif
              </Typography.Paragraph>
              <CustomButton type="primary-ghost" onClick={exportExcel} loading={loading}>
                Export Excel
              </CustomButton>
              {/* <CustomButton
              type="primary-ghost"
              onClick={() => setLookDetail(!lookDetail)}
              className="group justify-center items-center flex space-x-2">
              <AiFillEye size={20} className="text-primary group-hover:text-white" />
              Lihat Detail
            </CustomButton> */}
            </div>
          )}
          <div className="mb-6 flex gap-4 flex-row">
            <div className="bg-white p-3 flex flex-col flex-grow rounded-md">
              <Typography.Paragraph className="font-semibold text-base">
                Total Saldo Pinjaman
              </Typography.Paragraph>
              <Typography.Paragraph className="font-semibold text-2xl text-primary">
                Rp. {Format.numberFormat(Math.round(summaryList?.total_balance))}
              </Typography.Paragraph>
            </div>
            <div className="bg-white p-3 flex flex-col flex-grow rounded-md">
              <Typography.Paragraph className="font-semibold text-base">
                Total Angsuran
              </Typography.Paragraph>
              <Typography.Paragraph className="font-semibold text-2xl text-primary">
                Rp. {Format.numberFormat(Math.round(summaryList?.total_installment_payment))}
              </Typography.Paragraph>
            </div>
            <div className="bg-white p-3 flex flex-col flex-grow rounded-md">
              <Typography.Paragraph className="font-semibold text-base">
                Jumlah Debitur
              </Typography.Paragraph>
              <Typography.Paragraph className="font-semibold text-2xl text-primary">
                {Format.numberFormat(Math.round(summaryList?.total_asset))}
              </Typography.Paragraph>
            </div>
          </div>

          <div className="bg-white rounded p-6 flex items-center justify-between my-6">
            <div className="flex text-base font-semibold gap-2 flex-row">
              <img src="/icons/excel/excel.svg" alt="excel" />
              {summaryList?.file?.name}
            </div>
            <div className="font-medium text-base">
              {Math.round(summaryList?.file?.size || 0).toFixed(2)}Mb
            </div>
            <div className="text-gray-500 text-base">
              {Date.fullDate(summaryList?.file?.created_at)}
            </div>
            {!isProduct && isAdmin && (
              <Popconfirm
                placement="bottomRight"
                title={'Anda yakin akan hapus file ini?'}
                description={'Anda perlu upload ulang untuk menampilkan data ini'}
                onConfirm={deleteSummary}
                okText="Ya"
                cancelText="Tidak"
                okType="danger"
                okButtonProps={{ loading: loadingDelete }}>
                <Typography.Text className="text-primary hover:cursor-pointer text-base underline font-bold">
                  Hapus
                </Typography.Text>
              </Popconfirm>
            )}
          </div>
        </>
      )}

      {!error.isError && (
        <CustomTable.TableGlobal
          id="table-kreditur"
          dataSource={items}
          keyRow="loan_number"
          columns={columns}
          loading={loading}
          pagination={{
            pageSize: 10,
            onChange: paginationChange,
            current: paginationSync?.page,
            total: total,
            showSizeChanger: false,
          }}
        />
      )}
    </Spin>
  );
}

export default UploadAsset;
