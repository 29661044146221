import { Progress, Select, Space, Typography, Upload } from 'antd';
import md5 from 'md5';
import React, { useEffect, useState } from 'react';
import { MdFileDownload } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CustomMessage, Page } from '../../components';
import { CustomButton, CustomNotification } from '../../components/_shared';
import { Constant, Images } from '../../const';
import { Format, Util } from '../../helper';
import { Aquisition, Eba, PeriodicPayment, Reference } from '../../service';
import { setFileBase64, setFileUpload } from '../../store/reference';
const { Dragger } = Upload;

const UploadDataOriginator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const idEba = searchParams.get('eba-id');
  const approveId = searchParams.get('approve-id');
  const tab = searchParams.get('tab');

  const { approvalFile } = useSelector((state) => state.aquisition);
  const { loading } = useSelector((state) => state.reference);
  const { detail } = useSelector((state) => state.eba);
  const ebaName = detail?.name;

  const breadcrumbs = [
    { name: 'Management Produk', href: '/management-product' },
    { name: ebaName, href: `/assets?page=product&eba-id=${idEba}&eba-name=${ebaName}` },
    { name: 'Upload Data', href: null },
  ];

  const fileExt = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/wps-office.xlsx',
    'text/plain',
  ];
  const typeList = [
    { key: 1, value: 'Update Data' },
    { key: 2, value: 'Data Akuisisi' },
  ];

  const dispatch = useDispatch();

  const defaultSelect = tab == 2 ? typeList[1].value : typeList[0].value;

  const [payloadDone, setPayloadDone] = useState({ file_name: '', chunk_count: 0, checksum: '' });
  const [loadingSend, setLoadingSend] = useState(false);
  const [uploadType, setUploadType] = useState(defaultSelect);
  const [fileList, setFileList] = useState([]);
  const [progressUpload, setProgressUpload] = useState(0);
  // const [transactionDate, setTransactionDate] = useState('');

  const convertToBase64 = async (file) => {
    let fileSize = 0;
    let base64 = '';
    await Format.getBase64(file)
      .then((res) => {
        fileSize = res?.length;
        base64 = res;
        dispatch(setFileBase64(res));
      })
      .catch(() => {
        CustomMessage({ type: Constant.ERROR, messages: `Failed convert base64. Try again` });
      });

    return { fileSize, base64 };
  };

  const initUpload = async (name) => {
    await dispatch(
      Reference.chunkUpload({ payload: { file_name: name }, params: '?is_init=true' })
    );
  };

  const chunkUpload = async ({ base64, fileSize, chunkSize, onProgress, file, chunkCount }) => {
    let chunkDone = 0;
    for (let i = 0; i < fileSize; i += chunkSize) {
      const chunk = base64.slice(i, i + chunkSize);

      const payload = {
        file_name: file.name,
        chunk: chunk,
        chunk_no: i / chunkSize,
      };

      // DO: chunk upload
      await dispatch(Reference.chunkUpload({ payload: payload }));

      chunkDone++;
      const percent = Math.round((chunkDone / chunkCount) * 100).toFixed(0);
      onProgress({
        percent: percent,
      });
      setProgressUpload(percent);
    }
  };

  const uploadAcquisition = (fileId62) => {
    const body = {
      approval_id62: approvalFile?.id62 || approveId,
      file_id62: fileId62,
    };
    dispatch(Aquisition.aquisitionSubmissionNewData(body))
      .unwrap()
      .then(() => {
        CustomNotification({
          type: Constant.SUCCESS,
          message: 'Sukses Upload',
          description: 'File sukses diunggah',
        });
        setLoadingSend(false);
        navigate(`/assets/request-acquisition${location.search}&file-id=${fileId62}&tab=${tab}`);
        setProgressUpload(0);
      })
      .catch(() => {
        setLoadingSend(false);
        setFileList([]);
        setProgressUpload(0);
      });
  };

  const uploadUpdateData = (fileId62) => {
    const body = {
      file_id62: fileId62,
      eba_id62: idEba,
      // transaction_date: transactionDate,
    };
    dispatch(PeriodicPayment.periodicUpload(body))
      .unwrap()
      .then(() => {
        CustomNotification({
          type: Constant.SUCCESS,
          message: 'Sukses Upload',
          description: 'File sukses diunggah',
        });
        setLoadingSend(false);
        navigate(`/assets/update-data${location.search}&file-id=${fileId62}&is_publish=false`);
        setProgressUpload(0);
      })
      .catch(() => {
        setProgressUpload(0);
        setLoadingSend(false);
        setFileList([]);
      });
  };

  const onChangeType = (e) => {
    if (!approveId && e === typeList[1].value) {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Tidak Ada Request Akuisisi',
        description: 'Data request akuisisi tidak ada atau sudah diupload',
      });
      return;
    }
    setUploadType(e);
  };

  const downloadFormatExcel = () => {
    if (uploadType === typeList[0].value) {
      dispatch(PeriodicPayment.periodicDownloadFormatExcel());
      return;
    }
    dispatch(Aquisition.aquisitionDownloadFormatExcel());
  };

  const onRemoveFile = () => {
    setFileList([]);
  };

  // const onChangeDate = (_, date) => {
  //   setTransactionDate(date);
  // };

  // PROPS UPLOAD
  const propsUpload = {
    name: 'file',
    multiple: true,
    accept: fileExt.toString(),
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${percent}%`,
    },
    onRemove: onRemoveFile,
    beforeUpload: (file, listFile) => {
      let isPassed;
      isPassed = Util.validateFileType(file, fileExt);

      // if (!transactionDate && uploadType === typeList[0].value) {
      //   CustomNotification({
      //     type: Constant.ERROR,
      //     message: 'Belum pilih tanggal transaksi',
      //   });
      //   return false;
      // }

      if (!(approvalFile?.id62 || approveId) && uploadType !== typeList[0].value) {
        CustomNotification({
          type: Constant.ERROR,
          message: 'Tidak Ada Request Akuisisi',
          description: 'Data request akuisisi tidak ada atau sudah diupload',
        });
        return false;
      }

      if (!isPassed) {
        CustomNotification({
          type: Constant.ERROR,
          message: 'Format File Tidak Sesuai',
          description: 'Format file yang diterima adalah xlsx, xls, dan txt',
        });
        return isPassed;
      }

      if (listFile.length > 1) {
        CustomNotification({
          type: Constant.ERROR,
          message: 'Pilih File',
          description: 'Hanya 1 file yang dapat diupload ',
        });
        return false;
      }

      setFileList(listFile);

      return isPassed;
    },
    customRequest: async ({ file, onSuccess, onProgress }) => {
      const { name } = file;
      const chunkSize = 16384 * 6;

      // DO: set file to redux
      dispatch(setFileUpload(file));

      // DO: convert to base64
      const { fileSize, base64 } = await convertToBase64(file);
      const chunkCount = parseInt(fileSize / chunkSize) + 1;

      // DO: set payload for sent when status done
      setPayloadDone({
        file_name: name,
        checksum: md5(base64),
        chunk_count: chunkCount,
      });

      // DO: init upload
      await initUpload(name);

      //DO: chunk upload
      await chunkUpload({ base64, fileSize, chunkSize, onProgress, file, chunkCount });
      onSuccess(file);
    },

    onChange: async (info) => {
      const { status } = info.file;

      if (status === 'done') {
        // DO: store file
        await dispatch(Reference.chunkUpload({ payload: payloadDone, params: '?is_checksum=true' }))
          .unwrap()
          .then((res) => {
            setLoadingSend(true);
            if (uploadType === typeList[0].value) {
              uploadUpdateData(res.data.file_id62);
            } else {
              uploadAcquisition(res.data.file_id62);
            }
          })
          .catch(() => {
            CustomMessage({
              type: Constant.ERROR,
              messages: `${info.file.name} file gagal diunggah.`,
            });
            setProgressUpload(0);
          });
      } else if (status === 'error') {
        CustomMessage({ type: Constant.ERROR, messages: `${info.file.name} file upload failed.` });
      }
    },
  };

  useEffect(() => {
    dispatch(Eba.getDetailEba(idEba));
  }, [idEba]);

  // const disabledDate = (date) => {
  //   const current = dayjs(date).format('YYYY-MM-DD');
  //   const lastUpdated = dayjs(detail?.last_updated_data).endOf('month').format('YYYY-MM-DD');
  //   return current <= lastUpdated;
  // };

  return (
    <Page
      title="Upload"
      breadcrumbs={breadcrumbs}
      loading={loadingSend}
      textSpin="Menunggu dialihkan">
      <div className="flex justify-between items-center">
        <Typography.Paragraph className="font-bold text-3xl">Masukkan Data</Typography.Paragraph>
        <div className="flex space-x-2">
          <Space>
            <Typography.Paragraph className="font-bold text-base">Tipe: </Typography.Paragraph>
            <Select
              onChange={onChangeType}
              options={typeList}
              className="select-type"
              value={uploadType}
            />
          </Space>
          <CustomButton
            type="primary-ghost"
            height="32px"
            onClick={downloadFormatExcel}
            className="
            group
            flex
            justify-center
            items-center
            space-x-2">
            Unduh Format Excel
            <MdFileDownload size={20} className="text-primary group-hover:text-white" />
          </CustomButton>
        </div>
      </div>
      {/* {uploadType === typeList[0].value && (
        <div className="flex space-x-2 my-5">
          <Space>
            <Typography.Paragraph className="font-bold text-sm">
              Pilih Tanggal:
            </Typography.Paragraph>
            <DatePicker
              onChange={onChangeDate}
              className="custom-input"
              {...((detail?.last_updated_data !== null || detail?.last_updated_data !== '') && {
                disabledDate: (date) => disabledDate(date),
              })}
            />
          </Space>
        </div>
      )} */}
      <div>
        <Dragger {...propsUpload} disabled={loading} fileList={fileList} maxCount={1}>
          {loading || loadingSend ? (
            <Progress
              type="circle"
              percent={progressUpload}
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#87d068',
              }}
            />
          ) : (
            <>
              <div className="ant-upload-drag-icon">
                <img src={Images.DOCUMENT} alt="upload icon" className="m-auto" />
              </div>
              <p className="ant-upload-hint text-xl">Drag & drop your document here or</p>
              <CustomButton type="primary" className="mt-3" disabled={loading}>
                Browse
              </CustomButton>
            </>
          )}
        </Dragger>
      </div>
    </Page>
  );
};

export default UploadDataOriginator;
