import { Util } from '../../../helper';

export const columnsInformasiTagihan = [
  {
    title: 'Kode EBA',
    dataIndex: 'informasi_tagihan',
    align: 'center',
    fixed: true,
    width: 180,
    render: (record) => record?.kode_eba,
  },
  {
    title: 'Kelas EBA',
    dataIndex: 'informasi_tagihan',
    align: 'center',
    width: 180,
    render: (record) => record?.kelas_eba,
  },
  {
    title: 'Akumulasi Tagihan Bunga Yang Tertagih Sampai Akhir Periode Laporan',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_tagihan',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_tagihan_bunga_tertagih_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_tagihan',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_tagihan_bunga_tertagih_non_rp),
      },
    ],
  },
  {
    title: 'Akumulasi Tagihan Denda Yang Tertagih Sampai Akhir Periode Laporan',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_tagihan',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_tagihan_denda_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_tagihan',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_tagihan_denda_non_rp),
      },
    ],
  },
  {
    title: 'Akumulasi Tagihan Pokok Yang Tertagih Sampai Akhir Periode Laporan',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_tagihan',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_tagihan_pokok_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_tagihan',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_tagihan_pokok_non_rp),
      },
    ],
  },
  {
    title: 'Akumulasi Total Tagihan yang Tertagih Sampai Dengan Akhir Periode Laporan ',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_tagihan',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_total_tagihan_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_tagihan',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_total_tagihan_non_rp),
      },
    ],
  },
  {
    title: 'Jumlah Debitur yang terlambat sampai dengan akhir periode pelaporan (Pihak)',
    dataIndex: 'informasi_tagihan',
    align: 'center',
    width: 180,
    render: (record) => Util.renderNumber(record?.jumlah_debitur_terlambat),
  },
  {
    title:
      'Persentase Jumlah Debitur yang terlambat terhadap Total Jumlah Debitur sampai dengan akhir periode pelaporan',
    dataIndex: 'informasi_tagihan',
    align: 'center',
    width: 180,
    render: (record) => record?.persentase_debitur_terlambat,
  },
  {
    title:
      'Akumulasi Nilai Tagihan Bunga Debitur yang terlambat sampai dengan akhir Periode Laporan',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_tagihan',
        align: 'center',
        width: 180,
        render: (record) =>
          Util.renderNumber(record?.akumulasi_nilai_tagihan_bunga_debitur_terlambat_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_tagihan',
        align: 'center',
        width: 180,
        render: (record) =>
          Util.renderNumber(record?.akumulasi_nilai_tagihan_bunga_debitur_terlambat_non_rp),
      },
    ],
  },
  {
    title:
      'Akumulasi Nilai Tagihan Pokok Debitur yang terlambat sampai dengan akhir Periode Laporan',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_tagihan',
        align: 'center',
        width: 180,
        render: (record) =>
          Util.renderNumber(record?.akumulasi_nilai_tagihan_pokok_debitur_terlambat_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_tagihan',
        align: 'center',
        width: 180,
        render: (record) =>
          Util.renderNumber(record?.akumulasi_nilai_tagihan_pokok_debitur_terlambat_non_rp),
      },
    ],
  },
  {
    title:
      'Persentase Nilai Tagihan Bunga yang terlambat terhadap Total Nilai Tagihan Bunga sampai dengan akhir periode pelaporan (%)',
    dataIndex: 'informasi_tagihan',
    align: 'center',
    width: 180,
    render: (record) => record?.persentase_nilai_tagihan_bunga_terlambat,
  },
  {
    title:
      'Persentase Nilai Tagihan Pokok yang terlambat terhadap Total Nilai Tagihan Pokok sampai dengan akhir periode pelaporan (%)',
    dataIndex: 'informasi_tagihan',
    align: 'center',
    width: 180,
    render: (record) => record?.persentase_nilai_tagihan_pokok_terlambat,
  },
];
