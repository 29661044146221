/* eslint-disable prettier/prettier */
import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { User } from '../../service';

const initialState = {
  items: [],
  user: {},
  itemTotal: 0,
  paginationSync: {
    page: 1,
  },
  loading: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    // user list
    builder.addCase(User.userList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(User.userList.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload?.data?.results;
      state.itemTotal = action.payload?.data?.count;
      state.paginationSync.page = action?.meta?.arg?.page;
    });
    builder.addCase(User.userList.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
      state.items = [];
      state.paginationSync.page = 1;
    });
    // user create
    builder.addCase(User.userCreate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(User.userCreate.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Create User',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(User.userCreate.rejected, (state, action) => {
      let msg = '';
      if (typeof action.payload?.email !== 'undefined') {
        msg = action.payload?.email[0];
      } else if (typeof action.payload?.eba_id62 !== 'undefined') {
        msg = action.payload?.eba_id62[0];
      } else {
        msg = 'Failed to create user';
      }
      CustomNotification({
        type: Constant.ERROR,
        message: msg,
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });
    // user Update
    builder.addCase(User.userUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(User.userUpdate.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Update User',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(User.userUpdate.rejected, (state, action) => {
      let msg = '';
      if (typeof action.payload?.email !== 'undefined') {
        msg = action.payload?.email[0];
      } else if (typeof action.payload?.eba_id62 !== 'undefined') {
        msg = action.payload?.eba_id62[0];
      } else {
        msg = 'Failed to update user';
      }
      CustomNotification({
        type: Constant.ERROR,
        message: msg,
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });
    // user detail
    builder.addCase(User.userDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(User.userDetail.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(User.userDetail.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });
    // user profile
    builder.addCase(User.userProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(User.userProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.data;
    });
    builder.addCase(User.userProfile.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });
    // user delete
    builder.addCase(User.userDelete.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(User.userDelete.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Delete User',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(User.userDelete.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed to delete user',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
