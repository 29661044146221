import React from 'react';
import Title from './title';
import { Typography } from 'antd';
import { Format } from '../../helper';

const Buffer = ({ data }) => {
  return (
    <div className="bg-white rounded-xl mb-6 p-6">
      <Title title="Buffer" />
      <div className="flex box-border flex-row gap-6">
        <div className="flex flex-col gap-4 rounded-[10px] w-1/2">
          <div className="flex justify-between p-4 border-[#F1F3F6] border-[1.5px] rounded-[10px] box-border">
            <Typography.Paragraph className="text-base font-bold">
              Buffer Re-investment
            </Typography.Paragraph>
            <Typography.Paragraph className="text-base font-bold text-[#E70A09]">
              Rp. {Format.numberFormat(Math.round(data?.buffer?.buffer_reinvestment))}
            </Typography.Paragraph>
          </div>
          <div className="flex justify-between p-4 border-[#F1F3F6] border-[1.5px] rounded-[10px] box-border">
            <Typography.Paragraph className="text-base font-bold">
              Buffer Settled
            </Typography.Paragraph>
            <Typography.Paragraph className="text-base font-bold text-[#E70A09]">
              Rp. {Format.numberFormat(Math.round(data?.buffer?.buffer_setteld))}
            </Typography.Paragraph>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center rounded-[10px] bg-[#E70A09] w-1/2 flex-grow">
          <Typography.Paragraph className="text-white text-lg font-medium">
            Total Buffer
          </Typography.Paragraph>
          <Typography.Paragraph className="text-white text-2xl font-bold">
            {Format.numberFormat(Math.round(data?.buffer?.total_buffer))}
          </Typography.Paragraph>
        </div>
      </div>
    </div>
  );
};

export default Buffer;
