import { Typography } from 'antd';
import React from 'react';

const Card = ({ title, description, isClick, onClick, cursor = 'cursor-pointer' }) => {
  return (
    <div
      className={`
      group
      border-1
      ${isClick ? 'bg-primary' : 'bg-white'}
      rounded-xl
      p-3
      ${cursor}
      hover:shadow-lg
      `}
      onClick={onClick}>
      <Typography.Paragraph className={`font-bold text-base ${isClick ? 'text-white' : ''} `}>
        {title}
      </Typography.Paragraph>
      {description && (
        <Typography.Paragraph className={`mt-3 font-medium text-xs ${isClick ? 'text-white' : ''}`}>
          {description}
        </Typography.Paragraph>
      )}
    </div>
  );
};

export default Card;
