import { Result } from 'antd';
import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  defer,
  Route,
  useNavigate,
} from 'react-router-dom';
import { AuthenticatedLayout } from './components/layout/AuthenticatedLayout';
import { AuthLayout } from './components/layout/AuthLayout';
import { HomeLayout } from './components/layout/HomeLayout';
import CustomButton from './components/_shared/custom-button';
import { Cookie } from './helper';
import { protectedRoute, unProtectedRoute } from './routes';

const getUserData = () =>
  new Promise((resolve) => {
    const user = Cookie.getCookie('user');
    if (!user?.token) {
      // to enable animation
      setTimeout(() => {
        resolve(null);
      }, 3000);
    } else {
      resolve(user);
    }
  });

const NotFoundPage = () => {
  const navigate = useNavigate();
  const onClickBackHome = () => {
    navigate('/');
  };
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <CustomButton type="primary" onClick={onClickBackHome}>
          Back Home
        </CustomButton>
      }
    />
  );
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ userPromise: getUserData() })}
      errorElement={<NotFoundPage />}>
      <Route element={<HomeLayout />}>
        {unProtectedRoute.map((res, i) => (
          <Route key={i} path={res.path} element={<res.element />} />
        ))}
      </Route>

      <Route element={<AuthenticatedLayout />}>
        {protectedRoute.map((res, i) => (
          <Route key={i} path={res.path} element={<res.element />} />
        ))}
      </Route>
    </Route>
  )
);
