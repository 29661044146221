import { Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { BsBoxSeam } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Page } from '../../components';
import { AcquisitionDataSummary, UpdatedTime } from '../../components/_shared';

const Summary = () => {
  const [searchParams] = useSearchParams();
  const updated = searchParams.get('updated');
  const ebaId = searchParams.get('eba-id');
  const ebaName = searchParams.get('eba-name');
  const status = searchParams.get('status');

  const breadcrumbs = [
    { name: 'Management Produk', href: '/management-product' },
    {
      name: ebaName,
      href: `/assets?page=product&eba-id=${ebaId}&eba-name=${ebaName}`,
    },
    { name: 'Summary Akuisisi', href: null },
  ];

  const { detail } = useSelector((state) => state.eba);

  //   (3, "Data Diajukan Admin"),
  // (4, "Data Ditolak Supervisor"),
  // (5, "Data Diajukan Supervisor"),
  // (6, "Data Ditolak Originator"),
  // (7, "Data Setujui Originator"),

  const textTitle = () => {
    let text = '';
    switch (Number(status)) {
      case 3:
        text = 'Diajukan Admin';
        break;
      case 4:
        text = 'Ditolak Supervisor';
        break;
      case 5:
        text = 'Diajukan Supervisor';
        break;
      case 6:
        text = 'Ditolak Originator';
        break;
      case 7:
        text = 'Disetujui Originator';
        break;

      default:
        text = 'Disetujui Supervisor';
        break;
    }

    return text;
  };

  return (
    <Page title="Summary Approval" breadcrumbs={breadcrumbs}>
      <div className="flex items-center space-x-3">
        <div
          className="
            flex
            items-center
            justify-center
          bg-primary
            rounded-[50px]
            w-[48px]
            h-[48px]
              ">
          <BsBoxSeam size={20} color="#ffffff" className="font-bold" />
        </div>
        <Typography.Paragraph className="font-bold text-3xl">
          {detail?.name} - {moment(updated?.split('.')[0]).format('MMMM YYYY')}
        </Typography.Paragraph>
      </div>
      <UpdatedTime updated={updated} />
      <AcquisitionDataSummary title={textTitle()} />
    </Page>
  );
};

export default Summary;
