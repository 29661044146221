import moment from 'moment';
import { DatePicker, Modal, Result, Typography } from 'antd';
import React, { useState } from 'react';
import { MdWarning } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Constant } from '../../../const';
import { Reinvestment } from '../../../service';
import { CustomNotification } from '../../_shared';
import CustomButton from '../../_shared/custom-button';

const ModalWithdraw = ({ openModal, setOpenModal, id62, endDate }) => {
  const dispatch = useDispatch();

  const { loadingDelete } = useSelector((state) => state.reinvestment);
  const [date, setDate] = useState('');

  const onClickSend = async () => {
    if (!date) {
      CustomNotification({ type: Constant.WARNING, message: 'Masukkan tanggal roll over' });
      return;
    }
    const payload = {
      id62,
      date,
    };
    await dispatch(Reinvestment.withdrawReinvestment(payload));
    setOpenModal(false);
  };

  const onClickCancel = () => {
    setOpenModal(false);
  };
  const onPickDate = (_, dateString) => {
    setDate(dateString);
  };
  const disabledDate = (current) => {
    // disabled current month and before
    return current && current < moment(endDate).endOf('month');
  };

  return (
    <Modal open={openModal} footer={null} closable={false}>
      <Result
        className="p-5"
        icon={
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center bg-primary w-[60px] h-[60px] rounded-[50px]">
              <MdWarning color="white" size={36} />
            </div>
          </div>
        }
        title={
          <Typography.Paragraph className="font-bold text-base">
            Apakah anda yakin ingin roll over ini?
          </Typography.Paragraph>
        }
        extra={
          <div>
            <div className="space-x-5">
              <Typography.Text className="text-left font-bold">
                Pilih Tanggal Roll Over:
              </Typography.Text>
              <DatePicker
                className="custom-input"
                onChange={onPickDate}
                disabledDate={disabledDate}
              />
            </div>
            <div className="space-x-4 mt-6">
              <CustomButton
                type="primary-ghost"
                key="cancel"
                onClick={onClickCancel}
                className="w-24">
                Batalkan
              </CustomButton>
              <CustomButton
                type="primary"
                key="send"
                onClick={onClickSend}
                className="w-24"
                loading={loadingDelete}>
                Setuju
              </CustomButton>
            </div>
          </div>
        }
      />
    </Modal>
  );
};

export default ModalWithdraw;
