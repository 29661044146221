import dayjs from 'dayjs';

const rulesPercentage = [
  {
    required: true,
    message: 'Field tidak boleh kosong',
  },
  () => ({
    validator(_, value) {
      if (!value) {
        return Promise.reject();
      }
      if (isNaN(value)) {
        return Promise.reject('Hanya angka');
      }
      if (value < 0) {
        return Promise.reject('Kurang dari 0');
      }
      if (value > 100) {
        return Promise.reject('Lebih dari 100%');
      }
      return Promise.resolve();
    },
  }),
];

const rulesNominal = [
  {
    required: true,
    message: 'Field tidak boleh kosong',
  },
];
const period = (range, index) => [
  {
    required: true,
    message: 'Field tidak boleh kosong.',
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      const fieldValue = getFieldValue('fields');
      const lastPeriod = Number(fieldValue[index - 1] ? fieldValue[index - 1].period : 0);
      const nextPeriod = Number(fieldValue[index + 1] ? fieldValue[index + 1].period : range);

      if (value < lastPeriod && index === 0) {
        return Promise.reject(new Error(`Periode kurang dari 0`));
      }

      if (value < lastPeriod) {
        return Promise.reject(new Error(`Periode kurang dari periode sebelumnya`));
      }

      if (value > range) {
        return Promise.reject(new Error(`Lebih dari periode maksimal ${range} bulan`));
      }

      if (value > nextPeriod) {
        return Promise.reject(new Error(`Periode lebih dari periode setelahnya`));
      }
      return Promise.resolve();
    },
  }),
];

const periodDate = (range, endDateEba) => [
  () => ({
    validator(_, value) {
      const current = dayjs(value);
      const endDate = dayjs(endDateEba);
      const diff = current.diff(endDate, 'month');

      console.log('range ', range);
      console.log('div ', diff);

      if (diff > 0) {
        return Promise.reject(new Error(`Lebih dari periode maksimal ${range} bulan`));
      }

      return Promise.resolve();
    },
  }),
];

export default {
  rulesPercentage,
  rulesNominal,
  period,
  periodDate,
};
