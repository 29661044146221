import { Spin } from 'antd';
import React, { Fragment } from 'react';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { CustomBreadcrumb, UpdatedTime } from '../_shared';

const Page = ({
  title = '',
  children,
  breadcrumbs = [],
  loading = false,
  textSpin = 'Loading...',
  updated = null,
}) => {
  const titlePrefix = 'Bahana | ';
  useDocumentTitle(`${titlePrefix}${title}`);
  return (
    <Fragment>
      <Spin spinning={loading} tip={textSpin}>
        {breadcrumbs.length > 0 && <CustomBreadcrumb listItem={breadcrumbs} />}
        {updated && <UpdatedTime updated={updated} />}

        {children}
      </Spin>
    </Fragment>
  );
};

export default Page;
