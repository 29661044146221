/* eslint-disable no-unused-vars */
import { Form, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Eba, User } from '../../service';
import { CustomButton, CustomInput, Select } from '../_shared';

function UserForm({ title, open, onClose, values, isUpdate, group }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { optionsProduct } = useSelector((state) => state.eba);

  const [isOriginator, setIsOriginator] = useState(values?.role === 'originator');

  const fetchDomainEmail = (mail) => {
    if (!mail?.includes('@')) return;
    const split = mail?.split('@');
    dispatch(Eba.getEbaDomainEmail('@' + split[1]));
  };

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        name: values?.name || '',
        email: values?.email || '',
        password: '',
        role: values?.role || '',
        product: values?.product || [],
        company: values?.company || '',
      });
      setIsOriginator(values?.role === 'originator');
      fetchDomainEmail(values.email);
    }
  }, [values]);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const reFetch = () => {
    if (group !== 'originator') {
      dispatch(User.userList({ limit: 5, offset: 0, group: 'internal', page: 1 }));
    } else {
      dispatch(User.userList({ limit: 5, offset: 0, group: 'originator', page: 1 }));
    }
    onClose();
  };

  const onSubmit = (e) => {
    if (isUpdate) {
      const body = {
        id: values?.id,
        payload: {
          full_name: e.name,
          email: e.email,
          role: e.role,
          company: e.company,
          group: e.role !== 'originator' ? 'internal' : 'originator',
          eba_id62s: e.product,
        },
      };
      dispatch(User.userUpdate(body))
        .unwrap()
        .then(() => reFetch());
    } else {
      const payload = {
        full_name: e.name,
        email: e.email,
        password: e.password,
        role: e.role,
        company: e.company,
        group: e.role !== 'originator' ? 'internal' : 'originator',
        eba_id62: e.product,
      };

      dispatch(User.userCreate(payload))
        .unwrap()
        .then(() => reFetch());
    }
  };

  return (
    <Modal
      title={<h3 className="text-xl font-bold">{title}</h3>}
      open={open}
      onCancel={() => handleClose()}
      centered
      destroyOnClose={true}
      footer={[
        <CustomButton key="back" type="secondary" onClick={() => handleClose()}>
          Tutup
        </CustomButton>,
        <CustomButton key="submit" type="primary" form="myForm" htmlType="submit">
          {isUpdate ? 'Simpan' : 'Buat User'}
        </CustomButton>,
      ]}
      width={'50%'}>
      <Form id="myForm" layout="vertical" form={form} requiredMark={false} onFinish={onSubmit}>
        <Form.Item
          label="Name"
          name="name"
          className="mt-5"
          rules={[
            {
              required: true,
              message: 'Field tidak boleh kosong',
            },
          ]}>
          <CustomInput
            placeholder="Masukan nama"
            type="text"
            onChange={(e) => {
              form.setFieldValue('name', e.target.value);
            }}
            className="mt-2 py-[18px] px-[24px]"
          />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          className="mt-5"
          dependencies={['role']}
          validateFirst
          rules={[
            {
              required: true,
              message: 'Field tidak boleh kosong',
            },
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            () => ({
              async validator(_, value) {
                if (!isOriginator) {
                  return Promise.resolve();
                }
                fetchDomainEmail(value);

                return Promise.resolve();
              },
            }),
          ]}>
          <CustomInput
            placeholder="Masukan email"
            type="text"
            onChange={(e) => {
              form.setFieldValue('email', e.target.value);
              fetchDomainEmail(e.target.value);
              if (optionsProduct && optionsProduct.length !== 0) {
                form.setFieldValue('product', []);
              }
            }}
            className="mt-2 py-[18px] px-[24px]"
          />
        </Form.Item>
        {!isUpdate && (
          <Form.Item
            label="Password"
            name="password"
            className="mb-1"
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
            ]}>
            <Input.Password
              placeholder="Masukan Password"
              onChange={(e) => {
                form.setFieldValue('password', e.target.value);
              }}
              className="custom-input rounded mt-2 py-[18px] px-[24px]"
            />
          </Form.Item>
        )}
        <Form.Item
          label="Role"
          name="role"
          className="mb-1"
          rules={[
            {
              required: true,
              message: 'Field tidak boleh kosong',
            },
          ]}>
          <Select
            placeholder="Pilih Role"
            className="mt-2 py-[14px] pl-[13px] h-[60px]"
            onChange={(value) => {
              setIsOriginator(value === 'originator' ? true : false);
              form.setFieldValue('role', value);
            }}
            options={[
              {
                value: 'admin',
                label: 'Admin',
              },
              {
                value: 'supervisor',
                label: 'Supervisor',
              },
              {
                value: 'originator',
                label: 'Originator',
              },
            ]}
          />
        </Form.Item>
        {isOriginator && (
          <Form.Item
            label="Produk"
            name="product"
            className="mb-1"
            dependencies={['email']}
            disabled={optionsProduct?.length === 0}>
            <Select
              mode="multiple"
              placeholder="Pilih Produk"
              className="mt-2 py-[14px] px-[20px]"
              options={optionsProduct}
              filterOption={(input, product) =>
                (product?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        )}
        <Form.Item
          label="Company"
          name="company"
          className="mt-5"
          rules={[
            {
              required: true,
              message: 'Field tidak boleh kosong',
            },
          ]}>
          <CustomInput
            placeholder="Masukan nama company"
            type="text"
            onChange={(e) => {
              form.setFieldValue('company', e.target.value);
            }}
            className="mt-2 py-[18px] px-[24px]"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

UserForm.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  values: PropTypes.object,
  isUpdate: PropTypes.bool,
};

export default UserForm;
