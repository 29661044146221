import { Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Format } from '../../helper';
import { Cashflow } from '../../service';

const CashflowCount = ({ ebaId, approvalId, installmentTotal }) => {
  const dispatch = useDispatch();
  const { selectedRowKeys } = useSelector((state) => state.aquisition);
  const { generate } = useSelector((state) => state.cashflow);
  const [cashflowDifference, setCashflowDifference] = useState(0);

  useEffect(() => {
    dispatch(Cashflow.cashflowGenerate({ ebaId, approvalId, rowKey: selectedRowKeys }))
      .unwrap()
      .then(({ data }) => {
        const difference = data?.total_installment_asset - Number(installmentTotal);
        setCashflowDifference(difference);
      });
  }, [selectedRowKeys, cashflowDifference]);

  const CashflowCallback = useCallback(() => {
    const textColor =
      generate?.total_installment_asset >= installmentTotal ? 'text-green_main' : '';
    return (
      <>
        <div className="flex justify-between border-b-1 p-3 ">
          <Typography.Text className="text-grey">Total Angsuran yang Diakuisisi</Typography.Text>
          <Typography.Text className="text-grey">Jumlah Debitur</Typography.Text>
        </div>

        <div className="flex justify-between border-b-1 px-3 py-6 bg-white ">
          <Typography.Text className={`text-4xl ${textColor}`}>
            Rp {Format.numberFormat(Math.round(generate?.total_installment_asset))}
            <Typography.Text className="text-grey">
              {' '}
              ( Selisih: Rp {Format.numberFormat(Math.round(cashflowDifference))} )
            </Typography.Text>
          </Typography.Text>
          <Typography.Text className="text-4xl">{generate?.total_debtor} Orang</Typography.Text>
        </div>
      </>
    );
  }, [generate]);

  return <CashflowCallback />;
};

export default CashflowCount;
