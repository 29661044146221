import React, { useEffect, useState } from 'react';
import { CustomTable } from '../..';
import { assetClassDetailColumn } from '../../../screens/management-product/components/tableConfig';
import { useDispatch, useSelector } from 'react-redux';
import { Util } from '../../../helper';
import usePage from '../../../hooks/usePage';
import { Investment } from '../../../service';
import { Alert } from 'antd';

const InvestorList = ({ gradeId }) => {
  const dispatch = useDispatch();
  const { isProduct } = usePage();
  const { isSupervisor, isAdmin } = Util.role();

  const { investor, loadingInvestor } = useSelector((state) => state.investment);

  const [isRefetch, setRefetch] = useState(false);
  const [percentProcess, setPercentProcess] = useState('');
  const [error, setError] = useState({ isError: false, notes: '' });

  useEffect(() => {
    dispatch(Investment.investorList({ gradeId: gradeId }))
      .unwrap()
      .then(({ data }) => {
        if (data?.status === 1) {
          // if data still process
          const percent = (data?.count / data?.count_total) * 100;
          setPercentProcess(percent.toFixed(1) + '%');

          setTimeout(() => {
            setRefetch(!isRefetch);
          }, 3000);
        } else if (data?.status === 2) {
          // there is error when upload data
          setError({ notes: data?.notes, isError: true });
        }
      })
      .catch(() => {});
  }, [isRefetch]);

  return (
    <div className="mb-5">
      {error?.isError ? (
        <Alert message="Error upload" description={error.notes} type="error" showIcon />
      ) : (
        <CustomTable.TableGlobal
          dataSource={investor?.length > 0 ? investor : []}
          columns={assetClassDetailColumn(isSupervisor, isProduct && isAdmin)}
          loading={loadingInvestor}
          tipLoading={
            <>
              <p>Data sedang diproses</p>
              <p>{percentProcess}</p>
            </>
          }
          scroll={{ x: 1200 }}
        />
      )}
    </div>
  );
};

export default InvestorList;
