import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Cost } from '../../service';

const initialState = {
  items: [],
  itemTotal: 0,
  paginationSync: {
    page: 1,
  },
  loading: false,
  isBulkUpdating: false,
};

export const costSlice = createSlice({
  name: 'cost',
  initialState,
  reducers: {
    // change state of isBulkUpdating
    setIsBulkUpdating: (state, action) => {
      state.isBulkUpdating = action.payload;
    },
  },
  extraReducers: (builder) => {
    // cost list
    builder.addCase(Cost.getCost.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cost.getCost.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(Cost.getCost.rejected, (_, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
    });

    // cost summary
    builder.addCase(Cost.getCostSummary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cost.getCostSummary.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload?.data?.results;
      state.itemTotal = action.payload?.data?.count;
      state.paginationSync.page = action?.meta?.arg?.page;
    });
    builder.addCase(Cost.getCostSummary.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
      state.items = [];
      state.paginationSync.page = 1;
    });

    // cost dashboard
    builder.addCase(Cost.getCostDashboard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cost.getCostDashboard.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload?.data?.results;
      state.itemTotal = action.payload?.data?.count;
      state.paginationSync.page = action?.meta?.arg?.page;
    });
    builder.addCase(Cost.getCostDashboard.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
      state.items = [];
      state.paginationSync.page = 1;
    });

    // cost detail
    builder.addCase(Cost.getCostDetailByMonth.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cost.getCostDetailByMonth.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload?.data?.results;
      state.itemTotal = action.payload?.data?.count;
      state.paginationSync.page = action?.meta?.arg?.page;
    });
    builder.addCase(Cost.getCostDetailByMonth.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
      state.items = [];
      state.paginationSync.page = 1;
    });

    // create cost
    builder.addCase(Cost.createCost.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cost.createCost.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Create Cost',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Cost.createCost.rejected, (state, action) => {
      const msg = 'Failed to create cost';

      CustomNotification({
        type: Constant.ERROR,
        message: msg,
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // update cost
    builder.addCase(Cost.updateCost.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cost.updateCost.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(Cost.updateCost.rejected, (state, action) => {
      const msg = 'Failed to update cost';

      CustomNotification({
        type: Constant.ERROR,
        message: msg,
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // update cost detail
    builder.addCase(Cost.updateCostDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cost.updateCostDetail.fulfilled, (state, action) => {
      state.loading = false;
      // prevent multiple notification when bulk update
      if (!state.isBulkUpdating) {
        CustomNotification({
          type: Constant.SUCCESS,
          message: 'Success Update Cost detail',
          description: action?.payload?.message?.id,
        });
        state.isBulkUpdating = true;
      }
    });
    builder.addCase(Cost.updateCostDetail.rejected, (state, action) => {
      const msg = 'Failed to update cost detail';

      CustomNotification({
        type: Constant.ERROR,
        message: msg,
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // create cost detail
    builder.addCase(Cost.createCostDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cost.createCostDetail.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Create Cost Detail',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Cost.createCostDetail.rejected, (state, action) => {
      const msg = 'Failed to create cost detail';

      CustomNotification({
        type: Constant.ERROR,
        message: msg,
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // Calculate
    builder.addCase(Cost.calculateAmount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cost.calculateAmount.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Calculate Cost Detail',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Cost.calculateAmount.rejected, (state, action) => {
      const msg = 'Failed to Calculate cost detail';

      CustomNotification({
        type: Constant.ERROR,
        message: msg,
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // GET cost by id
    builder.addCase(Cost.getCostByID.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cost.getCostByID.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Get Cost Detail',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Cost.getCostByID.rejected, (state, action) => {
      const msg = 'Failed to get cost detail';

      CustomNotification({
        type: Constant.ERROR,
        message: msg,
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // delete cost
    builder.addCase(Cost.deleteCost.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cost.deleteCost.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Delete Cost',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Cost.deleteCost.rejected, (state, action) => {
      const msg = 'Failed to delete cost';

      CustomNotification({
        type: Constant.ERROR,
        message: msg,
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // cost type
    builder.addCase(Cost.getCostType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cost.getCostType.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload.data;
    });
    builder.addCase(Cost.getCostType.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // cost detail excel download
    builder.addCase(Cost.DownloadCostDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cost.DownloadCostDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload.data;
    });
    builder.addCase(Cost.DownloadCostDetail.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // update cost actual cash
    builder.addCase(Cost.updateCostActual.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cost.updateCostActual.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(Cost.updateCostActual.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Edit Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });
  },
});

export const { setIsBulkUpdating } = costSlice.actions;
export default costSlice.reducer;
