import { Button } from 'antd';
import React from 'react';

const CustomButton = ({
  id,
  children,
  disabled = false,
  type = 'primary' | 'secondary' | 'link',
  className = '',
  onClick,
  loading = false,
  height = '48px',
  htmlType = 'button',
  style,
  ...props
}) => {
  const randomId = Math.floor(Math.random() * 1000 + 1);
  return (
    <Button
      id={`button-${id || randomId}`}
      onClick={onClick}
      type={type}
      loading={loading}
      disabled={disabled}
      htmlType={htmlType}
      className={`custom-button custom-button-${disabled ? 'disabled' : type} ${className} `}
      style={{ height: height, ...style }}
      {...props}>
      {children}
    </Button>
  );
};

export default React.memo(CustomButton);
