import { Typography, Modal, Pagination } from 'antd';
import React, { useState, useEffect } from 'react';
import { MdFileDownload } from 'react-icons/md';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Page, ReportComponent } from '../../components';
import CustomButton from '../../components/_shared/custom-button';
import { Report } from '../../service';
import { useSearchParams } from 'react-router-dom';

const ReportsInternal = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const ebaName = searchParams.get('eba-name');
  const ebaId = searchParams.get('eba-id');
  const dateFilter = searchParams.get('date');

  const [columns, setColumns] = useState([]);
  const [ibpaColumns, setIbpaColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState({ limit: 10, offset: 0, current: 1 });

  // eslint-disable-next-line no-unused-vars
  const { loadingDownload, internal_length, internal_detail, loading } = useSelector(
    (state) => state.report
  );

  const breadcrumbs = [
    { name: 'Management Produk', href: '/management-product' },
    { name: `Reporting ${ebaName}`, href: `/reports${location.search}` },
    { name: 'Reporting Internal', href: null },
  ];

  const download = () => {
    const payload = {
      report: 'internal',
      ebaId: ebaId,
      period_date: dateFilter,
      period: 'monthly',
    };
    dispatch(Report.ExportExcelInternal(payload));
  };

  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
    setColumns([]);
  };

  useEffect(() => {
    const payload = {
      id: ebaId,
      dateFilter: dateFilter,
      offset: page.offset,
      limit: page.limit,
    };
    dispatch(Report.ReportInternalDetail(payload));
  }, [ebaId, dateFilter, page]);

  useEffect(() => {
    let newCol = [...columns];
    internal_detail?.map((item) => {
      item.payment.map((pay) => {
        if (!newCol.includes(pay.kelas)) {
          newCol.push(pay.kelas);
        }
      });
    });
    setColumns(newCol);
  }, [internal_detail]);

  useEffect(() => {
    let newCol = [...ibpaColumns];
    internal_detail.map((item) => {
      item.ibpa.map((ibpa) => {
        if (!newCol.includes(ibpa.kelas)) {
          newCol.push(ibpa.kelas);
        }
      });
    });
    setIbpaColumns(newCol);
  }, [internal_detail]);

  const children = columns.map((item) => ({
    text: item,
    className: 'border',
  }));

  const ibpaChildren = ibpaColumns.map((item) => {
    return {
      text: `Harga Pasar Wajar ${item} (IBPA)`,
      className: 'border',
      rowSpan: 2,
    };
  });

  return (
    <Page title="Reporting Internal" breadcrumbs={breadcrumbs}>
      <div className="flex justify-between items-center my-6">
        <div>
          <Typography.Paragraph className="font-bold text-3xl">
            Reporting - Internal
          </Typography.Paragraph>
          <Typography.Paragraph className="font-bold text-xl mt-2">
            {dayjs(dateFilter).format('MMMM YYYY')}
          </Typography.Paragraph>
        </div>
        <div className="flex flex-row-reverse gap-4">
          <CustomButton
            type="primary"
            onClick={download}
            loading={loadingDownload}
            className="
            group
            justify-center
            items-center
            flex
            space-x-2">
            <MdFileDownload size={20} className="text-white group-hover:text-primary" />
            Download Excel
          </CustomButton>
          <CustomButton
            type="primary-ghost"
            onClick={() => setOpen(true)}
            loading={loadingDownload}
            className="
            group
            justify-center
            items-center
            flex
            space-x-2">
            <AiOutlineClockCircle size={20} className="text-primary group-hover:text-white" />
            Log History
          </CustomButton>
        </div>
      </div>
      <div className="w-full overflow-auto mb-4 rounded-t-10">
        <ReportComponent.TableReportInternal
          internalDetail={internal_detail}
          loading={loading}
          columns={children}
          ibpaChildren={ibpaChildren}
        />
      </div>
      {internal_length > 10 && (
        <div className="flex justify-end">
          <Pagination
            onChange={paginationChange}
            current={page.current}
            total={internal_length}
            showSizeChanger={false}
          />
        </div>
      )}

      <Modal
        title="Log History - Reporting Internal"
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        width="85%">
        <ReportComponent.LogHistoryInternal />
      </Modal>
    </Page>
  );
};

export default ReportsInternal;
