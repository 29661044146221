import React from 'react';
import { Typography } from 'antd';

const Title = ({ title }) => {
  return (
    <>
      <Typography.Paragraph className="font-bold text-2xl mb-6">{title}</Typography.Paragraph>
    </>
  );
};

export default Title;
