import { Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BsBoxSeam } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Page } from '../../components';
import Table from '../../components/table';
import {
  UpdatedTime,
  CustomButton,
  ModalApprove,
  CustomNotification,
} from '../../components/_shared';
import { Constant } from '../../const';
import { Util } from '../../helper';
import { useAuth } from '../../hooks/useAuth';
import { Aquisition } from '../../service';
import { columns } from '../detail-data-acquisition/components/table';

const Detail = () => {
  const { user } = useAuth();
  const location = useLocation();
  const { isAdmin } = Util.role();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const updated = searchParams.get('updated');
  const status = searchParams.get('status');
  const approvalId = searchParams.get('approval-id');
  const ebaId = searchParams.get('eba-id');
  const ebaName = searchParams.get('eba-name');

  const breadcrumbs = [
    { name: 'Management Produk', href: '/management-product' },
    {
      name: ebaName,
      href: `/assets?page=product&eba-id=${ebaId}&eba-name=${ebaName}`,
    },
    {
      name: 'Summary Akuisisi',
      href: `/summary-acquisition-originator${location.search}`,
    },
    { name: 'Detail Summary Akuisisi', href: null },
  ];

  const { approvalSummaryDetail, loadingDownload, loading } = useSelector(
    (state) => state.aquisition
  );

  const [openModal, setIsOpenModal] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    dispatch(Aquisition.acquisitionApprovalSummaryDetail(approvalId));
  }, []);

  const onClickReject = () => {
    setIsApprove(false);
    setIsOpenModal(true);
  };

  const onClickApprove = () => {
    setIsApprove(true);
    setIsOpenModal(true);
  };

  const onClickCancel = () => {
    setIsOpenModal(false);
  };

  const onClickSend = () => {
    const action = isApprove ? 'approve' : 'reject';

    if (!notes && !isApprove) {
      CustomNotification({
        type: Constant.ERROR,
        message: `Catatan tidak boleh kosong`,
      });
      return;
    }
    const payload = {
      id62: approvalId,
      approve: isApprove,
      notes: notes,
    };
    dispatch(Aquisition.aquisitionApprovalByOriginator(payload))
      .unwrap()
      .then((res) => {
        setIsOpenModal(false);
        CustomNotification({
          type: Constant.SUCCESS,
          message: `Sukses ${action}`,
          description: res?.message?.id || `Data berhasil ${action} oleh ${user.role}`,
        });
        navigate(`/assets?page=product&eba-id=${ebaId}&eba-name=${ebaName}`);
      })
      .catch(() => {
        setIsOpenModal(false);
      });
  };

  const downloadExcel = () => {
    dispatch(Aquisition.acquisitionDownloadDetailApprovalSummaryAcquisition(approvalId));
  };

  return (
    <Page title="Summary Approval" breadcrumbs={breadcrumbs}>
      <div className="flex items-center space-x-3">
        <div
          className="
            flex
            items-center
            justify-center
          bg-primary
            rounded-[50px]
            w-[48px]
            h-[48px]
              ">
          <BsBoxSeam size={20} color="#ffffff" className="font-bold" />
        </div>
        <Typography.Paragraph className="font-bold text-3xl">
          {ebaName} - {moment(updated?.split('.')[0]).format('MMMM YYYY')}
        </Typography.Paragraph>
      </div>
      <UpdatedTime updated={updated?.split('.')[0]} />
      <div className="flex justify-between my-6">
        <Typography.Text className="font-bold text-2xl">Disetujui Supervisor</Typography.Text>
        <div className="flex space-x-3">
          <CustomButton type="primary-ghost" onClick={downloadExcel} loading={loadingDownload}>
            <span className="text-sm">Download Excel</span>
          </CustomButton>
          {!isAdmin && status != 8 && (
            <>
              <CustomButton type="primary-ghost" className="px-10" onClick={onClickReject}>
                <span className=" text-sm">Reject</span>
              </CustomButton>
              <CustomButton
                type="primary"
                className="
            group
            flex
            justify-center
            items-center
            px-10
            space-x-2"
                onClick={onClickApprove}>
                <span className=" text-sm">Approve</span>
              </CustomButton>
            </>
          )}
        </div>
      </div>
      <Table.TableGlobal
        dataSource={approvalSummaryDetail?.results}
        columns={columns}
        rowKey={(record) => record.id62}
        scroll={{ x: 1600 }}
      />
      <ModalApprove
        isApprove={isApprove}
        loading={loading}
        notes={notes}
        onClickCancel={onClickCancel}
        onClickSend={onClickSend}
        openModal={openModal}
        setNotes={setNotes}
      />
    </Page>
  );
};

export default Detail;
