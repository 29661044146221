import { Typography } from 'antd';
import { Date, Format, Util } from '../../helper';

const columnsSummary = (ebaEnd) => [
  {
    title: 'Jumlah Debitur',
    dataIndex: 'debtor',
    width: 110,
    align: 'center',
    render: (record) => <Typography.Paragraph>{Format.numberFormat(record)}</Typography.Paragraph>,
  },
  {
    title: `Total Pokok Debitur Sampai ${ebaEnd} (Rp)`,
    dataIndex: 'principal_installments',
    width: 180,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: `Total Bunga Debitur Sampai ${ebaEnd} (Rp)`,
    dataIndex: 'interest_installments',
    width: 180,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: `Total Pembayaran Debitur Sampai ${ebaEnd} (Rp)`,
    dataIndex: 'total_installment_payments',
    width: 220,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: `Total Cashflow Debitur Sampai ${ebaEnd} (Rp)`,
    dataIndex: 'total_cashflow',
    width: 200,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Umur Rata-Rata (tahun)',
    dataIndex: 'age_average',
    width: 110,
    align: 'center',
    render: (record) => <Typography.Paragraph>{record}</Typography.Paragraph>,
  },
  {
    title: 'Rate Bunga Rata-Rata',
    dataIndex: 'interest_rate_average',
    width: 150,
    align: 'center',
    render: (record) => <Typography.Paragraph>{record}</Typography.Paragraph>,
  },
];

const columnsPoolOfAsset = [
  {
    title: 'No',
    dataIndex: 'id62',
    width: 100,
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'No Pinjaman',
    dataIndex: 'loan_number',
    key: 'loan_number',
    width: 180,
    fixed: 'left',
    align: 'center',
    render: (record) => record,
  },
  // {
  //   title: 'Position Date',
  //   dataIndex: 'position_date',
  //   width: 180,
  //   align: 'center',
  //   render: Date.twoDigitWithDash,
  // },
  {
    title: 'Demografi',
    dataIndex: 'demographics',
    key: 'demografi',
    width: 180,
    align: 'center',
  },
  {
    title: 'Tanggal Awal Kredit',
    dataIndex: 'credit_start_date',
    key: 'credit_start_date',
    width: 180,
    align: 'center',
    render: Date.twoDigitWithDash,
  },
  {
    title: 'Tanggal Jatuh Tempo',
    dataIndex: 'credit_due_date',
    key: 'credit_due_date',
    width: 180,
    align: 'center',
    render: Date.twoDigitWithDash,
  },
  {
    title: 'Jangka Waktu (Bulan)',
    dataIndex: 'creadit_period',
    key: 'creadit_period',
    width: 180,
    align: 'center',
    render: (record) => (
      <Typography.Paragraph className="text-right">{record}</Typography.Paragraph>
    ),
  },
  {
    title: 'Plafond Awal(Rp)',
    dataIndex: 'initial_ceiling_amount',
    key: 'initial_ceiling_amount',
    width: 180,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Saldo Pinjaman(Rp)',
    dataIndex: 'loan_balance',
    key: 'loan_balance',
    width: 180,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Angsuran Pokok(Rp)',
    dataIndex: 'principal_installments',
    key: 'principal_installments',
    width: 180,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Angsuran Bunga(Rp)',
    dataIndex: 'interest_installments',
    key: 'interest_installments',
    width: 180,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Total Angsuran(Rp)',
    dataIndex: 'total_installment_payment',
    key: 'total_installment_payment',
    width: 180,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Suku Bunga (Fixed Rate)',
    dataIndex: 'interest_rate_type',
    key: 'interest_rate_type',
    width: 180,
    align: 'center',
  },
  {
    title: 'Penghasilan Debitur(Rp)',
    dataIndex: 'debtor_salary',
    key: 'debtor_salary',
    width: 180,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'DBR',
    dataIndex: 'dbr',
    width: 180,
    align: 'center',
    render: (record) => <>{record}%</>,
  },
  {
    title: 'Jenis Pensiunan',
    dataIndex: 'retired_type',
    key: 'retired_type',
    width: 180,
    align: 'center',
  },
  {
    title: 'Nama Perusahaan Asuransi',
    dataIndex: 'insurance_company',
    key: 'insurance_name',
    width: 180,
    align: 'center',
  },
];

const columnsDebitur = [
  {
    title: 'No Pinjaman',
    dataIndex: 'loan_number',
    width: 180,
    align: 'center',
  },
  {
    title: 'Nama',
    dataIndex: 'debtor_name',
    width: 180,
    align: 'center',
  },
  {
    title: 'Nilai(Rp)',
    dataIndex: 'total_installment_payment',
    width: 180,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Tanggal Lunas',
    dataIndex: 'credit_due_date',
    width: 180,
    align: 'center',
  },
  {
    title: 'Saldo Pinjaman',
    dataIndex: 'loan_balance',
    width: 180,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Total Angsuran',
    dataIndex: 'total_installment_payment',
    width: 180,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Tanggal Akuisisi',
    dataIndex: 'acquisition_date',
    width: 180,
    align: 'center',
  },
  {
    title: 'Keterangan',
    dataIndex: 'note',
    width: 200,
    align: 'center',
  },
];

export { columnsSummary, columnsPoolOfAsset, columnsDebitur };
