import React, { useState } from 'react';
import { AssetsComponent, Page } from '../../components';
import { useSearchParams } from 'react-router-dom';
import { BsBoxSeam } from 'react-icons/bs';
import { Typography, DatePicker } from 'antd';
import TableComponentCoupon from './components/TableComponentCoupon';
import TableComponentInfo from './components/TableComponentInfo';

const listCard = [
  { id: 0, title: 'EBA Information', description: 'Lorem Ipsum' },
  { id: 1, title: 'Kupon', description: 'Lorem Ipsum' },
];

const ManagementAsset = () => {
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  const ebaName = searchParams.get('eba-name');

  const [click, setClick] = useState(0);

  const breadcrumbs = [
    { name: 'Management Produk', href: '/management-product' },
    { name: ebaName, href: `/management-asset?page=product&eba-id=${ebaId}&eba-name=${ebaId}` },
  ];

  return (
    <Page title={ebaName} breadcrumbs={breadcrumbs}>
      <div className="flex items-center space-x-3">
        <div className="flex items-center justify-center bg-primary rounded-[50px] w-[48px] h-[48px]">
          <BsBoxSeam size={20} color="#ffffff" className="font-bold" />
        </div>
        <Typography.Paragraph className="font-bold text-3xl">{ebaName}</Typography.Paragraph>
      </div>
      <div className="flex justify-between items-center mt-10 mb-6">
        <Typography.Paragraph className="font-bold text-3xl">Produk Dimiliki</Typography.Paragraph>
      </div>
      <div className="flex flex-col gap-6">
        <div className="grid grid-cols-2 gap-5 mb-6">
          {listCard.map((res, i) => (
            <AssetsComponent.Card
              key={res.title}
              title={res.title}
              description={res.description}
              onClick={() => setClick(i)}
              isClick={i === click}
            />
          ))}
        </div>
        <div className="h-12 flex flex-row items-center gap-4">
          <Typography.Paragraph className="font-bold text-base">
            Pilih Tahun :{' '}
          </Typography.Paragraph>
          <DatePicker className="h-full" />
        </div>
        <div>
          {click === 0 ? <TableComponentInfo id={ebaId} /> : <TableComponentCoupon id={ebaId} />}
        </div>
      </div>
    </Page>
  );
};

export default ManagementAsset;
