import { Breadcrumb } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CustomBreadcrumb = ({ listItem = [] }) => {
  const navigate = useNavigate();

  const onClick = (href, goBack = false) => {
    if (goBack) navigate(-1, { replace: true });
    if (href) navigate(href, { replace: true });
  };
  return (
    <Breadcrumb separator=">" className="mb-10">
      {listItem.map(
        (res, i) =>
          !res?.isHide && (
            <Breadcrumb.Item
              key={i}
              onClick={() => onClick(res?.href, res?.goBack)}
              className={`
          hover:font-bold
          cursor-pointer
          font-medium 
          ${i + 1 === listItem.length ? 'custom-breadcrumb-red' : 'text-grey'}
          `}>
              {res?.name}
            </Breadcrumb.Item>
          )
      )}
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;
