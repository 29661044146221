import { DatePicker, Space, Typography } from 'antd';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cashflow } from '../../service';
import Table from '../table';
import { columnsBeforeAcquisition } from './tableConfig';

const BeforeAcquisition = ({ ebaId }) => {
  const { loss } = useSelector((state) => state.cashflow);

  const dispatch = useDispatch();

  const thisYear = moment(Date.now()).format('YYYY');
  const [year, setYear] = useState(thisYear);

  useEffect(() => {
    const payload = {
      ebaId: ebaId,
      year: year,
    };
    dispatch(Cashflow.cashflowLoss(payload));
  }, [year]);

  const onChangeFilter = (_, e) => {
    setYear(e);
  };

  return (
    <Fragment>
      <Space>
        <Typography.Paragraph className="font-bold text-sm">Filter by year:</Typography.Paragraph>
        <DatePicker picker="year" onChange={onChangeFilter} />
      </Space>
      <div className="mt-5">
        <Table.TableGlobal
          keyRow="month"
          columns={columnsBeforeAcquisition}
          dataSource={loss}
          scroll={{ x: 900 }}
        />
      </div>
    </Fragment>
  );
};

export default BeforeAcquisition;
