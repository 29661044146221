const getStorage = (name) => {
  try {
    const data = localStorage.getItem(name);
    return JSON.parse(data);
  } catch (err) {
    return null;
  }
};

const setStorage = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
};

const removeStorage = (name) => {
  localStorage.removeItem(name);
};

export default { getStorage, setStorage, removeStorage };
