import { Form, Modal, Typography } from 'antd';
import React, { useCallback } from 'react';
import { CustomButton, CustomInputNumber } from '../../_shared';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Cost } from '../../../service';

export default function CostFormPaid({ open, onClose, record }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { confirm } = Modal;

  const { loading } = useSelector((state) => state.cost);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const showConfirm = () => {
    confirm({
      title: (
        <Typography.Paragraph className="font-bold text-base text-center w-[354px]">
          Apakah anda yakin ingin mengubah biaya ini?
        </Typography.Paragraph>
      ),
      icon: <ExclamationCircleFilled />,
      centered: true,
      width: '50%',
      okText: 'Ubah',
      okButtonProps: {
        className: 'custom-button custom-button-primary',
        style: { height: '48px' },
      },
      cancelText: 'Batal',
      cancelButtonProps: {
        className: 'custom-button custom-button-secondary',
        style: { height: '48px' },
      },
      onOk() {
        const payload = form.getFieldsValue();
        const body = {
          payload,
          id62: record.cost.cost_detail_id62s[record.period - 1],
        };
        dispatch(Cost.updateCostActual(body))
          .unwrap()
          .then(() => {
            handleClose();
            Modal.destroyAll();
          });
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  const onSubmit = useCallback(
    async (e) => {
      if (!record?.detail_cost_actual_cash) {
        const body = {
          payload: e,
          id62: record.cost.cost_detail_id62s[record.period - 1],
        };
        dispatch(Cost.updateCostActual(body));
      } else {
        showConfirm();
      }
    },
    [form, record]
  );

  return (
    <Modal
      title={<h3 className="text-xl font-bold">Form Edit Biaya</h3>}
      open={open}
      onCancel={() => handleClose()}
      centered
      footer={[
        <CustomButton key="back" type="secondary" onClick={() => handleClose()}>
          Cancel
        </CustomButton>,
        <CustomButton key="submit" type="primary" form="myForm" htmlType="submit" loading={loading}>
          Simpan
        </CustomButton>,
      ]}
      width="70%"
      destroyOnClose={true}>
      <Form
        id="myForm"
        layout="vertical"
        initialValues={{ actual_cash: '' }}
        requiredMark={false}
        form={form}
        onFinish={onSubmit}>
        <Form.Item
          label="Nominal Biaya"
          name="actual_cash"
          rules={[
            {
              required: true,
              message: 'Field tidak boleh kosong',
            },
          ]}>
          <CustomInputNumber
            className="py-[18px] px-[24px] w-full"
            placeholder="Masukkan Biaya"
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
