import { Util } from '../../../helper';

export const columnsInformasiUmum = [
  {
    title: 'Kode EBA',
    dataIndex: 'informasi_umum',
    align: 'center',
    fixed: 'left',
    width: 180,
    render: (record) => record?.kode_eba,
  },
  {
    title: 'Jumlah Kelas EBA',
    dataIndex: 'informasi_umum',
    align: 'center',
    width: 180,
    render: (record) => Util.renderNumber(record?.jumlah_kelas_eba),
  },
  {
    title: 'Kelas EBA',
    dataIndex: 'informasi_umum',
    align: 'center',
    width: 180,
    render: (record) => Util.renderNumber(record?.kelas_eba),
  },
  {
    title: 'Jenis Pendapatan',
    dataIndex: 'informasi_umum',
    align: 'center',
    width: 180,
    render: (record) => record?.jenis_pendapatan,
  },
  {
    title: 'Tingkat Suku Bunga',
    dataIndex: 'informasi_umum',
    align: 'center',
    width: 180,
    render: (record) => record?.tingkat_suku_bunga,
  },
  {
    title: 'Dividen',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.dividen_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.dividen_non_rp),
      },
      {
        title: '%',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: (record) => record?.dividen_percentage,
      },
    ],
  },
  {
    title: 'Peringkat EBA',
    dataIndex: 'informasi_umum',
    align: 'center',
    width: 180,
    render: (record) => record?.peringkat_eba,
  },
  {
    title: 'Sarana Peningkatan Kredit',
    dataIndex: 'informasi_umum',
    align: 'center',
    width: 180,
    render: (record) => record?.sarana_peningkatan_kredit,
  },
  {
    title: 'Persentase Nilai Kepemilikan Kreditur Awal (Originator) terhadap Total Nilai EBA',
    dataIndex: 'informasi_umum',
    align: 'center',
    width: 180,
    render: (record) => Util.renderNumber(record?.persentase_nilai_kepemilikan_originator),
  },
  {
    title: 'Jumlah pemegang EBA',
    dataIndex: 'informasi_umum',
    align: 'center',
    width: 180,
    render: (record) => Util.renderNumber(record?.jumlah_pemegang_eba),
  },
  {
    title: 'Nilai Pasar Wajar EBA',
    dataIndex: 'informasi_umum',
    align: 'center',
    width: 180,
    render: (record) => Util.renderNumber(record?.nilai_pasar_wajar),
  },
  {
    title: 'Jumlah Debitur Awal',
    dataIndex: 'informasi_umum',
    align: 'center',
    width: 180,
    render: (record) => Util.renderNumber(record?.jumlah_debitur_awal),
  },
  {
    title: 'Jumlah Total Nilai Tagihan Awal',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.jumlah_total_nilai_tagihan_awal_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.jumlah_total_nilai_tagihan_awal_non_rp),
      },
    ],
  },
  {
    title: 'Rata-rata Tertimbang Jatuh Tempo Tagihan Awal (dalam bulan)',
    dataIndex: 'informasi_umum',
    align: 'center',
    width: 180,
    render: (record) => Util.renderNumber(record?.rerata_tertimbang_jatuh_tempo),
  },
  {
    title: 'Total Nilai Agunan Awal',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: () => '-',
        // render: (record) => Util.renderNumber(record?.rerata_tertimbang_jatuh_tempo),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: () => '-',
        // render: (record) => Util.renderNumber(record?.rerata_tertimbang_jatuh_tempo),
      },
    ],
  },
  {
    title: 'Jumlah Debitur Sampai Dengan Akhir Periode Laporan',
    dataIndex: 'informasi_umum',
    align: 'center',
    width: 180,
    render: (record) => Util.renderNumber(record?.jumlah_debitur_sampai_dengan_akhir_periode),
  },
  {
    title: 'Nilai Tagihan Sampai Dengan Akhir Periode Laporan',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: (record) =>
          Util.renderNumber(record?.nilai_tagihan_sampai_akhir_periode_laporan_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: (record) =>
          Util.renderNumber(record?.nilai_tagihan_sampai_akhir_periode_laporan_non_rp),
      },
    ],
  },
  {
    title: 'Nilai Agunan Sampai Dengan Akhir Periode Laporan',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.nilai_agunan_sampai_akhir_periode_laporan_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: (record) =>
          Util.renderNumber(record?.nilai_agunan_sampai_akhir_periode_laporan_non_rp),
      },
    ],
  },
  {
    title:
      'Persentase Rata-Rata Tertimbang Nilai Tagihan Terhadap Nilai Agunan Sampai Dengan Akhir Periode Laporan',
    dataIndex: 'informasi_umum',
    align: 'center',
    width: 180,
    render: (record) => Util.renderNumber(record?.persentase_rerata_tertimbang_nilai_tagihan),
  },
  {
    title:
      'Rata-rata Tertimbang Jatuh Tempo Tagihan sampai dengan akhir periode pelaporan (dalam bulan)',
    dataIndex: 'informasi_umum',
    align: 'center',
    width: 180,
    render: (record) => Util.renderNumber(record?.rerata_tertimbang_jatuh_tempo_tagihan),
  },
  {
    title: 'Akumulasi Nilai Pembayaran Dipercepat (Prepayment) Sampai Dengan Akhir Periode Laporan',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_nilai_pembayaran_dipercepat_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_nilai_pembayaran_dipercepat_non_rp),
      },
    ],
  },
  {
    title: 'Akumulasi Nilai Tagihan Yang Dihapus Buku Sampai Dengan Akhir Periode Laporan',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_nilai_tagihan_dihapus_buku_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_nilai_tagihan_dihapus_buku_non_rp),
      },
    ],
  },
  {
    title: 'Akumulasi Nilai Pemulihan Hapus Buku sampai dengan akhir Periode Laporan',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_nilai_pemulihan_hapus_buku_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_nilai_pemulihan_hapus_buku_non_rp),
      },
    ],
  },
];
