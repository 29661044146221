import { Typography } from 'antd';
import { Date, Util } from '../../../helper';
import { StatusColor } from '../../_shared';
import AcquisitionAction from './acquisitionAction';
import UpdateAction from './updateAction';

const columns = (onClickDetail) => [
  {
    title: 'Pengajuan Data Akuisisi',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    width: 180,
    render: (res) => <p>{Date.monthYear(res)}</p>,
  },
  {
    title: 'Cashflow yang Diperlukan (Rp)',
    dataIndex: 'cost',
    key: 'cost',
    align: 'center',
    width: 180,
    render: Util.renderNumber,
  },
  {
    title: 'Total Angsuran yang Diperlukan (Rp)',
    dataIndex: 'installment',
    key: 'installment',
    align: 'center',
    width: 200,
    render: (record) => {
      return Util.renderNumber(record.total);
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: 200,
    render: (record) => <StatusColor status={record} />,
  },
  {
    title: 'Last Updated',
    dataIndex: 'updated_at',
    key: 'updated_at',
    align: 'center',
    width: 200,
    render: Date.lastUpdate,
  },
  {
    title: 'Aksi',
    key: 'action',
    width: 180,
    align: 'center',
    render: (_, record) => <AcquisitionAction record={record} onClickDetail={onClickDetail} />,
  },
];

const columnsOriginator = [
  {
    title: 'Pengajuan Data',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    render: (res) => <span>{res.text}</span>,
  },
  {
    title: 'Cost (IDR)',
    dataIndex: 'cost',
    key: 'cost',
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Tanggal Permintaan',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    render: (res) => <p>{Date.fullDate(res)}</p>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: (record) => <StatusColor status={record} />,
  },
];

const columnsHistoryUpdate = (onClickDetail) => [
  {
    title: 'Tanggal Periode',
    dataIndex: 'transaction_date',
    align: 'center',
    render: Date.twoDigitWithDash,
  },
  {
    title: 'Catatan',
    dataIndex: 'notes',
    align: 'center',
    render: (res) => <p>{res.text}</p>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: (status) => (
      <Typography.Text className="py-3 px-5 font-bold rounded-[50px] w-[240px] bg-green_bg text-green_text">
        {status.text}
      </Typography.Text>
    ),
  },
  {
    title: 'Last Updated',
    dataIndex: 'updated_at',
    align: 'center',
    render: Date.lastUpdate,
  },
  {
    title: 'Aksi',
    key: 'action',
    width: 180,
    align: 'center',
    render: (_, record) => <UpdateAction record={record} onClickDetail={onClickDetail} />,
  },
];

export { columns, columnsOriginator, columnsHistoryUpdate };
