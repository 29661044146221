import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Approval } from '../../service';

const initialState = {
  detail: { name: 'APPROVAL' },
  loading: false,
};

export const approvalSlice = createSlice({
  name: 'approval',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Spv Approval
    builder.addCase(Approval.SpvApproval.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Approval.SpvApproval.fulfilled, (state, action) => {
      state.loading = false;
      state.detail = action.payload.data;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Approval.SpvApproval.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Approval',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });
  },
});

export default approvalSlice.reducer;
