import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Util } from '../../helper';
import { Eba } from '../../service';
import CustomButton from '../_shared/custom-button';

export default function FeedbackAction({ record, callback }) {
  const { isSupervisor, isAdmin } = Util.role();
  const { confirm } = Modal;
  const dispatch = useDispatch();

  const showConfirm = () => {
    confirm({
      title: (
        <h3 className="text-xl font-bold">Apakah Anda yakin ingin untuk publish produk ini?</h3>
      ),
      icon: <ExclamationCircleFilled />,
      centered: true,
      width: '30%',
      okText: 'Publish',
      okButtonProps: {
        className: 'custom-button custom-button-primary',
        style: { height: '48px' },
      },
      cancelText: 'Cancel',
      cancelButtonProps: {
        className: 'custom-button custom-button-secondary',
        style: { height: '48px' },
      },
      onOk() {
        dispatch(Eba.AdminApproval(record?.id62))
          .unwrap()
          .then(() => {
            const query = {
              limit: 5,
              offset: 0,
              status_value: '2,3,4',
              search: '',
              is_simulation: 'False',
            };
            dispatch(Eba.getListEba(query));
          });
        Modal.destroyAll();
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  return (
    <>
      {isAdmin &&
        (record?.status?.value === 4 ? (
          <CustomButton
            type="publish"
            className="bg-white text-green_main border-green_main hover:text-white hover:bg-green_main hover:border-white"
            onClick={showConfirm}>
            Publish
          </CustomButton>
        ) : (
          <CustomButton type="primary-ghost" onClick={() => callback(record)}>
            Lihat Detail
          </CustomButton>
        ))}
      {isSupervisor && (
        <CustomButton type="primary-ghost" onClick={() => callback(record)}>
          Lihat Detail
        </CustomButton>
      )}
    </>
  );
}
