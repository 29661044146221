import React, { useEffect, useState } from 'react';
import Table from '../../table';
import { columnsLogInternal } from './columns';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Report } from '../../../service';

const LogHistoryInternal = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  const dateFilter = searchParams.get('date');
  const [page, setPage] = useState({ limit: 10, offset: 0, current: 1 });
  const { internal_log_history, loading } = useSelector((state) => state.report);

  useEffect(() => {
    const payload = {
      id: ebaId,
      dateFilter: dateFilter,
      limit: page.limit,
      offset: page.offset,
    };
    dispatch(Report.ReportInternalLogHistory(payload));
  }, [ebaId, page, dateFilter]);

  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
  };

  return (
    <Table.TableGlobal
      columns={columnsLogInternal}
      dataSource={internal_log_history?.results}
      className="table-preview-data"
      bordered
      totalData={internal_log_history?.count}
      onChangePagination={paginationChange}
      current={page.current}
      loading={loading}
      rowKey={(record) => record.log_history.id62}
    />
  );
};

export default LogHistoryInternal;
