import React, { useCallback, useState } from 'react';
import { BiPencil } from 'react-icons/bi';
import { useSearchParams } from 'react-router-dom';
import { ManagementProductComponent, CustomButton } from '../..';
import { Format } from '../../../helper';

export default function CostDetailAction({ record }) {
  const [open, setOpen] = useState(false);
  const [editPaid, setEditPaid] = useState(false);
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');

  const ButtonAction = useCallback(() => {
    if (!record?.is_paid) {
      return (
        <>
          <BiPencil style={{ cursor: 'pointer' }} onClick={() => setOpen(true)} />
        </>
      );
    }

    if (record?.detail_cost_actual_cash) {
      return (
        <div className="flex flex-row gap-4">
          {Format.numberFormat(Math.floor(record.detail_cost_actual_cash))}
          <BiPencil style={{ cursor: 'pointer' }} onClick={() => setEditPaid(true)} />
        </div>
      );
    }
    return (
      <CustomButton
        size="small"
        style={{ height: '40px' }}
        type="primary"
        onClick={() => setEditPaid(true)}>
        Update Biaya
      </CustomButton>
    );
  }, [record?.is_paid, record?.detail_cost_actual_cash]);

  return (
    <>
      <div className="flex justify-center gap-5">
        <ButtonAction />
      </div>
      <ManagementProductComponent.CostForm
        isUpdate={true}
        open={open}
        onClose={() => setOpen(false)}
        record={record}
        ebaId={ebaId}
      />

      <ManagementProductComponent.CostFormPaid
        open={editPaid}
        onClose={() => setEditPaid(false)}
        record={record}
      />
    </>
  );
}
