import React, { useEffect, useState } from 'react';
import { MdFileDownload } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { UserLog } from '../../service';

import { Page } from '../../components';
import CustomButton from '../../components/_shared/custom-button';

import { DatePicker, Pagination } from 'antd';
import Typography from 'antd/es/typography/Typography';
import dayjs from 'dayjs';
import { Format } from '../../helper';

const Logbook = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const idEba = searchParams.get('eba-id');
  const ebaName = searchParams.get('eba-name');

  const { logCashflow } = useSelector((state) => state.userLog);
  const [DateFilter, setDateFilter] = useState(dayjs().format('YYYY-MM-DD'));
  const [DecrementHistory, setDecrementHistory] = useState([]);
  const [IncrementHistory, setIncrementHistory] = useState([]);

  useEffect(() => {
    const payload = {
      ebaId: idEba,
      period_date: DateFilter || dayjs().format('YYYY-MM-DD'),
    };
    dispatch(UserLog.userLogCashflow(payload));
  }, [DateFilter]);

  useEffect(() => {
    setDecrementHistory([]);
    setIncrementHistory([]);
    const escapeEmpty = logCashflow.filter((item) => item?.cashflow_option !== null);
    if (escapeEmpty.length > 0) {
      const decrement = escapeEmpty.filter((item) => item?.cashflow_option === '-');
      const increment = escapeEmpty.filter((item) => item?.cashflow_option === '+');
      setDecrementHistory((prev) => (decrement.length > 0 ? [...prev, ...decrement] : prev));
      setIncrementHistory((prev) => (increment.length > 0 ? [...prev, ...increment] : prev));
    }
  }, [logCashflow]);

  // PAGINATION SETTING : ================================
  const pageSize = 5;
  const [CurrentAdd, setCurrentAdd] = useState(1);
  const [MinIndexAdd, setMinIndexAdd] = useState(0);
  const [MaxIndexAdd, setMaxIndexAdd] = useState(0);
  const [CurrentDes, setCurrentDes] = useState(1);
  const [MinIndexDes, setMinIndexDes] = useState(0);
  const [MaxIndexDes, setMaxIndexDes] = useState(0);
  useEffect(() => {
    setMinIndexAdd(0);
    setMaxIndexAdd(pageSize);
    setMinIndexDes(0);
    setMaxIndexDes(pageSize);
  }, []);
  const handleChangeAdd = (Add) => {
    setCurrentAdd(Add);
    setMinIndexAdd((Add - 1) * pageSize);
    setMaxIndexAdd(Add * pageSize);
  };
  const handleChangeDes = (Des) => {
    setCurrentDes(Des);
    setMinIndexDes((Des - 1) * pageSize);
    setMaxIndexDes(Des * pageSize);
  };

  // DOWNLOAD LOGBOOK :
  const downloadLogbook = () => {
    const payload = {
      ebaId: idEba,
      ebaName: ebaName,
    };
    dispatch(UserLog.downloadLogbook(payload));
  };

  return (
    <Page title="Logbook">
      <div className="flex justify-between items-center mt-6">
        <div>
          <Typography.Paragraph className="font-bold text-3xl">Logbook</Typography.Paragraph>
          <Typography.Paragraph className="font-bold text-xl mt-2">
            {ebaName ? ebaName : null}
          </Typography.Paragraph>
        </div>
        <CustomButton
          onClick={() => downloadLogbook()}
          type="primary"
          className="
            group
            justify-center
            items-center
            flex
            space-x-2">
          <MdFileDownload size={20} className="text-white group-hover:text-primary" />
          Unduh Logbook
        </CustomButton>
      </div>
      <h4 className="text-base font-bold mb-4 mt-2">
        Dibawah merupakan informasi mengenai history update dan upload data akusisi oleh originator
      </h4>
      <DatePicker
        picker="month"
        onChange={(_, dateString) => {
          setDateFilter(dateString);
        }}
        defaultValue={dayjs()}
      />

      {/* Decrement */}
      <div className="flex flex-col gap-6 mt-4 mb-4">
        <h4 className="text-xl font-bold">History Update Data oleh Originator</h4>
        <div className="flex justify-between pb-4 border-b-1 border-[#AFAFAF]">
          <p className="text-xs text-[#AFAFAF] ml-4">Tanggal & Waktu</p>
          <p className="text-xs text-[#AFAFAF] mr-4 w-[240px]">Nilai Asset yang Berkurang(Rp)</p>
        </div>
        <div>
          {DecrementHistory?.length === 0 ? (
            <div className="flex bg-white items-center justify-center py-8 text-base font-bold">
              History Not Found
            </div>
          ) : (
            DecrementHistory?.map(
              (data, index) =>
                index >= MinIndexDes &&
                index < MaxIndexDes && (
                  <div
                    className="py-6 pl-4 pr-[103px] box-border flex justify-between bg-white mb-[1px]"
                    key={index}>
                    <h4 className="text-base font-bold">
                      {data?.date?.date} {data?.date?.time}
                    </h4>
                    <h4 className="text-base font-bold">
                      {data?.update_cashflow !== null
                        ? Format.numberFormat(data?.update_cashflow)
                        : '-'}
                    </h4>
                  </div>
                )
            )
          )}
          <div className="flex mt-4 justify-end">
            {DecrementHistory?.length >= 5 ? (
              <Pagination
                pageSize={pageSize}
                current={CurrentDes}
                total={DecrementHistory.length}
                onChange={handleChangeDes}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {/* Increment */}
      <div className="flex flex-col gap-6 mt-4 mb-4">
        <h4 className="text-xl font-bold">History Update Data oleh Originator</h4>
        <div className="flex justify-between pb-4 border-b-1 border-[#AFAFAF]">
          <p className="text-xs text-[#AFAFAF] ml-4">Tanggal & Waktu</p>
          <p className="text-xs text-[#AFAFAF] mr-4 w-[240px]">Nilai Asset yang Ditambahkan(Rp)</p>
        </div>
        <div>
          {IncrementHistory?.length === 0 ? (
            <div className="flex bg-white items-center justify-center py-8 text-base font-bold">
              History Not Found
            </div>
          ) : (
            IncrementHistory.map(
              (data, index) =>
                index >= MinIndexAdd &&
                index < MaxIndexAdd &&
                data.cashflow_option === '+' &&
                (data.length !== 0 ? (
                  <div
                    className="py-6 pl-4 pr-[103px] box-border flex justify-between bg-white mb-[1px]"
                    key={index}>
                    <h4 className="text-base font-bold">
                      {data?.date?.date} {data?.date?.time}
                    </h4>
                    <h4 className="text-base font-bold">
                      {Format.numberFormat(data?.update_cashflow)}
                    </h4>
                  </div>
                ) : (
                  <div className="flex bg-white items-center justify-center py-8 text-base font-bold">
                    History Not Found
                  </div>
                ))
            )
          )}
          <div className="flex mt-4 justify-end">
            {IncrementHistory?.length >= 5 ? (
              <Pagination
                pageSize={pageSize}
                current={CurrentAdd}
                total={IncrementHistory.length}
                onChange={handleChangeAdd}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Logbook;
