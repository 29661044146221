import React from 'react';
import { Typography, Modal } from 'antd';
import { CustomButton } from '../../_shared';

export default function CustomModalConfirmation({ title, open, onConfirm, onClose }) {
  return (
    <Modal
      open={open}
      footer={null}
      onCancel={() => {
        onClose();
      }}
      destroyOnClose={true}
      centered
      width={'486px'}>
      <div className="flex flex-col p-6 gap-6 justify-center items-center">
        <img width={80} src="/icons/popup/warningPopup.svg" alt="warning" />
        <Typography.Paragraph className="font-bold text-base text-center w-[354px]">
          {title}
        </Typography.Paragraph>
        <div className="flex flex-row-reverse gap-5 mt-5">
          <CustomButton onClick={onConfirm} type="primary">
            Yes
          </CustomButton>
          <CustomButton onClick={() => onClose()} type="primary-ghost">
            No
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
}
