import { ManagementUserComponent } from '../../../components';
import { Date, Format } from '../../../helper';

const user = {
  column: [
    {
      title: 'ID User',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nama User',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Peran',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (record) => (
        <ManagementUserComponent.UserAction id={record.id62} group={record.group} />
      ),
    },
  ],
};

const userSPV = {
  column: [
    {
      title: 'ID User',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nama User',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Peran',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
  ],
};

const logging = {
  column: [
    {
      title: 'ID User',
      dataIndex: 'user',
      key: 'user',
      render: (record) => record.id_user,
    },
    {
      title: 'Nama User',
      dataIndex: 'user',
      key: 'user',
      render: (record) => record.name,
    },
    {
      title: 'Peran',
      dataIndex: 'user',
      key: 'user',
      render: (record) => record.role,
    },
    {
      title: 'Aktivitas Terakhir',
      dataIndex: 'log_desc',
      key: 'log_desc',
    },
    {
      title: 'Timestamp',
      dataIndex: 'date',
      key: 'date',
      render: (record) => Date.logDate(record?.date + ', ' + record?.time),
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (record) => <ManagementUserComponent.UserLogAction id={record?.user?.id62_user} />,
    },
  ],
};

const loggingDetail = {
  column: [
    {
      title: 'Nama User',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Peran',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Aktivitas',
      dataIndex: 'log_desc',
      key: 'log_desc',
    },
    {
      title: 'Menu',
      dataIndex: 'module',
      key: 'module',
    },
    {
      title: 'Aksi',
      dataIndex: 'log_type',
      key: 'log_type',
      // render: (record, item, index) => (index < 3 ? 'aksi' : record),
    },
    {
      title: 'Sebelum',
      dataIndex: 'before',
      key: 'before',
      render: (record) =>
        typeof record !== 'number' ? record : Format.numberFormat(Math.floor(record)),
    },
    {
      title: 'Sesudah',
      dataIndex: 'after',
      key: 'after',
      render: (record) =>
        typeof record !== 'number' ? record : Format.numberFormat(Math.floor(record)),
    },
    {
      title: 'Timestamp',
      dataIndex: 'date',
      key: 'date',
      render: (record) => Date.logDate(record?.date + ', ' + record?.time),
    },
  ],
};

export { user, userSPV, logging, loggingDetail };
