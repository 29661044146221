import { Typography } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Util } from '../../../helper';
import periodicPayment from '../../../service/api/periodicPayment';
import Table from '../../table';
import { columnsInappropiate } from '../tableConfig';

const InappropiateData = ({ fileId, tabClick }) => {
  const dispatch = useDispatch();

  const { inappropiate, loading } = useSelector((state) => state.periodic);

  const [page, setPage] = useState({ limit: 10, offset: 0, current: 1 });

  useEffect(() => {
    if (tabClick === 2) {
      const payload = {
        file_id62: fileId,
        limit: page.limit,
        offset: page.offset,
        data_type: 'unfound',
      };
      dispatch(periodicPayment.periodicFail(payload));
    }
  }, [page, tabClick]);

  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
    Util.scrollToElement('table-inappropiate-data');
  };

  return (
    <Fragment>
      <Typography.Paragraph className="text-primary font-bold mt-5">
        Jumlah Data Yang Tidak Sesuai: {inappropiate.count}
      </Typography.Paragraph>

      <Typography.Paragraph className="font-bold text-base mt-5">
        Summary Data Yang Tidak Sesuai
      </Typography.Paragraph>
      <Table.TableGlobal
        id="table-inappropiate-data"
        className="table-preview-data mt-10"
        columns={columnsInappropiate}
        dataSource={inappropiate.results}
        loading={loading}
        totalData={inappropiate.count}
        onChangePagination={paginationChange}
        current={page.current}
      />
    </Fragment>
  );
};

export default InappropiateData;
