import React, { useState } from 'react';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ManagementProductComponent } from '../..';
import { Investment } from '../../../service';
import { CustomPopconfirm } from '../../_shared';

export default function AssetClassDetailAction({ item }) {
  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();
  const gradeId = searchParam.get('grade-id');

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const refetch = () => {
    dispatch(Investment.investorList({ gradeId: gradeId }));
  };

  const Delete = () => {
    setLoading(true);
    dispatch(Investment.DeleteInvestorDetail({ id62: item.id62 }))
      .then(() => {
        refetch();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex gap-2">
      <BiPencil style={{ cursor: 'pointer' }} onClick={() => setOpen(true)} />
      <CustomPopconfirm
        placement="left"
        title="Hapus Investor"
        description="Anda yakin akan hapus investor ini?"
        onConfirm={Delete}
        loading={loading}>
        <BiTrash style={{ cursor: 'pointer' }} />
      </CustomPopconfirm>
      <ManagementProductComponent.AssetClassDetailForm
        item={item}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
}
