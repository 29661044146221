import { Modal, Result, Typography } from 'antd';
import React from 'react';
import { MdWarning } from 'react-icons/md';
import CustomButton from '../../_shared/custom-button';

const ModalEditConfirm = ({ openModal, onClickCancel, onClickSend, loading }) => {
  return (
    <Modal open={openModal} footer={null} closable={false}>
      <Result
        icon={
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center bg-primary w-[60px] h-[60px] rounded-[50px]">
              <MdWarning color="white" size={36} />
            </div>
          </div>
        }
        title={
          <Typography.Paragraph className="font-bold text-base">
            Edit salah satu dari Pokok, Imbal Hasil, Bulan Awal atau Bulan Akhir akan menghapus
            kupon dan investor. Lanjutkan?
          </Typography.Paragraph>
        }
        extra={
          <div className="space-y-5">
            <div className="space-x-4">
              <CustomButton
                type="primary-ghost"
                key="cancel"
                onClick={onClickCancel}
                className="w-24">
                Tidak
              </CustomButton>
              <CustomButton
                type="primary"
                key="send"
                onClick={onClickSend}
                className="w-24"
                loading={loading}>
                Ya
              </CustomButton>
            </div>
          </div>
        }
      />
    </Modal>
  );
};

export default ModalEditConfirm;
