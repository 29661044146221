import React, { useState } from 'react';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ManagementProductComponent } from '../..';
import { Reinvestment } from '../../../service';
import { CustomPopconfirm } from '../../_shared';
import CustomButton from '../../_shared/custom-button';
import ModalWithdraw from './ModalWithdraw';
import { Tooltip } from 'antd';

export default function ReInvestmentAction({ record }) {
  const { id62, is_withdrawal, eba, end_date } = record;
  const [searchParams] = useSearchParams();
  const isSimulation = searchParams.get('is_simulation');
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.buffer);
  const { loadingDelete } = useSelector((state) => state.reinvestment);

  const [open, setOpen] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);

  const Open = () => {
    setOpen(true);
  };

  const Delete = () => {
    dispatch(Reinvestment.deleteDetail({ id: id62 }));
  };

  const onClickWithdraw = (e) => {
    e?.stopPropagation();
    setOpenWithdraw(true);
  };

  return (
    <>
      <div className="flex justify-center gap-5">
        {eba?.status?.value == 0 && !isSimulation ? (
          <Tooltip
            mouseEnterDelay={2}
            title="Melanjutkan re-investment untuk ke end date berikutnya. Sehingga akan menarik semua bunga sampai end date"
            placement="topRight">
            <CustomButton type="primary" disabled={is_withdrawal} onClick={onClickWithdraw}>
              Roll Over
            </CustomButton>
          </Tooltip>
        ) : (
          <>
            <BiPencil style={{ cursor: 'pointer' }} onClick={Open} />
            <CustomPopconfirm
              placement="left"
              title="Hapus Re-investment"
              description="Anda yakin akan hapus data ini?"
              onConfirm={Delete}
              loading={loadingDelete}>
              <BiTrash style={{ cursor: 'pointer' }} />
            </CustomPopconfirm>
          </>
        )}
      </div>
      <ManagementProductComponent.ReInvestmentForm
        isUpdate={true}
        open={open}
        onClose={() => setOpen(false)}
        id={id62}
        buffer={data.amount}
      />
      <ModalWithdraw
        openModal={openWithdraw}
        setOpenModal={setOpenWithdraw}
        id62={id62}
        endDate={end_date}
      />
    </>
  );
}
