/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { DatePicker, Form, Modal } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Eba, Investment } from '../../../service';
import { CustomButton, CustomInput, CustomInputNumber, Select } from '../../_shared';
import ModalEditConfirm from './modalEditConfirm';
import rulesForm from './rulesFormItem';

export default function AssetClassForm({ open, onClose, isUpdate = false, id = '' }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');

  const { detail } = useSelector((state) => state.eba);

  const [categories, setCategories] = useState([]);
  const [currentBudget, setCurrentBudget] = useState(0);
  const [modalConfirm, setModalConfirm] = useState({ show: false, payload: {} });

  const handleModalClose = () => {
    form.resetFields();
    onClose();
  };
  const handleCloseConfirmEdit = () => setModalConfirm({ show: false, payload: {} });

  useEffect(() => {
    if (open) {
      dispatch(Investment.getCategories({ ebaId: ebaId, limit: 10000 }))
        .unwrap()
        .then((res) => {
          setCategories(
            res?.data?.map((e) => {
              return {
                value: e?.id62,
                label: e?.name,
              };
            })
          );
        });
      if (isUpdate) {
        dispatch(Investment.getClassByID({ id: id }))
          .unwrap()
          .then((res) => {
            form.setFieldValue('name', res?.data?.name);
            form.setFieldValue('category', res?.data?.category?.id62);
            form.setFieldValue('amount', parseInt(res?.data?.amount));
            form.setFieldValue('coupon_rate', res?.data?.coupon_rate);
            form.setFieldValue('eba_code', res?.data?.eba_code);
            form.setFieldValue(
              'start_date',
              res?.data?.start_date !== '' ? dayjs(`1 ${res?.data?.start_date}`) : dayjs()
            );
            form.setFieldValue(
              'end_date',
              res?.data?.end_date !== '' ? dayjs(`1 ${res?.data?.end_date}`) : dayjs()
            );
            setCurrentBudget(res?.data?.amount);
          });
      }
    }
  }, [open, isUpdate]);

  const confirmUpdate = () => {
    dispatch(Investment.updateClass({ id: id, payload: modalConfirm.payload }))
      .unwrap()
      .then(() => {
        dispatch(Eba.getDetailEba(ebaId));
        dispatch(Investment.getClass({ ebaId: ebaId, limit: 5 }));

        handleCloseConfirmEdit();
        handleModalClose();
      });
  };

  const onSubmit = (e) => {
    const payload = {
      eba_id62: ebaId,
      name: e.name,
      category_id62: e.category,
      amount: e.amount,
      coupon_rate: e.coupon_rate,
      start_date: moment(e.start_date?.$d).startOf('month').format('YYYY-MM-DD'),
      end_date: moment(e.end_date?.$d).endOf('month').format('YYYY-MM-DD'),
      eba_code: e.eba_code,
    };

    if (!isUpdate) {
      dispatch(Investment.createClass(payload))
        .unwrap()
        .then(() => {
          dispatch(Eba.getDetailEba(ebaId));
          dispatch(Investment.getClass({ ebaId: ebaId, limit: 5 }));
          handleModalClose();
        });
    } else {
      setModalConfirm({ show: true, payload });
    }
  };

  const cancelUpdate = () => {
    handleModalClose();
    handleCloseConfirmEdit();
  };

  const disabledDate = (date) => {
    const currnt = dayjs(date);

    // first_payment_date format is YYYY-MM-DD
    // ensure the date is 01
    const firstPaymentDate = detail.first_payment_date || '';
    const [year, month] = firstPaymentDate.split('-');
    const dayOneFirstPayment = `${year}-${month}-01`;
    const firstPayment = dayjs(dayOneFirstPayment);
    const endDateEba = dayjs(detail.end_date);
    return currnt < firstPayment || currnt >= endDateEba;
  };

  return (
    <Modal
      title={<h3 className="text-xl font-bold">Informasi Senior Tranches</h3>}
      open={open}
      onCancel={() => handleModalClose()}
      destroyOnClose={true}
      centered
      footer={[
        <CustomButton key="back" type="secondary" onClick={() => handleModalClose()}>
          Cancel
        </CustomButton>,
        <CustomButton key="submit" type="primary" form="myForm" htmlType="submit">
          Simpan
        </CustomButton>,
      ]}
      width={'50%'}>
      <Form id="myForm" layout="vertical" form={form} requiredMark={false} onFinish={onSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            label="Nama"
            name="name"
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
            ]}>
            <CustomInput
              placeholder="Tuliskan nama senior tranche"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
          <Form.Item
            label="Jenis Penawaran"
            name="category"
            validateFirst
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
            ]}>
            <Select
              placeholder="Pilih Jenis penawaran"
              className="py-[13px] px-[20px] h-[60px]"
              options={categories}
            />
          </Form.Item>
          <Form.Item
            label="Pokok Awal"
            validateFirst
            name="amount"
            rules={rulesForm.rulesAmountClass(detail.issuance_value, isUpdate, currentBudget)}>
            <CustomInputNumber
              placeholder="Masukan pokok awal"
              className="py-[14px] px-[24px] w-full"
              maxLength={19}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              min={1}
            />
          </Form.Item>
          <Form.Item
            label="Imbah Hasil (Annual)"
            name="coupon_rate"
            validateFirst
            rules={rulesForm.rulesImbalHasilClass}>
            <CustomInput
              placeholder="Tuliskan imbah hasil"
              type="text"
              className="py-[18px] px-[24px]"
              suffix={<span>%</span>}
            />
          </Form.Item>
          <Form.Item
            className="w-full"
            label="Bulan Awal"
            dependencies={['end_date']}
            name="start_date"
            validateFirst
            rules={rulesForm.rulesStartDateClass}>
            <DatePicker
              className="custom-input rounded py-[18px] bg-white px-[24px] w-full"
              placeholder="MM/YYYY"
              picker="month"
              disabledDate={disabledDate}
            />
          </Form.Item>
          <Form.Item
            className="w-full"
            name="end_date"
            label="Bulan Akhir"
            dependencies={['start_date']}
            validateFirst
            rules={rulesForm.rulesEndDateClass}>
            <DatePicker
              className="custom-input rounded py-[18px] bg-white px-[24px] w-full"
              placeholder="MM/YYYY"
              picker="month"
              disabledDate={disabledDate}
            />
          </Form.Item>
        </div>
        <Form.Item
          className="w-full"
          name="eba_code"
          validateFirst
          label="Kode EBA"
          rules={[
            {
              required: true,
              message: 'Field tidak boleh kosong',
            },
          ]}>
          <CustomInput
            placeholder="Tuliskan kode EBA"
            type="text"
            className="py-[18px] px-[24px]"
          />
        </Form.Item>
        <ModalEditConfirm
          openModal={modalConfirm.show}
          onClickSend={confirmUpdate}
          onClickCancel={cancelUpdate}
        />
      </Form>
    </Modal>
  );
}
