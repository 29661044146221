import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Util } from '../../helper';
import { Revenue } from '../../service';

const initialState = {
  loading: false,
  revenuePeriod: [],
  isCovered: false,
};

export const revenueSlice = createSlice({
  name: 'revenue',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // revenue period
    builder.addCase(Revenue.revenuePeriod.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Revenue.revenuePeriod.fulfilled, (state, action) => {
      state.loading = false;
      state.revenuePeriod = action?.payload?.data?.results;
      state.isCovered = action?.payload?.data?.is_covered;
    });
    builder.addCase(Revenue.revenuePeriod.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.revenuePeriod = initialState.revenuePeriod;
      state.loading = false;
    });

    // create revenue
    builder.addCase(Revenue.createRevenuePeriod.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Revenue.createRevenuePeriod.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Create Earning Percentage',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Revenue.createRevenuePeriod.rejected, (state, action) => {
      let descriptionWording = '';
      if (action.payload?.data?.period === 'This period already has a percentage value') {
        descriptionWording = 'Periode sudah diinputkan';
      } else {
        descriptionWording = action.payload?.message?.period || Util.errorHandle(action.payload);
      }

      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed to Create Earning Percentage',
        description: descriptionWording,
      });
      state.loading = false;
    });
  },
});

export default revenueSlice.reducer;
