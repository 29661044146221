import { Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { BsBoxSeam } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Page } from '../../components';
import { AcquisitionDataSummary, CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Date, Storage } from '../../helper';
import { Aquisition } from '../../service';

const PreviewDataAcquisition = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const approvalId = searchParams.get('approval-id');
  const updated = searchParams.get('updated');
  const status = searchParams.get('status');
  const ebaName = searchParams.get('eba-name');
  const ebaId = searchParams.get('eba-id');

  const selectedRowKeys = Storage.getStorage('recommendation');

  const dispatch = useDispatch();

  const { detail } = useSelector((state) => state.eba);

  const breadcrumbs = [
    { name: 'Management Produk', href: '/management-product' },
    { name: ebaName, href: `/assets?page=product&eba-id=${ebaId}&eba-name=${ebaName}` },
    { name: 'Pengelolaan EBA', href: null, goBack: true, isHide: status == 8 },
    { name: 'Summary Akuisisi', href: null },
  ];

  const onClickSubmit = () => {
    const payload = {
      approval_id62: approvalId,
      acquisition_id62s: selectedRowKeys,
      approve: true,
    };
    dispatch(Aquisition.aquisitionApprovalByAdmin(payload))
      .unwrap()
      .then(() => {
        CustomNotification({
          type: Constant.SUCCESS,
          message: 'Sukses',
          description: 'Sukses melakukan pengajuan',
        });
        Storage.removeStorage('reject_reason');
        navigate(`/assets?page=product&eba-id=${ebaId}&eba-name=${ebaName}&tab=2`);
      })
      .catch(() => {});
  };

  return (
    <Page title="Preview Data" breadcrumbs={breadcrumbs}>
      <div className="flex items-center space-x-3">
        <div
          className="
            flex
            items-center
            justify-center
          bg-primary
            rounded-[50px]
            w-[48px]
            h-[48px]
              ">
          <BsBoxSeam size={20} color="#ffffff" className="font-bold" />
        </div>
        <Typography.Paragraph className="font-bold text-3xl">
          Rekomendasi - {detail?.name}
        </Typography.Paragraph>
      </div>
      <Typography.Paragraph className="text-grey mt-3">
        Diperbarui {Date.parseTimeAgo(moment(updated).format('YYYY-MM-DD HH:mm:ss'))}
      </Typography.Paragraph>
      <AcquisitionDataSummary title="Summary Setelah Akuisisi" onClickSubmit={onClickSubmit} />
    </Page>
  );
};

export default PreviewDataAcquisition;
