import { Divider, Select } from 'antd';
import React, { useState } from 'react';
import { CustomInput, CustomInputNumber } from '../_shared';
import { type } from './FilterPoolOfAsset';

const FilterInputType = ({ typeFilter, options, data, value, onChange }) => {
  const [text, setText] = useState(value);

  const changeText = (e) => {
    setText(e);
    onChange(e, data);
  };

  if (typeFilter === type.TEXT) {
    return (
      <CustomInput
        value={text}
        className="border h-[40px] bg-white rounded-lg flex items-center"
        onChange={(e) => changeText(e.target.value)}
      />
    );
  }
  if (typeFilter === type.NUMBER) {
    return (
      <CustomInputNumber
        value={value}
        onChange={(e) => onChange(e, data)}
        className="w-full border h-[40px] bg-white rounded-lg flex items-center"
        formatter={(v) => `${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(v) => v.replace(/\$\s?|(,*)/g, '')}
      />
    );
  }
  if (typeFilter === type.SELECT) {
    return (
      <Select
        showSearch
        placeholder=""
        onChange={onChange}
        className="border h-[40px] bg-white rounded-lg flex items-center"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={options}
        allowClear
      />
    );
  }

  return <Divider />;
};

export default FilterInputType;
