import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const getTransaction = createAsyncThunk(
  'get/getTransaction',
  async ({ ebaId, limit = 10000, offset = 0 }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `transaction/?eba_id62=${ebaId}&limit=${limit}&offset=${offset}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default {
  getTransaction,
};
