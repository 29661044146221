import { notification } from 'antd';
import { Constant } from '../../../const';

const CustomNotification = ({
  type = Constant.SUCCESS || Constant.INFO || Constant.WARNING || Constant.ERROR,
  message = '',
  description = '',
}) =>
  notification.open({
    type: type,
    message: message,
    description: description,
    duration: 7, //Tenp
  });

export default CustomNotification;
