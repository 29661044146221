import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const createClass = createAsyncThunk('post/createClasas', async (payload, { rejectWithValue }) => {
  try {
    return await API.post({
      url: `investment/grade/`,
      payload,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getCategories = createAsyncThunk(
  'get/getCategories',
  async (payload, { rejectWithValue }) => {
    try {
      const { ebaId, limit, offset = 0 } = payload;
      return await API.get({
        url: `investment/category/?eba_id62=${ebaId}&limit=${limit}&offset=${offset}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const getClass = createAsyncThunk('get/getClass', async (payload, { rejectWithValue }) => {
  try {
    const { ebaId, limit, offset = 0 } = payload;
    return await API.get({
      url: `investment/grade/?eba_id62=${ebaId}&limit=${limit}&offset=${offset}`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getClassByID = createAsyncThunk('get/getClassByID', async ({ id }, { rejectWithValue }) => {
  try {
    return await API.get({
      url: `investment/grade/${id}/`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const deleteClass = createAsyncThunk('delete/deleteClass', async ({ id }, { rejectWithValue }) => {
  try {
    return await API.deleteRequest({
      url: `investment/grade/${id}/`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const updateClass = createAsyncThunk(
  'put/updateClass',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      return await API.put({
        url: `investment/grade/${id}/`,
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getRating = createAsyncThunk(
  'get/rating',
  async ({ ebaId, gradeId }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `investment/rating/?eba_id62=${ebaId}&grade_id62=${gradeId}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createRating = createAsyncThunk('post/createRating', async (payload, { rejectWithValue }) => {
  try {
    return await API.post({
      url: 'investment/rating/',
      payload,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getCoupon = createAsyncThunk('get/coupon', async ({ gradeId }, { rejectWithValue }) => {
  try {
    return await API.get({
      url: `investment/coupon/?grade_id62=${gradeId}`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getCouponSummary = createAsyncThunk(
  'get/couponSummary',
  async ({ gradeId }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `investment/coupon/summary/?grade_id62=${gradeId}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createCoupon = createAsyncThunk('post/createCoupon', async (payload, { rejectWithValue }) => {
  try {
    return await API.post({
      url: 'investment/coupon/',
      payload,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const updateCoupon = createAsyncThunk('put/updateCoupon', async (body, { rejectWithValue }) => {
  const { grade_id62, coupon } = body;
  try {
    return await API.put({
      url: `investment/grade/${grade_id62}/coupon/`,
      payload: coupon,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getPayback = createAsyncThunk(
  'get/getPayback',
  async ({ search, gradeId }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `investment/payback/?search=${search}&grade_id62=${gradeId}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createPayback = createAsyncThunk(
  'post/createPayback',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({
        url: 'investment/payback/',
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updatePayback = createAsyncThunk(
  'put/updatePayback',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      return await API.put({
        url: `investment/payback/${id}/`,
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const AddInvestor = createAsyncThunk('post/addInvestor', async (payload, { rejectWithValue }) => {
  try {
    const response = await API.post({ url: 'investment/investor/', payload });
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const investorList = createAsyncThunk('get/investor', async ({ gradeId }, { rejectWithValue }) => {
  try {
    return await API.get({
      url: `investment/investor/?grade_id62=${gradeId}`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const InvestorDownloadFormatTxt = createAsyncThunk(
  'get/aquisitionDownloadFormatExcel',
  async (_, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `investment/investor/format-txt/`,
        isDownload: true,
        fileName: 'Format_Data_Investor',
        format: 'txt',
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getInvestorByID = createAsyncThunk(
  'get/getInvestorByID',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `investment/investor/${id}/`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const DeleteInvestorDetail = createAsyncThunk(
  'delete/investor',
  async ({ id62 }, { rejectWithValue }) => {
    try {
      return await API.deleteRequest({
        url: `investment/investor/${id62}/`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const UpdateInvestorDetail = createAsyncThunk(
  'update/investor',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      return await API.put({
        url: `investment/investor/${id}/`,
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const DeleteAllInvestor = createAsyncThunk(
  'delete/investor',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await API.deleteRequest({
        url: `investment/investor/all/${id}/`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default {
  createClass,
  getCategories,
  getClass,
  deleteClass,
  getClassByID,
  updateClass,
  getRating,
  getCoupon,
  AddInvestor,
  InvestorDownloadFormatTxt,
  investorList,
  createRating,
  createCoupon,
  updateCoupon,
  getInvestorByID,
  DeleteInvestorDetail,
  UpdateInvestorDetail,
  getPayback,
  createPayback,
  updatePayback,
  DeleteAllInvestor,
  getCouponSummary,
};
