import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Util } from '../../helper';
import { Aquisition } from '../../service';

const initialState = {
  list: [],
  items: [],
  itemTotal: 0,
  paginationSync: {
    page: 1,
  },
  approval: {},
  approvalSummaryDetail: {},
  approvalFile: {},
  submission: {},
  submissionList: {},
  recommendation: {},
  selectedRowKeys: [],
  selectedRow: [],
  lastSelectedRowKeys: '',
  lastSelectedRow: {},
  cashflowRecommendation: 0,
  debiturRecommendation: 0,
  summaryNpv: {},
  summary: [],
  cashflowSummary: 0,
  download: {},
  loading: false,
  loadingDownload: false,
  loadingDelete: false,
  loadingSend: false,
  loadingRequest: false,
  loadingUpload: false,
  summaryList: [],
  totalDiffAcquisition: {},
  loadingDiffAcquisition: true,
};

const countCashflow = (dataSelected = []) => {
  let sumOf = 0;
  dataSelected.forEach((item) => {
    sumOf += Number(item?.total_installment_payment || 0);
  });

  return sumOf;
};

export const aquisitionSlice = createSlice({
  name: 'aquisition',
  initialState,
  reducers: {
    setSelectedRowKeys: (state, action) => {
      let row = state.selectedRow;
      const selected = action.payload.row;

      // check is element exist
      const isExist = row?.some((res) => res.id62 === selected.id62);

      // add or remove element
      if (!isExist) row.push(selected);
      else row = row.filter((el) => el.id62 !== selected.id62);

      // set last selected
      state.lastSelectedRow = selected;
      state.lastSelectedRowKeys = selected.id62;

      state.selectedRowKeys = action.payload.key;
      state.selectedRow = action.payload?.type === 'all' ? selected : row;
      state.cashflowRecommendation =
        action.payload?.type === 'all' ? countCashflow(selected) : countCashflow(row);
    },
    removeSelectedRowKey: (state) => {
      state.selectedRowKeys = initialState.selectedRowKeys;
      state.selectedRow = initialState.selectedRow;
      state.cashflowRecommendation = initialState.cashflowRecommendation;
    },
  },
  extraReducers: (builder) => {
    // aquisition list
    builder.addCase(Aquisition.aquisitionList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Aquisition.aquisitionList.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload.data;
      state.items = action.payload?.data?.results;
      state.itemTotal = action.payload?.data?.count;
      state.paginationSync.page = action?.meta?.arg?.page;
    });
    builder.addCase(Aquisition.aquisitionList.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
      state.items = [];
      state.paginationSync.page = 1;
    });

    // acquisition approval
    builder.addCase(Aquisition.aquisitionApproval.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Aquisition.aquisitionApproval.fulfilled, (state, action) => {
      state.loading = false;
      const result = [];
      let file = {};
      action.payload.data.results.map((res, i) => {
        result.push({ ...res, key: i });
        if (res.status.value === 0) file = res;
      });
      state.approvalFile = file;
      state.approval = { ...action.payload.data, results: result };
    });
    builder.addCase(Aquisition.aquisitionApproval.rejected, (state) => {
      state.loading = false;
    });

    // acquisition submit new data
    builder.addCase(Aquisition.aquisitionSubmissionNewData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Aquisition.aquisitionSubmissionNewData.fulfilled, (state, action) => {
      state.loading = false;
      state.submission = action.payload.data;
    });
    builder.addCase(Aquisition.aquisitionSubmissionNewData.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Upload Data',
        description: action?.payload?.message?.id || action?.payload?.file_id62[0],
      });
      state.loading = false;
    });

    // acquisition submission
    builder.addCase(Aquisition.aquisitionSubmission.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Aquisition.aquisitionSubmission.fulfilled, (state, action) => {
      state.submissionList = action.payload.data;
      state.loading = false;
    });
    builder.addCase(Aquisition.aquisitionSubmission.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // acquisition submission recommendation
    builder.addCase(Aquisition.aquisitionSubmissionRecommendation.pending, (state) => {
      state.loadingSend = false;
      state.selectedRowKeys = [];
      state.selectedRow = [];
    });
    builder.addCase(Aquisition.aquisitionSubmissionRecommendation.fulfilled, (state, action) => {
      const selectedLength = state.selectedRowKeys?.length;
      let recommendationKey = [];

      let recommendationResult = action.payload.data?.acquisition;
      // init recomendation key
      recommendationResult?.map((res) => {
        if (!res?.is_picked && res?.id62) {
          // auto check recommendation not reject
          recommendationKey.push(res.id62);
        }
      });

      if (selectedLength !== 0 && selectedLength !== recommendationKey?.length) {
        recommendationKey = state.selectedRowKeys;
        recommendationResult = state.selectedRow;
      }

      state.selectedRowKeys = recommendationKey || [];
      state.selectedRow = recommendationResult || [];
    });
    builder.addCase(Aquisition.aquisitionSubmissionRecommendation.rejected, (_, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
    });

    // acquisition download excel
    builder.addCase(Aquisition.aquisitionDownloadExcel.pending, (state) => {
      state.loadingDownload = true;
    });
    builder.addCase(Aquisition.aquisitionDownloadExcel.fulfilled, (state, action) => {
      state.loadingDownload = false;
      state.download = action.payload.data;
    });
    builder.addCase(Aquisition.aquisitionDownloadExcel.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Download Data',
        description: action?.payload?.message?.id,
      });
      state.loadingDownload = false;
    });

    // acquisition asset download excel
    builder.addCase(Aquisition.aquisitionAssetDownloadFormatExcel.pending, (state) => {
      state.loadingDownload = true;
    });
    builder.addCase(Aquisition.aquisitionAssetDownloadFormatExcel.fulfilled, (state, action) => {
      state.loadingDownload = false;
      state.download = action.payload.data;
    });
    builder.addCase(Aquisition.aquisitionAssetDownloadFormatExcel.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Download Data',
        description: action?.payload?.message?.id,
      });
      state.loadingDownload = false;
    });

    // acquisition delete file
    builder.addCase(Aquisition.aquisitionDeleteFile.pending, (state) => {
      state.loadingDelete = true;
    });
    builder.addCase(Aquisition.aquisitionDeleteFile.fulfilled, (state, action) => {
      state.loadingDelete = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: action?.payload?.message?.id,
      });
    });
    builder.addCase(Aquisition.aquisitionDeleteFile.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Hapus Data',
        description: action?.payload?.message?.id,
      });
      state.loadingDelete = false;
    });

    // acquisition send file
    builder.addCase(Aquisition.aquisitionSendDataUpload.pending, (state) => {
      state.loadingSend = true;
    });
    builder.addCase(Aquisition.aquisitionSendDataUpload.fulfilled, (state, action) => {
      state.loadingSend = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: action?.payload?.message?.id,
      });
    });
    builder.addCase(Aquisition.aquisitionSendDataUpload.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Kirim Data',
        description: action?.payload?.message?.id || action?.payload?.file_id62[0],
      });
      state.loadingSend = false;
    });

    // acquisition total diff
    builder.addCase(Aquisition.getTotalDiffAcquistion.pending, (state) => {
      state.loadingDiffAcquisition = true;
    });
    builder.addCase(Aquisition.getTotalDiffAcquistion.fulfilled, (state, action) => {
      state.totalDiffAcquisition = action.payload.data;
      state.loadingDiffAcquisition = false;
    });
    builder.addCase(Aquisition.getTotalDiffAcquistion.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loadingDiffAcquisition = false;
    });

    // acquisition admin request
    builder.addCase(Aquisition.acquisitionAdminRequest.pending, (state) => {
      state.loadingRequest = true;
    });
    builder.addCase(Aquisition.acquisitionAdminRequest.fulfilled, (state, action) => {
      state.loadingRequest = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: action?.payload?.message?.id,
      });
    });
    builder.addCase(Aquisition.acquisitionAdminRequest.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Kirim Request Akuisisi',
        description: Util.errorHandle(action.payload),
      });
      state.loadingRequest = false;
    });

    // acquisition approval by admin
    builder.addCase(Aquisition.aquisitionApprovalByAdmin.pending, (state) => {
      state.loadingSend = true;
    });
    builder.addCase(Aquisition.aquisitionApprovalByAdmin.fulfilled, (state) => {
      state.loadingSend = false;
    });
    builder.addCase(Aquisition.aquisitionApprovalByAdmin.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Kirim Data',
        description: Util.errorHandle(action.payload),
      });
      state.loadingSend = false;
    });

    // acquisition summary...
    builder.addCase(Aquisition.acquisitionSummary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Aquisition.acquisitionSummary.fulfilled, (state, action) => {
      state.summaryNpv = action.payload.data;
      state.loading = false;
    });
    builder.addCase(Aquisition.acquisitionSummary.rejected, (state) => {
      state.loading = false;
    });

    // acquisition summary after acquisition
    builder.addCase(Aquisition.acquisitionApprovalSummary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Aquisition.acquisitionApprovalSummary.fulfilled, (state, action) => {
      // DO: sort 'AKUISISI' always in last index
      const sort = action.payload.data
        ?.sort((a, b) => a?.description?.localeCompare(b?.description))
        ?.reverse();
      state.cashflowSummary = sort.slice(-1)[0]?.cashflow;
      state.summary = sort;
      state.loading = false;
    });
    builder.addCase(Aquisition.acquisitionApprovalSummary.rejected, (state) => {
      state.loading = false;
    });

    // download excel summary after acquisition
    builder.addCase(Aquisition.acquisitionDownloadApprovalSummaryAcquisition.pending, (state) => {
      state.loadingDownload = true;
    });
    builder.addCase(Aquisition.acquisitionDownloadApprovalSummaryAcquisition.fulfilled, (state) => {
      state.loadingDownload = false;
    });
    builder.addCase(Aquisition.acquisitionDownloadApprovalSummaryAcquisition.rejected, (state) => {
      state.loadingDownload = false;
    });

    // download excel detail summary after acquisition
    builder.addCase(
      Aquisition.acquisitionDownloadDetailApprovalSummaryAcquisition.pending,
      (state) => {
        state.loadingDownload = true;
      }
    );
    builder.addCase(
      Aquisition.acquisitionDownloadDetailApprovalSummaryAcquisition.fulfilled,
      (state) => {
        state.loadingDownload = false;
      }
    );
    builder.addCase(
      Aquisition.acquisitionDownloadDetailApprovalSummaryAcquisition.rejected,
      (state) => {
        state.loadingDownload = false;
      }
    );

    // acquisition summary detail
    builder.addCase(Aquisition.acquisitionApprovalSummaryDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Aquisition.acquisitionApprovalSummaryDetail.fulfilled, (state, action) => {
      state.approvalSummaryDetail = action.payload.data;
      state.loading = false;
    });
    builder.addCase(Aquisition.acquisitionApprovalSummaryDetail.rejected, (state) => {
      state.loading = false;
    });

    // acquisition approve by supervisor
    builder.addCase(Aquisition.aquisitionApprovalBySupervisor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Aquisition.aquisitionApprovalBySupervisor.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(Aquisition.aquisitionApprovalBySupervisor.rejected, (state) => {
      state.loading = false;
    });

    // create product upload asset
    builder.addCase(Aquisition.acquisitionUpload.pending, (state) => {
      state.loadingUpload = true;
    });
    builder.addCase(Aquisition.acquisitionUpload.fulfilled, (state) => {
      state.loadingUpload = false;
    });
    builder.addCase(Aquisition.acquisitionUpload.rejected, (state) => {
      state.loadingUpload = false;
    });

    // get acquisition summary list after upload assets
    builder.addCase(Aquisition.aquisitionSummaryList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Aquisition.aquisitionSummaryList.fulfilled, (state, action) => {
      state.loading = false;
      state.summaryList = action.payload?.data;
    });
    builder.addCase(Aquisition.aquisitionSummaryList.rejected, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.summaryList = initialState.summaryList;
    });

    // delete acquisition summary
    builder.addCase(Aquisition.aquisitionSummaryDelete.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Aquisition.aquisitionSummaryDelete.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(Aquisition.aquisitionSummaryDelete.rejected, (state) => {
      state.loading = false;
    });

    // download excel acquisition
    builder.addCase(Aquisition.acquisitionExportExcel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Aquisition.acquisitionExportExcel.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(Aquisition.acquisitionExportExcel.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setSelectedRowKeys, removeSelectedRowKey } = aquisitionSlice.actions;
export default aquisitionSlice.reducer;
