import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Page, PoolOfAssetComponent } from '../../components';
import CustomTabs from '../../components/_shared/custom-tabs';
import { setTabSelected } from '../../store/pool-of-asset';

const PoolOfAsset = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  const approvalId = searchParams.get('approval-id');
  const ebaName = searchParams.get('eba-name');
  const ebaEnd = searchParams.get('eba-end');

  const breadcrumbs = [
    { name: 'Management Produk', href: '/management-product' },
    { name: ebaName, href: `/assets?page=product&eba-id=${ebaId}&eba-name=${ebaName}` },
    { name: 'Debitur Akuisisi', href: null },
  ];

  const tabsItem = [
    {
      label: 'Summary',
      children: <PoolOfAssetComponent.Summary approvalId={approvalId} ebaEnd={ebaEnd} />,
    },
    {
      label: 'Debitur Akuisisi',
      children: <PoolOfAssetComponent.PoolOfAsset approvalId={approvalId} />,
    },
    {
      label: 'Record',
      children: <PoolOfAssetComponent.Record approvalId={approvalId} />,
    },
  ];

  useEffect(() => {
    // set tab selected to 1 when revisit page
    dispatch(setTabSelected(1));
  }, []);

  const onTabClick = (e) => {
    dispatch(setTabSelected(e));
  };

  return (
    <Page title="Debitur Akuisisi" breadcrumbs={breadcrumbs}>
      <CustomTabs onTabClick={onTabClick} tabsItem={tabsItem} />
    </Page>
  );
};

export default PoolOfAsset;
