import React from 'react';
import { BiPencil } from 'react-icons/bi';
import { ManagementProductComponent } from '../..';

export default function EquityBufferAction({ item }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div className="flex justify-center gap-5">
        <BiPencil style={{ cursor: 'pointer' }} onClick={() => setOpen(true)} />
      </div>
      <ManagementProductComponent.EquityBufferForm
        open={open}
        onClose={() => setOpen(false)}
        id62={item?.id62}
        isUpdate={true}
        ebaId={item?.eba}
        data={item}
      />
    </>
  );
}
