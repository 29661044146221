import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Util } from '../../../helper';
import { columnsDetailNotUpdate } from '../../../screens/entry-data/components/tableConfig';
import { PeriodicPayment } from '../../../service';
import Table from '../../table';

const LostData = ({ idEba }) => {
  const dispatch = useDispatch();

  const { unupdated, loading, summary } = useSelector((state) => state.periodic);

  const [page, setPage] = useState({ limit: 10, offset: 0, current: 1 });

  const unupdatedData = ({ limit, offset }) => {
    dispatch(
      PeriodicPayment.unupdatedPeriodicPayment({
        ebaId: idEba,
        limit: limit,
        offset: offset,
        transactionDate: summary?.transaction_date || moment().format('YYYY-MM-DD'),
      })
    );
  };

  useEffect(() => {
    unupdatedData({ limit: page.limit, offset: page.offset });
  }, [page]);

  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
    Util.scrollToElement('table-unupdated');
  };

  return (
    <Fragment>
      <Table.TableGlobal
        id="table-unupdated"
        key="table-unupdated"
        columns={columnsDetailNotUpdate}
        dataSource={unupdated?.results}
        loading={loading}
        className="mt-10"
        totalData={unupdated?.count}
        current={page.current}
        onChangePagination={paginationChange}
      />
    </Fragment>
  );
};

export default LostData;
