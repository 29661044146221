import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const chunkUpload = createAsyncThunk('post/chunkUpload', async (body, { rejectWithValue }) => {
  const { params = '', payload } = body;
  try {
    return await API.post({ url: `reference/chunk-upload/${params}`, payload: payload });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const base64Upload = createAsyncThunk('post/base64Upload', async (body, { rejectWithValue }) => {
  const { base64 = '', fileName } = body;
  try {
    return await API.post({
      url: `reference/base64-upload/`,
      payload: {
        file_name: fileName,
        file_base64: base64,
      },
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

export default {
  chunkUpload,
  base64Upload,
};
