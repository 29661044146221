import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Cashflow } from '../../service';

const initialState = {
  loading: false,
  loadingGenerate: false,
  loss: [],
  acquisition: [],
  combination: [],
  generate: {},
};

export const cashflowSlice = createSlice({
  name: 'cashflow',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Summary Cashflow Loss
    builder.addCase(Cashflow.cashflowLoss.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cashflow.cashflowLoss.fulfilled, (state, action) => {
      state.loss = action.payload.data;
      state.loading = false;
    });
    builder.addCase(Cashflow.cashflowLoss.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed to Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // Summary Cashflow Acquisition
    builder.addCase(Cashflow.cashflowAcquisition.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cashflow.cashflowAcquisition.fulfilled, (state, action) => {
      state.acquisition = action.payload.data;
      state.loading = false;
    });
    builder.addCase(Cashflow.cashflowAcquisition.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed to Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // Summary Cashflow Acquisition Combination
    builder.addCase(Cashflow.cashflowCombination.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Cashflow.cashflowCombination.fulfilled, (state, action) => {
      state.combination = action.payload.data;
      state.loading = false;
    });
    builder.addCase(Cashflow.cashflowCombination.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed to Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // Cashflow Generated
    builder.addCase(Cashflow.cashflowGenerate.pending, (state) => {
      state.loadingGenerate = true;
    });
    builder.addCase(Cashflow.cashflowGenerate.fulfilled, (state, action) => {
      state.generate = action.payload.data;
      state.loadingGenerate = false;
    });
    builder.addCase(Cashflow.cashflowGenerate.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Generate Cashflow',
        description: action.payload?.message?.id || 'Error pada system',
      });
      state.loadingGenerate = false;
    });
  },
});

export default cashflowSlice.reducer;
