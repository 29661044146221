import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const periodicUpload = createAsyncThunk(
  'post/periodicUpload',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({ url: 'periodic-payment/upload-data/', payload });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const periodicSummary = createAsyncThunk(
  'get/periodicSummary',
  async (payload, { rejectWithValue }) => {
    const {
      search = '',
      start_date = '',
      eba_id62 = '',
      file_id62 = '',
      limit = '',
      offset = '',
    } = payload;
    try {
      return await API.get({
        url: `periodic-payment/summary/?search=${search}&start_date=${start_date}&eba_id62=${eba_id62}&file_id62=${file_id62}&limit=${limit}&offset=${offset}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const periodicSummarySend = createAsyncThunk(
  'post/periodicSummarySend',
  async (fileId, { rejectWithValue }) => {
    try {
      return await API.post({
        url: `periodic-payment/summary/send/`,
        payload: { file_id62: fileId },
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const periodicFail = createAsyncThunk('post/periodicFail', async (payload, { rejectWithValue }) => {
  const {
    search = '',
    start_date = '',
    eba_id62 = '',
    file_id62 = '',
    limit = '',
    offset = '',
    data_type = '',
  } = payload;
  try {
    return await API.get({
      url: `periodic-payment/fail/?search=${search}&start_date=${start_date}&eba_id62=${eba_id62}&file_id62=${file_id62}&data_type=${data_type}&limit=${limit}&offset=${offset}`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const summaryPeriodicPayment = createAsyncThunk(
  'post/summaryPeriodicPayment',
  async ({ ebaId, month, fileId, isPublish }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `periodic-payment/summary/dashboard/?eba_id62=${ebaId}&file_id62=${fileId}&period_date=${month}&publish=${isPublish}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const historyPeriodicPayment = createAsyncThunk(
  'get/historyPeriodicPayment',
  async (payload, { rejectWithValue }) => {
    const { ebaId, fileId = '', limit, offset, isArchive = false } = payload;
    try {
      return await API.get({
        url: `periodic-payment/history/?eba_id62=${ebaId}&file_id62=${fileId}&limit=${limit}&offset=${offset}&archive=${isArchive}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const periodicDownloadFormatExcel = createAsyncThunk(
  'get/periodicDownloadFormatExcel',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.get({
        url: 'periodic-payment/format-excel/',
        isDownload: true,
        fileName: 'Format_Update_Data_Bulanan',
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const periodicDownloadExcel = createAsyncThunk(
  'get/periodicDownloadExcel',
  async (payload, { rejectWithValue }) => {
    const { fileId, ebaId, periodDate, ebaName, isPublish } = payload;
    try {
      return await API.get({
        url: `periodic-payment/summary/export-excel/?eba_id62=${ebaId}&period_date=${periodDate}&publish=${isPublish}&file_id62=${fileId}`,
        isDownload: true,
        fileName: `Summary_Update_Data_Period_${periodDate}_${ebaName}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const periodicSummaryDetail = createAsyncThunk(
  'get/periodicSummaryDetail',
  async (payload, { rejectWithValue }) => {
    const {
      search = '',
      start_date = '',
      eba_id62 = '',
      file_id62 = '',
      year = '',
      period_date = '',
      limit = '',
      offset = '',
    } = payload;
    try {
      return await API.get({
        url: `periodic-payment/?search=${search}&start_date=${start_date}&eba_id62=${eba_id62}&file_id62=${file_id62}&limit=${limit}&offset=${offset}&year=${year}&period_date=${period_date}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const unupdatedPeriodicPayment = createAsyncThunk(
  'get/unupdatedPeriodicPayment',
  async (payload, { rejectWithValue }) => {
    const { ebaId, transactionDate = '', limit, offset } = payload;
    try {
      return await API.get({
        url: `periodic-payment/unupdated/?eba_id62=${ebaId}&transaction_date=${transactionDate}&limit=${limit}&offset=${offset}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const DownloadExcel = createAsyncThunk(
  'get/exportExcel',
  async ({ ebaId, period, ebaName }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `/periodic-payment/format-excel/?eba_id62=${ebaId}&period=${period}`,
        isDownload: true,
        fileName: `Summary_update_data_${period}_${ebaName}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default {
  periodicUpload,
  periodicSummary,
  periodicFail,
  summaryPeriodicPayment,
  periodicDownloadFormatExcel,
  historyPeriodicPayment,
  periodicSummarySend,
  periodicSummaryDetail,
  unupdatedPeriodicPayment,
  periodicDownloadExcel,
  DownloadExcel,
};
