import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Util } from '../../helper';
import { PoolOfAsset } from '../../service';

const initialState = {
  poolOfAsset: {},
  summary: [],
  record: {},
  tabSelected: 1,
  loading: false,
  loadingDownload: false,
  filter: {
    dbr_max: '',
    dbr_min: '',
    otm: '',
    max: '',
    min: '',
    tenor_max: '',
    tenor_min: '',
    age: '',
    holding: '',
    month: '',
  },
  filterActive: [],
};

export const poolOfAssetSlice = createSlice({
  name: 'poolOfAsset',
  initialState,
  reducers: {
    setTabSelected: (state, action) => {
      state.tabSelected = action.payload;
    },
    setFilter: (state, action) => {
      const { id, name, data } = action.payload;

      const found = state.filterActive.some((e) => e?.key === id);
      state.filter = { ...state.filter, [id]: data };
      if (!found) {
        state.filterActive = [...state.filterActive, { key: id, name: name }];
      }
    },
    removeFilter: (state, action) => {
      const id = action.payload;
      state.filter = { ...state.filter, [id]: '' };
      const filter = state.filterActive.filter((e) => e?.key !== id);
      state.filterActive = filter;
    },
  },
  extraReducers: (builder) => {
    // download template pool of asset
    builder.addCase(PoolOfAsset.downloadTemplate.pending, (state) => {
      state.loadingDownload = true;
    });
    builder.addCase(PoolOfAsset.downloadTemplate.fulfilled, (state) => {
      state.loadingDownload = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Sukses Unduh Data',
      });
    });
    builder.addCase(PoolOfAsset.downloadTemplate.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Unduh Data',
        description: Util.errorHandle(action.payload),
      });
      state.loadingDownload = false;
    });

    // pool of asset
    builder.addCase(PoolOfAsset.poolOfAsset.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(PoolOfAsset.poolOfAsset.fulfilled, (state, action) => {
      state.loading = false;
      state.poolOfAsset = action.payload.data;
    });
    builder.addCase(PoolOfAsset.poolOfAsset.rejected, (state) => {
      state.loading = false;
    });

    // summary
    builder.addCase(PoolOfAsset.summary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(PoolOfAsset.summary.fulfilled, (state, action) => {
      state.loading = false;
      state.summary = [action.payload.data];
    });
    builder.addCase(PoolOfAsset.summary.rejected, (state) => {
      state.loading = false;
    });

    // record
    builder.addCase(PoolOfAsset.record.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(PoolOfAsset.record.fulfilled, (state, action) => {
      state.loading = false;
      state.record = action.payload.data;
    });
    builder.addCase(PoolOfAsset.record.rejected, (state) => {
      state.loading = false;
    });

    // download template record
    builder.addCase(PoolOfAsset.downloadRecord.pending, (state) => {
      state.loadingDownload = true;
    });
    builder.addCase(PoolOfAsset.downloadRecord.fulfilled, (state) => {
      state.loadingDownload = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Sukses Unduh Data',
      });
    });
    builder.addCase(PoolOfAsset.downloadRecord.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Unduh Data',
        description: Util.errorHandle(action.payload),
      });
      state.loadingDownload = false;
    });

    // download summary
    builder.addCase(PoolOfAsset.downloadSummary.pending, (state) => {
      state.loadingDownload = true;
    });
    builder.addCase(PoolOfAsset.downloadSummary.fulfilled, (state) => {
      state.loadingDownload = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Sukses Unduh Data',
      });
    });
    builder.addCase(PoolOfAsset.downloadSummary.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Unduh Data',
        description: Util.errorHandle(action.payload),
      });
      state.loadingDownload = false;
    });
  },
});

export const { setTabSelected, setFilter, removeFilter } = poolOfAssetSlice.actions;

export default poolOfAssetSlice.reducer;
