import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const createBei = createAsyncThunk('post/createBei', async (body, { rejectWithValue }) => {
  try {
    return await API.post({ url: 'param/bursa/', payload: body });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getBei = createAsyncThunk('get/getBei', async (ebaId, { rejectWithValue }) => {
  try {
    return await API.get({ url: `param/bursa/eba/${ebaId}/` });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const updateBei = createAsyncThunk('put/updateBei', async (payload, { rejectWithValue }) => {
  const { id62, body } = payload;
  try {
    return await API.put({ url: `param/bursa/${id62}/`, payload: body });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const deleteBei = createAsyncThunk('delete/deleteBei', async (ebaId, { rejectWithValue }) => {
  try {
    return await API.deleteRequest({ url: `param/bursa/${ebaId}/` });
  } catch (error) {
    return rejectWithValue(error);
  }
});

export default { createBei, deleteBei, updateBei, getBei };
