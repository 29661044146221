import React from 'react';
import Table from '../../table';
import { columnsInformasiPembayaran } from './columns';

const tableInformasiTambahan = ({ page, setPage, item }) => {
  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
  };

  return (
    <Table.TableGlobal
      columns={columnsInformasiPembayaran}
      dataSource={item}
      totalData={item.length}
      className="table-preview-data"
      scroll={{ x: 1200 }}
      bordered
      onChangePagination={paginationChange}
      current={page.current}
      keyRow={'informasi_pembayaran'}
    />
  );
};

export default tableInformasiTambahan;
