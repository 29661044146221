import { Format } from '../../../helper';

const columnsProfileLogExternal = [
  {
    title: 'Tanggal Efektif',
    dataIndex: 'profile',
    align: 'center',
    render: (record) => record?.tanggal_efektif,
  },
  {
    title: 'Tanggal Masa Penawaran Umum Awal',
    dataIndex: 'profile',
    align: 'center',
    render: (record) => record?.tanggal_masa_penawaran_umum_awal,
  },
  {
    title: 'Jenis Tagihan',
    dataIndex: 'profile',
    align: 'center',
    render: (record) => record?.jenis_tagihan,
  },
];

const columnsInfoUmumLogExternal = [
  {
    title: 'Dividen',
    children: [
      {
        title: 'Dividen (Rp)',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 160,
        render: (record) =>
          isNaN(record?.dividen_rp)
            ? record?.dividen_rp
            : Format.numberFormat(Math.round(record?.dividen_rp)),
      },
      {
        title: 'Dividen (Non Rp)',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 160,
        render: (record) =>
          isNaN(record?.dividen_non_rp)
            ? record?.dividen_non_rp
            : Format.numberFormat(Math.round(record?.dividen_non_rp)),
      },
      {
        title: 'Dividen (%)',
        dataIndex: 'informasi_umum',
        align: 'center',
        width: 160,
        render: (record) => record?.dividen_percentage,
      },
    ],
  },
  {
    title: 'Jumlah Total Tagihan Awal (Non-Rp)',
    dataIndex: 'informasi_umum',
    align: 'center',
    render: (record) =>
      isNaN(record?.jumlah_total_nilai_tagihan_awal_non_rp)
        ? record?.jumlah_total_nilai_tagihan_awal_non_rp
        : Format.numberFormat(Math.round(record?.jumlah_total_nilai_tagihan_awal_non_rp)),
  },
  {
    title: 'Total Nilai Agunan Awal',
    children: [
      {
        title: 'Total Nilai Agunan Awal (Rp)',
        dataIndex: 'informasi_umum',
        align: 'center',
        render: (record) =>
          isNaN(record?.total_nilai_agunan_awal_rp)
            ? record?.total_nilai_agunan_awal_rp
            : Format.numberFormat(Math.round(record?.total_nilai_agunan_awal_rp)),
      },
      {
        title: 'Total Nilai Agunan Awal (Non-Rp)',
        dataIndex: 'informasi_umum',
        align: 'center',
        render: (record) =>
          isNaN(record?.total_nilai_agunan_awal_non_rp)
            ? record?.total_nilai_agunan_awal_non_rp
            : Format.numberFormat(Math.round(record?.total_nilai_agunan_awal_non_rp)),
      },
    ],
  },
  {
    title: 'Nilai Tagihan Sampai Dengan Akhir Periode Laporan (Non-Rp)',
    dataIndex: 'informasi_umum',
    align: 'center',
    render: (record) =>
      isNaN(record?.nilai_tagihan_sampai_akhir_periode_laporan_non_rp)
        ? record?.nilai_tagihan_sampai_akhir_periode_laporan_non_rp
        : Format.numberFormat(
            Math.round(record?.nilai_tagihan_sampai_akhir_periode_laporan_non_rp)
          ),
  },
  {
    title: 'Nilai Agunan Sampai Dengan Akhir Periode Laporan',
    children: [
      {
        title: 'Nilai Agunan Sampai Dengan Akhir Periode Laporan (Rp)',
        dataIndex: 'informasi_umum',
        align: 'center',
        render: (record) =>
          isNaN(record?.nilai_agunan_sampai_akhir_periode_laporan_rp)
            ? record?.nilai_agunan_sampai_akhir_periode_laporan_rp
            : Format.numberFormat(Math.round(record?.nilai_agunan_sampai_akhir_periode_laporan_rp)),
      },
      {
        title: 'Nilai Agunan Sampai Dengan Akhir Periode Laporan (Non-Rp)',
        dataIndex: 'informasi_umum',
        align: 'center',
        render: (record) =>
          isNaN(record?.nilai_tagihan_sampai_akhir_periode_laporan_non_rp)
            ? record?.nilai_tagihan_sampai_akhir_periode_laporan_non_rp
            : Format.numberFormat(
                Math.round(record?.nilai_tagihan_sampai_akhir_periode_laporan_non_rp)
              ),
      },
    ],
  },
  {
    title:
      'Persentase Rata-Rata Tertimbang Nilai Tagihan Terhadap Nilai Agunan Sampai Dengan Akhir Periode Laporan',
    dataIndex: 'informasi_umum',
    align: 'center',
    render: (record) =>
      isNaN(record?.persentase_rerata_tertimbang_nilai_tagihan)
        ? record?.persentase_rerata_tertimbang_nilai_tagihan
        : Format.numberFormat(Math.round(record?.persentase_rerata_tertimbang_nilai_tagihan)),
  },
  {
    title: 'Akumulasi Nilai Pembayaran Dipercepat (Prepayment) Sampai Dengan Akhir Periode Laporan',
    children: [
      {
        title: 'Akumulasi Nilai Tagihan Yang Dihapus Buku Sampai Dengan Akhir Periode Laporan (Rp)',
        dataIndex: 'informasi_umum',
        align: 'center',
        render: (record) =>
          isNaN(record?.akumulasi_nilai_tagihan_dihapus_buku_rp)
            ? record?.akumulasi_nilai_tagihan_dihapus_buku_rp
            : Format.numberFormat(Math.round(record?.akumulasi_nilai_tagihan_dihapus_buku_rp)),
      },
      {
        title:
          'Akumulasi Nilai Tagihan Yang Dihapus Buku Sampai Dengan Akhir Periode Laporan (Non-Rp)',
        dataIndex: 'informasi_umum',
        align: 'center',
        render: (record) =>
          isNaN(record?.akumulasi_nilai_tagihan_dihapus_buku_non_rp)
            ? record?.akumulasi_nilai_tagihan_dihapus_buku_non_rp
            : Format.numberFormat(Math.round(record?.akumulasi_nilai_tagihan_dihapus_buku_non_rp)),
      },
    ],
  },
  {
    title: 'Akumulasi Nilai Pemulihan Hapus Buku Sampai Dengan Akhir Periode Laporan',
    children: [
      {
        title: 'Akumulasi Nilai Pemulihan Hapus Buku Sampai Dengan Akhir Periode Laporan (Rp)',
        dataIndex: 'informasi_umum',
        align: 'center',
        render: (record) =>
          isNaN(record?.akumulasi_nilai_pemulihan_hapus_buku_rp)
            ? record?.akumulasi_nilai_pemulihan_hapus_buku_rp
            : Format.numberFormat(Math.round(record?.akumulasi_nilai_pemulihan_hapus_buku_rp)),
      },
      {
        title: 'Akumulasi Nilai Pemulihan Hapus Buku Sampai Dengan Akhir Periode Laporan (Non-Rp)',
        dataIndex: 'informasi_umum',
        align: 'center',
        render: (record) =>
          isNaN(record?.akumulasi_nilai_pemulihan_hapus_buku_non_rp)
            ? record?.akumulasi_nilai_pemulihan_hapus_buku_non_rp
            : Format.numberFormat(Math.round(record?.akumulasi_nilai_pemulihan_hapus_buku_non_rp)),
      },
    ],
  },
];

const columnsInfoTagihLogExternal = [
  {
    title: 'Akumulasi Tagihan Bunga Yang Tertagih Sampai Dengan Akhir Periode Laporan (Non-Rp)',
    dataIndex: 'informasi_tagihan',
    align: 'center',
    width: 370,
    render: (record) =>
      isNaN(record?.akumulasi_tagihan_bunga_tertagih_non_rp)
        ? record?.akumulasi_tagihan_bunga_tertagih_non_rp
        : Format.numberFormat(Math.round(record?.akumulasi_tagihan_bunga_tertagih_non_rp)),
  },
  {
    title: 'Akumulasi Tagihan Denda Yang Tertagih Sampai Dengan Akhir Periode Laporan',
    children: [
      {
        title: 'Akumulasi Tagihan Denda Yang Tertagih Sampai Dengan Akhir Periode Laporan (Rp)',
        dataIndex: 'informasi_tagihan',
        align: 'center',
        render: (record) =>
          isNaN(record?.akumulasi_tagihan_denda_rp)
            ? record?.akumulasi_tagihan_denda_rp
            : Format.numberFormat(Math.round(record?.akumulasi_tagihan_denda_rp)),
      },
      {
        title: 'Akumulasi Tagihan Denda Yang Tertagih Sampai Dengan Akhir Periode Laporan (Non-Rp)',
        dataIndex: 'informasi_tagihan',
        align: 'center',
        render: (record) =>
          isNaN(record?.akumulasi_tagihan_denda_non_rp)
            ? record?.akumulasi_tagihan_denda_non_rp
            : Format.numberFormat(Math.round(record?.akumulasi_tagihan_denda_non_rp)),
      },
    ],
  },
];

const columnsInfoTambahanLogExternal = [
  {
    title: 'Tanggal Pembayaran',
    dataIndex: 'informasi_pembayaran',
    align: 'center',
    width: 200,
    render: (record) => record?.tanggal_pembayaran,
  },
  {
    title: 'Akumulasi Bunga / Dividen Dibayarkan Kepada Pemegang EBA (Non-Rp)',
    dataIndex: 'informasi_pembayaran',
    align: 'center',
    render: (record) =>
      isNaN(record?.akumulasi_bunga_non_rp)
        ? record?.akumulasi_bunga_non_rp
        : Format.numberFormat(Math.round(record?.akumulasi_bunga_non_rp)),
  },
  {
    title: 'Akumulasi Pokok Dibayarkan Kepada Pemegang EBA (Non-Rp)',
    dataIndex: 'informasi_pembayaran',
    align: 'center',
    render: (record) =>
      isNaN(record?.akumulasi_pokok_non_rp)
        ? record?.akumulasi_pokok_non_rp
        : Format.numberFormat(Math.round(record?.akumulasi_pokok_non_rp)),
  },
  {
    title: 'Akumulasi Hasil Investasi Dibayarkan Kepada Pemegang EBA (Non-Rp)',
    dataIndex: 'informasi_pembayaran',
    align: 'center',
    render: (record) =>
      isNaN(record?.akumulasi_hasil_investasi_non_rp)
        ? record?.akumulasi_hasil_investasi_non_rp
        : Format.numberFormat(Math.round(record?.akumulasi_hasil_investasi_non_rp)),
  },
];

const columnsInvestor = [
  {
    title: 'Kode EBA',
    dataIndex: 'investor',
    align: 'center',
    width: 180,
  },
  {
    title: 'Single Investor Identification',
    dataIndex: 'investor',
    align: 'center',
    width: 180,
  },
  {
    title: 'Nama Investor',
    dataIndex: 'investor',
    align: 'center',
    width: 180,
  },
  {
    title: 'Jenis Investor',
    dataIndex: 'investor',
    align: 'center',
    width: 180,
  },
  {
    title: 'Keterangan Investor',
    dataIndex: 'investor',
    align: 'center',
    width: 180,
  },
  {
    title: 'Kewarnaganegaraan',
    dataIndex: 'investor',
    align: 'center',
    width: 180,
  },
  {
    title: 'Total Investasi Awal(Rp)',
    dataIndex: 'investor',
    align: 'center',
    width: 180,
    render: (record) => (isNaN(record) ? record : Format.numberFormat(Math.round(record))),
  },
  {
    title: ' Total Investasi Akhir Periode(Rp)',
    dataIndex: 'investor',
    align: 'center',
    width: 180,
    render: (record) => (isNaN(record) ? record : Format.numberFormat(Math.round(record))),
  },
];

export {
  columnsProfileLogExternal,
  columnsInfoUmumLogExternal,
  columnsInfoTagihLogExternal,
  columnsInfoTambahanLogExternal,
  columnsInvestor,
};
