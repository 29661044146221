export default {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
  ADMIN: 'admin',
  SUPERVISOR: 'supervisor',
  ORIGINATOR: 'originator',
  ACQUISITION: 'AKUISISI',
  FALSE: 'False',
  TRUE: 'True',
};
