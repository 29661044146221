import { Typography } from 'antd';
import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import Table from '../../components/table';
import { columnsFeedbackProduct } from './components/tableConfig';
import { useDispatch, useSelector } from 'react-redux';
import { Note } from '../../service';
import { CustomNotification } from '../../components/_shared';

const DetailFeedback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const productName = searchParams.get('product-name');
  const isSimulation = searchParams.get('is_simulation');
  const ebaId = searchParams.get('id62');

  const dispatch = useDispatch();
  const { detailList } = useSelector((state) => state.note);

  useEffect(() => {
    dispatch(Note.getDetailFeedback({ id62: ebaId, approval: 'False' }));
  }, [ebaId]);

  const breadcrumbs = [
    {
      name: `${isSimulation === 'true' ? 'Simulasi Produk' : 'Management Produk'}`,
      href: '/management-product',
    },
    { name: 'Detail Feedback', href: null },
  ];
  const GetDetailPage = (e) => {
    if (e.page === 'Eba Info') {
      navigate(
        `/management-product/eba-info?page=management-product&eba-id=${ebaId}&eba-name=${productName}&rejected=${e?.page}&status=${e?.status?.value}`
      );
    } else if (e.page === 'Upload Asset') {
      navigate(
        `/management-product/upload-assets?page=management-product&eba-id=${ebaId}&eba-name=${productName}&rejected=${e?.page}&status=${e?.status?.value}`
      );
    } else if (e.page === 'Ekuitas Buffer') {
      navigate(
        `/management-product/equity-buffer?page=management-product&eba-id=${ebaId}&eba-name=${productName}&rejected=${e?.page}&status=${e?.status?.value}`
      );
    } else if (e.page === 'Kelas') {
      navigate(
        `/management-product/asset-class?page=management-product&eba-id=${ebaId}&eba-name=${productName}&rejected=${e?.page}&status=${e?.status?.value}`
      );
    } else if (e.page === 'Biaya') {
      navigate(
        `/management-product/cost?page=management-product&eba-id=${ebaId}&eba-name=${productName}&rejected=${e?.page}&status=${e?.status?.value}`
      );
    } else if (e.page === 'Reinvestment') {
      navigate(
        `/management-product/re-investment?page=management-product&eba-id=${ebaId}&eba-name=${productName}&rejected=${e?.page}&status=${e?.status?.value}`
      );
    } else {
      CustomNotification({
        type: 'error',
        message: 'Terjadi kesalahan sistem',
      });
    }
  };
  return (
    <Page title="Detail Feedback" breadcrumbs={breadcrumbs}>
      <div className="flex justify-between items-center my-6">
        <Typography.Paragraph className="font-bold text-3xl">
          {`Feedback - ${productName}`}
        </Typography.Paragraph>
      </div>
      <Table.TableGlobal
        columns={columnsFeedbackProduct(GetDetailPage)}
        dataSource={Array.isArray(detailList.results) ? detailList.results : []}
      />
    </Page>
  );
};

export default DetailFeedback;
