import dayjs from 'dayjs';
import { Format } from '../../../helper';

let dotRegex = new RegExp(/^[^.]*$/);

const rulesAssetPeriod = ({ interval, periodDiff, index }) => [
  {
    required: true,
    message: 'Field tidak boleh kosong',
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      const fieldValue = getFieldValue('fields');
      const lastPeriod = Number(fieldValue[index - 1] ? fieldValue[index - 1].period : 0);
      const nextInterval = lastPeriod + interval * 2;
      const nextPeriod = Number(
        fieldValue[index + 1] ? fieldValue[index + 1].period : nextInterval
      );

      if (!dotRegex.test(value)) {
        return Promise.reject(new Error(`Hanya masukkan bilangan bulat`));
      }
      value = Number(value);
      if (value > periodDiff) {
        return Promise.reject(new Error(`Lebih dari periode maksimal ${periodDiff} bulan`));
      }
      if (value <= lastPeriod && index !== 0) {
        return Promise.reject(new Error('Periode kurang dari atau sama dengan periode sebelumnya'));
      }
      if (value >= nextPeriod && index !== fieldValue?.length - 1) {
        return Promise.reject(new Error('Periode lebih dari atau sama dengan periode selanjutnya'));
      }
      if (!value) {
        return Promise.reject(new Error('Hanya masukkan angka'));
      }
      return Promise.resolve();
    },
  }),
];

const rulesAssetPercent = [
  {
    required: true,
    message: 'Field tidak boleh kosong',
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      let hundred = 0;
      const fieldValue = getFieldValue('fields');
      fieldValue?.forEach((res) => {
        hundred += Number(res?.percentage);
      });
      hundred = Number(hundred).toFixed(2);
      if (!Number(value) && value === 0) {
        return Promise.reject(new Error('Hanya masukkan angka'));
      }
      if (hundred < 100.0) {
        return Promise.reject(new Error(`Total peluruhan saat ini ${hundred}% kurang dari 100% `));
      }
      if (hundred > 100.0) {
        return Promise.reject(new Error(`Total peluruhan saat ini ${hundred}% lebih dari 100%`));
      }
      return Promise.resolve();
    },
  }),
];

const rulesEndDateClass = [
  {
    required: true,
    message: 'Field tidak boleh kosong',
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      const currnt = dayjs(value).format('YYYY-MM');
      const start = getFieldValue('start_date');
      const startMonth = dayjs(start).format('YYYY-MM');

      if (!value) {
        return Promise.reject();
      }
      if (startMonth && currnt <= startMonth && start) {
        return Promise.reject('Tidak bisa kurang dari bulan awal');
      }
      return Promise.resolve();
    },
  }),
];

const rulesStartDateClass = [
  {
    required: true,
    message: 'Field tidak boleh kosong',
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      const currnt = dayjs(value).format('YYYY-MM');
      const end = getFieldValue('end_date');
      const endMonth = dayjs(end).format('YYYY-MM');

      if (!value) {
        return Promise.reject();
      }
      if (endMonth && currnt >= endMonth && end) {
        return Promise.reject('Tidak bisa lebih dari bulan akhir');
      }
      return Promise.resolve();
    },
  }),
];

const rulesAmountClass = (budget, isUpdate, current) => [
  {
    required: true,
    message: 'Field tidak boleh kosong',
  },
  () => ({
    validator(_, value) {
      if (!Number(value)) {
        return Promise.reject('Hanya masukkan angka');
      }
      if (!isUpdate && value > budget) {
        return Promise.reject(`Lebih dari total portofolio ${Format.numberFormat(budget)}`);
      }
      if (isUpdate && value > parseInt(budget) + parseInt(current)) {
        return Promise.reject(
          `Lebih dari total portofolio ${Format.numberFormat(parseInt(budget) + parseInt(current))}`
        );
      }
      if (value < 1) {
        return Promise.reject('Kurang dari 1');
      }
      return Promise.resolve();
    },
  }),
];

const rulesImbalHasilClass = [
  {
    required: true,
    message: 'Field tidak boleh kosong',
  },
  () => ({
    validator(_, value) {
      if (!value) {
        return Promise.reject();
      }
      if (isNaN(value)) {
        return Promise.reject('Hanya angka');
      }
      if (value < 0) {
        return Promise.reject('Kurang dari 0');
      }
      if (value > 100) {
        return Promise.reject('Lebih dari 100%');
      }
      return Promise.resolve();
    },
  }),
];

export default {
  rulesAssetPeriod,
  rulesAssetPercent,
  rulesEndDateClass,
  rulesStartDateClass,
  rulesAmountClass,
  rulesImbalHasilClass,
};
