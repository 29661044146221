import React, { useState } from 'react';
import { Typography, Modal } from 'antd';
import { Format } from '../../helper';
import { CustomButton, SimpleTable } from '../_shared';

const CashflowCard = ({ title, type, data }) => {
  const [isOpenModal, setOpenModal] = useState(false);

  const tableHeader = [
    [
      {
        text: 'No',
      },
      {
        text: 'Nama',
      },
      {
        text: 'Total',
      },
    ],
  ];

  return (
    <div className="box-border border-1 border-[#F1F3F6] rounded-[10px] p-4 flex flex-row justify-between gap-6 w-1/2">
      <div>
        <Typography.Paragraph className="font-bold mb-[30px]">{title}</Typography.Paragraph>
        {typeof data === 'number' ? (
          <Typography.Paragraph className="font-bold text-4xl text-[#E70A09]">
            Rp {Format.numberFormat(Math.round(data))}
          </Typography.Paragraph>
        ) : (
          <Typography.Paragraph className="font-bold text-4xl text-[#E70A09]">
            Rp {Format.numberFormat(Math.round(data?.total))}
          </Typography.Paragraph>
        )}
      </div>
      {type === 'cost' ? (
        <>
          <div className="w-1/3 flex flex-col justify-center">
            {JSON.stringify(Modal, isOpenModal, setOpenModal)}

            <CustomButton
              type="primary-ghost"
              onClick={setOpenModal}
              disabled={!data?.details.length}>
              Lihat Detail
            </CustomButton>
            <Modal
              open={isOpenModal}
              centered
              onCancel={() => setOpenModal(false)}
              footer={null}
              width={1000}>
              <div className=" mt-8">
                {data?.details.length && (
                  <SimpleTable headers={tableHeader}>
                    {data?.details.map((item, key) => (
                      <tr key={key}>
                        <td className="border p-2 text-center">{key + 1}</td>
                        <td className="border p-2 text-center">{item?.cost_name}</td>
                        <td className="border p-2 text-center">
                          Rp {Format.numberFormat(Math.round(item?.cost_total))}
                        </td>
                      </tr>
                    ))}
                  </SimpleTable>
                )}
              </div>
            </Modal>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CashflowCard;
