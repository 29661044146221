import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormOtp } from '../../components/login';
import CreateNewPassword from '../create-new-password';

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const section = searchParams.get('section');

  const SectionShow = () => {
    switch (section) {
      case 'otp-input':
        return <FormOtp isAuth />;
      default:
        return <CreateNewPassword isAuth />;
    }
  };

  return (
    <div className="flex justify-center bg-white p-12 rounded-lg overflow-hidden max-w-[70vw] mx-auto">
      <SectionShow />
    </div>
  );
};

export default ForgotPassword;
