/* eslint-disable no-unused-vars */
import { Card, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomTable, ManagementProductComponent, Page } from '../../components';
import { CustomButton, ApproveDeclineModal } from '../../components/_shared';
import { Format, Util } from '../../helper';
import { assetClassColumn } from './components/tableConfig';
import { useSelector, useDispatch } from 'react-redux';
import { Eba, Investment, Transaction } from '../../service';
import usePage from '../../hooks/usePage';

export default function AssetClass() {
  const { isProduct } = usePage();
  const { isSupervisor, isAdmin } = Util.role();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const isSimulation = searchParams.get('is_simulation');
  const status = searchParams.get('status');
  const ebaId = searchParams.get('eba-id');
  const ebaName = searchParams.get('eba-name');

  const { detail: detailEba } = useSelector((state) => state.eba);
  const { items, loading, paginationSync, itemTotal, trigger, allClassComplete } = useSelector(
    (state) => state.investment
  );

  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [isDisableNext, setIsDisableNext] = useState(true);
  const [openAddClass, setOpenAddClass] = useState(false);

  const breadcrumbs = [
    {
      name: `${isSimulation === 'true' ? 'Simulasi Produk' : 'Management Produk'}`,
      href: '/management-product',
    },
    {
      name: 'Draft Produk',
      href: '/management-product/?status=1',
      isHide: isProduct || isSupervisor,
    },
    { name: 'Management Asset', href: null, isHide: !isProduct },
    { name: 'Senior Tranches', href: null },
  ];

  const fetchData = ({ offset }) => {
    dispatch(Investment.getClass({ ebaId: ebaId, limit: 10, offset }));
  };

  const paginationChange = (page, pageSize) => {
    fetchData({ offset: (page - 1) * pageSize, page });
  };

  useEffect(() => {
    dispatch(Eba.getDetailEba(ebaId));
    fetchData({ offset: 0 });
  }, [trigger]);

  useEffect(() => {
    dispatch(Transaction.getTransaction({ ebaId: ebaId, limit: 5, offset: 0, page: 1 }));
  }, [ebaId]);

  useEffect(() => {
    if (items?.length > 0) {
      if (allClassComplete) {
        setIsDisableNext(!allClassComplete);
      }
    }
  }, [items, allClassComplete]);

  const ButtonAddClass = ({ disabled = false }) => (
    <Tooltip
      title={disabled ? 'Tidak tersisa budget untuk input senior tranche' : ''}
      placement="bottomRight">
      <div>
        <CustomButton
          loading={loading}
          type="primary-ghost"
          className="
            group
            justify-center
            items-center
            flex
            space-x-2"
          disabled={disabled}
          {...(disabled && { style: { pointerEvents: 'none' } })}
          onClick={() => setOpenAddClass(true)}>
          <BsPlusCircleFill
            size={20}
            className={disabled ? 'text-grey' : `text-primary group-hover:text-white`}
          />
          Input Senior Tranches
        </CustomButton>
      </div>
    </Tooltip>
  );

  return (
    <Page title="Senior Tranches" breadcrumbs={breadcrumbs}>
      <div className="mb-2">
        <Typography.Paragraph className="font-bold text-3xl">Senior Tranches</Typography.Paragraph>
      </div>
      <div className="mb-2">
        <Typography.Paragraph className="font-bold text-xl">
          {ebaName ? ebaName : null}
        </Typography.Paragraph>
      </div>
      <Typography.Paragraph className="text-base">
        Di bawah ini merupakan detail informasi senior tranches
      </Typography.Paragraph>
      <div className="flex justify-between items-center gap-5 my-6">
        <Typography.Paragraph className="text-base font-bold">
          Asset Under Management
        </Typography.Paragraph>
        {!isSupervisor && (
          <ButtonAddClass
          // disabled={
          //   parseInt(summary.budget) == 0 && buffer?.itemTotal !== 0 && items.length !== 0
          // }
          />
        )}
      </div>
      <div className="flex justify-between items-center gap-3 mb-3">
        <Card className="p-1.5 w-full">
          <div className="flex flex-col">
            <Typography.Text className="text-base font-semibold">Penerbitan Awal</Typography.Text>
            <Typography.Text className="text-primary text-2xl">
              Rp. {Format.numberFormat(detailEba?.issuance_value)}
            </Typography.Text>
          </div>
        </Card>
        <Card className="p-1.5 w-full">
          <div className="flex flex-col">
            <Typography.Text className="text-base font-semibold">
              Equity Tranches (Class B)
            </Typography.Text>
            <Typography.Text className="text-primary text-2xl">
              Rp. {Format.numberFormat(detailEba?.outstanding_amount)}
            </Typography.Text>
          </div>
        </Card>
      </div>
      <div className="mb-5">
        <CustomTable.TableGlobal
          dataSource={items}
          columns={assetClassColumn(isSupervisor, isProduct)}
          loading={loading}
          scroll={{ x: 800 }}
          pagination={{
            pageSize: 10,
            onChange: paginationChange,
            current: paginationSync?.page,
            total: itemTotal,
            showSizeChanger: false,
          }}
        />
      </div>
      {isAdmin && !isProduct && (
        <div className="flex flex-row-reverse items-center gap-5">
          <Tooltip
            title={isDisableNext ? 'Anda belum atur senior tranche atau budget belum habis' : ''}
            placement="topRight">
            <div>
              <CustomButton
                type="primary"
                disabled={isDisableNext}
                {...(isDisableNext && { style: { pointerEvents: 'none' } })}
                onClick={() =>
                  navigate(
                    // eslint-disable-next-line prettier/prettier
                    `/management-product/equity-buffer?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}&${status}${
                      isSimulation === 'true' ? '&is_simulation=true' : '&is_simulation=false'
                    }`
                  )
                }>
                Selanjutnya
              </CustomButton>
            </div>
          </Tooltip>
          <CustomButton
            type="primary-ghost"
            onClick={() =>
              navigate(
                `/management-product/equity-buffer?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}&status=${status}`
              )
            }>
            Kembali
          </CustomButton>
        </div>
      )}
      {isSupervisor &&
        !isProduct &&
        (isSimulation === 'true' ? (
          <>
            <div className="flex flex-row-reverse mt-5 gap-5">
              <CustomButton
                type="primary"
                onClick={() => {
                  navigate(
                    `/management-product/equity-buffer?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}&is_simulation=true`
                  );
                }}>
                Next
              </CustomButton>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row-reverse mt-5 gap-5">
              <CustomButton
                type="primary"
                onClick={() => {
                  setOpen(true);
                  setType('approve');
                }}>
                Approve
              </CustomButton>
              <CustomButton
                type="primary-ghost"
                onClick={() => {
                  setOpen(true);
                  setType('decline');
                }}>
                Decline
              </CustomButton>
            </div>
            <ApproveDeclineModal
              type={type}
              open={open}
              onClose={() => setOpen(false)}
              ebaId={ebaId}
              page={'Kelas'}
              navigate={`/management-product/equity-buffer?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}&status=${status}`}
            />
          </>
        ))}
      {openAddClass && (
        <ManagementProductComponent.AssetClassForm
          open={openAddClass}
          onClose={() => setOpenAddClass(false)}
        />
      )}
    </Page>
  );
}
