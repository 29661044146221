import AppropiateData from './update-data/appropiateData';
import FailedInsepection from './acquisition/failedInsepection';
import InappropiateData from './update-data/inappropiateData';
import LostData from './update-data/lostData';
import NotCompletedData from './update-data/notCompletedData';
import PassInsepection from './acquisition/passInsepection';

export default {
  PassInsepection,
  FailedInsepection,
  AppropiateData,
  InappropiateData,
  LostData,
  NotCompletedData,
};
