import { Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BsBoxSeam, BsPlusCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AssetsComponent, Page } from '../../components';
import CustomButton from '../../components/_shared/custom-button';
import CustomTabs from '../../components/_shared/custom-tabs';
import { Util } from '../../helper';
import { useAuth } from '../../hooks/useAuth';
import { Aquisition, Eba } from '../../service';

const Assets = () => {
  const { user } = useAuth();
  const { isOriginator } = Util.role();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idEba = searchParams.get('eba-id');
  const page = searchParams.get('page');
  const tab = searchParams.get('tab');
  const isSimulation = searchParams.get('is_simulation') || false;

  const dispatch = useDispatch();
  const { approvalFile } = useSelector((state) => state.aquisition);
  const { detail } = useSelector((state) => state.eba);
  const ebaName = detail?.name;

  const breadcrumbs = [
    {
      name: isSimulation === 'true' ? 'Simulasi Produk' : 'Management Produk',
      href: isSimulation === 'true' ? '/simulation' : '/management-product',
    },
    { name: ebaName, href: null },
  ];

  const [click, setClick] = useState(0);
  const [cardTitle, setCardTitle] = useState('Pengelolaan EBA');
  const [tabSelected, setTabSelected] = useState(Number(tab) || 1);

  const listCard = [
    {
      title: 'Pengelolaan EBA',
      description: 'Update Data dan Akusisi Data dalam satu tahun',
      isShow: true,
    },
    { title: 'Debitur Akuisisi', description: 'Data Pool of Asset', isShow: true },
    {
      title: 'Archive',
      description: 'Update Data dan Akusisi Data lebih dari satu tahun',
      isShow: !isOriginator,
    },
  ];

  const tabsItem = [
    {
      label: 'Update Data',
      children: (
        <AssetsComponent.DataUpdate
          navigate={navigate}
          idEba={idEba}
          ebaName={ebaName}
          tabSelected={tabSelected}
          cardSelected={click}
          pageContent={page}
        />
      ),
    },
    {
      label: 'Data Akuisisi',
      children: (
        <AssetsComponent.DataAcquisition
          navigate={navigate}
          idEba={idEba}
          ebaName={ebaName}
          cardTitle={cardTitle}
          approvalFile={approvalFile}
          user={user}
          tabSelected={tabSelected}
          cardSelected={click}
        />
      ),
    },
  ];

  useEffect(() => {
    if (tab) {
      setTabSelected(Number(tab));
    }
    dispatch(Eba.getDetailEba(idEba));
    dispatch(Aquisition.aquisitionApproval({ idEba }));
  }, [idEba]);

  const onClickCard = (key, title) => {
    setClick(key);
    setCardTitle(title);
  };

  const onClickUpload = () => {
    let approveId = approvalFile?.id62 ? `&approve-id=${approvalFile?.id62}` : '';
    navigate(`upload-data-originator${location.search + approveId}`);
  };

  const onTabClick = (e) => {
    setTabSelected(e);
  };

  return (
    <Page title="Assets" breadcrumbs={breadcrumbs}>
      <div className="flex items-center space-x-3">
        <div
          className="
            flex
            items-center
            justify-center
          bg-primary
            rounded-[50px]
            w-[48px]
            h-[48px]
              ">
          <BsBoxSeam size={20} color="#ffffff" className="font-bold" />
        </div>
        <Typography.Paragraph className="font-bold text-3xl">{ebaName}</Typography.Paragraph>
      </div>
      <div className="flex justify-between items-center mt-10 mb-6">
        <Typography.Paragraph className="font-bold text-3xl">
          Histori Pengajuan Data
        </Typography.Paragraph>
        {isOriginator && (
          <CustomButton
            type="primary"
            className="
            group
            justify-center
            items-center
            flex
            space-x-2"
            onClick={onClickUpload}>
            <BsPlusCircleFill size={20} className="text-white group-hover:text-primary" />
            Upload Data Terbaru
          </CustomButton>
        )}
      </div>
      <div>
        <div className={`grid grid-cols-${isOriginator ? '2' : '3'} gap-5 mb-6`}>
          {listCard.map(
            (res, i) =>
              res.isShow && (
                <AssetsComponent.Card
                  key={res.title}
                  title={res.title}
                  description={res.description}
                  onClick={() => onClickCard(i, res.title)}
                  isClick={i === click}
                />
              )
          )}
        </div>

        {click !== 1 ? (
          <CustomTabs tabsItem={tabsItem} activeKey={tabSelected} onTabClick={onTabClick} />
        ) : (
          <AssetsComponent.DataAcquisition
            navigate={navigate}
            idEba={idEba}
            ebaName={ebaName}
            cardTitle={cardTitle}
            approvalFile={approvalFile}
            user={user}
            tabSelected={tabSelected}
            cardSelected={click}
            ebaEnd={moment(detail?.final_cashflow_date).format('YYYY')}
          />
        )}
      </div>
    </Page>
  );
};

export default Assets;
