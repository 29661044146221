import React from 'react';
import Table from '../../table';
import { columnsInformasiUmum } from './columns';

const tableInformasiUmum = ({ page, setPage, item }) => {
  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
  };

  return (
    <Table.TableGlobal
      columns={columnsInformasiUmum}
      dataSource={item}
      totalData={item?.length}
      className="table-preview-data"
      bordered
      scroll={{ x: 1300 }}
      onChangePagination={paginationChange}
      current={page.current}
      keyRow="informasi_umum"
    />
  );
};

export default tableInformasiUmum;
