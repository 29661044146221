import React from 'react';
import Title from './title';
import CashflowCard from './cashflowCard';
import { Format } from '../../helper';

const Cashflow = ({ data }) => {
  return (
    <div className="bg-white rounded-xl mb-6 p-6">
      <Title title="Cashflow" />
      <div className="flex box-border flex-row gap-6">
        <CashflowCard title="Earning Cash" type="earn" data={data?.earning_cash} />
        <CashflowCard title="Total Cost" type="cost" data={data?.cost} />
      </div>
      <div className="box-border border-1 border-[#F1F3F6] rounded-[10px] p-4 flex justify-center items-center mt-6 font-bold text-2xl mb-6">
        Cashflow Position : Rp&nbsp;
        {Format.numberFormat(Math.round(data?.cashflow_position))}
      </div>
    </div>
  );
};

export default Cashflow;
