import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Cookie } from '../../helper';
import { Login } from '../../service';

const initialState = {
  login: {},
  loading: false,
  loadingForgot: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // login
    builder.addCase(Login.login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Login.login.fulfilled, (state, action) => {
      state.loading = false;
      state.login = action.payload.data;
      Cookie.setCookie('user', action.payload.data);

      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Login Success',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Login.login.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Login Failed',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // send email confirmation
    builder.addCase(Login.sendEmailVerification.pending, (state) => {
      state.loadingForgot = true;
    });
    builder.addCase(Login.sendEmailVerification.fulfilled, (state) => {
      state.loadingForgot = false;
    });
    builder.addCase(Login.sendEmailVerification.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed',
        description: action?.payload?.message?.id,
      });
      state.loadingForgot = false;
    });

    // set password
    builder.addCase(Login.setNewPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Login.setNewPassword.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Sukses',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Login.setNewPassword.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });
  },
});

export default loginSlice.reducer;
