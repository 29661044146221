/* eslint-disable prettier/prettier */
import { Form, Modal, Switch, Typography } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { Util } from '../../../helper';
import { Param } from '../../../service';
import {
  CustomButton,
  CustomInput,
  CustomInputNumber,
  CustomPopconfirm,
  Select,
} from '../../_shared';
import rules from './rulesBeiSet';

const BeiSet = ({ open, setOpen, ebaId }) => {
  const [form] = useForm();
  const watch = useWatch('fields', form);
  const dispatch = useDispatch();

  const { bei, loading } = useSelector((state) => state.param);

  const [isUpdate, setIsUpdate] = useState(false);
  const [triggerDelete, setTriggerDelete] = useState(false);

  useEffect(() => {
    if (open) {
      dispatch(Param.getBei(ebaId))
        .unwrap()
        .then((res) => {
          form.setFieldValue('fields', res.data);
          if (res.data?.length > 0) setIsUpdate(true);
        });
    }
  }, [open, triggerDelete]);

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionGenerate = (opt, min, max) => {
    if (opt === '-') {
      return `${Util.abbreviateNumber(max)} - ${Util.abbreviateNumber(min)}`;
    } else {
      return `${opt} ${Util.abbreviateNumber(max)}`;
    }
  };

  const onSubmit = (e) => {
    const payload = e.fields.map((res) => ({
      operator: res.operator,
      percentage: res.percentage,
      range_end: res.range_end,
      range_start: res.range_start,
      description: descriptionGenerate(res.operator, res.range_start, res.range_end),
    }));

    if (bei?.length > 0) {
      e.fields.map((res) => {
        const body = {
          operator: res.operator,
          percentage: res.percentage,
          range_end: res.range_end,
          range_start: res.range_start,
          description: descriptionGenerate(res.operator, res.range_start, res.range_end),
        };
        dispatch(Param.updateBei({ id62: res.id62, body }));
      });
    } else {
      dispatch(Param.createBei({ eba_id62: ebaId, params: payload }))
        .unwrap()
        .then(() => {
          handleClose();
        });
    }
  };
  const removeParameter = (remove, name) => {
    if (bei?.length > 0) {
      const id = bei[name].id62;
      dispatch(Param.deleteBei(id))
        .unwrap()
        .then(() => {
          setTriggerDelete(!triggerDelete);
        });
    } else {
      remove(name);
    }
  };

  return (
    <Modal
      title={<h3 className="text-xl font-bold">Batasan parameter BEI</h3>}
      open={open}
      onCancel={handleClose}
      centered
      footer={
        isUpdate
          ? null
          : [
            <CustomButton key="back" type="primary-ghost" onClick={() => handleClose()}>
              Batal
            </CustomButton>,
            <CustomButton
              loading={loading}
              key="submit"
              type="primary"
              form="myForm"
              htmlType="submit">
              Simpan
            </CustomButton>,
          ]
      }
      width={'900px'}
      bodyStyle={{ height: '50%', minHeight: 400 }}>
      {bei?.length > 0 && (
        <div className="flex space-x-4 my-5">
          <Typography.Paragraph>Edit</Typography.Paragraph>
          <Switch onChange={() => setIsUpdate(!isUpdate)} checked={!isUpdate} />
        </div>
      )}
      <Form
        disabled={isUpdate}
        id="myForm"
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={onSubmit}
        initialValues={{
          fields: [],
        }}>
        <Form.List name="fields">
          {(fields, { add, remove }) => {
            return (
              <>
                <div className="flex flex-column items-center gap-3 mb-5">
                  <div className="flex items-start justify-evenly w-full">
                    <Typography.Paragraph className="flex items-center justify-center text-base w-1/4">
                      Operator
                    </Typography.Paragraph>
                    <Typography.Paragraph className="flex items-center justify-center text-base w-1/4">
                      Dari Nominal
                    </Typography.Paragraph>
                    <Typography.Paragraph className="flex items-center justify-center text-base w-1/4">
                      Sampai Nominal
                    </Typography.Paragraph>
                    <Typography.Paragraph className="text-base text-center w-1/4">
                      Persentase
                    </Typography.Paragraph>
                  </div>
                </div>
                <div className="max-h-[460px] overflow-y-scroll custom-scrollbar pr-1">
                  {fields.map((field, index) => (
                    <div
                      key={field.key}
                      className="flex items-center justify-evenly w-full gap-3 mb-10">
                      <Form.Item
                        className=" w-1/3 mb-0"
                        name={[index, 'operator']}
                        validateFirst
                        rules={[
                          {
                            required: true,
                            message: 'Field tidak boleh kosong',
                          },
                          ({ setFieldValue }) => ({
                            validator(_, value) {
                              if (value !== '-') {
                                setFieldValue(['fields', index, 'range_start'], 0);
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}>
                        <Select
                          className="py-3 px-1 overflow-hidden flex items-center h-full"
                          options={[
                            {
                              value: '-',
                              label: 'Sampai',
                            },
                            {
                              value: '<',
                              label: 'Kurang Dari',
                            },
                            {
                              value: '>',
                              label: 'Lebih dari',
                            },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item
                        className="mb-0 w-1/3"
                        name={[index, 'range_start']}
                        validateFirst
                        rules={rules.rulesNominal}>
                        <CustomInputNumber
                          disabled={watch[field.name]?.operator !== '-' || isUpdate}
                          placeholder="Range Awal"
                          className="py-3 px-1 w-full"
                          maxLength={19}
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                        />
                      </Form.Item>
                      <Form.Item
                        className="mb-0 w-1/3"
                        name={[index, 'range_end']}
                        validateFirst
                        rules={rules.rulesNominal}>
                        <CustomInputNumber
                          placeholder={
                            watch[field.name]?.operator === '-'
                              ? 'Range akhir'
                              : watch[field.name]?.operator === '<' ? 'Kurang dari' : 'Lebih dari'
                          }
                          className="py-3 px-1 w-full"
                          maxLength={19}
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          min={0}
                        />
                      </Form.Item>
                      <Form.Item
                        className="mb-0 w-1/5"
                        name={[index, 'percentage']}
                        validateFirst
                        rules={rules.rulesPercentage}>
                        <CustomInput suffix={<span>%</span>} className="py-[18px] px-[24px]" />
                      </Form.Item>
                      <CustomPopconfirm
                        loading={loading}
                        placement="topRight"
                        title="Hapus Parameter"
                        description="Anda yakin akan hapus parameter ini?"
                        onConfirm={() => removeParameter(remove, field.name)}>
                        <BsTrash role="button" className="text-xl" />
                      </CustomPopconfirm>
                    </div>
                  ))}
                </div>

                {bei?.length > 0 ? null : (
                  <div className="mt-2">
                    <CustomButton
                      type="primary-ghost"
                      className="group justify-center items-center flex w-full space-x-2"
                      onClick={() => add()}
                      icon={
                        <BsPlusCircleFill
                          size={20}
                          className={'text-primary group-hover:text-white'}
                        />
                      }>
                      Input Parameter
                    </CustomButton>
                  </div>
                )}
              </>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default BeiSet;
