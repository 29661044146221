import { Date, Format, Util } from '../../helper';

const columns = [
  {
    title: 'Nomor Pinjaman',
    dataIndex: 'loan_number',
    key: 'loan_number',
  },
  {
    title: 'Nama',
    dataIndex: 'debtor_name',
    key: 'debtor_name',
    render: (record) => record || '-',
  },
  {
    title: 'Tanggal Kredit Awal',
    dataIndex: 'credit_start_date',
    key: 'credit_start_date',
    render: (record) => Date.fullDate(record),
  },
  {
    title: 'Tanggal Jatuh Tempo',
    dataIndex: 'credit_due_date',
    key: 'credit_due_date',
    render: (record) => Date.fullDate(record),
  },
  {
    title: 'Angsuran Pokok(Rp)',
    dataIndex: 'principal_installments',
    key: 'principal_installments',
    render: Util.renderNumber,
  },
  {
    title: 'Angsuran Bunga(Rp)',
    dataIndex: 'interest_installments',
    key: 'interest_installments',
    render: Util.renderNumber,
  },
  {
    title: 'Total Angsuran(Rp)',
    key: 'total_angsuran',
    render: (_, record) =>
      Format.numberFormat(record.principal_installments + record.interest_installments),
  },
  // {
  //   title: 'Tenor',
  //   dataIndex: 'tenor',
  //   key: 'tenor',
  //   render: (record) => `${Number(record / 12).toFixed(1)} Tahun` || '-',
  // },
];

const passColumns = [
  {
    title: 'Nama',
    dataIndex: 'debtor_name',
    key: 'debtor_name',
    render: (record) => record || '-',
  },
  {
    title: 'Nomor Pinjaman',
    dataIndex: 'loan_number',
    key: 'loan_number',
  },
  {
    title: 'Tanggal Kredit Awal',
    dataIndex: 'credit_start_date',
    key: 'credit_start_date',
    render: (record) => Date.fullDate(record),
  },
  {
    title: 'Tanggal Jatuh Tempo',
    dataIndex: 'credit_due_date',
    key: 'credit_due_date',
    render: (record) => Date.fullDate(record),
  },
  {
    title: 'Saldo Pinjaman',
    dataIndex: 'loan_balance',
    key: 'loan_balance',
    render: Util.renderNumber,
  },
  {
    title: 'DBR',
    dataIndex: 'dbr',
    key: 'dbr',
    render: (record) => record || '-',
  },
  {
    title: 'Tanggal Lahir',
    dataIndex: 'birth_date',
    key: 'birth_date',
    render: (record) => Date.fullDate(record),
  },
];

const failedColumns = [
  ...passColumns,
  {
    title: 'Kriteria Tidak Dipenuhi',
    dataIndex: 'criteria_not_passed',
    key: 'criteria_not_passed',
  },
];

const columnsInappropiate = [
  {
    title: 'Nomor Pinjaman',
    dataIndex: 'loan_number',
    key: 'loan_number',
    align: 'center',
  },
  {
    title: 'Alasan',
    dataIndex: 'notes',
    key: 'notes',
    align: 'center',
    render: (record) => record.text,
  },
];

const columnsAppropiatePeriod = [
  {
    title: 'Periode',
    dataIndex: 'period_date',
    render: (record) => (record ? Date.monthYear(record) : '-'),
  },
  {
    title: 'Total Cash Inflow(Rp)',
    dataIndex: 'cash_inflow',
    align: 'center',
    render: Util.renderNumber,
  },
];

const columnsDetailData = [
  {
    title: 'Nomor Pinjaman',
    dataIndex: 'loan_number',
    width: 180,
    align: 'center',
  },
  {
    title: 'Tanggal Kredit Awal',
    dataIndex: 'credit_start_date',
    width: 180,
    align: 'center',
    render: (record) => Date.twoDigitWithDash(record),
  },
  {
    title: 'Tanggal Jatuh Tempo',
    dataIndex: 'credit_due_date',
    width: 180,
    align: 'center',
    render: (record) => Date.twoDigitWithDash(record),
  },
  {
    title: 'Angsuran Pokok (Rp)',
    dataIndex: 'principal_installments',
    width: 200,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Angsuran Bunga (Rp)',
    dataIndex: 'interest_installments',
    width: 180,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Total Angsuran (Rp)',
    dataIndex: 'total_installment_payment',
    width: 180,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Bunga (%)',
    dataIndex: 'interest_rate_type',
    width: 150,
    align: 'center',
    render: (record) => record || '0',
  },
  {
    title: 'Tenor (Tahun)',
    dataIndex: 'tenor',
    width: 150,
    align: 'center',
    render: Util.tooltipYear,
  },
];
export {
  columns,
  columnsInappropiate,
  columnsAppropiatePeriod,
  columnsDetailData,
  failedColumns,
  passColumns,
};
