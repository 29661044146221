import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Reinvestment } from '../../service';
import { Util } from '../../helper';

const initialState = {
  list: [],
  listDetail: [],
  detail: { name: 'REINVESTMENT' },
  loading: false,
  loadingDelete: false,
  trigger: false,
};

export const reinvest = createSlice({
  name: 'reinvestment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list eba
    builder.addCase(Reinvestment.getList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Reinvestment.getList.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload.data;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Get Data',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Reinvestment.getList.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // Get Detail
    builder.addCase(Reinvestment.getDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Reinvestment.getDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.listDetail = action.payload.data;
    });
    builder.addCase(Reinvestment.getDetail.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Detail',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // Tambah
    builder.addCase(Reinvestment.CreateReinvestment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Reinvestment.CreateReinvestment.fulfilled, (state, action) => {
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Create Reinvestment',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
      state.trigger = !state.trigger;
    });
    builder.addCase(Reinvestment.CreateReinvestment.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Menambahkan',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // Edit
    builder.addCase(Reinvestment.UpdateReinvestment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Reinvestment.UpdateReinvestment.fulfilled, (state, action) => {
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Update Reinvestment',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
      state.trigger = !state.trigger;
    });
    builder.addCase(Reinvestment.UpdateReinvestment.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Edit',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // Delete
    builder.addCase(Reinvestment.deleteDetail.pending, (state) => {
      state.loadingDelete = true;
    });
    builder.addCase(Reinvestment.deleteDetail.fulfilled, (state, action) => {
      state.loadingDelete = false;
      state.trigger = !state.trigger;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Hapus Reinvestment',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Reinvestment.deleteDetail.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Hapus',
        description: action?.payload?.message?.id,
      });
      state.loadingDelete = false;
    });

    // Withdraw Reinvestment
    builder.addCase(Reinvestment.withdrawReinvestment.pending, (state) => {
      state.loadingDelete = true;
    });
    builder.addCase(Reinvestment.withdrawReinvestment.fulfilled, (state, action) => {
      state.trigger = !state.trigger;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Cairkan Dana',
        description: action?.payload?.message?.id,
      });
      state.loadingDelete = false;
    });
    builder.addCase(Reinvestment.withdrawReinvestment.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Cairkan Dana',
        description: Util.errorHandle(action.payload),
      });
      state.loadingDelete = false;
    });
  },
});

export default reinvest.reducer;
