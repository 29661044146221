import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const SpvApproval = createAsyncThunk(
  'post/supervisior/approval',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({ url: `notes/`, payload });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default { SpvApproval };
