import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './login';
import aquisitionReducer from './aquisition';
import referenceReducer from './reference';
import ebaReducer from './eba';
import userReducer from './user';
import userLogReducer from './user-log';
import periodicReducer from './periodic-payment';
import cashflowReducer from './cashflow';
import revenueReducer from './revenue';
import bufferReducer from './buffer';
import poolOfAssetReducer from './pool-of-asset';
import reportReducer from './report';
import investment from './investment';
import reinvestment from './reinvestment';
import cost from './cost';
import approval from './approval';
import note from './note';
import transaction from './transaction';
import waterfall from './waterfall';
import param from './param';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    aquisition: aquisitionReducer,
    reference: referenceReducer,
    eba: ebaReducer,
    user: userReducer,
    userLog: userLogReducer,
    periodic: periodicReducer,
    cashflow: cashflowReducer,
    revenue: revenueReducer,
    buffer: bufferReducer,
    poa: poolOfAssetReducer,
    report: reportReducer,
    investment: investment,
    reinvestment: reinvestment,
    cost: cost,
    approval: approval,
    note: note,
    transaction: transaction,
    waterfall: waterfall,
    param: param,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
