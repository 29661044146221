import { Form, Input, Typography } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { Login } from '../../service';
import Page from '../page-wrapper';
import { CustomButton, CustomInput } from '../_shared';

const FormLogin = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.login);

  const initValue = {
    email: '',
    password: '',
    role: '',
  };

  const onSubmit = (e) => {
    const body = {
      email: e.email,
      password: e.password,
    };
    dispatch(Login.login(body))
      .unwrap()
      .then((res) => {
        auth.login(res.data);
      })
      .catch(() => {});
  };

  const onClickForgotPassword = () => {
    navigate('?section=forgot-password');
  };

  return (
    <Page title="Sign in">
      <Form layout="vertical" initialValues={initValue} requiredMark={false} onFinish={onSubmit}>
        <div className="w-[360px] flex flex-col">
          <Typography.Text className="font-bold text-[40px] ">Sign In</Typography.Text>

          <Form.Item
            label="Email"
            name="email"
            className="mt-16"
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ]}>
            <CustomInput
              placeholder="yourmail@email.com"
              type="text"
              className="mt-2 py-[18px] px-[24px]"
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            className="mb-1"
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
            ]}>
            <Input.Password className="custom-input rounded mt-2 py-[18px] px-[24px]" />
          </Form.Item>

          <CustomButton type="link" className="self-end text-sm" onClick={onClickForgotPassword}>
            Forgot Password?
          </CustomButton>

          <CustomButton type="primary" className="mt-20" htmlType="submit" loading={loading}>
            Sign In
          </CustomButton>
        </div>
      </Form>
    </Page>
  );
};

export default FormLogin;
