/* eslint-disable prettier/prettier */
import { Tooltip, Typography } from 'antd';
import { TiWarningOutline } from 'react-icons/ti';
import { AiOutlineStop } from 'react-icons/ai';
import { CustomButton, ManagementProductComponent } from '../../../components';
import AssetClassDetailAction from '../../../components/management-product/asset-class-detail/assetClassDetailAction';
import { Date, Format, Util } from '../../../helper';

const statusColor = (status) => {
  let className;
  switch (status.value) {
    case 0:
    case 4:
      className = `bg-green_bg text-green_text`;
      break;
    case 3:
      className = `bg-[#FFF2F2] text-primary`;
      break;
    default:
      className = `bg-blue_bg text-blue_text`;
      break;
  }
  return (
    <Typography.Text className={`py-3 px-5 font-bold rounded-[50px] w-[240px] ${className}`}>
      {status.desc}
    </Typography.Text>
  );
};

const statusColorCost = (status) => {
  let className;
  switch (status) {
    case true:
      className = `bg-green_bg text-green_text`;
      break;
    default:
      className = `bg-blue_bg text-blue_text`;
      break;
  }
  return (
    <Typography.Text className={`py-3 px-5 font-bold rounded-[50px] w-[240px] ${className}`}>
      {status ? 'Lunas ' : 'Belum Dibayar'}
    </Typography.Text>
  );
};

const columnsListProduct = (onClick) => [
  {
    title: 'Nama Produk',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    className: 'font-bold before:hidden',
    render: (record) => record || '-',
  },
  {
    title: 'Bulan Awal',
    dataIndex: 'start_date',
    key: 'start_date',
    align: 'center',
    className: 'font-bold before:hidden',
    render: Date.twoDigitWithDash,
  },
  {
    title: 'Bulan Akhir',
    dataIndex: 'end_date',
    key: 'end_date',
    align: 'center',
    className: 'font-bold before:hidden',
    render: Date.twoDigitWithDash,
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    className: 'before:hidden',
    width: 180,
    render: (record) => statusColor(record),
  },
  {
    title: 'Last Updated',
    dataIndex: 'updated_at',
    key: 'updated_at',
    align: 'center',
    width: 180,
    className: 'font-bold before:hidden',
    render: Date.lastUpdate,
  },
  {
    title: 'Aksi',
    key: 'action',
    width: 200,
    align: 'center',
    render: (_, record) => (
      <CustomButton type="primary-ghost" onClick={() => onClick(record)}>
        Lihat Detail
      </CustomButton>
    ),
  },
];

const columnsDraftProduct = [
  {
    title: 'Nama Produk',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    className: 'font-bold',
    width: 180,
    render: (record) => record || '-',
  },
  {
    title: 'Bulan Awal',
    dataIndex: 'start_date',
    key: 'start_date',
    align: 'center',
    width: 180,
    className: 'font-bold',
    render: Date.twoDigitWithDash,
  },
  {
    title: 'Bulan Akhir',
    dataIndex: 'end_date',
    key: 'end_date',
    align: 'center',
    width: 180,
    className: 'font-bold',
    render: Date.twoDigitWithDash,
  },
  {
    title: 'Last Updated',
    dataIndex: 'updated_at',
    key: 'updated_at',
    align: 'center',
    width: 180,
    className: 'font-bold',
    render: Date.lastUpdate,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: 180,
    render: (record) => statusColor(record),
  },
  {
    title: 'Aksi',
    key: 'action',
    align: 'center',
    width: 100,
    render: (record) => <ManagementProductComponent.DraftAction item={record} />,
  },
];

const columnsFeedbackList = (onClick) => [
  {
    title: 'Nama Produk',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: 180,
    className: 'font-bold',
    render: (record) => record || '-',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: 180,
    render: (record) => statusColor(record),
  },
  {
    title: 'Last Updated',
    dataIndex: 'updated_at',
    key: 'updated_at',
    align: 'center',
    width: 180,
    className: 'font-bold',
    render: Date.lastUpdate,
  },
  {
    title: 'Aksi',
    key: 'action',
    align: 'center',
    width: 100,
    render: (record) => (
      <ManagementProductComponent.FeedbackAction record={record} callback={onClick} />
    ),
  },
];

const columnsFeedbackProduct = (onClick) => [
  {
    title: 'Nama Halaman',
    dataIndex: 'page',
    key: 'page',
    align: 'center',
    // width: 180,
    className: 'font-bold',
    render: (record) => record || '-',
  },
  {
    title: 'Feedback',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    className: 'font-bold',
    // width: 260,
    render: (record) => record || '-',
  },
  {
    title: 'Status',
    dataIndex: 'eba',
    key: 'eba',
    align: 'center',
    // width: 200,
    render: (record) => statusColor(record.status),
  },
  {
    title: 'Last Updated',
    dataIndex: 'updated_at',
    key: 'updated_at',
    // width: 40,
    render: Date.lastUpdate,
  },
  {
    title: 'Aksi',
    key: 'action',
    align: 'center',
    width: 100,
    render: (record) => (
      <CustomButton type="primary-ghost" onClick={() => onClick(record)}>
        Lihat Detail
      </CustomButton>
    ),
  },
];

const uploadAssetsColumn = (page) => [
  {
    title: 'No',
    dataIndex: 'no',
    render: (_, item, index) => Format.numberFormat((page - 1) * 10 + index + 1),
  },
  {
    title: <div className="text-center">No Pinjaman</div>,
    dataIndex: 'loan_number',
    key: 'loan_number',
    render: (record) => record || '-',
  },
  // ? removed by request https://trello.com/c/FPXBFU5c/612-hide-kolom-nama-debitur
  // ? not deleted because it might be needed again
  // {
  //   title: <div className="text-center">Nama Debitur</div>,
  //   dataIndex: 'debtor_name',
  //   key: 'debtor_name',
  //   render: (record) => record || '-',
  // },
  {
    title: <div className="text-center">Nilai Angsuran(Rp)</div>,
    dataIndex: 'total_installment_payment',
    key: 'total_installment_payment',
    render: Util.renderNumber,
  },
  {
    title: <div className="text-center">Tanggal Lunas</div>,
    dataIndex: 'credit_due_date',
    key: 'credit_due_date',
    render: (record) => <div className="text-center">{Date.twoDigitWithDash(record)}</div>,
  },
  {
    title: <div className="text-center">Saldo Pinjaman(Rp)</div>,
    dataIndex: 'loan_balance',
    key: 'loan_balance',
    render: Util.renderNumber,
  },
  {
    title: <div className="text-center">Tanggal Kredit Awal</div>,
    dataIndex: 'credit_start_date',
    key: 'credit_start_date',
    render: Date.twoDigitWithDash,
  },
];

const earningPercentageColumn = (showAction) => [
  [
    {
      text: 'Persentase',
      className: 'border',
      render: (record) => record || '-',
    },
    {
      text: 'Start (Month)',
      className: 'border',
      render: (record) => record || '-',
    },
    {
      text: 'End (Month)',
      className: 'border',
      render: (record) => record || '-',
    },
    showAction
      ? {
          text: 'Aksi',
          className: 'border',
        }
      : null,
  ],
];

const earningPercentageColumnSimulation = [
  [
    {
      text: 'Persentase',
      className: 'border',
    },
    {
      text: 'Periode Mulai',
      className: 'border',
    },
    {
      text: 'Periode Selesai',
      className: 'border',
    },
    {
      text: 'Aksi',
      className: 'border',
    },
  ],
];

const equityBufferColumn = [
  {
    title: 'Tanggal',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    render: Date.twoDigitWithDash,
  },
  {
    title: 'Deskripsi',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    render: (record) => record || '-',
  },
  {
    title: 'Debit(Rp)',
    dataIndex: 'details',
    key: 'details',
    align: 'center',
    render: (record) => Util.renderNumber(record.debit),
  },
  {
    title: 'Kredit(Rp)',
    dataIndex: 'details',
    key: 'details',
    align: 'center',
    render: (record) => Util.renderNumber(record?.credit),
  },
  {
    title: 'Saldo(Rp)',
    dataIndex: 'details',
    key: 'details',
    align: 'center',
    render: (record) => Util.renderNumber(record?.balance),
  },
];

// const equityBufferColumnSimulation = (showAction) => [
//   {
//     title: 'Tanggal',
//     dataIndex: 'date',
//     key: 'date',
//     align: 'center',
//     render: Date.twoDigitWithDash,
//   },
//   {
//     title: 'Deskripsi',
//     dataIndex: 'description',
//     key: 'description',
//     align: 'left',
//     render: (record) => record || '-',
//   },
//   {
//     title: 'Debit (Rp)',
//     dataIndex: 'details',
//     key: 'details',
//     align: 'center',
//     render: (record) => Util.renderNumber(record.debit),
//   },
//   {
//     title: 'Kredit (Rp)',
//     dataIndex: 'details',
//     key: 'details',
//     align: 'center',
//     render: (record) => Util.renderNumber(record?.credit),
//   },
//   {
//     title: 'Saldo (Rp)',
//     dataIndex: 'details',
//     key: 'details',
//     align: 'center',
//     render: (record) => Util.renderNumber(record?.balance),
//   },
//   showAction
//     ? {
//         title: 'Aksi',
//         render: (record) =>
//           record.module.value === 0 ? (
//             <ManagementProductComponent.EquityBufferAction item={record} />
//           ) : (
//             <></>
//           ),
//       }
//     : { width: 0 },
// ];

const equityBufferColumnSimulation = () => [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    render: Date.twoDigitWithDash,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    align: 'left',
    render: (record) => record || '-',
  },
  {
    title: 'Cash Inflow (Rp)',
    dataIndex: 'details',
    key: 'details',
    align: 'center',
    render: (record) => Util.renderNumber(record?.credit),
  },
  {
    title: 'Cash Outflow (Rp)',
    dataIndex: 'details',
    key: 'details',
    align: 'center',
    render: (record) => Util.renderNumber(record.debit),
  },
  {
    title: 'Saldo (Rp)',
    dataIndex: 'details',
    key: 'details',
    align: 'center',
    render: (record) => Util.renderNumber(record?.balance),
  },
];

const tooltipText = (coupon, investor, isSupervisor) => {
  let tooltip = '';
  const text = isSupervisor ? 'Admin' : 'Anda';

  if (!coupon) {
    tooltip = `${text} belum atur kupon`;
  }
  if (!investor) {
    tooltip = `${text} belum upload data investor`;
  }

  if (!investor && !coupon) {
    tooltip = `${text} belum atur kupon dan upload data investor`;
  }

  return tooltip;
};

const assetClassColumn = (isSupervisor) => [
  {
    title: 'Senior Tranches',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      const isNotSet = !record.coupon_status || !record.investor_status;
      if (isNotSet) {
        return (
          <Tooltip
            title={tooltipText(record.coupon_status, record.investor_status, isSupervisor)}
            placement="topLeft"
            className="relative">
            <div>
              <TiWarningOutline
                size={18}
                className="
                absolute
                top-[-14px]
                left-[-14px] "
                color="#fdcc00"
              />
              <Typography.Text>{text}</Typography.Text>
            </div>
          </Tooltip>
        );
      }

      return text;
    },
  },
  {
    title: 'Bulan Awal',
    dataIndex: 'start_date',
    key: 'start_date',
    align: 'center',
    render: (record) => record || '-',
  },
  {
    title: 'Bulan Akhir',
    dataIndex: 'end_date',
    key: 'end_date',
    align: 'center',
    render: (record) => record || '-',
  },
  {
    title: 'Nilai Penerbitan (Rp)',
    dataIndex: 'amount',
    key: 'amount',
    render: Util.renderNumber,
    align: 'center',
  },
  {
    title: 'Aksi',
    key: 'action',
    align: 'center',
    render: (record) => (
      <ManagementProductComponent.AssetClassAction id={record?.id62} record={record} />
    ),
  },
];

const assetClassData = [
  {
    class: 'A1',
    start_period: 'Penambahan Ekuitas B',
    end_period: 'John Doe',
    aum_value: '500 B',
  },
];

const assetClassDetailColumn = (isSupervisor, isProductAdmin) => [
  {
    title: 'Kode EBA',
    dataIndex: 'eba_code',
    key: 'eba',
    align: 'center',
    width: 100,
    render: (record) => record || '-',
  },
  {
    title: 'Single Investor Identification',
    dataIndex: 'sid',
    key: 'sid',
    align: 'center',
    render: (record) => record || '-',
  },
  {
    title: 'Nama Investor',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    render: (record) => record || '-',
  },
  {
    title: 'Jenis Investor',
    dataIndex: 'investor_type',
    align: 'center',
    render: (record) => record || '-',
  },
  {
    title: 'Keterangan Investor',
    dataIndex: 'note',
    key: 'note',
    align: 'center',
    render: (record) => record || '-',
  },
  {
    title: 'Total Investasi (Rp)',
    dataIndex: 'amount',
    key: 'amount',
    width: 200,
    align: 'center',
    render: Util.renderNumber,
  },
  isSupervisor || isProductAdmin
    ? { width: 0 }
    : {
        title: 'Aksi',
        key: 'action',
        render: (item) => <AssetClassDetailAction item={item} />,
      },
];

const logRatingColumn = [
  [
    {
      text: 'Rating',
      className: 'border',
    },
    {
      text: 'Rating Date',
      className: 'border',
    },
    {
      text: 'Rating Agency',
      className: 'border',
    },
  ],
];

const logRatingData = [
  {
    id: 1,
    rating: 'AAA',
    rating_period: '3',
    rating_date: '12 Januari 2022',
    ratting_given_by: 'John Doe',
  },
  {
    id: 2,
    rating: 'AA',
    rating_period: '3',
    rating_date: '12 Januari 2022',
    ratting_given_by: 'John Doe',
  },
];

const costColumn = [
  {
    title: 'Bulan',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    render: (record) => record || '-',
  },
  {
    title: 'Biaya (Rp)',
    dataIndex: 'total_amount',
    key: 'total_amount',
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Pajak (Rp)',
    dataIndex: 'total_tax',
    key: 'total_tax',
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Total Biaya (Rp)',
    dataIndex: 'total_cost',
    key: 'total_cost',
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Status',
    dataIndex: 'is_paid',
    align: 'center',
    width: '20%',
    render: (record) => statusColorCost(record),
  },
  {
    title: 'Aksi',
    key: 'action',
    align: 'center',
    width: '10%',
    render: (record) =>
      record === undefined || null ? (
        '-'
      ) : (
        <ManagementProductComponent.CostAction
          name={record?.date}
          period={record?.period_date}
          is_paid={record.is_paid}
        />
      ),
  },
];

const costSummary = (showConfirm) => [
  {
    title: 'Nama Biaya',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    render: (record) => record || '-',
  },
  {
    title: 'Start Date',
    dataIndex: 'period_start',
    key: 'period_start',
    align: 'center',
    render: (record) => record || '-',
  },
  {
    title: 'End Date',
    dataIndex: 'period_end',
    key: 'period_end',
    align: 'center',
    render: (record) => record || '-',
  },
  {
    title: 'Total Biaya',
    dataIndex: 'total_amount',
    key: 'total_amount',
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Pajak',
    dataIndex: 'tax_amount',
    key: 'tax_amount',
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Total',
    align: 'center',
    render: (record) =>
      Util.renderNumber(parseInt(record?.total_amount) + parseInt(record?.tax_amount)),
  },
  {
    title: 'Aksi',
    align: 'center',
    render: (record) => (
      <div className="flex justify-center">
        <AiOutlineStop className="cursor-pointer" onClick={() => showConfirm(record.id62)} />
      </div>
    ),
  },
];

const constData = [
  {
    id: 1,
    date: 'November 2022',
    exprenses_per_month: 'IDR 120.000.000,00',
  },
  {
    id: 2,
    date: 'Oktober 2022',
    exprenses_per_month: 'IDR 120.000.000,00',
  },
  {
    id: 3,
    date: 'September 2022',
    exprenses_per_month: 'IDR 120.000.000,00',
  },
  {
    id: 4,
    date: 'Agustus 2022',
    exprenses_per_month: 'IDR 120.000.000,00',
  },
  {
    id: 5,
    date: 'Juli 2022',
    exprenses_per_month: 'IDR 120.000.000,00',
  },
];

const costDetailColumn = (showAction) => [
  {
    title: 'Nama Biaya',
    dataIndex: 'name',
    align: 'center',
    render: (_, record) => record?.cost?.name || '-',
  },
  {
    title: 'Periode Biaya',
    dataIndex: 'period',
    align: 'center',
    render: (record) => record || '-',
  },
  {
    title: 'Total (Rp)',
    dataIndex: 'amount',
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Pajak (Rp)',
    dataIndex: 'detail_cost_tax',
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Total Biaya + Pajak (Rp)',
    dataIndex: 'detail_cost_total',
    align: 'center',
    render: Util.renderNumber,
  },
  showAction
    ? {
        title: 'Aksi',
        key: 'action',
        width: '10%',
        align: 'center',
        render: (_, record) => <ManagementProductComponent.CostDetailAction record={record} />,
      }
    : {},
];

const costDetailData = [
  {
    name: 'Biaya 1',
    period: 'Per Bulan',
    total: 'IDR 119.600.000,00',
  },
  {
    name: 'Biaya 2',
    period: 'Per Kuartal',
    total: 'IDR 119.600.000,00',
  },
  {
    name: 'Biaya 3',
    period: 'Per Tahun',
    total: 'IDR 119.600.000,00',
  },
  {
    name: 'Biaya 4',
    period: 'Kustom',
    total: 'IDR 119.600.000,00',
  },
];

const reInvestmentColumn = (showAction) => {
  const column = [
    {
      title: 'Instrument Investasi',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Imbah Hasil (%)',
      dataIndex: 'percentage_invest',
      width: 200,
      align: 'center',
      render: (record) => record || '-',
    },
    {
      title: 'Jumlah Penempatan (Rp)',
      dataIndex: 'amount_invest',
      align: 'center',
      width: 200,
      render: Util.renderNumber,
    },
    {
      title: 'Pajak (%)',
      dataIndex: 'tax',
      align: 'center',
      width: 200,
      render: (record) => record || '-',
    },
    {
      title: 'Tanggal Awal',
      dataIndex: 'start_date',
      align: 'center',
      width: 180,
      render: Date.twoDigitWithDash,
    },
    {
      title: 'Tanggal Akhir',
      dataIndex: 'end_date',
      align: 'center',
      width: 180,
      render: Date.twoDigitWithDash,
    },
  ];

  if (showAction) {
    column.push({
      title: 'Aksi',
      key: 'action',
      width: 100,
      align: 'center',
      render: (record) => <ManagementProductComponent.ReInvestmentAction record={record} />,
    });
  }

  return column;
};

const reInvestmentData = [
  {
    name: 'Investasi 1',
    percent: '60%',
    amount: 'IDR 780.000.000,00',
  },
  {
    name: 'Investasi 2',
    percent: '5%',
    amount: 'IDR 65.500.000,00',
  },
];

export {
  columnsListProduct,
  uploadAssetsColumn,
  earningPercentageColumn,
  earningPercentageColumnSimulation,
  equityBufferColumn,
  equityBufferColumnSimulation,
  assetClassColumn,
  assetClassData,
  assetClassDetailColumn,
  logRatingColumn,
  logRatingData,
  columnsDraftProduct,
  columnsFeedbackProduct,
  columnsFeedbackList,
  costColumn,
  constData,
  costDetailColumn,
  costDetailData,
  reInvestmentColumn,
  reInvestmentData,
  costSummary,
};
