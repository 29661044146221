/* eslint-disable no-unused-vars */
import { Popconfirm, Tooltip } from 'antd';
import React, { useState } from 'react';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { IoMdSettings } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ManagementProductComponent } from '../..';
import { Util } from '../../../helper';
import usePage from '../../../hooks/usePage';
import { Investment } from '../../../service';

const tooltipText = (coupon, investor) => {
  let tooltip = '';
  if (!coupon) {
    tooltip = 'Anda belum atur kupon';
  }
  if (!investor) {
    tooltip = 'Anda belum upload data investor';
  }

  if (!investor && !coupon) {
    tooltip = 'Anda belum atur kupon dan upload data investor';
  }

  return tooltip;
};

export default function AssetClassAction({ id, record }) {
  const { isProduct, isManagementProduct } = usePage();
  const { isSupervisor } = Util.role();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  const ebaName = searchParams.get('eba-name');
  const status = searchParams.get('status');
  const isSimulation = searchParams.get('is_simulation') || false;

  const { loadingDelete } = useSelector((state) => state.investment);

  const [open, setOpen] = useState(false);

  const Delete = () => {
    dispatch(Investment.deleteClass({ id: id }));
  };

  const onClickSetting = () => {
    navigate(
      `/management-product/asset-class/${id}?page=${
        isProduct ? '' : 'management-'
      }product&eba-id=${ebaId}&eba-name=${ebaName}&grade-id=${id}&status=${status}${
        isSimulation ? '&is_simulation=true' : ''
      }`
    );
  };

  const SettingButton = () => {
    return (
      <Tooltip
        title={tooltipText(record.coupon_status, record.investor_status)}
        placement="topLeft"
        className="relative">
        <IoMdSettings style={{ cursor: 'pointer' }} onClick={onClickSetting} />
      </Tooltip>
    );
  };

  const DeleteButton = () => {
    return (
      <Popconfirm
        placement="left"
        title="Hapus Senior Tranches"
        description="Anda yakin akan hapus senior tranche ini?"
        onConfirm={Delete}
        okText="Ya"
        cancelText="Batal"
        okButtonProps={{
          loading: loadingDelete,
          className: 'custom-button-primary',
        }}
        cancelButtonProps={{
          className: 'custom-button-primary-ghost',
        }}>
        <BiTrash style={{ cursor: 'pointer' }} />
      </Popconfirm>
    );
  };

  if (isProduct || (isManagementProduct && isSupervisor)) {
    // published product
    return (
      <div className="flex gap-5 justify-center">
        <SettingButton />
        {(!record.coupon_status || !record.investor_status) && <DeleteButton />}
      </div>
    );
  }

  return (
    <div className="flex gap-5 justify-center">
      <BiPencil style={{ cursor: 'pointer' }} onClick={() => setOpen(true)} />
      <SettingButton />
      <DeleteButton />
      <ManagementProductComponent.AssetClassForm
        open={open}
        onClose={() => setOpen(false)}
        isUpdate={true}
        id={id}
        updatedData={record}
      />
    </div>
  );
}
