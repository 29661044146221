import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Util } from '../../helper';
import { Investment } from '../../service';

const initialState = {
  trigger: false,
  list: [],
  detail: { name: 'INVESTMENT' },
  classDetail: {},
  classPeriodDiff: 60,
  loading: false,
  loadingInvestor: false,
  loadingCoupon: false,
  loadingDelete: false,
  investorFile: {},
  data: {},
  items: [],
  itemTotal: 0,
  paginationSync: {
    page: 1,
  },
  ratings: [],
  coupon: [],
  investor: [],
  couponSummary: [],
  allClassComplete: false,
};

export const classSlice = createSlice({
  name: 'investment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create grade
    builder.addCase(Investment.createClass.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Investment.createClass.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Create Class',
        description: action?.payload?.message?.id,
      });
      state.trigger = !state.trigger;
    });
    builder.addCase(Investment.createClass.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed to create Class',
        description: Util.errorHandle(action.payload),
      });
      state.loading = false;
    });

    // delete class
    builder.addCase(Investment.deleteClass.pending, (state) => {
      state.loadingDelete = true;
    });
    builder.addCase(Investment.deleteClass.fulfilled, (state, action) => {
      state.loadingDelete = false;
      state.trigger = !state.trigger;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Sukses Hapus',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Investment.deleteClass.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Hapus',
        description: Util.errorHandle(action.payload),
      });
      state.loadingDelete = false;
    });

    // delete all investor
    builder.addCase(Investment.DeleteAllInvestor.pending, (state) => {
      state.loadingDelete = true;
    });
    builder.addCase(Investment.DeleteAllInvestor.fulfilled, (state, action) => {
      state.loadingDelete = false;
      state.trigger = !state.trigger;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Sukses Hapus',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Investment.DeleteAllInvestor.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Hapus',
        description: Util.errorHandle(action.payload),
      });
      state.loadingDelete = false;
    });

    // listClass class
    builder.addCase(Investment.getClass.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Investment.getClass.fulfilled, (state, action) => {
      const classData = action.payload?.data;
      const classLength = action.payload?.data?.length;
      let coupon = 0;
      let investor = 0;
      classData?.forEach((res) => {
        // calculate coupon and investore each class
        if (res.coupon_status) coupon += 1;
        if (res.investor_status) investor += 1;
      });

      // is coupon and investor filled
      const isCouponComplete = coupon === classLength;
      const isInvestorComplete = investor === classLength;
      const childComplete = isCouponComplete && isInvestorComplete;

      // check is all class fill
      state.allClassComplete = classLength > 0 && childComplete;
      state.items = classData;
      state.itemTotal = classLength;
      state.paginationSync.page = action?.meta?.arg?.page;
      state.loading = false;
    });
    builder.addCase(Investment.getClass.rejected, (state, action) => {
      state.loading = false;
      state.items = [];
      state.paginationSync.page = 1;

      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
    });
    // class detail
    builder.addCase(Investment.getClassByID.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Investment.getClassByID.fulfilled, (state, action) => {
      state.loading = false;
      state.classDetail = action.payload.data;
      const startDate = dayjs(`1 ${action.payload?.data?.start_date}`);
      const endDate = dayjs(`1 ${action.payload?.data?.end_date}`);
      state.classPeriodDiff = endDate.diff(startDate, 'month');
    });
    builder.addCase(Investment.getClassByID.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });
    // class Update
    builder.addCase(Investment.updateClass.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Investment.updateClass.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Update Class',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Investment.updateClass.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed to update class',
        description: Util.errorHandle(action?.payload),
      });
      state.loading = false;
    });
    // ratings list
    builder.addCase(Investment.getRating.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Investment.getRating.fulfilled, (state, action) => {
      state.loading = false;
      state.ratings = action.payload?.data;
    });
    builder.addCase(Investment.getRating.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Rating',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // Upload Investor
    builder.addCase(Investment.AddInvestor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Investment.AddInvestor.fulfilled, (state, action) => {
      state.loading = false;
      state.submission = action.payload.data;
    });
    builder.addCase(Investment.AddInvestor.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Upload Data',
        description: Util.errorHandle(action.payload),
      });
      state.loading = false;
    });

    // Download TXT
    builder.addCase(Investment.InvestorDownloadFormatTxt.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Investment.InvestorDownloadFormatTxt.fulfilled, (state, action) => {
      state.loading = false;
      state.submission = action.payload.data;
    });
    builder.addCase(Investment.InvestorDownloadFormatTxt.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Upload Data',
        description: Util.errorHandle(action.payload),
      });
      state.loading = false;
    });
    // get investor list
    builder.addCase(Investment.investorList.pending, (state) => {
      state.loadingInvestor = true;
    });
    builder.addCase(Investment.investorList.fulfilled, (state, action) => {
      state.loadingInvestor = false;
      state.investor = action.payload?.data;
    });
    builder.addCase(Investment.investorList.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed to get investor',
        description: action?.payload?.message?.id,
      });
      state.loadingInvestor = false;
    });
    // create ratting
    builder.addCase(Investment.createRating.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Investment.createRating.fulfilled, (state) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Create Rating',
      });
    });
    builder.addCase(Investment.createRating.rejected, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed to create rating',
        description: Util.errorHandle(action.payload),
      });
    });

    // create coupon
    builder.addCase(Investment.createCoupon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Investment.createCoupon.fulfilled, (state) => {
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Create Coupon',
      });
      state.loading = false;
    });
    builder.addCase(Investment.createCoupon.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed to create coupon',
        description: Util.errorHandle(action.payload),
      });
      state.loading = false;
    });

    // update coupon
    builder.addCase(Investment.updateCoupon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Investment.updateCoupon.fulfilled, (state, action) => {
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Sukses Ubah Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });
    builder.addCase(Investment.updateCoupon.rejected, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Ubah Data',
        description: Util.errorHandle(action.payload),
      });
    });

    // get coupon
    builder.addCase(Investment.getCoupon.pending, (state) => {
      state.loadingCoupon = true;
    });
    builder.addCase(Investment.getCoupon.fulfilled, (state, action) => {
      state.loadingCoupon = false;
      state.coupon = action.payload?.data;
    });
    builder.addCase(Investment.getCoupon.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Coupon',
        description: action?.payload?.message?.id,
      });
      state.loadingCoupon = false;
    });

    // get coupon summary
    builder.addCase(Investment.getCouponSummary.pending, (state) => {
      state.loadingCoupon = true;
    });
    builder.addCase(Investment.getCouponSummary.fulfilled, (state, action) => {
      state.loadingCoupon = false;
      state.couponSummary = action.payload?.data;
    });
    builder.addCase(Investment.getCouponSummary.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Coupon',
        description: action?.payload?.message?.id,
      });
      state.loadingCoupon = false;
    });

    // create payback
    builder.addCase(Investment.createPayback.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Investment.createPayback.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Create Coupon',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Investment.createPayback.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed to create coupon',
        description: Util.errorHandle(action.payload),
      });
      state.loading = false;
    });

    // get payback
    builder.addCase(Investment.getPayback.pending, (state) => {
      state.loadingCoupon = true;
    });
    builder.addCase(Investment.getPayback.fulfilled, (state, action) => {
      state.loadingCoupon = false;
      state.coupon = action.payload?.data;
    });
    builder.addCase(Investment.getPayback.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Coupon',
        description: action?.payload?.message?.id,
      });
      state.loadingCoupon = false;
    });
  },
});

export default classSlice.reducer;
