import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Report } from '../../service';

const initialState = {
  internal_list: [],
  internal_detail: [],
  internal_length: 0,
  internal_log_history: [],
  external_list: [],
  external_detail: [],
  external_log_history: [],
  internal: [],
  profile: [],
  umum: [],
  tagihan: [],
  pembayaran: [],
  investor: [],
  loading: false,
  loadingDownload: false,
  external_payment_date: [],
  external_prepayment: {},
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list internal
    builder.addCase(Report.listReportInternal.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Report.listReportInternal.fulfilled, (state, action) => {
      state.loading = false;
      state.internal_list = action.payload.data;
    });
    builder.addCase(Report.listReportInternal.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // list external
    builder.addCase(Report.listReportExternal.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Report.listReportExternal.fulfilled, (state, action) => {
      state.loading = false;
      state.external_list = action.payload.data;
    });
    builder.addCase(Report.listReportExternal.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // get internal report detail
    builder.addCase(Report.ReportInternalDetail.pending, (state) => {
      state.loading = true;
      // reset state to prevent bug in column
      state.internal_detail = [];
    });
    builder.addCase(Report.ReportInternalDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.internal_detail = action.payload.data?.results;
      state.internal_length = action.payload.data?.count;
    });
    builder.addCase(Report.ReportInternalDetail.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // get internal log history
    builder.addCase(Report.ReportInternalLogHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Report.ReportInternalLogHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.internal_log_history = action.payload.data;
    });
    builder.addCase(Report.ReportInternalLogHistory.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // Create Internal Report
    builder.addCase(Report.CreateInternalReport.pending, (state) => {
      state.loadingDownload = true;
    });
    builder.addCase(Report.CreateInternalReport.fulfilled, (state, action) => {
      state.loadingDownload = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Create Report',
        description: action?.payload?.data?.msg,
      });
    });
    builder.addCase(Report.CreateInternalReport.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Create Report',
        description: action?.payload?.data.msg,
      });
      state.loadingDownload = false;
    });

    // Detail Report External
    builder.addCase(Report.GetReportExternalDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Report.GetReportExternalDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.external_detail = action.payload?.data?.results;
    });
    builder.addCase(Report.GetReportExternalDetail.rejected, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Report External',
        description: action?.payload?.message?.id,
      });
    });

    // External Log History
    builder.addCase(Report.ReportExternalLogHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Report.ReportExternalLogHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.external_log_history = action.payload?.data?.results;
    });
    builder.addCase(Report.ReportExternalLogHistory.rejected, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Log History',
        description: action?.payload?.message?.id,
      });
    });

    // Create Internal Report
    builder.addCase(Report.CreateExternalReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Report.CreateExternalReport.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Create Report',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Report.CreateExternalReport.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Create Report',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // Delete Internal Report
    builder.addCase(Report.DeleteReportInternal.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Report.DeleteReportInternal.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Delete Report',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Report.DeleteReportInternal.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Delete Report',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // Get External Payment Date
    builder.addCase(Report.GetPaymentDateExternal.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Report.GetPaymentDateExternal.fulfilled, (state, action) => {
      state.loading = false;
      state.external_payment_date = action.payload.data?.results;
    });
    builder.addCase(Report.GetPaymentDateExternal.rejected, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Payment Date',
        description: action?.payload?.message?.id,
      });
    });

    // Get External Prepayment
    builder.addCase(Report.GetPrePaymentExternal.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Report.GetPrePaymentExternal.fulfilled, (state, action) => {
      state.loading = false;
      state.external_prepayment = action.payload.data?.prepayment;
    });
    builder.addCase(Report.GetPrePaymentExternal.rejected, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Prepayment',
        description: action?.payload?.message?.id,
      });
    });
  },
});

export default reportSlice.reducer;
