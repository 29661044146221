import { Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const TableGlobal = ({
  dataSource,
  columns,
  keyRow,
  pageSize,
  onChangePagination,
  onShowSizeChange,
  totalData,
  current,
  pagination,
  hideOnSinglePage,
  className,
  loading,
  tipLoading = '',
  ...props
}) => {
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      rowKey={(record) => record[keyRow]}
      className={className}
      loading={{ spinning: loading, tip: tipLoading }}
      pagination={
        Object.keys(pagination).length !== 0
          ? { ...pagination, hideOnSinglePage: true }
          : {
              pageSize: pageSize || dataSource?.length,
              onChange: onChangePagination,
              total: totalData,
              showSizeChanger: false,
              current: current,
              hideOnSinglePage: hideOnSinglePage,
              onShowSizeChange: onShowSizeChange,
            }
      }
      {...props}
    />
  );
};

export default TableGlobal;

TableGlobal.defaultProps = {
  keyRow: 'id62',
  pageSize: 10,
  onChangePagination: () => {},
  onShowSizeChange: () => {},
  current: 1,
  pagination: {},
  hideOnSinglePage: true,
  className: 'custom-table-global',
  loading: false,
  tipLoading: '',
};

TableGlobal.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.any,
  keyRow: PropTypes.string,
  pageSize: PropTypes.number,
  onChangePagination: PropTypes.func,
  onShowSizeChange: PropTypes.func,
  totalData: PropTypes.number,
  current: PropTypes.number,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  hideOnSinglePage: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  tipLoading: PropTypes.string,
  props: PropTypes.any,
};
