import { Divider, Form, Input, Modal, Result, Typography } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import { CustomNotification } from '../../components/_shared';
import CustomButton from '../../components/_shared/custom-button';
import { Constant } from '../../const';
import { useAuth } from '../../hooks/useAuth';
import { Login } from '../../service';

const ChangePassword = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const field = [
    {
      id: 'current-password',
      name: 'Password Sekarang',
      rules: [
        { min: 8, message: 'Minimal 8 karakter' },
        {
          required: true,
          message: 'Field tidak boleh kosong',
        },
      ],
    },
    {
      id: 'new-password',
      name: 'Password Baru',
      rules: [
        {
          required: true,
          message: 'Field tidak boleh kosong',
        },
        { min: 8, message: 'Minimal 8 karakter' },
      ],
    },
    {
      id: 'confirm-password',
      name: 'Konfirmasi Password',
      rules: [
        {
          required: true,
          message: 'Field tidak boleh kosong',
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('new-password') === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error('Konfirmasi password tidak sesuai dengan password baru!')
            );
          },
        }),
        { min: 8, message: 'Minimal 8 karakter' },
      ],
    },
  ];

  const onFinish = (value) => {
    const payload = {
      current_password: value['current-password'],
      new_password: value['new-password'],
    };
    setLoadingUpdate(true);
    dispatch(Login.changePassword(payload))
      .unwrap()
      .then(() => {
        setLoadingUpdate(false);
        CustomNotification({ type: Constant.SUCCESS, message: 'Sukses Ubah Password' });
        navigate('/edit-profile', { replace: true });
      })
      .catch((err) => {
        CustomNotification({
          type: Constant.ERROR,
          message: 'Gagal Ubah Password',
          description: err?.message?.id,
        });
        setLoadingUpdate(false);
      });
  };

  const onClickForgotPassword = () => {
    setLoadingSendEmail(true);
    dispatch(Login.sendEmailVerification(user.email))
      .unwrap()
      .then(() => {
        setOpenModal(true);
        setLoadingSendEmail(false);
      })
      .catch(() => {
        setLoadingSendEmail(false);
      });
  };

  const closeModal = () => {
    setOpenModal(false);
    navigate('/edit-profile/forgot-password?section=otp-input');
  };

  return (
    <Page title="Change Password">
      <Form
        name="edit-profile"
        onFinish={onFinish}
        layout="horizontal"
        className="mt-5"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        requiredMark={false}>
        <div className="bg-white p-12 rounded-lg overflow-hidden max-w-[70vw] mx-auto">
          <Typography.Text className="text-2xl font-bold">Change Password</Typography.Text>
          <Divider />
          {field.map((res, i) => (
            <Form.Item
              key={i}
              name={res.id}
              label={<span className="font-bold">{res.name}</span>}
              rules={res.rules}
              validateFirst
              extra={
                res.id === 'current-password' && (
                  <CustomButton
                    loading={loadingSendEmail}
                    key="button-change-password"
                    type="link"
                    style={{ padding: 0 }}
                    onClick={onClickForgotPassword}>
                    <span className="text-primary underline">Forgot Password</span>
                  </CustomButton>
                )
              }>
              <Input.Password id={res.id} className="custom-input" />
            </Form.Item>
          ))}
        </div>

        <div className="flex justify-end max-w-[70vw] mx-auto my-6">
          <CustomButton type="primary" htmlType="submit" loading={loadingUpdate}>
            Ubah Password
          </CustomButton>
        </div>
      </Form>
      <Modal open={openModal} footer={false} closable={false} centered>
        <Result
          status="success"
          title="Kode OTP Telah Dikirim"
          subTitle="Silahkan periksa email Anda"
          extra={[
            <CustomButton key={1} type="primary" className="px-8" onClick={closeModal}>
              Tutup
            </CustomButton>,
          ]}
        />
      </Modal>
    </Page>
  );
};

export default ChangePassword;
