import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const downloadTemplate = createAsyncThunk(
  'get/downloadTemplate',
  async (approvalId, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `pool-of-asset/export-excel/?approval_id62=${approvalId}`,
        isDownload: true,
        fileName: 'Debitur Akuisisi',
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const poolOfAsset = createAsyncThunk('get/poolOfAsset', async (payload, { rejectWithValue }) => {
  const {
    approvalId,
    limit,
    offset,
    search = '',
    min = '',
    max = '',
    tenor_max = '',
    tenor_min = '',
    dropdown = '',
    dbr_max = '',
    dbr_min = '',
  } = payload;
  try {
    return await API.get({
      url: `pool-of-asset/?approval_id62=${approvalId}&limit=${limit}&offset=${offset}&search=${search}&min_balance=${min}&max_balance=${max}&dbr_max=${dbr_max}&dbr_min=${dbr_min}&tenor_max=${tenor_max}&tenor_min=${tenor_min}${dropdown}`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const summary = createAsyncThunk('get/summary', async (payload, { rejectWithValue }) => {
  const { approvalId, limit, offset } = payload;
  try {
    return await API.get({
      url: `pool-of-asset/summary/?approval_id62=${approvalId}&limit=${limit}&offset=${offset}`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const record = createAsyncThunk('get/record', async (payload, { rejectWithValue }) => {
  const { approvalId, limit, offset, active, search } = payload;
  try {
    return await API.get({
      url: `pool-of-asset/record/?approval_id62=${approvalId}&limit=${limit}&offset=${offset}&active=${active}&search=${search}`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const downloadRecord = createAsyncThunk(
  'get/downloadRecord',
  async (approvalId, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `pool-of-asset/record/export-excel/?approval_id62=${approvalId}`,
        isDownload: true,
        fileName: 'Record_Pool_of_Asset',
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const downloadSummary = createAsyncThunk(
  'get/downloadSummary',
  async (approvalId, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `pool-of-asset/summary/export-excel/?approval_id62=${approvalId}`,
        isDownload: true,
        fileName: 'Summary_Pool_of_Asset',
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default {
  downloadTemplate,
  poolOfAsset,
  summary,
  record,
  downloadRecord,
  downloadSummary,
};
