import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Util } from '../../helper';
import { Eba } from '../../service';

const initialState = {
  list: [],
  detail: { name: 'EBA' },
  loading: false,
  loadingEmail: false,
  productEmail: [],
  classPeriodDiff: 0,
  domain: {},
  summary: {},
  ebaPeriodDiff: 0,
};

export const ebaSlice = createSlice({
  name: 'eba',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list eba
    builder.addCase(Eba.getListEba.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Eba.getListEba.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload.data;
    });
    builder.addCase(Eba.getListEba.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // create eba
    builder.addCase(Eba.createEba.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Eba.createEba.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Create Product',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Eba.createEba.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal',
        description: Util.errorHandle(action?.payload),
      });
      state.loading = false;
    });

    // detail eba
    builder.addCase(Eba.getDetailEba.pending, (state) => {
      state.loading = true;
      state.detail = [];
    });
    builder.addCase(Eba.getDetailEba.fulfilled, (state, action) => {
      state.loading = false;
      state.detail = action.payload.data;

      // different period
      const startDate = action.payload?.data?.start_date;
      const endDate = dayjs(action.payload?.data?.end_date);
      state.ebaPeriodDiff = Math.abs(endDate.diff(startDate, 'months'));
    });
    builder.addCase(Eba.getDetailEba.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // Admin Approval
    builder.addCase(Eba.AdminApproval.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Eba.AdminApproval.fulfilled, (state, action) => {
      state.loading = false;
      state.detail = action.payload.data;
    });
    builder.addCase(Eba.AdminApproval.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Approval',
        description: action?.payload?.data?.Message,
      });
      state.loading = false;
    });

    // Edit Eba
    builder.addCase(Eba.editEba.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Eba.editEba.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(Eba.editEba.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal',
        description: Util.errorHandle(action?.payload),
      });
      state.loading = false;
    });

    // Delete Eba
    builder.addCase(Eba.deleteEba.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Eba.deleteEba.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(Eba.deleteEba.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Delete',
        description: action?.payload?.name,
      });
      state.loading = false;
    });

    // list domain
    builder.addCase(Eba.getDomainEmail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Eba.getDomainEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.domain = action.payload.data;
    });
    builder.addCase(Eba.getDomainEmail.rejected, (state) => {
      state.loading = false;
    });

    // Summary Eba
    builder.addCase(Eba.getSummaryEba.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Eba.getSummaryEba.fulfilled, (state, action) => {
      state.loading = false;
      state.summary = action.payload.data;
    });
    builder.addCase(Eba.getSummaryEba.rejected, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
    });

    // email
    builder.addCase(Eba.getEbaDomainEmail.pending, (state) => {
      state.loadingEmail = true;
    });
    builder.addCase(Eba.getEbaDomainEmail.fulfilled, (state, action) => {
      const optionsProduct = [];
      const emailProduct = [];
      action.payload.data?.map((e) => {
        optionsProduct.push({
          label: e.name,
          value: e.id62,
        });
        emailProduct.push(...e.email);
      });
      state.optionsProduct = optionsProduct;
      state.emailProduct = [...new Set(emailProduct)];
      state.loadingEmail = false;
    });
    builder.addCase(Eba.getEbaDomainEmail.rejected, (state, action) => {
      state.loadingEmail = false;
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data Product',
        description: action?.payload?.message?.id,
      });
    });
  },
});

export default ebaSlice.reducer;
