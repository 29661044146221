import { Form, Modal, Typography } from 'antd';
import React from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { Revenue } from '../../../service';
import { CustomButton, CustomInput, CustomNotification } from '../../_shared';
import rules from './rulesForm';
export default function EarningPercentageForm({
  open,
  onClose,
  ebaId,
  isUpdate,
  id62,
  setCurrent,
  setTotal,
  record,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.revenue);
  const { ebaPeriodDiff } = useSelector((state) => state.eba);

  const handleModalClose = () => {
    form.resetFields();
    onClose();
  };

  const onSubmit = async (e) => {
    if (isUpdate) {
      const payload = {
        value: e.percentage,
      };
      await dispatch(Revenue.editRevenuePeriod({ payload: payload, id62: id62 }))
        .unwrap()
        .then((res) => {
          CustomNotification({
            type: 'success',
            message: 'Success Edit Percentage',
            description: res.message?.id,
          });
          onClose();
          setCurrent(1);
          setTotal(res.data.count);
        });
    } else {
      for (let i = 0; i < e?.fields.length; i++) {
        const payload = {
          eba: ebaId,
          period_start: e?.fields[i].period_start,
          period_end: e?.fields[i].period_end,
          value: e?.fields[i].percentage,
        };
        await dispatch(Revenue.createRevenuePeriod(payload));
      }
    }
    await dispatch(Revenue.revenuePeriod({ ebaId }))
      .unwrap()
      .then((res) => {
        setTotal(res.data.count);
        onClose();
        setCurrent(1);
      });
  };

  return (
    <Modal
      title={
        <h3 className="text-xl font-bold">
          {isUpdate ? 'Edit Earning Percentage' : 'Input Earning Percentage'}
        </h3>
      }
      open={open}
      onCancel={() => handleModalClose()}
      centered
      footer={[
        <CustomButton key="back" type="secondary" onClick={() => handleModalClose()}>
          Batal
        </CustomButton>,
        <CustomButton key="submit" type="primary" form="myForm" htmlType="submit" loading={loading}>
          Simpan
        </CustomButton>,
      ]}
      width={'50%'}
      destroyOnClose={true}>
      <Form
        id="myForm"
        layout="vertical"
        requiredMark={false}
        form={form}
        onFinish={onSubmit}
        {...(isUpdate
          ? { initialValues: { ...{ percentage: record?.value?.replace('%', '') } } }
          : {})}>
        {isUpdate ? (
          <div>
            <div className="flex flex-column items-center mb-5">
              <div className="flex items-center justify-evenly w-full">
                <Typography.Paragraph className="text-base text-center w-1/3">
                  Porsi Eba
                </Typography.Paragraph>
              </div>
            </div>
            <Form.Item className="text-center w-full" name="percentage" rules={rules.rulesPercent}>
              <CustomInput type="text" className="py-[18px] px-[24px]" suffix={<span>%</span>} />
            </Form.Item>
          </div>
        ) : (
          <Form.List name="fields">
            {(fields, { add }) => {
              return (
                <div>
                  <div className="flex flex-column items-center gap-3 mb-5">
                    <div className="flex items-center justify-evenly w-full gap-3">
                      <Typography.Paragraph className="text-base text-center w-1/3">
                        Start (Month)
                      </Typography.Paragraph>
                      <Typography.Paragraph className="text-base text-center w-1/3">
                        End (Month)
                      </Typography.Paragraph>
                      <Typography.Paragraph className="text-base text-center w-1/3">
                        Porsi Eba
                      </Typography.Paragraph>
                    </div>
                  </div>
                  {fields.map((field, index) => (
                    <div key={field.key} className="flex flex-column items-center gap-3 mb-5">
                      <div className="flex items-baseline justify-evenly w-full gap-3">
                        <Form.Item
                          className="text-center w-1/3"
                          name={[index, 'period_start']}
                          validateFirst
                          rules={rules.rulesPeriodStart}>
                          <CustomInput type="text" className="py-[18px] px-[24px]" />
                        </Form.Item>
                        <Form.Item
                          className="text-center w-1/3"
                          validateFirst
                          name={[index, 'period_end']}
                          rules={rules.rulesPeriodEnd(ebaPeriodDiff)}>
                          <CustomInput type="text" className="py-[18px] px-[24px]" />
                        </Form.Item>
                        <Form.Item
                          className="text-center w-1/3"
                          validateFirst
                          name={[index, 'percentage']}
                          rules={rules.rulesPercent}>
                          <CustomInput
                            type="text"
                            className="py-[18px] px-[24px]"
                            suffix={<span>%</span>}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  ))}
                  <div>
                    <CustomButton
                      type="primary-ghost"
                      className="
                      group
                      justify-center
                      items-center
                      flex
                      w-full
                      space-x-2"
                      onClick={() => add()}>
                      <BsPlusCircleFill size={20} className="text-primary group-hover:text-white" />
                      Input Earning Percentage
                    </CustomButton>
                  </div>
                </div>
              );
            }}
          </Form.List>
        )}
      </Form>
    </Modal>
  );
}
