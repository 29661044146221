import { InputNumber } from 'antd';
import React from 'react';

const CustomInputNumber = ({ value, placeholder, className, type, controls = false, ...props }) => {
  return (
    <InputNumber
      placeholder={placeholder}
      type={type}
      value={value}
      className={`custom-input rounded ${className} `}
      controls={controls}
      {...props}
    />
  );
};

export default CustomInputNumber;
