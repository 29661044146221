import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, Space, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { MdLibraryBooks } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ManagementProductComponent, Page } from '../../components';
import { CustomButton } from '../../components/_shared';
import { Util } from '../../helper';
import usePage from '../../hooks/usePage';
import { Investment } from '../../service';
import investment from '../../service/api/investment';

export default function AssetClassDetail() {
  const { isProduct, isManagementProduct } = usePage();
  const { isSupervisor } = Util.role();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { confirm } = Modal;

  const [searchParams] = useSearchParams();
  const isSimulation = searchParams.get('is_simulation');
  const ebaId = searchParams.get('eba-id');
  const status = searchParams.get('status');
  const ebaName = searchParams.get('eba-name');
  const gradeId = searchParams.get('grade-id');

  const { ratings, coupon, classDetail, investor } = useSelector((state) => state.investment);

  const [log, setLog] = useState(false);
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(false);

  useEffect(() => {
    dispatch(Investment.getClassByID({ id: gradeId }));
    dispatch(Investment.getRating({ ebaId: ebaId, gradeId: gradeId }));
    dispatch(Investment.getCoupon({ search: '', gradeId: gradeId }));
  }, []);

  const breadcrumbs = [
    {
      name: `${isSimulation === 'true' ? 'Simulasi Produk' : 'Management Produk'}`,
      href: `${isSimulation === 'true' ? '/simulation' : '/management-product'}`,
    },
    {
      name: 'Draft Produk',
      href: '/management-product/?status=1',
      isHide: isProduct || isSupervisor,
    },
    { name: 'Management Asset', href: null, isHide: !isProduct },
    {
      name: `Senior Tranches ${ebaName}`,
      // eslint-disable-next-line prettier/prettier
      href: `/management-product/asset-class?page=${
        isProduct ? '' : 'management-'
      }product&eba-id=${ebaId}&eba-name=${ebaName}&status=${status}&is_simulation=${
        isSimulation === 'true'
      }`,
    },
    { name: 'Detail Senior Tranches', href: null },
  ];

  // const PeriodCoupon = useCallback(
  //   ({ emptyText, field }) => {
  //     if (loadingCoupon)
  //       return (
  //         <td className="border p-2 ">
  //           <Spin size="small" />
  //         </td>
  //       );
  //     if (coupon?.length === 0) {
  //       return <td className="border p-2 text-left min-w-[50px]">{emptyText}</td>;
  //     }
  //     return coupon?.map((couponPeriod, index) => (
  //       <td className="border p-2 text-center min-w-[50px]" key={index}>
  //         {couponPeriod[field]}
  //       </td>
  //     ));
  //   },
  //   [coupon, loadingCoupon]
  // );

  const onClickBack = () => {
    navigate(
      // eslint-disable-next-line prettier/prettier
      `/management-product/asset-class?page=${
        isProduct ? '' : 'management-'
      }product&eba-id=${ebaId}&eba-name=${ebaName}&status=${status}&is_simulation=${
        isSimulation === 'true'
      }`
    );
  };

  const ButtonAddInvestor = useCallback(() => {
    if (isSupervisor) return null;
    return (
      <CustomButton
        onClick={() =>
          navigate(
            // eslint-disable-next-line prettier/prettier
            `/management-product/asset-class/1/add-investor?page=${
              isProduct ? '' : 'management-'
            }product&eba-id=${ebaId}&eba-name=${ebaName}&status=${status}&grade-id=${gradeId}&class-detail=${
              classDetail?.name
            }&is_simulation=${isSimulation === 'true'}`
          )
        }
        type="primary-ghost"
        className=" group justify-center items-center flex space-x-2">
        <BsPlusCircleFill size={20} className="text-primary group-hover:text-white" />
        Input Investor
      </CustomButton>
    );
  }, []);

  const ButtonSetCoupon = useCallback(() => {
    if (isSupervisor) return null;
    return (
      <CustomButton type="primary-ghost" onClick={() => setOpen(true)}>
        Atur
      </CustomButton>
    );
  }, []);

  const ButtonAddRating = useCallback(() => {
    if (isManagementProduct && isSupervisor) return null;
    return (
      <CustomButton
        type="primary-ghost"
        className=" group justify-center items-center flex space-x-2"
        onClick={() => setRating(true)}>
        <BsPlusCircleFill size={20} className="text-primary group-hover:text-white" />
        Input Rating
      </CustomButton>
    );
  }, []);

  const ButtonDeleteInvestor = useCallback(() => {
    if (isManagementProduct && isSupervisor) return null;
    const handleDelete = () => {
      confirm({
        title: 'Apakah kamu yakin ingin menghapus data investor?',
        icon: <ExclamationCircleFilled />,
        okButtonProps: {
          className: 'custom-button custom-button-primary',
          style: { height: '48px' },
        },
        cancelText: 'Cancel',
        cancelButtonProps: {
          className: 'custom-button custom-button-secondary',
          style: { height: '48px' },
        },
        onOk() {
          dispatch(investment.DeleteAllInvestor({ id: gradeId }))
            .unwrap()
            .then(() => {
              dispatch(Investment.investorList({ gradeId: gradeId }));
            });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    };
    return (
      <CustomButton
        type="primary-ghost"
        className=" group justify-center items-center flex space-x-2"
        onClick={() => handleDelete()}>
        Delete All Investor
      </CustomButton>
    );
  }, []);

  return (
    <Page
      title="Detail Senior Tranches"
      breadcrumbs={breadcrumbs}
      updated={ebaId ? classDetail?.updated_at : ''}>
      <div className="mb-2">
        <Typography.Paragraph className="font-bold text-3xl">
          Detail Senior Tranches {classDetail?.name}
        </Typography.Paragraph>
      </div>
      <div className="mb-2">
        <Typography.Paragraph className="font-bold text-xl">{ebaName}</Typography.Paragraph>
      </div>
      <div className="flex justify-between items-center gap-5 my-6">
        <Typography.Paragraph className="text-base font-bold">
          Periode Peluruhan Pokok dan Data Investor
        </Typography.Paragraph>
        <div className="flex items-center gap-3">
          <ButtonSetCoupon />
          {!isSupervisor && <ButtonAddRating />}
          <CustomButton
            type="primary"
            className=" group justify-center items-center flex space-x-2"
            onClick={() => setLog(true)}>
            <MdLibraryBooks size={20} className="text-white group-hover:text-primary" />
            Log Rating
          </CustomButton>
        </div>
      </div>
      <ManagementProductComponent.PeriodTable gradeId={gradeId} />
      {/* <div className="mb-5 p-0 block ml-[256px] table-horizontal overflow-x-scroll overflow-y-visible">
        <table className="bg-white border-collapse border">
          <tbody>
            <tr>
              <th className="border p-2 w-[256px] absolute left-0 top-auto text-left bg-white">
                Periode
              </th>
              <PeriodCoupon field="period" emptyText="Periode kupon belum diatur" />
            </tr>
            <tr>
              <th className="border p-2 w-[256px] absolute left-0 top-auto text-left bg-white">
                Persentase (%)
              </th>
              <PeriodCoupon field="percentage" emptyText="Persentase kupon belum diatur" />
            </tr>
          </tbody>
        </table>
      </div> */}
      <div className="flex justify-between items-center gap-5 my-6">
        <Typography.Paragraph className="text-base font-bold">
          Data Investor Tercatat
        </Typography.Paragraph>
        <Space>
          <ButtonAddInvestor />
          {investor?.length > 0 && <ButtonDeleteInvestor />}
        </Space>
      </div>

      <ManagementProductComponent.InvestorList gradeId={gradeId} />

      {!isProduct && (
        <div className="flex flex-row-reverse items-center gap-5">
          <CustomButton type="primary-ghost" onClick={onClickBack}>
            Kembali
          </CustomButton>
        </div>
      )}

      <ManagementProductComponent.AssetClassLogRating
        open={log}
        onClose={() => setLog(false)}
        AssetClassRating={ratings}
      />
      <ManagementProductComponent.AssetClassSet
        open={open}
        onClose={() => setOpen(false)}
        ebaId={ebaId}
        gradeId={gradeId}
        isProduct={isProduct}
        isEdit={coupon?.length !== 0}
      />
      <ManagementProductComponent.AssetClassRating
        open={rating}
        onClose={() => setRating(false)}
        ebaId={ebaId}
        gradeId={gradeId}
      />
    </Page>
  );
}
