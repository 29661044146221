const summaryHeader = [
  [
    {
      text: 'Keterangan',
      width: '10%',
      className: 'border',
      rowSpan: 2,
    },
    {
      text: 'Jumlah Debitur',
      width: '10%',
      className: 'border',
      rowSpan: 2,
    },
    {
      text: 'Pembayaran Porsi KP Bukopin',
      className: 'border',
      colSpan: 3,
    },
    {
      text: 'Pembayaran Porsi Bahana',
      className: 'border',
      colSpan: 3,
    },
  ],
  [
    {
      text: 'Pokok (Rp)',
      className: 'border',
    },
    {
      text: 'Bunga (Rp)',
      className: 'border',
    },
    {
      text: 'Total Tagihan (Rp)',
      className: 'border',
    },
    {
      text: 'Pokok (Rp)',
      className: 'border',
    },
    {
      text: 'Bunga (Rp)',
      className: 'border',
    },
    {
      text: 'Total Tagihan (Rp)',
      className: 'border',
    },
  ],
];

const summaryData = [
  {
    description: 'Lancar',
    number_of_debtors: '14.821',
    payment_bukopin: {
      main: '9.005.431.104',
      rate: '20.510.300.863',
      total: '29.515.731.967',
    },
    payment_bahana: {
      main: '5.403.258.663',
      rate: '12.306.180.518',
      total: '17.709.438.181',
    },
  },
  {
    description: 'Meninggal Dunia',
    number_of_debtors: '37',
    payment_bukopin: {
      main: '21.527.607',
      rate: '43.562.039',
      total: '69.089.646',
    },
    payment_bahana: {
      main: '12.916.564',
      rate: '26.137.223',
      total: '383.654.659',
    },
  },
  {
    description: 'Lepas Lunas',
    number_of_debtors: '315',
    payment_bukopin: {
      main: '181.682.904',
      rate: '457.741.527',
      total: '639.424.431',
    },
    payment_bahana: {
      main: '109.009.743',
      rate: '274.644.916',
      total: '383.654.659',
    },
  },
  {
    description: 'Lunas Jatuh Tempo',
    number_of_debtors: '214',
    payment_bukopin: {
      main: '125.252.151',
      rate: '362.813.713',
      total: '517.723.251',
    },
    payment_bahana: {
      main: '12.623.713',
      rate: '612.332.891',
      total: '162.721.712',
    },
  },
  {
    description: 'Sub Total',
    number_of_debtors: '15.173',
    payment_bukopin: {
      main: '9.208.641.616',
      rate: '21.011.604.426',
      total: '30.220.246.044',
    },
    payment_bahana: {
      main: '5.525.184.970',
      rate: '12.606.962.657',
      total: '18.132.147.627',
    },
  },
];

const summaryInflow = [
  [
    {
      text: 'Periode',
      width: '50%',
      className: 'border',
    },
    {
      text: 'Total Cash Inflow(Rp)',
    },
  ],
];

const summaryInflowData = [
  {
    period: 'November 2022',
    total: '17709000',
  },
];

export { summaryHeader, summaryData, summaryInflow, summaryInflowData };
