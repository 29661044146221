import { Avatar, Popover, Typography } from 'antd';
import React from 'react';
import { BiChevronDown, BiUserCircle } from 'react-icons/bi';
import { FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { CustomButton } from '../_shared';

const Header = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const onClickLogout = () => {
    logout();
  };

  const onClickMyProfile = () => {
    navigate('/my-profile');
  };

  const button = [
    { title: 'My Profile', icon: BiUserCircle, onClick: onClickMyProfile },
    { title: 'Logout', icon: FiLogOut, onClick: onClickLogout },
  ];

  const AvatarContent = (
    <div className="flex flex-col">
      {button.map((res, i) => (
        <CustomButton
          key={i}
          type="link"
          height="fit-content"
          className="group flex items-center mt-3 px-3 py-2 text-primary font-bold space-x-2"
          block
          onClick={res.onClick}>
          <res.icon size={25} className=" text-[#E70A09] group-hover:text-[#69b1ff]" />
          {res.title}
        </CustomButton>
      ))}
    </div>
  );

  return (
    <div
      className="
        flex
        justify-end
        w-full
        h-[80px]
        items-center
        py-2
        pr-9">
      <Popover placement="bottomRight" content={AvatarContent}>
        <div className="flex space-x-4 items-center">
          <Avatar
            src={user?.picture}
            style={{
              backgroundColor: '#00a2ae',
              verticalAlign: 'middle',
            }}>
            {user?.full_name[0]}
          </Avatar>
          <div>
            <Typography.Paragraph className="font-bold">{user?.full_name}</Typography.Paragraph>
            <Typography.Paragraph className="text-sm capitalize">{user?.role}</Typography.Paragraph>
          </div>
          <BiChevronDown color="#E70A09" size={20} />
        </div>
      </Popover>
    </div>
  );
};

export default React.memo(Header);
