import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Date, Storage, Util } from '../../helper';
import { Aquisition } from '../../service';
import Table from '../table';
import { columns } from './components/columns';
import { CustomNotification } from '../_shared';
import { Constant } from '../../const';

const isSubmitted = (status) => {
  const statusSubmit = [3, 5, 6, 7, 8];
  return statusSubmit.includes(status);
};

const DataAcquisition = ({
  cardTitle,
  idEba,
  navigate,
  approvalFile,
  ebaName,
  tabSelected,
  cardSelected,
  ebaEnd,
}) => {
  const { isOriginator, isSupervisor } = Util.role();
  const dispatch = useDispatch();
  const { approval, loading } = useSelector((state) => state.aquisition);

  const [page, setPage] = useState({ limit: 10, offset: 0, current: 1 });

  useEffect(() => {
    const payload = {
      idEba: idEba,
      limit: page.limit,
      offset: page.offset,
      isArchive: cardSelected === 2,
      status: cardSelected === 1 ? '8' : '',
    };
    if (idEba) dispatch(Aquisition.aquisitionApproval(payload));
  }, [page, cardSelected, idEba]);

  const onClickDetail = (record) => {
    const fileId = record?.file?.id62;
    const approveId = approvalFile?.id62 ? `&approve-id=${approvalFile?.id62}` : '';
    const recordDate = `&date=${record?.date || ''}`;
    let query = `upload-data-originator${
      location.search + approveId + recordDate
    }&tab=${tabSelected}`;

    if (!fileId && !isOriginator) {
      CustomNotification({
        type: Constant.WARNING,
        message: 'Originator belum upload data',
      });
      return;
    }
    if (isSupervisor && [0, 1, 2].includes(Number(record?.status?.value))) {
      CustomNotification({
        type: Constant.WARNING,
        message: 'Admin belum melakukan rekomendasi',
      });
      return;
    }
    if (!isOriginator) {
      // is not originator
      let path = 'management-eba';
      const showData = 'acquisition';

      if (record?.status?.value == 8) {
        // status is done
        path = 'preview-data-acquisition';
      }

      query = `/${path}?page=product&sub=${cardTitle}&eba-id=${idEba}&eba-name=${ebaName}&file-id=${fileId}&month=${Date.monthYear(
        record?.date
      )}&date=${record?.date}&status=${record?.status?.value}&updated=${
        record?.updated_at
      }&approval-id=${record.id62}&loss-cashflow=${record.cost}&installment-total=${
        record.installment.total
      }&installment-total-die=${record.installment.total_die}&installment-total-paidoff=${
        record.installment.total_paidoff
      }&loss-debitur=${record.lost_debtor}&tab=${tabSelected}&show=${showData}`;

      Storage.setStorage('reject_reason', record.notes);
    }

    if (isOriginator && isSubmitted(record?.status?.value)) {
      // approve by supervisor
      query = `/summary-acquisition-originator?page=product&updated=${record?.updated_at}&approval-id=${record.id62}&eba-id=${idEba}&eba-name=${ebaName}&status=${record?.status?.value}&tab=${tabSelected}&date=${record?.date}`;
    }

    if (cardSelected === 1) {
      // select pool of asset
      query = `/pool-of-asset?page=product&eba-id=${idEba}&eba-name=${ebaName}&section_page=pool-of-asset&approval-id=${record.id62}&eba-end=${ebaEnd}&date=${record?.date}`;
    }
    navigate(query);
  };

  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
  };

  return (
    <Table.TableGlobal
      dataSource={approval?.results}
      columns={columns(onClickDetail)}
      pageSize={5}
      keyRow="id62"
      scroll={{ x: 900 }}
      loading={loading}
      totalData={approval?.count}
      current={page.current}
      onChangePagination={paginationChange}
    />
  );
};

export default DataAcquisition;
