import { Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { BsBoxSeam } from 'react-icons/bs';
import { IoIosSend, IoMdArrowRoundBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Page } from '../../components';
import Table from '../../components/table';
import { CustomNotification, ModalApprove } from '../../components/_shared';
import CustomButton from '../../components/_shared/custom-button';
import { Constant } from '../../const';
import { Date, Format, Storage, Util } from '../../helper';
import { useAuth } from '../../hooks/useAuth';
import { Aquisition } from '../../service';
import { columns } from './components/table';

const DetailDataAcquisition = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { isSupervisor, isOriginator } = Util.role();
  const [searchParams] = useSearchParams();
  const approvalId = searchParams.get('approval-id');
  const month = searchParams.get('month');
  const updated = searchParams.get('updated');
  const ebaId = searchParams.get('eba-id');
  const ebaName = searchParams.get('eba-name');
  const status = searchParams.get('status');
  const cashflow = searchParams.get('cashflow');

  const dispatch = useDispatch();
  const { detail } = useSelector((state) => state.eba);
  const { selectedRowKeys, loadingDownload, approvalSummaryDetail, loading } = useSelector(
    (state) => state.aquisition
  );

  const [page, setPage] = useState({ current: 1, offset: 0 });
  const [openModal, setIsOpenModal] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [notes, setNotes] = useState('');

  const breadcrumbs = [
    { name: 'Management Produk', href: '/management-product' },
    { name: ebaName, href: `/assets?page=product&eba-id=${ebaId}&eba-name=${ebaName}` },
    {
      name: 'Pengelolaan EBA',
      href: `/management-eba${location.search}`,
      isHide: status == 8,
    },
    { name: 'Summary Akuisisi', href: null, goBack: true },
    { name: 'Detail Summary Akuisisi', href: null },
  ];

  useEffect(() => {
    dispatch(Aquisition.acquisitionApprovalSummaryDetail({ approvalId, offset: page.offset }));
  }, [page]);

  const downloadExcel = () => {
    dispatch(Aquisition.acquisitionDownloadDetailApprovalSummaryAcquisition(approvalId));
  };

  const onClickReject = () => {
    setIsApprove(false);
    setIsOpenModal(true);
  };

  const onClickApprove = () => {
    setIsApprove(true);
    setIsOpenModal(true);
  };

  const onClickCancel = () => {
    setIsOpenModal(false);
  };

  const approvalBy = (payload) => {
    if (isSupervisor) {
      return dispatch(Aquisition.aquisitionApprovalBySupervisor(payload));
    }
    if (isOriginator) {
      return dispatch(Aquisition.aquisitionApprovalByOriginator(payload));
    }
  };

  const onClickSend = () => {
    const action = isApprove ? 'approve' : 'reject';

    if (!notes && !isApprove) {
      CustomNotification({
        type: Constant.ERROR,
        message: `Catatan tidak boleh kosong`,
      });
      return;
    }

    const payload = {
      id62: approvalId,
      approve: isApprove,
      notes: notes,
    };
    approvalBy(payload)
      .unwrap()
      .then((res) => {
        setIsOpenModal(false);
        CustomNotification({
          type: Constant.SUCCESS,
          message: `Sukses ${action}`,
          description: res?.message?.id || `Data berhasil ${action} oleh ${user.role}`,
        });
        navigate(`/assets?page=product&eba-id=${ebaId}&eba-name=${ebaName}`);
      })
      .catch(() => {
        setIsOpenModal(false);
        CustomNotification({
          type: Constant.ERROR,
          message: `Gagal ${action}`,
          description: `Data gagal ${action} oleh ${user.role}`,
        });
      });
  };

  const onClickSubmit = () => {
    const payload = {
      approval_id62: approvalId,
      acquisition_id62s: selectedRowKeys,
      approve: true,
    };
    dispatch(Aquisition.aquisitionApprovalByAdmin(payload))
      .unwrap()
      .then(() => {
        CustomNotification({
          type: Constant.SUCCESS,
          message: 'Sukses',
          description: 'Sukses melakukan pengajuan',
        });
        Storage.removeStorage('reject_reason');
        navigate(`/assets?page=product&eba-id=${ebaId}&eba-name=${ebaName}&tab=2`);
      })
      .catch(() => {});
  };

  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize });
  };

  const onClickBack = () => {
    navigate(-1, { replace: true });
  };

  const ButtonShow = () => {
    let button = (
      <>
        <CustomButton type="primary-ghost" onClick={downloadExcel} loading={loadingDownload}>
          <span className="text-sm">Download Excel</span>
        </CustomButton>
        {[0, 1, 2, 6].includes(Number(status)) && (
          <CustomButton
            onClick={onClickSubmit}
            type="primary"
            className="
            group
            flex
            justify-center
            items-center
            space-x-2">
            <IoIosSend size={20} className="text-white group-hover:text-primary" />
            <span className=" text-sm">Ajukan</span>
          </CustomButton>
        )}
      </>
    );

    if (isSupervisor && [3].includes(Number(status))) {
      button = (
        <>
          <CustomButton type="primary-ghost" className="px-10" onClick={onClickReject}>
            <span className=" text-sm">Reject</span>
          </CustomButton>
          <CustomButton
            onClick={onClickApprove}
            type="primary"
            className="
            group
            flex
            justify-center
            items-center
            px-10
            space-x-2">
            <span className=" text-sm">Approve</span>
          </CustomButton>
        </>
      );
    }
    return button;
  };

  const TitlePage = () => {
    let title = `Rekomendasi - ${detail?.name}`;
    if (isSupervisor) {
      title = `${detail?.name} - Data Akuisisi Baru ${month}`;
    }

    return (
      <div className="flex items-center space-x-3">
        <IoMdArrowRoundBack size={30} className="cursor-pointer" onClick={onClickBack} />
        <div
          className="
            flex
            items-center
            justify-center
          bg-primary
            rounded-[50px]
            w-[48px]
            h-[48px]
              ">
          <BsBoxSeam size={20} color="#ffffff" className="font-bold" />
        </div>
        <Typography.Paragraph className="font-bold text-3xl">{title}</Typography.Paragraph>
      </div>
    );
  };

  const cashflowCount = useMemo(() => {
    return (
      <>
        <div className="flex justify-between border-b-1 p-3 ">
          <Typography.Text className=" text-primary font-extrabold">
            Cashflow Akuisisi
          </Typography.Text>
          <Typography.Text className=" text-primary font-extrabold">Jumlah Debitur</Typography.Text>
        </div>

        <div className="flex justify-between border-b-1 px-3 py-6 bg-white">
          <Typography.Text>{Format.numberFormat(Math.round(cashflow))}</Typography.Text>
          <Typography.Text>{approvalSummaryDetail?.count} Orang</Typography.Text>
        </div>
      </>
    );
  }, [approvalSummaryDetail]);

  return (
    <Page title="Detail Acquisition" breadcrumbs={breadcrumbs}>
      <TitlePage />
      <Typography.Paragraph className="text-grey mt-3">
        Diperbarui {Date.parseTimeAgo(moment(updated).format('YYYY-MM-DD HH:mm:ss'))}
      </Typography.Paragraph>
      <div className="flex justify-between my-6">
        <Typography.Text className="font-bold text-2xl">
          {isSupervisor ? 'Data Akusisi Baru Diajukan ' : 'Detail Data Akuisisi'}
        </Typography.Text>
        <div className="flex space-x-3">
          <ButtonShow />
        </div>
      </div>
      {isSupervisor && cashflowCount}
      <Table.TableGlobal
        dataSource={approvalSummaryDetail?.results}
        columns={columns}
        rowKey={(record) => record.id62}
        scroll={{ x: 1600 }}
        current={page.current}
        className="mt-10"
        pagination={{
          pageSize: 10,
          onChange: paginationChange,
          total: approvalSummaryDetail?.count,
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
      />

      <ModalApprove
        isApprove={isApprove}
        loading={loading}
        notes={notes}
        onClickCancel={onClickCancel}
        onClickSend={onClickSend}
        openModal={openModal}
        setNotes={setNotes}
      />
    </Page>
  );
};

export default DetailDataAcquisition;
