import { Date } from '../../../helper';

const columnProfile = [
  {
    title: 'Kode EBA',
    dataIndex: 'profile',
    align: 'center',
    width: 180,
    fixed: true,
    render: (record) => record?.kode_eba,
  },
  {
    title: 'Nama EBA',
    dataIndex: 'profile',
    align: 'center',
    width: 200,
    render: (record) => record?.nama_eba,
  },
  {
    title: 'Nama Kreditur Awal (Originator)',
    dataIndex: 'profile',
    align: 'center',
    width: 180,
    render: (record) => record?.originator,
  },
  {
    title: 'Nama Penyedia Jasa (Servicer)',
    dataIndex: 'profile',
    align: 'center',
    width: 180,
    render: (record) => record?.servicer,
  },
  {
    title: 'Kode Bank Kustodian',
    dataIndex: 'profile',
    align: 'center',
    width: 180,
    render: (record) => record?.kode_bank_kustodian,
  },
  {
    title: 'Nama Pemeringkat',
    dataIndex: 'profile',
    align: 'center',
    width: 180,
    render: (record) => record?.nama_pemeringkat,
  },
  {
    title: 'Nama Penilai',
    dataIndex: 'profile',
    align: 'center',
    width: 180,
    render: (record) => record?.nama_penilai,
  },
  {
    title: 'Nama Penyedia Sarana Peningkatan Kredit/Arus Kas',
    dataIndex: 'profile',
    align: 'center',
    width: 200,
    render: (record) => record?.nama_penyedia_sarana_peningkatan_kredit,
  },
  {
    title: 'Nama Pihak Terkait Lain',
    dataIndex: 'profile',
    align: 'center',
    width: 180,
    render: (record) => record?.nama_pihak_terkait_lain,
  },
  {
    title: 'Tanggal Efektif',
    dataIndex: 'profile',
    align: 'center',
    width: 180,
    render: (record) => Date.twoDigitWithDash(record?.tanggal_efektif),
  },
  {
    title: 'Tanggal Masa Penawaran Umum',
    align: 'center',
    children: [
      {
        title: 'Awal Penawaran',
        dataIndex: 'profile',
        align: 'center',
        width: 180,
        render: (record) => Date.twoDigitWithDash(record?.tanggal_masa_penawaran_umum_awal),
      },
      {
        title: 'Akhir Penawaran',
        dataIndex: 'profile',
        align: 'center',
        width: 180,
        render: () => '2019-09-3',
        // render: (record) => Date.twoDigitWithDash(record?.tanggal_masa_penawaran_umum_akhir | ''),
      },
    ],
  },
  {
    title: 'Jangka Waktu Jatuh Tempo EBA (Bulan)',
    dataIndex: 'profile',
    align: 'center',
    width: 200,
    render: (record) => record?.jangka_waktu_jatuh_tempo_eba,
  },

  {
    title: 'Jenis Tagihan',
    dataIndex: 'profile',
    align: 'center',
    width: 180,
    render: (record) => record?.jenis_tagihan,
  },
];

export { columnProfile };
