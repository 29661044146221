import { Layout, Tooltip } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { TiThMenu } from 'react-icons/ti';
import { Navigate, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { Images } from '../../const';
import { useAuth } from '../../hooks/useAuth';
import Header from '../header';
import SideComponent from './SideComponent';

export const LayoutEntry = React.memo(({ children }) => {
  const { Sider, Content } = Layout;

  const location = useLocation();
  const navigate = useNavigate();

  const [keySelected, setKey] = useState('management-product');
  const [isPoA, setIsPoa] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState(false);

  useEffect(() => {
    const path = location.pathname.replace('/', '');
    setKey(path);
  }, [location.pathname]);

  return (
    <Layout className="h-[100vh]">
      <Sider
        style={{
          background: '#ffffff',
          flex: 0,
          flexBasis: 'auto',
          overflowY: 'auto',
        }}>
        <div className="flex justify-center items-center space-x-3 py-5 px-3 mb-[58px]">
          {isPoA && (
            <Tooltip title={isShowMenu ? 'Filter' : 'Menu'} className="group">
              <TiThMenu
                size={30}
                id="button-menu"
                role="button"
                className="text-primary cursor-pointer transition ease-in-out delay-150 hover:scale-110 duration-300"
                onClick={() => {
                  setIsShowMenu(!isShowMenu);
                }}
              />
            </Tooltip>
          )}
          <div className="" onClick={() => navigate('/')}>
            <img src={Images.BAHANA_LOGO} alt="bahana logo" className="cursor-pointer" />
          </div>
        </div>
        <SideComponent
          isShowMenu={isShowMenu}
          keySelected={keySelected}
          setKey={setKey}
          isPoA={isPoA}
          setIsPoa={setIsPoa}
        />
      </Sider>
      <Layout>
        <Header />
        <Content id="content" className="h-full p-5 overflow-y-scroll">
          <Fragment>{children}</Fragment>
        </Content>
      </Layout>
    </Layout>
  );
});

export const AuthenticatedLayout = () => {
  const outlet = useOutlet();
  const { user } = useAuth();

  if (!user?.token) {
    return <Navigate to="/sign-in" />;
  }
  return <LayoutEntry>{outlet}</LayoutEntry>;
};
