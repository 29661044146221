import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { ManagementUserComponent } from '..';
import { useDispatch } from 'react-redux';
import { User } from '../../service';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';

function UserAction({ id, group }) {
  const { confirm } = Modal;

  const [open, setOpen] = useState(false);
  const [initialValues, setInitalValues] = useState({
    name: '',
    email: '',
    role: '',
    company: '',
    product: [],
  });
  const dispatch = useDispatch();

  const showConfirm = () => {
    confirm({
      title: <h3 className="text-xl font-bold">Apakah Anda yakin ingin menghapus user ini?</h3>,
      icon: <ExclamationCircleFilled />,
      centered: true,
      width: '30%',
      okText: 'Delete',
      okButtonProps: {
        className: 'custom-button custom-button-primary',
        style: { height: '48px' },
      },
      cancelText: 'Cancel',
      cancelButtonProps: {
        className: 'custom-button custom-button-secondary',
        style: { height: '48px' },
      },
      onOk() {
        dispatch(User.userDelete({ id }))
          .unwrap()
          .then(() => {
            if (group !== 'originator') {
              dispatch(User.userList({ limit: 5, offset: 0, group: 'internal', page: 1 }));
            } else {
              dispatch(User.userList({ limit: 5, offset: 0, group: 'originator', page: 1 }));
            }
          });

        Modal.destroyAll();
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  useEffect(() => {
    if (open) {
      dispatch(User.userDetail({ id }))
        .unwrap()
        .then((res) => {
          setInitalValues({
            id: id,
            name: res.data?.full_name,
            email: res.data?.email,
            role: res.data?.role,
            company: res.data?.company,
            product: res.data?.eba?.map((e) => {
              return {
                value: e?.id62,
                label: e?.name,
              };
            }),
          });
        });
    }
  }, [open]);

  return (
    <>
      <div className="flex gap-5">
        <BiPencil onClick={() => setOpen(true)} style={{ cursor: 'pointer' }} />
        <BiTrash onClick={showConfirm} style={{ cursor: 'pointer' }} />
      </div>
      <ManagementUserComponent.UserForm
        title="Update User"
        open={open}
        values={initialValues}
        isUpdate={true}
        group={group}
        onClose={() => setOpen(false)}
      />
    </>
  );
}

UserAction.propTypes = {
  id: PropTypes.string.isRequired,
};

export default UserAction;
