import { Date, Util } from '../../../helper';

const columnsDetail = [
  {
    title: 'Nomor pinjaman',
    dataIndex: 'asset',
    width: 150,
    align: 'center',
    render: (record) => record.loan_number,
  },
  {
    title: 'Pokok(Rp)',
    dataIndex: 'principal_payment',
    width: 150,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Bunga',
    dataIndex: 'interest_payment',
    width: 150,
    align: 'center',
    render: (record) => record,
  },
  {
    title: 'Tanggal Periode',
    dataIndex: 'period_date',
    width: 150,
    align: 'center',
    render: Date.twoDigitWithDash,
  },
  {
    title: 'Deskripsi',
    dataIndex: 'description',
    width: 150,
    align: 'center',
  },
];

const columnsDetailNotUpdate = [
  {
    title: 'Nomor pinjaman',
    dataIndex: 'asset',
    width: 150,
    align: 'center',
    render: (record) => record.loan_number,
  },
  {
    title: 'Pokok(Rp)',
    dataIndex: 'principal',
    width: 150,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Bunga(Rp)',
    dataIndex: 'interest',
    width: 150,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Tanggal Periode',
    dataIndex: 'period_date',
    width: 150,
    align: 'center',
    render: Date.twoDigitWithDash,
  },
  {
    title: 'Deskripsi',
    dataIndex: 'description',
    width: 150,
    align: 'center',
    render: (record) => record || '-',
  },
];

const summaryAcquisition = [
  [
    {
      text: 'Total Angsuran Akuisisi',
      width: '50%',
      className: 'border',
    },
    {
      text: 'Total Angsuran yang Hilang',
    },
  ],
];

export { columnsDetail, columnsDetailNotUpdate, summaryAcquisition };
