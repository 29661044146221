import { useSearchParams } from 'react-router-dom';
import { PageContent } from '../const';

const usePage = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  let isProduct = false,
    isManagementProduct = false;
  if (page === PageContent.PRODUCT) {
    isProduct = true;
  }
  if (page === PageContent.MANAGEMENT_PRODUCT) {
    isManagementProduct = true;
  }

  return { isProduct, isManagementProduct };
};

export default usePage;
