import { Alert, Spin, Table, Typography } from 'antd';
import Column from 'antd/es/table/Column';
import ColumnGroup from 'antd/es/table/ColumnGroup';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Format } from '../../../helper';
import periodicPayment from '../../../service/api/periodicPayment';
import TableComponent from '../../table';
import { columnsAppropiatePeriod } from '../tableConfig';

const AppropiateData = ({ fileId, ebaId, isPublish }) => {
  const [isRefetch, setIsRefetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [percentProcess, setPercentProcess] = useState('');
  const [error, setError] = useState({ isError: false, notes: '' });

  const renderAmount = (record) => (
    <Typography.Paragraph className="text-right">
      {Format.numberFormat(Math.round(record))}
    </Typography.Paragraph>
  );

  const dispatch = useDispatch();
  const { summary } = useSelector((state) => state.periodic);

  useEffect(() => {
    setLoading(true);
    dispatch(
      periodicPayment.summaryPeriodicPayment({
        fileId,
        ebaId,
        isPublish,
        month: '',
      })
    )
      .unwrap()
      .then(({ data }) => {
        if (data?.status === 1) {
          // if data still process
          const percent = (data?.count / data?.count_total) * 100 || 0;
          setPercentProcess(percent.toFixed(1) + '%');

          setTimeout(() => {
            setIsRefetch(!isRefetch);
          }, 3000);
        } else if (data?.status === 2) {
          // there is error when upload data
          setError({ notes: data?.notes, isError: true });
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [isRefetch]);

  const SummaryData = ({ data = [] }) => {
    let totalDebitur = 0;
    let totalBukopinPrinciple = 0;
    let totalBukopinInterest = 0;
    let totalBukopinAmount = 0;
    let totalBahanaPrinciple = 0;
    let totalBahanaInterest = 0;
    let totalBahanaAmount = 0;

    data.forEach((res) => {
      totalDebitur += Number(res.debtor);
      totalBukopinPrinciple += Number(res.principal_bank);
      totalBukopinInterest += Number(res.interest_installments_bank);
      totalBukopinAmount += Number(res.total_installment_payment_bank);
      totalBahanaPrinciple += Number(res.principal);
      totalBahanaInterest += Number(res.interest_installments);
      totalBahanaAmount += Number(res.total_installment_payment);
    });

    return (
      <Table.Summary.Row key="summary" className="bg-[#D5D5D5] bg-opacity-40">
        <Table.Summary.Cell index={0} align="center">
          Subtotal
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1} align="center">
          <Typography.Text> {renderAmount(totalDebitur)}</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} align="center">
          <Typography.Text>{renderAmount(totalBukopinPrinciple)}</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3} align="center">
          <Typography.Text>{renderAmount(totalBukopinInterest)}</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4} align="center">
          <Typography.Text>{renderAmount(totalBukopinAmount)}</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5} align="center">
          <Typography.Text>{renderAmount(totalBahanaPrinciple)}</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6} align="center">
          <Typography.Text>{renderAmount(totalBahanaInterest)}</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={7} align="center">
          <Typography.Text>{renderAmount(totalBahanaAmount)}</Typography.Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  return (
    <Spin
      spinning={loading}
      tip={
        <>
          <p>Data sedang diproses</p>
          <p>{percentProcess}</p>
        </>
      }>
      {error?.isError && (
        <Alert message="Error upload" description={error.notes} type="error" showIcon />
      )}
      <Typography.Paragraph key="title" className="font-bold text-base mt-5">
        Summary Data Yang Sesuai
      </Typography.Paragraph>
      <TableComponent.TableGlobal
        key="table-summary"
        dataSource={summary.result}
        className="table-preview-data mt-10"
        summary={(data) => <SummaryData data={data} />}
        pagination={false}
        scroll={{ x: 900 }}
        keyRow="description"
        bordered>
        <Column title="Keterangan" dataIndex="description" key="description" align="center" />
        <Column
          title="Jumlah Debitur"
          dataIndex="debtor"
          key="debtor"
          render={renderAmount}
          align="center"
        />
        <ColumnGroup title="Pembayaran Porsi Bukopin">
          <Column
            title="Pokok(Rp)"
            dataIndex="principal_bank"
            key="principal_bank"
            render={renderAmount}
            align="center"
          />
          <Column
            title="Bunga(Rp)"
            dataIndex="interest_installments_bank"
            key="interest_installments_bank"
            render={renderAmount}
            align="center"
          />
          <Column
            title="Total Tagihan(Rp)"
            dataIndex="total_installment_payment_bank"
            key="total_installment_payment_bank"
            render={renderAmount}
            align="center"
          />
        </ColumnGroup>
        <ColumnGroup title="Pembayaran Porsi Bahana">
          <Column title="Pokok(Rp)" dataIndex="principal" render={renderAmount} align="center" />
          <Column
            title="Bunga(Rp)"
            dataIndex="interest_installments"
            render={renderAmount}
            align="center"
          />
          <Column
            title="Total Tagihan(Rp)"
            dataIndex="total_installment_payment"
            key="total_installment_payment"
            render={renderAmount}
            align="center"
          />
        </ColumnGroup>
      </TableComponent.TableGlobal>

      <TableComponent.TableGlobal
        key="table-cashinflo"
        columns={columnsAppropiatePeriod}
        dataSource={[
          {
            key: 'period',
            cash_inflow: summary?.cash_inflow,
            period_date: summary?.period_date,
          },
        ]}
        className="table-preview-data mt-10"
        pagination={false}
        scroll={{ x: 900 }}
        bordered
        keyRow="cash_inflow"
      />
    </Spin>
  );
};

export default AppropiateData;
