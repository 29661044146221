import { Menu } from './const';
import {
  Assets,
  CostEba,
  CreateNewPassword,
  DetailDataAcquisition,
  EditProfile,
  EntryData,
  Logbook,
  LoginPage,
  ManagementEba,
  ManagementProduct,
  ManagementUser,
  PoolOfAsset,
  PreviewDataAcquisition,
  Profile,
  Reports,
  UploadData,
  SummaryAcquisitionOriginator,
  ManagementAsset,
  SimulationProduct,
  FeedbackAcquisition,
} from './screens';

export const unProtectedRoute = [
  { path: '/sign-in', element: LoginPage },
  { path: '/create-password', element: CreateNewPassword },
  { path: '*', element: LoginPage },
];

const path = (key) => `/${key}`;

export const protectedRoute = [
  { path: path(Menu.KEY.COST_EBA), element: CostEba },
  { path: path(Menu.KEY.ASSETS), element: Assets.Assets },
  { path: path(Menu.KEY.UPLOAD_DATA_ORIGINATOR), element: UploadData.UploadDataOriginator },
  { path: path(Menu.KEY.UPDATE_DATA), element: EntryData.UpdateData },
  { path: path(Menu.KEY.REQUEST_ACQUISITION), element: EntryData.RequestAquisitionData },
  { path: path(Menu.KEY.FEEDBACK_ACQUISITION), element: FeedbackAcquisition.FeedbackAcquisition },
  { path: path(Menu.KEY.LOGBOOK), element: Logbook },
  { path: path(Menu.KEY.MANAGEMENT_ASSETS), element: ManagementAsset.ManagementAsset },
  { path: path(Menu.KEY.MANAGEMENT_USER), element: ManagementUser },
  { path: path(Menu.KEY.MANAGEMENT_PRODUCT), element: ManagementProduct.ManagementProduct },
  { path: path(Menu.KEY.EBA_INFO), element: ManagementProduct.CreateProductAndSimulation },
  { path: path(Menu.KEY.UPLOAD_ASSETS), element: ManagementProduct.UploadAsset },
  { path: path(Menu.KEY.EQUITY_BUFFER), element: ManagementProduct.EquityBuffer },
  { path: path(Menu.KEY.ASSET_CLASS), element: ManagementProduct.AssetClass },
  { path: '/management-product/asset-class/:id', element: ManagementProduct.AssetClassDetail },
  {
    path: '/management-product/asset-class/:id/add-investor',
    element: ManagementProduct.AddInvestor,
  },
  { path: path(Menu.KEY.COST), element: ManagementProduct.Cost },
  { path: path(Menu.KEY.COST_SUMMARY), element: ManagementProduct.CostSummary },
  { path: '/management-product/cost/:id', element: ManagementProduct.InitialCost },
  { path: path(Menu.KEY.RE_INVESTMENT), element: ManagementProduct.ReInvestmentPage },
  { path: path(Menu.KEY.FEEDBACK_PRODUCT), element: ManagementProduct.DetailFeedback },
  { path: path(Menu.KEY.REPORTS), element: Reports.Reports },
  { path: path(Menu.KEY.REPORT_INTERNAL), element: Reports.ReportsInternal },
  { path: path(Menu.KEY.REPORT_EXTERNAL), element: Reports.ReportsExternal },
  { path: path(Menu.KEY.MANAGEMENT_EBA), element: ManagementEba },
  { path: path(Menu.KEY.PREVIEW_ACQUISITION), element: PreviewDataAcquisition },
  { path: path(Menu.KEY.DETAIL_ACQUISITION), element: DetailDataAcquisition },
  {
    path: path(Menu.KEY.SUMMARY_ACQUISITION_ORIGINATOR),
    element: SummaryAcquisitionOriginator.Summary,
  },
  {
    path: path(Menu.KEY.DETAIL_ACQUISITION_ORIGINATOR),
    element: SummaryAcquisitionOriginator.Detail,
  },
  { path: '/my-profile', element: Profile },
  { path: '/edit-profile', element: EditProfile.EditProfile },
  { path: '/edit-profile/change-password', element: EditProfile.ChangePassword },
  { path: '/edit-profile/forgot-password', element: EditProfile.ForgotPassword },
  { path: path(Menu.KEY.POOL_OF_ASSET), element: PoolOfAsset },
  { path: path(Menu.KEY.SIMULATION), element: SimulationProduct.SimulationProduct },
  {
    path: '/management-product/reinvestment-summary',
    element: ManagementProduct.ReinvestmentSummary,
  },
];
