import { Tooltip, Typography } from 'antd';
import { Date, Util } from '../../helper';

const getDetailYear = (month) => {
  const year = (month / 12).toFixed(0);
  const monthOver = month % 12;
  return (
    <Tooltip title={`${year} Tahun ${monthOver} Bulan`}>
      <span>{Number(month / 12).toFixed(1)}</span>
    </Tooltip>
  );
};

const columnsAcquisition = [
  {
    title: 'Nomor Pinjaman',
    dataIndex: 'loan_number',
    key: 'loan_number',
    width: 180,
    className: 'text-center overflow-visible',
    align: 'center',
    render: (text, record) => (
      <div className="relative">
        {record.is_recommended && (
          <Typography.Paragraph
            className="
              absolute 
              top-[-18px] 
              left-[-50px] 
              w-max 
              px-2 
              py-[2px] 
              rounded-br-[8px] 
              bg-black 
              font-bold 
              text-white 
              text-[8px]">
            Direkomendasikan Sistem
          </Typography.Paragraph>
        )}
        <Typography.Text>{text}</Typography.Text>
      </div>
    ),
  },
  {
    title: 'Tanggal Kredit Awal',
    dataIndex: 'credit_start_date',
    key: 'credit_start_date',
    width: 180,
    className: 'text-center',
    align: 'center',
    render: (record) => Date.twoDigitWithDash(record),
  },
  {
    title: 'Tanggal Jatuh Tempo',
    dataIndex: 'credit_due_date',
    key: 'credit_due_date',
    width: 180,
    className: 'text-center',
    align: 'center',
    render: (record) => Date.twoDigitWithDash(record),
  },
  {
    title: 'Angsuran Pokok (Rp)',
    dataIndex: 'principal_installments',
    key: 'principal_installments',
    width: 200,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Angsuran Bunga (Rp)',
    dataIndex: 'interest_installments',
    key: 'interest_installments',
    width: 180,
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Total Angsuran (Rp)',
    dataIndex: 'total_installment_payment',
    key: 'total_installment_payment',
    width: 180,
    align: 'center',
    className: 'text-right',
    render: Util.renderNumber,
  },
  {
    title: 'Bunga (%)',
    dataIndex: 'interest_rate_type',
    key: 'interest_rate_type',
    width: 150,
    className: 'text-center',
    align: 'center',
    render: (record) => record || '0%',
  },
  {
    title: 'Remaining Period',
    dataIndex: 'months_remaining',
    key: 'months_remaining',
    width: 150,
    className: 'text-center',
    align: 'center',
    render: (record) => `${record} Bulan`,
  },
  {
    title: 'Tenor (Tahun)',
    dataIndex: 'tenor',
    key: 'tenor',
    width: 150,
    className: 'text-center',
    align: 'center',
    render: (record) => getDetailYear(record),
  },
];

export { columnsAcquisition };
