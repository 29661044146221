import { Popconfirm, Tooltip, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { IoIosSend } from 'react-icons/io';
import { MdFileDownload } from 'react-icons/md';
import { SiMicrosoftexcel } from 'react-icons/si';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { EntryDataComponent, Page } from '../../components';
import {
  CustomButton,
  CustomNotification,
  CustomTabs,
  SimpleTable,
} from '../../components/_shared';
import { Constant } from '../../const';
import { Date, Util, Format } from '../../helper';
import { Aquisition } from '../../service';
import { removeFileUpload } from '../../store/reference';
import { HiInformationCircle } from 'react-icons/hi';
import { summaryAcquisition } from './components/tableConfig';

const breadcrumbsShow = (idEba, isDetail, productName) => {
  let breadcrumbs = [
    { name: 'Management Produk', href: '/management-product' },
    { name: productName, href: `/assets?page=product&eba-id=${idEba}` },
    {
      name: 'Request Akuisisi',
      href: `/assets/upload-data-originator?page=product&eba-id=${idEba}`,
    },
    { name: 'Masukkan Data', href: null },
  ];

  if (isDetail) {
    breadcrumbs = [...breadcrumbs, { name: 'Preview', href: null }];
  }

  return breadcrumbs;
};

const RequestAquisitionData = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const idEba = searchParams.get('eba-id');
  const approveId = searchParams.get('approve-id');
  const isSeeDetail = searchParams.get('preview');
  const tab = searchParams.get('tab');
  const queryDate = searchParams.get('date');

  const dispatch = useDispatch();
  const { upload, file } = useSelector((state) => state.reference);
  const { loadingDownload, loadingDelete, loadingSend, approvalFile } = useSelector(
    (state) => state.aquisition
  );
  const { detail } = useSelector((state) => state.eba);

  const [isRefetch, setIsRefetch] = useState(false);
  const [percentProcess, setPercentProcess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [totalDiffAcquisition, setTotalDiffAcquisition] = useState({});

  const [tabSelected, setTabselected] = useState(1);

  const ListParameterInappropiate = useCallback(() => {
    const parameterInappropiate = [
      { value: 'Memiliki KTP' },
      {
        value: 'Tanggal Awal di pembukuan kreditor',
      },
      { value: 'Tenor' },
      { value: 'Saldo pinjaman' },
      {
        value: 'Debt Burden Ration',
      },
      { value: 'Tanggal kelahiran debitur paling tua 4 september 1953' },
      { value: 'Terdapat cashflow lebih dari 1 bulan' },
    ];
    return (
      <ol type="1" className="space-y-2 p-3 list-decimal">
        {parameterInappropiate.map((res) => {
          return <li key={res.value}>{res.value}</li>;
        })}
      </ol>
    );
  }, []);

  const tabItems = [
    {
      label: 'Sesuai Kriteria Seleksi',
      children: <EntryDataComponent.PassInsepection fileId={upload.file_id62} />,
    },
    {
      label: (
        <label className="flex items-center cursor-pointer">
          Tidak Sesuai Kriteria Seleksi
          <Tooltip title={<ListParameterInappropiate />} color="blue" className="ml-2 cursor-help">
            <HiInformationCircle className="text-blue" />
          </Tooltip>
        </label>
      ),
      children: <EntryDataComponent.FailedInsepection fileId={upload.file_id62} />,
    },
  ];

  useEffect(() => {
    if (!file?.name) {
      // back to previous page, if file upload is empty
      navigate(`/assets/upload-data-originator${location.search}`, {
        replace: true,
      });
    }
  }, [upload]);

  // useEffect(() => {
  //   const periodDate = Date.twoDigitWithDash(file.lastModified);
  //   const query = `file_id62=${upload.file_id62}&eba_id62=${idEba}&period_date=${periodDate}`;
  //   dispatch(Aquisition.getTotalDiffAcquistion(query));
  // }, []);

  useEffect(() => {
    setIsLoading(true);
    const query = `file_id62=${upload.file_id62}&eba_id62=${idEba}&period_date=${queryDate}`;
    dispatch(Aquisition.getTotalDiffAcquistion(query))
      .unwrap()
      .then(({ data }) => {
        if (data?.status === 1) {
          // if data still process
          const percent = (data?.count / data?.count_total) * 100;
          setPercentProcess(percent.toFixed(1) + '%');

          setTimeout(() => {
            setIsRefetch(!isRefetch);
          }, 3000);
        } else {
          setTotalDiffAcquisition(data);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [isRefetch]);

  const onClickDetail = () => {
    navigate(`/assets/request-acquisition${location.search}&preview=true`);
  };

  const onClickSendData = () => {
    const body = {
      approval_id62: approvalFile.id62 || approveId,
      file_id62: upload.file_id62,
    };
    dispatch(Aquisition.aquisitionSendDataUpload(body))
      .unwrap()
      .then(() => {
        navigate(`/assets${location.search}`);
      })
      .catch(() => {
        CustomNotification({
          type: Constant.ERROR,
          message: 'Gagal ajukan data',
        });
      });
  };

  const confirmDelete = () => {
    let approveParams = approvalFile?.id62 ? `&approve-id=${approvalFile?.id62}` : '';
    dispatch(Aquisition.aquisitionDeleteFile(upload.file_id62))
      .unwrap()
      .then(() => {
        dispatch(removeFileUpload());
        navigate(
          `/assets/upload-data-originator?page=product&eba-id=${idEba}&tab=${tab}&date=${queryDate}` +
            approveParams,
          {
            replace: true,
          }
        );
      })
      .catch(() => {
        CustomNotification({
          type: Constant.ERROR,
          message: 'Gagal hapus file',
        });
      });
  };

  const downloadExcel = () => {
    let field = 'True';
    if (tabSelected === 2) field = 'False';
    dispatch(
      Aquisition.aquisitionDownloadExcel({
        isPass: field,
        fileId: upload.file_id62,
        fileName: tabItems[tabSelected - 1].label,
      })
    );
  };

  const onTabClick = (e) => {
    setTabselected(e);
  };

  return (
    <Page title="Enter Data" breadcrumbs={breadcrumbsShow(idEba, isSeeDetail, detail?.name)}>
      <div className="flex justify-between items-center">
        <Typography.Paragraph className="font-bold text-3xl">Masukkan Data</Typography.Paragraph>
        <div className="flex space-x-2">
          {isSeeDetail && (
            <CustomButton
              type="white-ghost"
              loading={loadingDownload}
              onClick={downloadExcel}
              className="
              group
              flex
              justify-center
              items-center
              space-x-2">
              Unduh Excel
              <MdFileDownload size={20} className="text-black group-hover:text-white" />
            </CustomButton>
          )}

          {!isSeeDetail && (
            <CustomButton type="primary-ghost" onClick={onClickDetail}>
              Lihat Detail
            </CustomButton>
          )}

          <CustomButton
            type="primary"
            className="
            group
            flex
            justify-center
            items-center
            space-x-2"
            onClick={onClickSendData}
            loading={loadingSend}>
            <IoIosSend size={20} className="text-white group-hover:text-primary" />
            <span>Kirim Data</span>
          </CustomButton>
        </div>
      </div>
      <div
        className="
        flex
        bg-white
        justify-between
        p-5
        mt-5
        rounded-lg
        mb-6">
        <div
          className="flex space-x-2
            justify-center
            items-center">
          <SiMicrosoftexcel className="text-primary" />
          <Typography.Text>{file.name}</Typography.Text>
        </div>

        <Typography.Text>{Util.bytesToSize(file.size)}</Typography.Text>

        <Typography.Text className="text-grey">
          {Date.fullDateFromUnix(file.lastModified)}
        </Typography.Text>
        <Popconfirm
          placement="bottomRight"
          title={'Anda yakin akan hapus file ini?'}
          description={'Anda perlu upload ulang untuk menampilkan data ini'}
          onConfirm={confirmDelete}
          okText="Ya"
          cancelText="Tidak"
          okType="danger"
          okButtonProps={{ loading: loadingDelete }}>
          <Typography.Text className="text-primary hover:cursor-pointer underline">
            Hapus
          </Typography.Text>
        </Popconfirm>
      </div>
      {!isSeeDetail && (
        <div className=" mb-5">
          <Typography.Paragraph className="font-bold text-2xl">
            Summary Data Akuisisi
          </Typography.Paragraph>
          {isLoading && (
            <Typography.Paragraph className="font-bold text-base">
              Mengambil Summary Data {percentProcess}
            </Typography.Paragraph>
          )}
          {!isLoading && (
            <>
              <Typography.Paragraph className="font-bold text-base">
                Jumlah Debitur Akuisisi : {totalDiffAcquisition.total_new_debitor} Orang
              </Typography.Paragraph>
              <SimpleTable headers={summaryAcquisition} className=" mt-5">
                <tr style={{ backgroundColor: '#FFF' }}>
                  <td className="border p-2 text-center">
                    Rp{Format.numberFormat(totalDiffAcquisition.total_installment_acquisition)}
                  </td>
                  <td className="border p-2 text-center">
                    Rp{Format.numberFormat(totalDiffAcquisition.total_installment_loss)}
                  </td>
                </tr>
              </SimpleTable>
              <Typography.Paragraph className="font-bold text-base mt-5">
                Selisih : Rp{Format.numberFormat(totalDiffAcquisition.diff_installment)}
              </Typography.Paragraph>
            </>
          )}
        </div>
      )}
      {isSeeDetail && <CustomTabs tabsItem={tabItems} onTabClick={onTabClick} />}
    </Page>
  );
};

export default RequestAquisitionData;
