import { Select, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Constant } from '../../const';
import { Storage, Date } from '../../helper';
import { Aquisition } from '../../service';
import Table from '../table';
import { CustomNotification } from '../_shared';
import CustomButton from '../_shared/custom-button';
import CashflowCount from './cashflowCount';

const typeList = [
  { key: 1, value: 'Jatuh Tempo Terpanjang' },
  { key: 2, value: 'Bunga Terbesar' },
];

const AquisitionData = ({ searchParams, lossCashflow, installmentTotal }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const idFile = searchParams.get('file-id');
  const approvalId = searchParams.get('approval-id');
  const ebaId = searchParams.get('eba-id');
  const status = searchParams.get('status');
  const rejectReason = Storage.getStorage('reject_reason');
  const month = searchParams.get('month');

  const dispatch = useDispatch();
  const { selectedRowKeys } = useSelector((state) => state.aquisition);
  const { generate } = useSelector((state) => state.cashflow);

  const [page, setPage] = useState({ limit: 10, offset: 0, current: 1 });
  const [sortBy, setSort] = useState('');

  useEffect(() => {
    const payloadRecommendation = {
      fileId: idFile,
    };
    dispatch(Aquisition.aquisitionSubmissionRecommendation(payloadRecommendation));
  }, []);

  useEffect(() => {
    const payloadAcquisition = {
      limit: page.limit,
      offset: page.offset,
      sortBy,
      fileId: idFile,
    };
    dispatch(Aquisition.aquisitionSubmission(payloadAcquisition));
  }, [page, sortBy]);

  const onChangeType = (_, data) => {
    if (data.key === 2) {
      setSort('highest_interest=True');
    } else {
      setSort('longest_tenor=True');
    }
  };

  const onClickSave = () => {
    // VALIDATION CASHFLOW CHANGE INTO INSTALLMENT
    // if (Number(generate?.total_cashflow) < Number(lossCashflow)) {
    //   CustomNotification({
    //     type: Constant.ERROR,
    //     message: 'Cashflow akuisisi kurang dari cashflow hilang',
    //   });
    //   return;
    // }
    if (Number(generate?.total_installment_asset) < Number(installmentTotal)) {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Angsuran akuisisi kurang dari angsuran hilang',
      });
      return;
    }
    if (generate?.total_debtor < 1) {
      CustomNotification({ type: Constant.ERROR, message: 'Belum Pilih Rekomendasi' });
      return;
    }

    const [onlyMonth, onlyYear] = month.split(' ');
    const period = `1 ${Date.convertIdToEn(onlyMonth)} ${onlyYear}`;

    const payload = {
      approval_id62: approvalId,
      acquisition_id62s: selectedRowKeys,
      approve: false,
      periode_date: Date.twoDigitWithDash(period),
    };
    dispatch(Aquisition.aquisitionApprovalByAdmin(payload))
      .unwrap()
      .then(() => {
        CustomNotification({
          type: Constant.SUCCESS,
          message: 'Sukses',
          description: 'Sukses simpan data',
        });
        Storage.setStorage('recommendation', selectedRowKeys);
        navigate(`/preview-data-acquisition${location.search}`);
      })
      .catch(() => {});
  };

  return (
    <div>
      <CashflowCount
        lossCashflow={lossCashflow}
        ebaId={ebaId}
        approvalId={approvalId}
        installmentTotal={installmentTotal}
      />
      {rejectReason && [2, 4, 6].includes(Number(status)) && (
        <div className="bg-white mt-6 p-5 rounded-lg border-1 border-[#F1F3F6]">
          <Typography.Paragraph className="text-primary font-bold">
            Alasan Reject
          </Typography.Paragraph>
          <Typography.Paragraph className="font-medium">{rejectReason}</Typography.Paragraph>
        </div>
      )}
      <div className="flex justify-between items-center my-6">
        <Typography.Text className="font-bold text-2xl">Rekomendasi</Typography.Text>
        <div className="flex space-x-3">
          <Space>
            <Typography.Paragraph className="font-bold text-sm">Sorting</Typography.Paragraph>
            <Select
              defaultValue={typeList[0].value}
              onChange={onChangeType}
              options={typeList}
              className="flex items-center bg-white border h-[48px] rounded-lg"
            />
          </Space>
          {![3, 5, 7, 8].includes(Number(status)) && (
            <CustomButton type="primary-ghost" onClick={onClickSave}>
              <span className=" text-sm">Simpan</span>
            </CustomButton>
          )}
        </div>
      </div>
      <Table.TableAcquisition setPage={setPage} page={page} status={status} />
    </div>
  );
};

export default AquisitionData;
