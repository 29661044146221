import { Avatar, Form, Input, Spin, Typography, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { MdUpload } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomInput, Page } from '../../components';
import { CustomNotification } from '../../components/_shared';
import CustomButton from '../../components/_shared/custom-button';
import { Constant } from '../../const';
import { Format, Util } from '../../helper';
import { useAuth } from '../../hooks/useAuth';
import { Reference, User } from '../../service';

const EditProfile = () => {
  const { user } = useAuth();
  const { isOriginator } = Util.role();

  const [form] = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [, setSearchParams] = useSearchParams();

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [imageFile, setImageFile] = useState({ file_name: '', url: '', file_id62: '' });
  const [defaultValue, setDefaultValue] = useState([]);
  const [nameChange, setNameChange] = useState(false);
  const [mailChange, setMailChange] = useState(false);

  const field = [
    {
      name: 'Name',
      rules: [
        {
          required: true,
          message: 'Field tidak boleh kosong',
        },
      ],
    },
    { name: 'Role', rules: [] },
    {
      name: 'Email',
      rules: [
        {
          type: 'email',
          message: 'Format email tidak sesuai',
        },
        {
          required: true,
          message: 'Field tidak boleh kosong',
        },
      ],
    },
    {
      name: 'Password',
      rules: [],
    },
  ];

  useEffect(() => {
    dispatch(User.userProfile({ id: user.user_id62 }))
      .unwrap()
      .then(({ data }) => {
        form.setFieldValue('Name', data.full_name);
        form.setFieldValue('Role', data.role);
        form.setFieldValue('Email', data.email);
        form.setFieldValue('Password', '********');
        setImageFile({ ...imageFile, url: data.picture });
        setDefaultValue(data);
      })
      .catch(() => {
        navigate('/my-profile', { replace: 'true' });
      });
  }, []);

  useEffect(() => {
    if (nameChange === true || mailChange === true) {
      setSearchParams('isChange=true');
    } else if (nameChange === false && mailChange === false) {
      setSearchParams('isChange=false');
    }
  }, [nameChange, mailChange]);

  const uploadButton = (
    <div
      className="flex
      flex-col
      justify-center
      items-center
      w-[120px]
      h-[120px]
      border-dashed
      border-2
      rounded-2xl
      font-bold
      text-grey">
      {loadingUpload ? (
        <Spin />
      ) : (
        <>
          <MdUpload size={20} />
          <Typography.Text className="text-grey mt-1 text-sm">Upload Foto</Typography.Text>
        </>
      )}
    </div>
  );

  const onFinish = (e) => {
    let body = {
      id: user?.user_id62,
      payload: {
        full_name: e.Name,
        email: e.Email,
        role: e.Role,
        group: e.role !== 'originator' ? 'internal' : 'originator',
      },
    };
    if (imageFile.file_id62) {
      body = { ...body, payload: { ...body.payload, picture: imageFile.file_id62 } };
    }
    setLoadingSave(true);
    dispatch(User.userUpdate(body))
      .unwrap()
      .then(() => {
        navigate('/my-profile');
        setLoadingSave(false);
      })
      .catch(() => {
        setLoadingSave(false);
      });
  };

  const beforeUpload = (file) => {
    const isImage = file.type?.includes('image');
    const size = 2; // size ini Mb
    const isLt2M = file.size <= 1024 * size * 1000;

    if (!isImage) {
      CustomNotification({ type: Constant.ERROR, message: 'Format file tidak sesuai' });
    }
    if (!isLt2M) {
      CustomNotification({ type: Constant.ERROR, message: `Maksimal ukuran file ${size}MB` });
    }

    return isLt2M && isImage;
  };

  const customRequest = async ({ file }) => {
    setLoadingUpload(true);

    // DO: convert to base64
    let base64 = '';
    await Format.getBase64(file)
      .then((res) => {
        base64 = res;
      })
      .catch(() => {
        CustomNotification({
          type: Constant.ERROR,
          message: 'Gagal convert ke base64, silahkan ulangi',
        });
      });

    const payload = { fileName: file.name, base64 };
    dispatch(Reference.base64Upload(payload))
      .unwrap()
      .then(({ data }) => {
        setImageFile(data);
        setLoadingUpload(false);
      })
      .catch(() => {
        CustomNotification({
          type: Constant.ERROR,
          message: 'Gagal Upload Foto',
          description: 'Silahkan ulangi lagi',
        });
        setLoadingUpload(false);
      });
  };

  return (
    <Page title="Edit Profile">
      <Form
        form={form}
        name="edit-profile"
        onFinish={onFinish}
        layout="horizontal"
        className="mt-5"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        requiredMark={false}>
        <div className="bg-white p-12 rounded-lg overflow-hidden max-w-[70vw] mx-auto">
          <Typography.Paragraph className="font-bold text-2xl">Edit Profile</Typography.Paragraph>
          <Form.Item name="photo" label={<span className="font-bold">Photo</span>}>
            <div className="flex space-x-2">
              <Avatar
                src={imageFile.url}
                size={120}
                shape="square"
                style={{
                  backgroundColor: '#00a2ae',
                  verticalAlign: 'middle',
                  width: '120px',
                  height: '120px',
                  fontSize: '70px',
                  borderRadius: '20px',
                }}>
                {user?.full_name[0]}
              </Avatar>
              <Upload
                customRequest={customRequest}
                beforeUpload={beforeUpload}
                maxCount={1}
                accept="image/*"
                showUploadList={false}>
                {uploadButton}
              </Upload>
            </div>
          </Form.Item>

          {field.map((res, i) => (
            <Form.Item
              key={i}
              name={res.name}
              label={<span className="font-bold">{res.name}</span>}
              rules={res.rules}>
              {res.name === 'Password' ? (
                <>
                  <Input.Password className="custom-input" value="*********" disabled />
                  <CustomButton
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => navigate('change-password')}>
                    <span className="text-primary underline">Ubah Password</span>
                  </CustomButton>
                </>
              ) : (
                <CustomInput
                  placeholder={res.name}
                  disabled={res.name === 'Role' || (res.name === 'Email' && isOriginator)}
                  maxLength={30}
                  onChange={(e) => {
                    if (res.name === 'Name') {
                      if (e.target.value !== defaultValue.full_name) {
                        setNameChange(true);
                      } else {
                        setNameChange(false);
                      }
                    } else if (res.name === 'Email') {
                      if (e.target.value !== defaultValue.email) {
                        setMailChange(true);
                      } else {
                        setMailChange(false);
                      }
                    }
                  }}
                />
              )}
            </Form.Item>
          ))}
        </div>
        <div className="flex justify-end max-w-[70vw] mx-auto my-6">
          <CustomButton type="primary" htmlType="submit" loading={loadingSave}>
            Simpan
          </CustomButton>
        </div>
      </Form>
    </Page>
  );
};

export default EditProfile;
