import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTable } from '../..';
import { Date, Format } from '../../../helper';
import { Investment } from '../../../service';

const Period = ({ gradeId }) => {
  const dispatch = useDispatch();

  const { couponSummary, loadingCoupon } = useSelector((state) => state.investment);

  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(Investment.getCouponSummary({ gradeId: gradeId }));
  }, []);

  const onChangePage = (e) => {
    setPage(e);
  };

  const columns = [
    {
      title: 'Periode',
      dataIndex: 'period',
      align: 'center',
    },
    {
      title: 'Bulan',
      dataIndex: 'payment_date',
      align: 'center',
      render: Date.fullDate,
    },
    {
      title: 'Persentase(%)',
      dataIndex: 'return_percentage',
      align: 'center',
    },
    {
      title: 'Cost(IDR)',
      dataIndex: 'total',
      align: 'center',
      render: Format.numberFormat,
    },
  ];

  return (
    <CustomTable.TableGlobal
      dataSource={couponSummary}
      columns={columns}
      onChangePagination={onChangePage}
      current={page}
      pageSize={5}
      loading={loadingCoupon}
    />
  );
};

export default Period;
