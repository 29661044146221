import lodash from 'lodash';
import { Typography } from 'antd';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { PoolOfAsset } from '../../service';
import Table from '../table';
import { CustomInput, Select } from '../_shared';
import { columnsPoolOfAsset } from './tableConfig';
import Title from './title';
import { removeFilter } from '../../store/pool-of-asset';

const sortOptions = columnsPoolOfAsset.map((col) => {
  return {
    value: col.dataIndex,
    label: col.title,
  };
});

const optionSelect = [
  { value: 'loan_number', label: 'No Pinjaman' },
  { value: 'demographics', label: 'Demografi' },
  { value: 'retired_type', label: 'Jenis Pensiunan' },
  { value: 'insurance_company', label: 'Nama Perusahaan Asuransi' },
  { value: 'interest_rate_type', label: 'Suku Bunga (Fixed Rate)' },
];
const PoolOfAssetComponent = ({ approvalId }) => {
  const dispatch = useDispatch();
  const { poolOfAsset, loading, filter, filterActive, loadingDownload } = useSelector(
    (state) => state.poa
  );

  const [page, setPage] = useState({ limit: 10, offset: 0, current: 1 });
  const [searchBased, setSearchBased] = useState({});
  const [search, setSearch] = useState('');
  const [pressEnter, setPressEnter] = useState(false);
  const [sort, setSort] = useState('');

  useEffect(() => {
    const payload = {
      approvalId,
      limit: page.limit,
      offset: page.offset,
      min: filter.min,
      max: filter.max,
      search: searchBased?.value ? '' : search,
      tenor_max: filter.tenor_max,
      tenor_min: filter.tenor_min,
      dbr_max: filter.dbr_max,
      dbr_min: filter.dbr_min,
      dropdown: `&${searchBased?.value}=${search}&sort_by=${sort?.value || ''}`,
    };
    dispatch(PoolOfAsset.poolOfAsset(payload));
  }, [page, search, filter, pressEnter, sort]);

  const onChangeSelect = (_, select) => {
    if (search) setPressEnter(!pressEnter);
    setSearchBased(select);
  };

  const onChangeSort = (_, select) => {
    setSort(select);
  };

  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
  };

  const onSearch = lodash.debounce((e) => {
    setSearch(e.target.value);
  }, 500);

  const onPressEnter = (e) => {
    onSearch(e);
    setPressEnter(!pressEnter);
  };

  const removeFilterActive = (id) => {
    dispatch(removeFilter(id));
  };

  const onClickDownload = () => {
    dispatch(PoolOfAsset.downloadTemplate(approvalId));
  };

  const FilterActiveComponent = useCallback(
    ({ filters }) => {
      return (
        filters?.length > 0 && (
          <div className="flex space-x-3 items-center my-4">
            <Typography.Text className="font-bold">Filter:</Typography.Text>
            {filters?.map((res) => (
              <div
                key={res?.key}
                className="flex items-center space-x-2 text-primary p-2 bg-[#FFE7E7] border-primary border-2 rounded-lg">
                <Typography.Text className="text-primary font-bold">{res?.name}</Typography.Text>
                <IoCloseCircleSharp
                  size={20}
                  className="cursor-pointer"
                  role="button"
                  onClick={() => removeFilterActive(res?.key)}
                />
              </div>
            ))}
          </div>
        )
      );
    },
    [filterActive]
  );

  return (
    <Fragment>
      <Title title="Debitur Akuisisi" onClickDownload={onClickDownload} loading={loadingDownload} />
      <div className="flex space-x-2 my-10">
        <Select
          showSearch
          placeholder="Urutkan berdasarkan"
          onChange={onChangeSort}
          className="border h-[48px] bg-white rounded-lg flex items-center w-[200px]"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={sortOptions}
          allowClear
        />
        <Select
          showSearch
          placeholder="Cari berdasarkan"
          onChange={onChangeSelect}
          className="border h-[48px] bg-white rounded-lg flex items-center w-[180px]"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={optionSelect}
          allowClear
        />
        <CustomInput
          placeholder={`Cari ${searchBased?.label || ''}`}
          onChange={onSearch}
          allowClear
          onPressEnter={onPressEnter}
        />
      </div>

      <FilterActiveComponent filters={filterActive} />

      <Table.TableGlobal
        columns={columnsPoolOfAsset}
        dataSource={poolOfAsset?.results}
        totalData={poolOfAsset?.count}
        className="table-preview-data"
        scroll={{ x: 3280 }}
        current={page.current}
        onChangePagination={paginationChange}
        loading={loading}
      />
    </Fragment>
  );
};

export default PoolOfAssetComponent;
