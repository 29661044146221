import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const Graph = ({ item }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'y',
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
      position: 'nearest',
    },
    scales: {
      y: {
        position: 'right',
      },
    },
  };

  const data = {
    labels: item?.map((label) => label?.month),
    datasets: [
      {
        label: 'Total',
        data: item?.map((numOne) => numOne?.total),
        borderColor: '#000',
        backgroundColor: '#000',
        pointStyle: false,
      },
    ],
  };

  return <Line options={options} data={data} />;
};

export default Graph;
