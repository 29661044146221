import { Menu, Modal } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Menu as Menus } from '../../const';
import FilterPoolOfAsset from '../pool-of-asset/FilterPoolOfAsset';
import ListMenu from './listMenu';
import { CustomButton } from '..';

const SideComponent = ({ isShowMenu, keySelected, setKey, isPoA, setIsPoa }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const sectionPage = searchParams.get('section_page');
  const page = searchParams.get('page');
  const [open, setOpen] = React.useState(false);
  const isChange = searchParams.get('isChange');

  const { tabSelected } = useSelector((state) => state.poa);

  useEffect(() => {
    const poa = sectionPage === 'pool-of-asset' && tabSelected == 2;
    setIsPoa(poa);
  }, [tabSelected, sectionPage]);

  const onClickMenu = ({ key }) => {
    setKey(key);
    if (location?.pathname === '/edit-profile' && isChange === 'true') {
      setOpen(true);
    } else {
      if (page) {
        navigate(`/${key}${location.search}`);
      } else {
        navigate(`/${key}`);
      }
    }
  };

  useEffect(() => {
    let key = location.pathname.slice(1);
    const last = key.length - 1;
    key = key.charAt(last) == '/' ? key.slice(0, -1) : key;
    setKey(key);
  }, [location]);

  const modalOk = () => {
    setOpen(false);
    navigate(`/${keySelected}`);
  };

  const RenderSideComponent = useCallback(() => {
    if (isPoA && !isShowMenu) return <FilterPoolOfAsset />;

    return (
      <>
        <Menu
          defaultSelectedKeys={[Menus.KEY.MANAGEMENT_PRODUCT]}
          selectedKeys={keySelected}
          defaultOpenKeys={[Menus.KEY.COST_EBA, Menus.KEY.MANAGEMENT_ASSETS]}
          mode="inline"
          items={ListMenu(keySelected, page)}
          onClick={onClickMenu}
        />
      </>
    );
  }, [isPoA, keySelected, isShowMenu, page, isChange]);

  return (
    <>
      <RenderSideComponent />
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        destroyOnClose={true}
        footer={[
          <CustomButton key="back" type="secondary" onClick={() => setOpen(false)}>
            Batal
          </CustomButton>,
          <CustomButton
            key="submit"
            type="primary"
            form="myForm"
            htmlType="submit"
            onClick={modalOk}>
            Pindah
          </CustomButton>,
        ]}>
        <p>Perubahan yang anda ubah belum disimpan. Tetap pindah halaman ?</p>
      </Modal>
    </>
  );
};

export default SideComponent;
