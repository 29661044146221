import React from 'react';
import Table from '../../table';
import { columnsInformasiTagihan } from './columns';

const tableInformasiTagihan = ({ page, setPage, item }) => {
  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
  };

  return (
    <Table.TableGlobal
      columns={columnsInformasiTagihan}
      dataSource={item}
      totalData={item.length}
      className="table-preview-data"
      scroll={{ x: 3000 }}
      bordered
      onChangePagination={paginationChange}
      current={page.current}
      keyRow={'informasi_tagihan'}
    />
  );
};

export default tableInformasiTagihan;
