import { DatePicker, Form, Modal, Skeleton, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Reinvestment } from '../../../service';
import { CustomButton, CustomInput, CustomInputNumber } from '../../_shared';
import rules from './rulesForm';

export default function ReInvestmentForm({ open, onClose, isUpdate, buffer, id }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');

  const { data } = useSelector((state) => state.buffer);
  const { loading: loadingCreate } = useSelector((state) => state.reinvestment);

  const [values, setValues] = useState({
    name: '',
    percent: 0,
  });
  const [loading, setLoading] = useState(false);
  const [initialValue, setInitialValue] = useState({
    name: '',
    percentage_invest: 0,
    amount_invest: 0,
    return_invest: '',
    period: 0,
  });

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const onSubmit = async (e) => {
    const body = (i) => ({
      eba_id62: ebaId,
      name: e.fields[i].name,
      percentage_invest: ((Number(e.fields[i].amount_invest) / Number(buffer)) * 100).toFixed(2),
      amount_invest: e.fields[i].amount_invest,
      tax: e.fields[i].tax,
      return_invest: e.fields[i].return_invest,
      start_date: dayjs(e.fields[i].range[0]).format('YYYY-MM-DD'),
      end_date: dayjs(e.fields[i].range[1]).format('YYYY-MM-DD'),
    });

    if (!isUpdate) {
      for (let i = 0; i < e?.fields.length; i++) {
        const payload = body(i);
        await dispatch(Reinvestment.CreateReinvestment(payload));
      }
    } else {
      for (let i = 0; i < e?.fields.length; i++) {
        const payload = body(i);
        await dispatch(Reinvestment.UpdateReinvestment({ id: id, payload }));
      }
    }
    handleClose();
  };

  useEffect(() => {
    if (open && isUpdate) {
      setLoading(true);
      dispatch(Reinvestment.getDetail({ id62: id }))
        .unwrap()
        .then((res) => {
          setValues({
            name: res?.data?.name,
            amount_invest: res?.data?.amount_invest,
          });
          setInitialValue({
            amount_invest: res?.data?.amount_invest,
            return_invest: res?.data?.return_invest,
            range: [dayjs(res?.data?.start_date), dayjs(res?.data?.end_date)],
          });

          setLoading(false);
        });
    }
  }, [open, id]);

  const disabledRangeDate = (current) => {
    const startEba = dayjs(data?.eba?.start_date);
    const endEba = dayjs(data?.eba?.end_date);
    return current < startEba || current > endEba;
  };

  const calculateBuffer = (index) => {
    const amount = form.getFieldValue('fields')[index]?.amount_invest;
    if (!amount) return '0';
    const percent = (Number(amount) / Number(buffer)) * 100;
    return percent.toFixed(2);
  };

  const onChangeAmountInvest = (e) => {
    form.setFieldValue('amount_invest', e);
    setValues((prev) => ({
      ...prev,
      percent: e,
    }));
  };

  return (
    <Modal
      title={
        <h3 className="text-xl font-bold">Form {isUpdate ? 'Edit' : 'Input'} Re-Investment</h3>
      }
      open={open}
      onCancel={() => handleClose()}
      centered
      footer={[
        <CustomButton key="back" type="secondary" onClick={() => handleClose()}>
          Cancel
        </CustomButton>,
        <CustomButton
          key="submit"
          type="primary"
          form="myForm"
          htmlType="submit"
          loading={loadingCreate}>
          Simpan
        </CustomButton>,
      ]}
      width={'90%'}
      destroyOnClose={true}>
      {loading ? (
        <Space className="justify-center flex">
          <Skeleton.Input active={loading} size="large" />
          <Skeleton.Input active={loading} size="large" />
          <Skeleton.Input active={loading} size="large" />
          <Skeleton.Input active={loading} size="large" />
          <Skeleton.Input active={loading} size="large" />
        </Space>
      ) : (
        <Form
          id="myForm"
          layout="vertical"
          requiredMark={false}
          form={form}
          onFinish={onSubmit}
          {...(isUpdate && {
            initialValues: {
              fields: [initialValue],
            },
          })}>
          <Form.List name="fields">
            {(fields, { add }) => {
              return (
                <div>
                  <div className="flex flex-column items-center gap-3 mb-5">
                    <div className="flex items-center justify-evenly w-full gap-3">
                      <Typography.Paragraph
                        className="text-base text-center"
                        style={{ width: '20%' }}>
                        Instrument Investasi
                      </Typography.Paragraph>
                      <Typography.Paragraph
                        className="text-base text-center"
                        style={{ width: '20%' }}>
                        Jumlah Penempatan (Rp)
                      </Typography.Paragraph>
                      <Typography.Paragraph
                        className="text-base text-center"
                        style={{ width: isUpdate ? '20%' : '15%' }}>
                        Pajak (%)
                      </Typography.Paragraph>
                      <Typography.Paragraph
                        className="text-base text-center"
                        style={{ width: isUpdate ? '20%' : '15%' }}>
                        Imbah Hasil
                      </Typography.Paragraph>
                      <Typography.Paragraph
                        className="text-base text-center"
                        style={{ width: '20%' }}>
                        Tanggal Awal - Tanggal Akhir
                      </Typography.Paragraph>
                      {isUpdate && (
                        <Typography.Paragraph
                          className="text-base text-center"
                          style={{ width: '10%' }}>
                          Aksi
                        </Typography.Paragraph>
                      )}
                    </div>
                  </div>
                  {fields.map((field, index) => (
                    <div key={field.key} className="flex flex-column items-center gap-3 mb-5">
                      <div className="flex items-baseline justify-evenly w-full gap-3">
                        {isUpdate ? (
                          <Typography.Paragraph
                            className="text-base text-center"
                            style={{ width: '20%' }}>
                            {values?.name}
                          </Typography.Paragraph>
                        ) : (
                          <Form.Item
                            className="text-center"
                            name={[index, 'name']}
                            validateFirst
                            style={{ width: '20%' }}
                            rules={rules.ruleName}>
                            <CustomInput type="text" className="py-[18px] px-[24px]" />
                          </Form.Item>
                        )}

                        <div
                          className="flex justify-start items-center gap-1"
                          style={{ width: '20%' }}>
                          <Form.Item
                            className="text-center w-4/6"
                            name={[index, 'amount_invest']}
                            validateFirst
                            rules={rules.ruleInvestAmount(
                              data?.amount_left,
                              isUpdate,
                              initialValue.amount_invest
                            )}>
                            <CustomInputNumber
                              className="py-[14px] px-[13px] w-full"
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              }
                              maxLength={19}
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              onChange={onChangeAmountInvest}
                            />
                          </Form.Item>
                          <Typography.Paragraph
                            className="text-sm w-2/6"
                            style={{ marginTop: '-25px' }}>
                            {calculateBuffer(index)}%
                          </Typography.Paragraph>
                        </div>
                        <div
                          className="flex justify-center"
                          style={{ width: isUpdate ? '20%' : '15%' }}>
                          <Form.Item
                            className="text-center"
                            name={[index, 'tax']}
                            style={{ width: '50%' }}
                            validateFirst
                            rules={rules.rulePercentInvest}>
                            <CustomInput type="text" className="py-[18px] px-[24px]" />
                          </Form.Item>
                        </div>
                        <div
                          className="flex justify-center"
                          style={{ width: isUpdate ? '20%' : '15%' }}>
                          <Form.Item
                            className="text-center"
                            name={[index, 'return_invest']}
                            style={{ width: '50%' }}
                            validateFirst
                            rules={rules.rulePercentInvest}>
                            <CustomInput type="text" className="py-[18px] px-[24px]" />
                          </Form.Item>
                        </div>
                        <div
                          className="flex justify-start items-center gap-5"
                          style={{ width: '20%' }}>
                          <Form.Item className="text-center" name={[index, 'range']} validateFirst>
                            <DatePicker.RangePicker
                              className="custom-input rounded py-[18px] bg-white px-[24px] w-full"
                              format="DD-MM-YYYY"
                              disabledDate={disabledRangeDate}
                            />
                          </Form.Item>
                        </div>
                        {isUpdate && (
                          <div className="flex gap-2 justify-center" style={{ width: '10%' }}>
                            <BiTrash style={{ cursor: 'pointer' }} />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {!isUpdate && (
                    <div>
                      <CustomButton
                        type="primary-ghost"
                        className="group justify-center items-center flex w-full space-x-2"
                        onClick={() => add()}>
                        <BsPlusCircleFill
                          size={20}
                          className="text-primary group-hover:text-white"
                        />
                        Input Re-investment
                      </CustomButton>
                    </div>
                  )}
                </div>
              );
            }}
          </Form.List>
        </Form>
      )}
    </Modal>
  );
}
