import { SearchOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AssetsComponent, CustomButton, CustomInput, Page } from '../../components';
import Table from '../../components/table';
import { PageContent } from '../../const';
import { Util } from '../../helper';
import { Eba } from '../../service';
import {
  columnsDraftProduct,
  columnsFeedbackList,
  columnsListProduct,
} from './components/tableConfig';

const ManagementProduct = () => {
  const { isOriginator, isAdmin, isSupervisor } = Util.role();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const idCard = searchParams.get('status');

  const { list, loading } = useSelector((state) => state.eba);

  const [page, setPage] = useState({ limit: 10, offset: 0, current: 1 });
  const [click, setClick] = useState(Number(idCard) + 1);
  const [SearchItem, setSearchItem] = useState('');

  const listCard = [
    { id: 1, title: 'Produk Dimiliki', description: 'Daftar produk yang dimiliki', isShow: true },
    {
      id: 2,
      title: 'Draft Produk',
      description: 'Draft produk yang diajukan admin',
      isShow: !isSupervisor,
    },
    {
      id: 3,
      title: `${isSupervisor ? 'Request' : 'Feedback'} Produk`,
      description: `Daftar ${isSupervisor ? 'request' : 'feedback'} pengajuan produk`,
      isShow: true,
    },
  ];

  const onClickCard = (key) => {
    setClick(key);
    navigate(`/management-product/?status=${key - 1}`);
    setPage({ limit: 10, offset: 0, current: 1 });
  };

  const status = {
    1: '0',
    2: '1',
    3: '2,3,4',
  };

  useEffect(() => {
    const payload = {
      limit: page.limit,
      offset: page.offset,
      status_value: status[click],
      search: SearchItem,
      is_simulation: 'False',
    };
    dispatch(Eba.getListEba(payload));
  }, [page, click, SearchItem]);

  const onClickEba = (data) => {
    navigate(
      `/assets?page=${PageContent.PRODUCT}&eba-id=${data.id62}&eba-name=${data.name}&status=${data?.status?.value}`
    );
  };

  const onClickAddProduct = () => {
    navigate('/management-product/eba-info?page=management-product');
  };

  const onClickDetailFeedback = (e) => {
    navigate(`/management-product/detail-feedback?&product-name=${e.name}&id62=${e.id62}`);
  };

  const onClickApprovalDecline = (data) => {
    navigate(
      `/management-product/eba-info?page=management-product&eba-id=${data.id62}&eba-name=${data.name}&status=${data?.status?.value}`
    );
  };

  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
    Util.scrollToElement('search-product');
  };

  const showDataTable = () => {
    let columns = columnsListProduct(onClickEba);
    let data = list?.results;
    let count = list?.count;

    if (click === 2) {
      columns = columnsDraftProduct;
      data = list?.results;
      count = list?.count;
    }

    if (click === 3) {
      columns = columnsFeedbackList(isSupervisor ? onClickApprovalDecline : onClickDetailFeedback);
      data = list?.results;
      count = list?.count;
    }
    return { columns, data, count };
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setSearchItem(encodeURIComponent(e.target.value));
    }
  };

  return (
    <Page title="Management Product">
      <div className="flex justify-between items-center my-6">
        <Typography.Paragraph className="font-bold text-3xl">
          {isOriginator ? 'Asset' : 'Management Produk'}
        </Typography.Paragraph>
        {isAdmin && (
          <CustomButton
            type="primary"
            className="
            group
            justify-center
            items-center
            flex
            space-x-2"
            onClick={onClickAddProduct}>
            <BsPlusCircleFill size={20} className="text-white group-hover:text-primary" />
            Input Produk
          </CustomButton>
        )}
      </div>
      <CustomInput
        id="search-product"
        prefix={<SearchOutlined />}
        className="my-5 p-3 max-w-70vh"
        placeholder="Search"
        onKeyDown={handleKeyDown}
      />
      {!isOriginator && (
        <div className={`my-5 grid grid-cols-${isSupervisor ? '2' : '3'} gap-5 mb-6`}>
          {listCard.map(
            (res) =>
              res.isShow && (
                <AssetsComponent.Card
                  key={res.id}
                  title={res.title}
                  description={res.description}
                  onClick={() => onClickCard(res.id)}
                  isClick={res.id === click}
                />
              )
          )}
        </div>
      )}
      <Table.TableGlobal
        columns={showDataTable().columns}
        dataSource={showDataTable().data}
        totalData={showDataTable().count}
        onChangePagination={paginationChange}
        current={page.current}
        scroll={{ x: 1000 }}
        loading={loading}
      />
    </Page>
  );
};

export default ManagementProduct;
