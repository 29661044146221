import React from 'react';
import { SimpleTable } from '../../../components/_shared';
import { kuponColumn, kuponData } from './tableConfig';

export default function TableComponentCoupon() {
  return (
    <div>
      <SimpleTable headers={kuponColumn} className="radius">
        {kuponData.length > 0 &&
          kuponData.map((item) => (
            <tr key={item?.payment} style={{ backgroundColor: '#fff' }}>
              <td className="border h-9 text-center">{item?.payment}</td>
              <td className="border h-9 text-center">{item?.date}</td>
              <td className="border h-9 text-center">{item?.begBalance}</td>
              <td className="border h-9 text-center">{item?.principalRepaid}</td>
              <td className="border h-9 text-center">{item?.interestPayment}</td>
              <td className="border h-9 text-center">{item?.endBalance}</td>
            </tr>
          ))}
        {kuponData.length === 0 && (
          <tr style={{ backgroundColor: '#fff' }}>
            <td colSpan={3} className="border text-center p-4">
              Not found
            </td>
          </tr>
        )}
      </SimpleTable>
    </div>
  );
}
