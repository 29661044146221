import { DatePicker, Form, Modal, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { Investment } from '../../../service';
import { CustomButton, CustomInput } from '../../_shared';

export default function AssetClassRating({ open, onClose, ebaId, gradeId }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const onSubmit = (e) => {
    let rating = [];

    for (let i = 0; i < e?.fields.length; i++) {
      rating.push({
        name: e?.fields[i].name,
        date: moment(e?.fields[i].date?.$d).format('YYYY-MM-DD'),
        maker: e?.fields[i].maker,
      });
    }
    const payload = {
      grade_id62: gradeId,
      rating: rating,
    };

    dispatch(Investment.createRating(payload))
      .unwrap()
      .then(() => {
        dispatch(Investment.getRating({ ebaId: ebaId, gradeId: gradeId }))
          .unwrap()
          .then(() => {
            handleClose();
          });
      });
  };

  return (
    <Modal
      title={<h3 className="text-xl font-bold">Rating Information</h3>}
      open={open}
      onCancel={() => handleClose()}
      centered
      footer={[
        <CustomButton key="back" type="primary-ghost" onClick={() => handleClose()}>
          Cancel
        </CustomButton>,
        <CustomButton key="submit" type="primary" form="myForm" htmlType="submit">
          Simpan
        </CustomButton>,
      ]}
      width={'50%'}
      destroyOnClose={true}>
      <Form id="myForm" layout="vertical" form={form} requiredMark={false} onFinish={onSubmit}>
        <Form.List name="fields">
          {(fields, { add }) => {
            return (
              <>
                <div className="flex flex-column items-center gap-3 mb-5">
                  <div className="flex items-center justify-evenly w-full">
                    <Typography.Paragraph className="text-base text-center w-1/3">
                      Rating
                    </Typography.Paragraph>
                    <Typography.Paragraph className="text-base text-center w-1/3">
                      Rating Date
                    </Typography.Paragraph>
                    <Typography.Paragraph className="text-base text-center w-1/3">
                      Rating Agency
                    </Typography.Paragraph>
                  </div>
                </div>
                {fields.map((field, index) => (
                  <div key={field.key} className="flex flex-column items-center gap-x-2.5 mb-5">
                    <div className="flex items-baseline justify-evenly w-full gap-3">
                      <Form.Item
                        className="text-center w-1/3"
                        name={[index, 'name']}
                        rules={[
                          {
                            required: true,
                            message: 'Field tidak boleh kosong',
                          },
                        ]}>
                        <CustomInput
                          type="text"
                          placeholder="Masukan rating"
                          className="py-[18px] px-[24px]"
                        />
                      </Form.Item>
                      <Form.Item
                        className="text-center w-1/3"
                        name={[index, 'date']}
                        rules={[
                          {
                            required: true,
                            message: 'Field tidak boleh kosong',
                          },
                        ]}>
                        <DatePicker
                          className="custom-input rounded py-[18px] bg-white px-[24px] w-full"
                          placeholder="dd/MM/YYYY"
                        />
                      </Form.Item>
                      <Form.Item
                        className="text-center w-1/3"
                        name={[index, 'maker']}
                        rules={[
                          {
                            required: true,
                            message: 'Field tidak boleh kosong',
                          },
                        ]}>
                        <CustomInput
                          type="text"
                          placeholder="Masukan Nama"
                          className="py-[18px] px-[24px]"
                        />
                      </Form.Item>
                    </div>
                  </div>
                ))}
                <div>
                  <CustomButton
                    type="primary-ghost"
                    className="group justify-center items-center flex w-full space-x-2"
                    onClick={() => add()}>
                    <BsPlusCircleFill size={20} className="text-primary group-hover:text-white" />
                    Input Rating
                  </CustomButton>
                </div>
              </>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
}
