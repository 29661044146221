import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormEmailVerification, FormLogin, FormOtp } from '../../components/login';

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const section = searchParams.get('section');

  const SectionShow = () => {
    switch (section) {
      case 'forgot-password':
        return <FormEmailVerification />;
      case 'otp-input':
        return <FormOtp />;
      default:
        return <FormLogin />;
    }
  };

  return (
    <div className="flex justify-end bg-sign-in bg-no-repeat bg-cover h-[100vh]">
      <div className="flex w-full max-w-[580px] h-full max-md:rounded-none bg-white items-center justify-center rounded-l-3xl">
        <SectionShow />
      </div>
    </div>
  );
};

export default LoginPage;
