import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const getCost = createAsyncThunk('get/getCost', async (payload, { rejectWithValue }) => {
  try {
    const { limit = 10, offset = 0, id } = payload;

    return await API.get({
      url: `cost/?eba_id62=${id}&limit=${limit}&offset=${offset}`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getCostDashboard = createAsyncThunk(
  'get/getCostDashboard',
  async (payload, { rejectWithValue }) => {
    try {
      const { limit = 10, offset = 0, id } = payload;

      return await API.get({
        url: `cost/dashboard/?eba_id62=${id}&limit=${limit}&offset=${offset}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createCost = createAsyncThunk('post/createCost', async (payload, { rejectWithValue }) => {
  try {
    return await API.post({
      url: `cost/`,
      payload,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getCostType = createAsyncThunk('get/getCostType', async (payload, { rejectWithValue }) => {
  try {
    const { limit = 10, offset = 0, ebaId = '' } = payload;

    return await API.get({
      url: `cost/type/${ebaId}/?limit=${limit}&offset=${offset}`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getCostByID = createAsyncThunk('get/getCostByID', async (id, { rejectWithValue }) => {
  try {
    return await API.get({
      url: `cost/${id}/`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const updateCost = createAsyncThunk(
  'post/updateCost',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      return await API.put({
        url: `cost/${id}/`,
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const calculateAmount = createAsyncThunk(
  'post/calculateAmount',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({
        url: 'cost/detail/calculate-amount/',
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createCostDetail = createAsyncThunk(
  'post/costDetail',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({
        url: 'cost/detail/',
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getCostDetailByMonth = createAsyncThunk(
  'get/getCostDetailByMonth',
  async (payload, { rejectWithValue }) => {
    try {
      const { limit = 10, offset = 0, id, period } = payload;

      return await API.get({
        url: `cost/detail/detail-by-month/?eba_id62=${id}&period_date=${period}&limit=${limit}&offset=${offset}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updateCostDetail = createAsyncThunk(
  'post/updateCostDetail',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      return await API.put({
        url: `cost/detail/${id}/`,
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updateCostTax = createAsyncThunk(
  'put/updateCostTax',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      return await API.put({
        url: `cost/detail/${id}/update-tax-to-end-period/`,
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const deleteCost = createAsyncThunk('delete/deleteCost', async (id, { rejectWithValue }) => {
  try {
    return await API.deleteRequest({
      url: `cost/${id}/`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getCostDetail = createAsyncThunk(
  'get/getCostDetail',
  async (payload, { rejectWithValue }) => {
    try {
      const { limit = 10, offset = 0, id, cost } = payload;

      return await API.get({
        url: `cost/detail/?eba_id62=${id}&cost_id62=${cost}&limit=${limit}&offset=${offset}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getListAum = createAsyncThunk('get/getListAum', async (payload, { rejectWithValue }) => {
  try {
    const { limit = 10, offset = 0, id } = payload;
    return await API.get({
      url: `cost/list-aum/?eba_id62=${id}&limit=${limit}&offset=${offset}`,
    });
  } catch (err) {
    return rejectWithValue(err);
  }
});

const DownloadCostDetail = createAsyncThunk(
  'get/downloadCOstDetailExcel',
  async ({ period, ebaId, ebaName }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `cost/detail/export-excel/?eba_id62=${ebaId}&period_date=${period}`,
        isDownload: true,
        fileName: `Cost_Detail_${ebaName}_${period}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getCostSummary = createAsyncThunk('get/costSummary', async (payload, { rejectWithValue }) => {
  try {
    const { limit = 10, offset = 0, id, cost } = payload;

    return await API.get({
      url: `cost/summary/?eba_id62=${id}&cost_id62=${cost}&limit=${limit}&offset=${offset}`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const updateCostActual = createAsyncThunk(
  'put/updateActualCast',
  async (body, { rejectWithValue }) => {
    try {
      const { id62, payload } = body;
      return await API.put({ url: `cost/detail/${id62}/update-actual-cash/`, payload });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default {
  getCost,
  getCostType,
  createCost,
  getCostDashboard,
  calculateAmount,
  updateCost,
  getCostByID,
  createCostDetail,
  getCostDetailByMonth,
  updateCostDetail,
  deleteCost,
  getCostDetail,
  getListAum,
  DownloadCostDetail,
  getCostSummary,
  updateCostActual,
  updateCostTax,
};
