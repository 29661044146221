import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Transaction } from '../../service';

const initialState = {
  items: [],
  itemTotal: 0,
  summary: {
    debit: 0,
    credit: 0,
    buffer: 0,
  },
  paginationSync: {
    page: 1,
  },
  loading: false,
};

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  extraReducers: (builder) => {
    // transaction list
    builder.addCase(Transaction.getTransaction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Transaction.getTransaction.fulfilled, (state, action) => {
      state.loading = false;
      state.summary = {
        credit: action.payload?.data?.total_credit,
        debit: action.payload?.data?.total_debit,
        buffer: action.payload?.data?.total_saldo,
      };
      state.items = action.payload?.data?.data;
      state.itemTotal = action.payload?.data?.data.length;
      state.paginationSync.page = action?.meta?.arg?.page;
    });
    builder.addCase(Transaction.getTransaction.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
      state.items = [];
      state.paginationSync.page = 1;
    });
  },
});

export default transactionSlice.reducer;
