import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const cashflowLoss = createAsyncThunk(
  'get/cashflowLoss',
  async ({ ebaId, year }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `cashflow/loss/?eba_id62=${ebaId}&year=${year}&limit=12&offset=0`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const cashflowAcquisition = createAsyncThunk(
  'get/cashflowAcquisition',
  async ({ ebaId }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `cashflow/acquisition/?eba_id62=${ebaId}&limit=12&offset=0`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const cashflowCombination = createAsyncThunk(
  'get/cashflowCombination',
  async ({ ebaId }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `cashflow/acquisition/?eba_id62=${ebaId}&limit=12&offset=0&data_type=combination`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const cashflowGenerate = createAsyncThunk(
  'post/cashflowGenerate',
  async ({ ebaId, rowKey, approvalId }, { rejectWithValue }) => {
    try {
      return await API.post({
        url: `cashflow/generate/`,
        payload: { eba_id62: ebaId, approval_id62: approvalId, asset_id62: rowKey || [] },
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default { cashflowLoss, cashflowAcquisition, cashflowGenerate, cashflowCombination };
