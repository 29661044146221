import { Util } from '../../../helper';

const columnsLogInternal = [
  {
    title: 'Pilihan Kelas',
    dataIndex: 'log_history',
    align: 'center',
    render: (record) => record.eba_class,
  },
  {
    title: 'Harga Pasar per Kelas (IBPA)',
    dataIndex: 'log_history',
    align: 'center',
    render: (record) => Util.renderNumber(record.ibpa),
  },
  {
    title: 'Status',
    dataIndex: 'log_history',
    align: 'center',
    render: (record) => record.status,
  },
];

export { columnsLogInternal };
