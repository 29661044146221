import { DatePicker, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Format } from '../../helper';

function comparationCashflow({ items, fetchItems }) {
  const onChange = (_, val) => {
    fetchItems(val);
  };
  return (
    <>
      <Space>
        <Typography.Paragraph className="font-bold text-sm">Filter by year:</Typography.Paragraph>
        <DatePicker picker="year" onChange={onChange} />
      </Space>
      <div className="flex flex-col gap-1">
        <div className="flex flex-row justify-between items-center py-4 px-4">
          <div className="w-3/4">Bulan</div>
          <div className="w-1/4 text-center">Cashflow yang hilang (Rp)</div>
        </div>
        {items.length > 0 &&
          items.map((item, key) => (
            <div
              className="flex flex-row justify-between items-center bg-white py-4 px-4"
              key={key}>
              <div className="w-3/4">{item?.month}</div>
              <div className="w-1/4 text-center">
                {Format.numberFormat(Math.round(item?.total_loss))}
              </div>
            </div>
          ))}
        {items.length == 0 && (
          <div className="flex flex-row justify-between items-center bg-white py-4 px-4">
            <div className="w-full text-center">Data tidak ditemukan</div>
          </div>
        )}
      </div>
    </>
  );
}

comparationCashflow.propTypes = {
  items: PropTypes.array.isRequired,
  fetchItems: PropTypes.func.isRequired,
};

export default comparationCashflow;
