import { Format } from '../../../helper';

export const columnsInvestor = [
  {
    title: 'Kode EBA',
    dataIndex: 'investor',
    align: 'center',
    width: 180,
    render: (record) =>
      record?.map((item, key) => (
        <div key={key}>
          {item?.kode_eba}
          {key + 1 <= record.length && ','}
        </div>
      )),
  },
  {
    title: 'Single Investor Identification',
    dataIndex: 'investor',
    align: 'center',
    width: 180,
    render: (record) =>
      record?.map((item, key) => (
        <div key={key}>
          {item?.single_investor_identification}
          {key + 1 <= record.length && ','}
        </div>
      )),
  },
  {
    title: 'Nama Investor',
    dataIndex: 'investor',
    align: 'center',
    width: 180,
    render: (record) =>
      record?.map((item, key) => (
        <div key={key}>
          {item?.nama_investor}
          {key + 1 <= record.length && ','}
        </div>
      )),
  },
  {
    title: 'Jenis Investor',
    dataIndex: 'investor',
    align: 'center',
    width: 180,
    render: (record) =>
      record?.map((item, key) => (
        <div key={key}>
          {item?.jenis_investor}
          {key + 1 <= record.length && ','}
        </div>
      )),
  },
  {
    title: 'Keterangan Investor',
    dataIndex: 'investor',
    align: 'center',
    width: 180,
    render: (record) =>
      record?.map((item, key) => (
        <div key={key}>
          {item?.keterangan_investor}
          {key + 1 <= record.length && ','}
        </div>
      )),
  },
  {
    title: 'Kewarnaganegaraan',
    dataIndex: 'investor',
    align: 'center',
    width: 180,
    render: (record) =>
      record?.map((item, key) => (
        <div key={key}>
          {item?.kewarganegaraan}
          {key + 1 <= record.length && ','}
        </div>
      )),
  },
  {
    title: 'Total Investasi Awal(Rp)',
    dataIndex: 'investor',
    align: 'right',
    width: 180,
    render: (record) =>
      record?.map((item, key) => (
        <div key={key}>
          {Format.numberFormat(item?.total_investasi_awal)}
          {key + 1 <= record.length && ','}
        </div>
      )),
  },
  {
    title: ' Total Investasi Akhir Periode(Rp)',
    dataIndex: 'investor',
    align: 'right',
    width: 180,
    render: (record) =>
      record?.map((item, key) => (
        <div key={key}>
          {Format.numberFormat(item?.total_investasi_akhir)}
          {key + 1 <= record.length && ','}
        </div>
      )),
  },
];
