import { Suspense } from 'react';
import { Await, Navigate, useLoaderData, useLocation, useOutlet } from 'react-router-dom';
import { Constant, Images } from '../../const';
import { AuthProvider } from '../../hooks/useAuth';
import { CustomMessage } from '../_shared';

export const AuthLayout = () => {
  const outlet = useOutlet();
  const location = useLocation();

  const { userPromise } = useLoaderData();

  if (location.pathname === '/') {
    return <Navigate to="/sign-in" />;
  }

  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center w-full h-[100vh]">
          <img src={Images.BAHANA_LOGO} alt="bahana logo" className="animate-rubberBand" />
        </div>
      }>
      <Await
        resolve={userPromise}
        errorElement={() =>
          CustomMessage({ type: Constant.ERROR, messages: 'Something went wrong' })
        }
        children={(user) => <AuthProvider userData={user}>{outlet}</AuthProvider>}
      />
    </Suspense>
  );
};
