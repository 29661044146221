import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const cashWaterfall = createAsyncThunk(
  'get/cashWaterfall',
  async ({ yearMonth, ebaId, start_date, end_date }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `waterfall/?year_month=${yearMonth}&eba_id62=${ebaId}&start_date=${
          start_date !== null ? start_date : ''
        }&end_date=${end_date !== null ? end_date : ''}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default { cashWaterfall };
