import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Util } from '../../helper';
import { Buffer } from '../../service';

const initialState = {
  loading: false,
  data: {},
};

export const bufferSlice = createSlice({
  name: 'buffer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // buffer details
    builder.addCase(Buffer.bufferDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Buffer.bufferDetail.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload?.data;
      state.data = { ...data, amount_left: Number(data.amount) - Number(data.reinvestment_amount) };
    });
    builder.addCase(Buffer.bufferDetail.rejected, (state) => {
      state.loading = false;
      state.data = {};
    });
    // get buffer
    builder.addCase(Buffer.getBuffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Buffer.getBuffer.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Get Buffer',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Buffer.getBuffer.rejected, (state) => {
      state.loading = false;
    });
    // create buffer
    builder.addCase(Buffer.createBuffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Buffer.createBuffer.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Success Create Buffer',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Buffer.createBuffer.rejected, (state, action) => {
      state.loading = false;
      let msg = '';
      if (typeof action.payload?.eba_id62 !== 'undefined') {
        msg = action.payload?.eba_id62[0];
      } else if (typeof action.payload?.percent !== 'undefined') {
        msg = action.payload?.percent[0];
      } else {
        msg = 'Failed to create buffer';
      }
      CustomNotification({
        type: Constant.ERROR,
        message: msg,
        description: Util.errorHandle(action.payload),
      });
    });
  },
});

export default bufferSlice.reducer;
