import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const login = createAsyncThunk('post/login', async (body, { rejectWithValue }) => {
  try {
    return await API.post({ url: 'authentication/login/', payload: body });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const forgotPassword = createAsyncThunk(
  'post/forgotPassword',
  async (body, { rejectWithValue }) => {
    try {
      return await API.post({ url: 'authentication/forgot-password/', payload: body });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const sendEmailVerification = createAsyncThunk(
  'post/sendEmailVerification',
  async (email, { rejectWithValue }) => {
    const body = {
      email: email,
    };
    try {
      return await API.post({
        url: 'authentication/send-email-verification/',
        payload: body,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const verifyUser = createAsyncThunk('post/verifyUser', async (code, { rejectWithValue }) => {
  const body = {
    token: code,
  };
  try {
    return await API.post({
      url: 'authentication/verify_token/',
      payload: body,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const setNewPassword = createAsyncThunk(
  'post/setNewPassword',
  async (body, { rejectWithValue }) => {
    try {
      return await API.post({
        url: 'authentication/set-password/',
        payload: body,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const changePassword = createAsyncThunk(
  'post/changePassword',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({
        url: 'authentication/change-password/',
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default {
  login,
  forgotPassword,
  sendEmailVerification,
  verifyUser,
  setNewPassword,
  changePassword,
};
