import React from 'react';
import { SimpleTable } from '../../../components/_shared';
import { ebaColumn, ebaData, detailData } from './tableConfig';

export default function TableComponentInfo() {
  return (
    <div className="flex flex-col gap-6">
      <SimpleTable headers={ebaColumn} className="radius">
        {ebaData.length > 0 &&
          ebaData.map((item) => (
            <tr key={item?.payment} style={{ backgroundColor: '#fff' }}>
              <td className="border h-9 text-center">{item?.kelas}</td>
              <td className="border h-9 text-center">{item?.jenis}</td>
              <td className="border h-9 text-center">{item?.pokokAwal}</td>
              <td className="border h-9 text-center">{item?.hasilInvestasi}</td>
              <td className="border h-9 text-center">{item?.tanggalPelunasan}</td>
            </tr>
          ))}
        {ebaData.length === 0 && (
          <tr style={{ backgroundColor: '#fff' }}>
            <td colSpan={3} className="border text-center p-4">
              Not found
            </td>
          </tr>
        )}
      </SimpleTable>
      <table className="rounded-[10px] bg-white border-[#F1F3F6] overflow-hidden">
        <tbody>
          {detailData.length > 0 &&
            detailData.map((item, i) => (
              <tr key={i} style={{ backgroundColor: '#fff' }}>
                <td className="font-bold border px-3 py-[10px] box-border h-9">{item.title}</td>
                <td className="h-9 px-3 border py-[10px] box-border">{item.data}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
