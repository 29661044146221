import React, { Fragment, useState, useEffect } from 'react';
import { Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PoolOfAsset } from '../../service';
import { Format } from '../../helper';
import { columnsSummary } from './tableConfig';
import Title from './title';

const Summary = ({ approvalId, ebaEnd }) => {
  const dispatch = useDispatch();
  const { loading, summary, loadingDownload } = useSelector((state) => state.poa);

  const [page] = useState({ limit: 10, offset: 0, current: 1 });

  useEffect(() => {
    const payload = {
      approvalId,
      limit: page.limit,
      offset: page.offset,
    };
    dispatch(PoolOfAsset.summary(payload));
  }, []);

  const onClickDownload = () => {
    dispatch(PoolOfAsset.downloadSummary(approvalId));
  };

  const numberFields = [
    'principal_installments',
    'interest_installments',
    'total_installment_payments',
    'total_cashflow',
  ];

  const FieldInfo = ({ title, dataIndex, summary }) => (
    <div className="flex">
      <Typography.Text className="text-base w-1/2 p-4 border-1 border-grey_bg">
        {title}
      </Typography.Text>
      <Typography.Text className="text-base w-1/2 p-4 border-1 border-grey_bg">
        {numberFields.includes(dataIndex)
          ? Format.numberFormat(summary[dataIndex])
          : summary[dataIndex]}
      </Typography.Text>
    </div>
  );

  return (
    <Fragment>
      <Title title="Summary" onClickDownload={onClickDownload} loading={loadingDownload} />
      {!loading && summary?.length > 0 && (
        <div className="bg-white rounded-lg mt-3">
          {columnsSummary(ebaEnd).map((res, key) => {
            return (
              <FieldInfo
                key={key}
                title={res.title}
                dataIndex={res.dataIndex}
                summary={summary[0]}
              />
            );
          })}
        </div>
      )}
    </Fragment>
  );
};

export default Summary;
