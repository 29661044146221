/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { Form, Typography } from 'antd';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Constant } from '../../const';
import { Login } from '../../service';
import { CustomButton, CustomInput, CustomNotification } from '../_shared';
import Page from '../page-wrapper';

const FormOtp = ({ isAuth = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initValue = {
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
    otp5: '',
    otp6: '',
  };

  const schema = yup.object().shape({
    otp1: yup.number().typeError('OTP ke-1 harus angka').required('OTP 1 Tidak boleh kosong'),
    otp2: yup.number().typeError('OTP ke-2 harus angka').required('OTP 2 Tidak boleh kosong'),
    otp4: yup.number().typeError('OTP ke-3 harus angka').required('OTP 4 Tidak boleh kosong'),
    otp3: yup.number().typeError('OTP ke-4 harus angka').required('OTP 3 Tidak boleh kosong'),
    otp5: yup.number().typeError('OTP ke-5 harus angka').required('OTP 5 Tidak boleh kosong'),
    otp6: yup.number().typeError('OTP ke-6 harus angka').required('OTP 6 Tidak boleh kosong'),
  });

  const inputFocus = (elmnt) => {
    if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
      const next = elmnt.target.tabIndex - 1;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex + 1;
      if (next <= 5) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  const onSubmit = (e) => {
    const otp = Object.values(e).join('');
    dispatch(Login.verifyUser(otp))
      .unwrap()
      .then(() => {
        if (isAuth) {
          navigate('?section=create-password&otp=' + otp);
        } else {
          navigate('/create-password?otp=' + otp);
        }
      })
      .catch((err) => {
        CustomNotification({
          type: Constant.ERROR,
          message: 'Gagal',
          description: err?.message?.id,
        });
      });
  };

  return (
    <Page title="OTP">
      <div className="flex flex-col px-10">
        <Formik initialValues={initValue} validationSchema={schema} onSubmit={onSubmit}>
          {({ handleSubmit, handleChange, values }) => (
            <Form
              noValidate
              onFinish={handleSubmit}
              className="flex flex-col items-center justify-center">
              <Typography.Paragraph className="font-bold text-[40px]">
                Input Security Code
              </Typography.Paragraph>

              <div className="flex space-x-3 mt-[100px]">
                {Array(6)
                  .fill()
                  .map((res, i) => (
                    <CustomInput
                      key={i}
                      id={`otp${i + 1}`}
                      type="text"
                      name={`otp${i + 1}`}
                      value={values[`otp${i + 1}`]}
                      maxLength="1"
                      onKeyUp={inputFocus}
                      onChange={handleChange}
                      tabIndex={i}
                      className="max-w-[70px] text-center text-2xl py-[18px] px-[24px]"
                    />
                  ))}
              </div>
              <CustomButton type="primary" htmlType="submit" block className="mt-[100px]">
                Verify Code
              </CustomButton>
            </Form>
          )}
        </Formik>
      </div>
    </Page>
  );
};

export default FormOtp;
