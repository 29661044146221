import BAHANA_LOGO from '../assets/images/bahana-logo.png';
import { ReactComponent as MANAGEMENT_USER } from '../assets/icons/management-user.svg';
import { ReactComponent as MANAGEMENT_PRODUCT } from '../assets/icons/management-product.svg';
import { ReactComponent as LOGBOOK } from '../assets/icons/logbook.svg';
import { ReactComponent as DASHBOARD } from '../assets/icons/dashboard.svg';
import { ReactComponent as COST } from '../assets/icons/cost.svg';
import { ReactComponent as ASSET } from '../assets/icons/asset.svg';
import DOCUMENT from '../assets/icons/document.svg';

export default {
  BAHANA_LOGO,
  MANAGEMENT_PRODUCT,
  MANAGEMENT_USER,
  LOGBOOK,
  DASHBOARD,
  COST,
  ASSET,
  DOCUMENT,
};
