import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const bufferDetail = createAsyncThunk(
  'get/bufferDetail',
  async ({ ebaId, limit = 10000, offset = 0 }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `buffer/buffer-details/?eba_id62=${ebaId}&limit=${limit}&offset=${offset}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getBuffer = createAsyncThunk('get/buffer', async ({ ebaId }, { rejectWithValue }) => {
  try {
    return await API.get({
      url: `buffer/?eba_id62=${ebaId}`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const createBuffer = createAsyncThunk('post/createBuffer', async (payload, { rejectWithValue }) => {
  try {
    return await API.post({
      url: `buffer/`,
      payload,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const editBuffer = createAsyncThunk('put/editBuffer', async (payload, { rejectWithValue }) => {
  try {
    return await API.put({
      url: `buffer/update_buffer/`,
      payload,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

export default {
  bufferDetail,
  createBuffer,
  getBuffer,
  editBuffer,
};
