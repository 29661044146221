import { Fragment } from 'react';
import { Navigate, useOutlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

export const HomeLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (user?.token) {
    return <Navigate to="/management-product" replace />;
  }

  return <Fragment>{outlet}</Fragment>;
};
