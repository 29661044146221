import { DatePicker, Space, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { IoIosSend } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { PreviewDataAcquisitionComponent } from '../..';
import { Constant } from '../../../const';
import { Util, Date } from '../../../helper';
import { useAuth } from '../../../hooks/useAuth';
import { Aquisition } from '../../../service';
import CustomButton from '../custom-button';
import CustomNotification from '../custom-notification';
import CustomTabs from '../custom-tabs';
import ModalApprove from '../modal-approve';
import { columnsSummary } from './tableConfig';

const AcquisitionDataSummary = ({ title = '', onClickSubmit, hasStaticDate }) => {
  const { user } = useAuth();
  const { isAdmin, isOriginator, isSupervisor } = Util.role();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const approvalId = searchParams.get('approval-id');
  const ebaId = searchParams.get('eba-id');
  const ebaName = searchParams.get('eba-name');
  const status = searchParams.get('status');

  const dispatch = useDispatch();
  const { summary, summaryNpv, loading, loadingDownload, cashflowSummary } = useSelector(
    (state) => state.aquisition
  );

  const [openModal, setIsOpenModal] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [notes, setNotes] = useState('');
  const [summaryRow, setSummaryRow] = useState([]);
  const [staticDate, setStaticDate] = useState('');

  const [paidLossDate, setPaidLossDate] = useState('');
  const [acquisitionDate, setAcquisitionDate] = useState('');
  const [lastMonthOn24th, setLastMonthOn24th] = useState('');

  useEffect(() => {
    setLastMonthOn24th(dayjs().subtract(1, 'month').date(24).format('YYYY-MM-DD'));
  }, []);

  useEffect(() => {
    const newSummary = [];
    let totalLostInstallment = 0;
    summary.forEach((res) => {
      setStaticDate(Date.fullDate(res.cashflow_npv_start_date));

      if (res.description === Constant.ACQUISITION) {
        newSummary.push({
          ...res,
          id62: 'total-lost-debtor',
          description: 'Total Angsuran Debitur Hilang',
          total_installment_earning: totalLostInstallment,
        });
      } else {
        totalLostInstallment += Number(res.total_installment_earning);
      }
      newSummary.push(res);
    });
    setSummaryRow(newSummary);
  }, [summary]);

  useEffect(() => {
    const payload = {
      approvalId: approvalId,
      ebaId: ebaId,
    };
    dispatch(Aquisition.acquisitionApprovalSummary(payload));
  }, [summaryNpv]);

  const downloadExcel = () => {
    dispatch(Aquisition.acquisitionDownloadDetailApprovalSummary({ approvalId, ebaId }));
  };

  const onClickDetail = () => {
    const originatorDetail = [5, 8];
    if (isOriginator && originatorDetail.includes(Number(status))) {
      navigate(`/detail-data-acquisition${location.search}&cashflow=${cashflowSummary}`);
      return;
    }
    navigate(`/detail-data-acquisition${location.search}&cashflow=${cashflowSummary}`);
  };

  const getAcquisitionDetail = async (payload) => {
    dispatch(Aquisition.acquisitionSummary(payload))
      .unwrap()
      .catch((err) => {
        err?.msg?.map((res) => {
          CustomNotification({ type: Constant.ERROR, description: res });
        });
        CustomNotification({
          type: Constant.ERROR,
          message: 'Tanggal Transaksi Minimal',
          description: err['Start date min'],
        });
      });
  };

  const onChangePaidLossPaidOffDate = async (_, dateString) => {
    setPaidLossDate(dateString);
    const payload = {
      approval_id62: approvalId,
      start_date: acquisitionDate,
      npv_date: dateString,
    };
    await getAcquisitionDetail(payload);
  };

  const onChangeAcquisitionDate = async (_, dateString) => {
    const npvDate = paidLossDate || lastMonthOn24th;
    setAcquisitionDate(dateString);
    const payload = {
      approval_id62: approvalId,
      start_date: dateString,
      npv_date: npvDate,
    };
    await getAcquisitionDetail(payload);
  };

  const tabsItem = [
    // {
    //   label: 'Sebelum Akuisisi',
    //   children: <PreviewDataAcquisitionComponent.BeforeAcquisition ebaId={ebaId} />,
    // },
    {
      label: 'Cashflow Akuisisi',
      children: <PreviewDataAcquisitionComponent.AfterAcquisition ebaId={ebaId} />,
    },
    {
      label: 'Cashflow Gabungan',
      children: <PreviewDataAcquisitionComponent.CombinationAcquisition ebaId={ebaId} />,
    },
  ];

  const SummaryData = ({ pageData }) => {
    let total = 0;
    let acquisition = 0;
    pageData?.forEach((res) => {
      if (res?.description === Constant.ACQUISITION) {
        acquisition = Number(res?.total_installment_earning);
      } else if (res?.id62 !== 'total-lost-debtor') {
        total += Number(res?.total_installment_earning);
      }
    });
    const diff = acquisition - Math.abs(total);
    return (
      <>
        <Table.Summary.Row className="bg-[#D5D5D5] bg-opacity-40 font-semibold">
          <Table.Summary.Cell index={0} colSpan={4} className="text-center">
            SELISIH
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} className="text-right">
            {Util.renderNumber(diff)}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const onClickReject = () => {
    setIsApprove(false);
    setIsOpenModal(true);
  };

  const onClickApprove = () => {
    setIsApprove(true);
    setIsOpenModal(true);
  };

  const onClickCancel = () => {
    setIsOpenModal(false);
  };

  const approvalBy = (payload) => {
    if (isSupervisor) {
      return dispatch(Aquisition.aquisitionApprovalBySupervisor(payload));
    }
    if (isOriginator) {
      return dispatch(Aquisition.aquisitionApprovalByOriginator(payload));
    }
  };

  const onClickSend = () => {
    const action = isApprove ? 'approve' : 'reject';

    if (!notes && !isApprove) {
      CustomNotification({
        type: Constant.ERROR,
        message: `Catatan tidak boleh kosong`,
      });
      return;
    }

    const payload = {
      id62: approvalId,
      approve: isApprove,
      notes: notes,
    };
    approvalBy(payload)
      .unwrap()
      .then((res) => {
        setIsOpenModal(false);
        CustomNotification({
          type: Constant.SUCCESS,
          message: `Sukses ${action}`,
          description: res?.message?.id || `Data berhasil ${action} oleh ${user.role}`,
        });
        navigate(
          `/assets?page=product&eba-name=${ebaName}&eba-id=${ebaId}&eba-name=${ebaName}&tab=2`
        );
      })
      .catch(() => {
        setIsOpenModal(false);
        CustomNotification({
          type: Constant.ERROR,
          message: `Gagal ${action}`,
          description: `Data gagal ${action} oleh ${user.role}`,
        });
      });
  };

  const ButtonShow = () => {
    let button = !isOriginator && [0, 1, 2, 4, 6].includes(Number(status)) && (
      <CustomButton
        onClick={onClickSubmit}
        type="primary"
        className="
            group 
            flex 
            justify-center 
            items-center
            space-x-2">
        <IoIosSend size={20} className="text-white group-hover:text-primary" />
        <span className=" text-sm">Ajukan</span>
      </CustomButton>
    );

    if (!isAdmin && status != 8) {
      button = (
        <>
          <CustomButton
            type="primary-ghost"
            className="
            flex 
            justify-center 
            items-center px-8"
            onClick={onClickReject}>
            <span className=" text-sm">Reject</span>
          </CustomButton>
          <CustomButton
            onClick={onClickApprove}
            type="primary"
            className="
            group 
            flex 
            justify-center 
            items-center
            space-x-2">
            <span className=" text-sm">Approve</span>
          </CustomButton>
        </>
      );
    }
    if (isSupervisor && status != 3) return null;
    if (isOriginator && status != 5) return null;
    return button;
  };

  return (
    <>
      <div className="flex justify-between my-6">
        <Typography.Text className="font-bold text-2xl">{title}</Typography.Text>
        <div className="flex space-x-3">
          <CustomButton
            type="primary-ghost"
            className="
            flex 
            justify-center 
            items-center"
            onClick={downloadExcel}
            loading={loadingDownload}>
            <span className=" text-sm">Download Excel</span>
          </CustomButton>
          <CustomButton
            type="primary-ghost"
            className="
            flex 
            justify-center 
            items-center"
            onClick={onClickDetail}>
            <span className="text-sm">Lihat Detail</span>
          </CustomButton>
          {ButtonShow()}
        </div>
      </div>

      {(isAdmin || isSupervisor) && status != 8 && (
        <div className="flex space-y-2 flex-col">
          <Space>
            <Typography.Paragraph className="font-bold text-sm">
              Tanggal Transaksi Akuisisi: {hasStaticDate && `${staticDate}`}
            </Typography.Paragraph>
            {!hasStaticDate && <DatePicker onChange={onChangeAcquisitionDate} />}
          </Space>
          {!hasStaticDate && (
            <Space>
              <Typography.Paragraph className="font-bold text-sm">
                Tanggal Update Data Bulanan:
              </Typography.Paragraph>
              <DatePicker
                onChange={onChangePaidLossPaidOffDate}
                defaultValue={dayjs().subtract(1, 'month').date(24)}
              />
            </Space>
          )}
        </div>
      )}

      <div className="mt-5">
        <Table
          rowClassName={(record) =>
            record.id62 === 'total-lost-debtor' ? 'bg-[#D5D5D5] bg-opacity-40 font-semibold' : ''
          }
          className="table-preview-data"
          columns={columnsSummary}
          rowKey={(record) => record.id62}
          dataSource={summaryRow}
          loading={loading}
          pagination={false}
          scroll={{ x: 900 }}
          summary={(data) => <SummaryData pageData={data} />}
          bordered
        />
      </div>
      {!isOriginator && (
        <div className="space-y-5 mt-10">
          <Typography.Text className="font-bold text-2xl">Perbandingan Cashflow</Typography.Text>
          <CustomTabs id="tab-comparation-cashflow" tabsItem={tabsItem} />
        </div>
      )}
      <ModalApprove
        isApprove={isApprove}
        loading={loading}
        notes={notes}
        onClickCancel={onClickCancel}
        onClickSend={onClickSend}
        openModal={openModal}
        setNotes={setNotes}
      />
    </>
  );
};

export default AcquisitionDataSummary;
