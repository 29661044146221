import UploadAsset from './upload-asset/UploadAsset';
import EarningPercentage from './upload-asset/EarningPercentage';
import EarningPercentageForm from './upload-asset/EarningPercentageForm';
import AssetClassAction from './asset-class/assetClassAction';
import AssetClassForm from './asset-class/assetClassForm';
import AssetClassLogRating from './asset-class/assetClassLogRating';
import AssetClassSet from './asset-class/assetClassSet';
import AssetClassRating from './asset-class/assetClassRating';
import DraftAction from './management-product/draftAction';
import CostForm from './cost/CostForm';
import CostAction from './cost/CostAction';
import CostDetailAction from './cost/CostDetailAction';
import CostDetailForm from './cost/CostDetailForm';
import CostUpdate from './cost/CostUpdate';
import ReInvestmentForm from './re-investment/ReInvestmentForm';
import ReInvestmentAction from './re-investment/ReInvestmentAction';
import ReInvestmentPublish from './re-investment/ReInvestmentPublish';
import CreateProductSupervisor from './create-product/CreateProductSupervisor';
import EquityBufferForm from './equity-buffer/EquityBufferForm';
import AssetClassDetailForm from './asset-class-detail/assetClassDetailForm';
import InvestorList from './asset-class-detail/investorList';
import PeriodTable from './asset-class-detail/period';
import EarningPercentageAction from './upload-asset/EarningPercentageAction';
import EquityBufferAction from './equity-buffer/EquityBufferAction';
import FeedbackAction from './FeedbackAction';
import BeiSet from './cost/BeiSet';
import CostFormPaid from './cost/CostFormPaid';

export default {
  UploadAsset,
  EarningPercentage,
  AssetClassAction,
  AssetClassForm,
  AssetClassLogRating,
  AssetClassSet,
  AssetClassRating,
  DraftAction,
  CostForm,
  CostAction,
  CostDetailAction,
  CostDetailForm,
  ReInvestmentForm,
  ReInvestmentAction,
  ReInvestmentPublish,
  CreateProductSupervisor,
  EarningPercentageForm,
  EquityBufferForm,
  AssetClassDetailForm,
  EarningPercentageAction,
  EquityBufferAction,
  CostUpdate,
  FeedbackAction,
  BeiSet,
  CostFormPaid,
  InvestorList,
  PeriodTable,
};
