import { Popconfirm } from 'antd';
import React from 'react';

const CustomPopconfirm = ({
  placement = 'left',
  onConfirm,
  loading,
  children,
  description,
  title,
}) => {
  return (
    <Popconfirm
      placement={placement}
      title={title}
      description={description}
      onConfirm={onConfirm}
      okText="Ya"
      cancelText="Batal"
      okButtonProps={{
        loading: loading,
        className: 'custom-button-primary',
      }}
      cancelButtonProps={{
        className: 'custom-button-primary-ghost',
      }}>
      {children}
    </Popconfirm>
  );
};

export default CustomPopconfirm;
