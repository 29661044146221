import { Util } from '../../../helper';

const columnsSummary = [
  {
    title: 'Keterangan',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    render: (text, record) => {
      if (record.id62 === 'total-lost-debtor') {
        return <span>{text.toUpperCase()}</span>;
      }
      return text;
    },
    onCell: (record) => ({
      colSpan: record.id62 === 'total-lost-debtor' ? 4 : 1,
    }),
  },
  {
    title: 'Jumlah Debitur',
    dataIndex: 'credit_bill',
    key: 'credit_bill',
    align: 'center',
    render: Util.renderNumber,
    onCell: (record) => ({
      colSpan: record.id62 === 'total-lost-debtor' ? 0 : 1,
    }),
  },
  {
    title: 'NPV(Rp)',
    dataIndex: 'cashflow_npv',
    key: 'cashflow_npv',
    align: 'center',
    render: (res, data) => {
      const desc = data?.description.toLowerCase();
      const isDead = desc === 'md' || desc === 'meninggal dunia';
      // client request hide
      if (isDead) return '-';
      return Util.renderNumber(res);
    },
    onCell: (record) => ({
      colSpan: record.id62 === 'total-lost-debtor' ? 0 : 1,
    }),
  },
  {
    title: 'Cashflow(Rp)',
    dataIndex: 'cashflow_earning',
    key: 'cashflow_earning',
    align: 'center',
    render: Util.renderNumber,
    onCell: (record) => ({
      colSpan: record.id62 === 'total-lost-debtor' ? 0 : 1,
    }),
  },
  {
    title: 'Total Angsuran(Rp)',
    dataIndex: 'total_installment_earning',
    key: 'total_installment_earning',
    align: 'center',
    render: (text, record) => {
      if (record.id62 === 'total-lost-debtor') {
        return <span>{Util.renderNumber(text)}</span>;
      }
      return Util.renderNumber(text);
    },
  },
];

export { columnsSummary };
