export const kuponColumn = [
  [
    {
      text: 'Payment',
      dataIndex: 'payment',
      key: 'payment',
    },
    {
      text: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      text: 'Beg.Balance',
      dataIndex: 'begBalance',
      key: 'begBalance',
    },
    {
      text: 'Principal Repaid',
      dataIndex: 'pincipalRepaid',
      key: 'pincipalRepaid',
    },
    {
      text: 'Interest Payment',
      dataIndex: 'interestPayment',
      key: 'interestPayment',
    },
    {
      text: 'End.Balance',
      dataIndex: 'endBalance',
      key: 'endBalance',
    },
  ],
];

export const kuponData = [
  {
    payment: 1,
    date: '4 December 2019',
    begBalance: '480.400.000.000',
    principalRepaid: '38.432.000.000',
    interestPayment: '11.109.250.000',
    endBalance: '441.969.000.000',
  },
  {
    payment: 2,
    date: '4 March 2020',
    begBalance: '441.968.000.000',
    principalRepaid: '38.432.000.000',
    interestPayment: '10.220.510.000',
    endBalance: '403.536.000.000',
  },
];
export const ebaColumn = [
  [
    {
      text: 'Senior Tranches dari EBA',
      dataIndex: 'kelas',
      key: 'kelas',
    },
    {
      text: 'Jenis Penawaran',
      dataIndex: 'jenis',
      key: 'jenis',
    },
    {
      text: 'Pokok Awal',
      dataIndex: 'pokokAwal',
      key: 'pokokAwal',
    },
    {
      text: 'Hasil Investasi',
      dataIndex: 'hasilInvestasi',
      key: 'hasilInvestasi',
    },
    {
      text: 'Tanggal Pelunasan',
      dataIndex: 'tanggalPelunasan',
      key: 'tanggalPelunasan',
    },
  ],
];

export const ebaData = [
  {
    kelas: 'A1',
    jenis: 'Umum',
    pokokAwal: '480.400.000.000',
    hasilInvestasi: '9.25%',
    tanggalPelunasan: '4 September 2022',
  },
  {
    kelas: 'A2',
    jenis: 'Terbatas',
    pokokAwal: '700.000.000.000',
    hasilInvestasi: '10%',
    tanggalPelunasan: '4 September 2022',
  },
];

export const detailData = [
  {
    title: 'Penerbit',
    data: 'KIK Bahana Bukopin Kumpulan Tagihan Kredit Pensiunan yang Dialihkan',
  },
  {
    title: 'Kreditur Awal & Agen Pengumpul',
    data: 'PT Bank Bukopin Tbk',
  },
  {
    title: 'Debitur',
    data: 'Debitur Kredit Pensiunan',
  },
  {
    title: 'Bank Kustodian',
    data: 'PT Bank Maybank Indonesia',
  },
  {
    title: 'Pencatatan',
    data: 'PT Bank Bukopin Tbk',
  },
  {
    title: 'Peringkat Awal EBA Senior Tranches A',
    data: 'AAA',
  },
  {
    title: 'Lembaga Pemeringkat',
    data: 'PT Pemeringkat Efek Indonesia (Pefindo)',
  },
  {
    title: 'Amorsitasi',
    data: 'Pembayaran pokok setiap 3 bulan',
  },
  {
    title: 'Aset Dasar Investasi',
    data: 'Kumpulan Tagihan Kredit Yang Dialihkan',
  },
  {
    title: 'Nilai Penerbitan',
    data: 'Sebesar-besarnya Rp. 1.300.000.000.000 (Satu Triliun Tiga Ratus Miliar Rupiah)',
  },
  {
    title: 'Periode Bunga',
    data: '30/360',
  },
];
