import React, { useEffect, useState } from 'react';
import { Page } from '../../components';
import Title from '../../components/cost-eba/title';
import Graph from '../../components/cost-eba/graph';
import Cashflow from '../../components/cost-eba/cashflow';
import Buffer from '../../components/cost-eba/buffer';
import { DatePicker, Typography, Empty } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Waterfall, Eba } from '../../service';
import dayjs from 'dayjs';
import { CustomButton } from '../../components/_shared';

const CostEba = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.waterfall);
  const { detail } = useSelector((state) => state.eba);
  const [searchParams] = useSearchParams();
  const isSimulation = searchParams.get('is_simulation');
  const ebaId = searchParams.get('eba-id');
  const ebaName = searchParams.get('eba-name');
  const [yearMonth, setYearMonth] = useState(dayjs(Date()).format('YYYY-MM'));
  const [startDate, setStartDate] = useState(dayjs(detail.first_payment_date).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(
    dayjs(detail.first_payment_date).add(6, 'M').format('YYYY-MM-DD')
  );
  const [filter, setFilter] = useState('');

  const breadcrumbs = [
    { name: `${isSimulation === 'true' ? 'Simulasi Produk' : 'Management Produk'}`, href: '/' },
    { name: 'Cash Waterfall', href: null },
  ];

  useEffect(() => {
    dispatch(
      Waterfall.cashWaterfall({
        yearMonth: yearMonth,
        ebaId: ebaId,
        start_date: startDate,
        end_date: endDate,
      })
    );
    dispatch(Eba.getDetailEba(ebaId));
  }, [yearMonth, ebaId, startDate, endDate]);

  const getMonthWording = (date) => {
    return dayjs(date).format('MMMM YYYY');
  };

  const disabledDate = (date) => {
    const currnt = dayjs(date);
    const firstPayment = dayjs(detail.first_payment_date);
    const endDateEba = dayjs(detail.end_date);
    return currnt < firstPayment || currnt >= endDateEba;
  };

  const rangeChange = (date) => {
    data !== null ? setStartDate(dayjs(date[0]?.$d).format('YYYY-MM-DD')) : setStartDate(null);
    data !== null ? setEndDate(dayjs(date[1]?.$d).format('YYYY-MM-DD')) : setEndDate(null);
  };

  useEffect(() => {
    console.log(endDate);
  }, [endDate]);

  return (
    <Page title="Cost EBA" breadcrumbs={breadcrumbs}>
      <>
        <Title title={`${ebaName} - Cash Waterfall`} />
        <div className="flex flex-row justify-between mb-6 items-center">
          <div className="flex flex-row items-center gap-5">
            <Typography.Paragraph className="font-bold">Pilih Bulan : </Typography.Paragraph>
            <DatePicker
              picker="month"
              onChange={(e) => setYearMonth(dayjs(e?.$d).format('YYYY-MM'))}
              disabledDate={disabledDate}
            />
          </div>
          {/* <img
            src="./icons/settings/settings.svg"
            alt="setting"
            onClick={() => navigate('/management-product/eba-info?page=management-product')}
          /> */}
        </div>
        <Cashflow data={data} />
        <Buffer data={data} />

        {/* ======== Graph ======== */}
        {loading === false && data?.financial_projections?.length !== 0 ? (
          <div className="w-full box-border max-h-[500px] rounded-xl px-6 py-6 bg-white">
            <div className="flex flex-row justify-between">
              <div className="flex flex-col">
                <Typography.Paragraph className="font-bold text-2xl">
                  Proyeksi Keuangan
                </Typography.Paragraph>
                <Typography.Paragraph className="font-medium text-xs">
                  {getMonthWording(yearMonth)}
                </Typography.Paragraph>
              </div>
              <div className="flex flex-row gap-2 items-center justify-end">
                <DatePicker.RangePicker
                  picker="month"
                  value={[dayjs(startDate), dayjs(endDate)]}
                  onChange={(e) => rangeChange(e)}
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                />
              </div>
            </div>
            <div className="h-[300px]">
              <Graph item={data} />
            </div>
            <div className="flex flex-row gap-4 mt-1">
              <CustomButton
                type={filter === '3m' ? 'primary' : 'primary-ghost'}
                onClick={() => {
                  setEndDate(
                    dayjs(startDate || Date())
                      .add(3, 'M')
                      .format('YYYY-MM-DD')
                  );
                  setFilter('3m');
                }}>
                3M
              </CustomButton>
              <CustomButton
                type={filter === '1y' ? 'primary' : 'primary-ghost'}
                onClick={() => {
                  setEndDate(
                    dayjs(startDate || Date())
                      .add(1, 'y')
                      .format('YYYY-MM-DD')
                  );
                  setFilter('1y');
                }}>
                1Y
              </CustomButton>
            </div>
          </div>
        ) : (
          <div className="w-full box-border rounded-xl px-6 pt-6 bg-white">
            <div className="flex flex-row justify-between">
              <div className="flex flex-col">
                <Typography.Paragraph className="font-bold text-2xl">
                  Proyeksi Keuangan
                </Typography.Paragraph>
                <Typography.Paragraph className="font-medium text-xs">
                  {yearMonth}
                </Typography.Paragraph>
              </div>
              <div className="flex flex-row gap-2 items-center justify-end">
                <DatePicker.RangePicker onChange={(e) => rangeChange(e)} format="YYYY-MM-DD" />
                {/* <DatePicker
                  disabledDate={disabledDate}
                  onChange={(e) =>
                    e?.$d !== undefined
                      ? setStartDate(dayjs(e?.$d).format('YYYY-MM-DD'))
                      : setStartDate(null)
                  }
                  placeholder="Start Date"
                />
                -
                <DatePicker
                  disabledDate={disabledDate}
                  onChange={(e) =>
                    e?.$d !== undefined
                      ? setEndDate(dayjs(e?.$d).format('YYYY-MM-DD'))
                      : setEndDate(null)
                  }
                  placeholder="End Date"
                /> */}
              </div>
            </div>
            <div className="py-3">
              <Empty />
            </div>
          </div>
        )}
      </>
    </Page>
  );
};

export default CostEba;
