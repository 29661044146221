import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import usePage from '../../../hooks/usePage';
import { CustomButton } from '../../_shared';

export default function CostAction({ name, period, is_paid }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  const ebaName = searchParams.get('eba-name');
  const isSimulation = searchParams.get('is_simulation');
  const status = searchParams.get('status');

  const { isProduct } = usePage();
  return (
    <CustomButton
      type="primary-ghost"
      onClick={() =>
        navigate(
          // eslint-disable-next-line prettier/prettier
          `/management-product/cost/1?page=${
            isProduct ? '' : 'management-'
          }product&eba-name=${ebaName}&eba-id=${ebaId}&name=${name}&period=${period}&status=${status}&is_paid=${is_paid}${
            isSimulation === 'true' ? '&is_simulation=true' : ''
          }`
        )
      }>
      Lihat Detail
    </CustomButton>
  );
}
