/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Form, DatePicker, Select } from 'antd';
import { CustomButton, CustomInput, CustomInputNumber } from '../../_shared';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Report, Investment, Eba } from '../../../service';

const AddExternalReport = ({ ebaId }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [selectedClass, setSelectedClass] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');

  const { external_payment_date, external_prepayment } = useSelector((state) => state.report);
  const { items: classEba } = useSelector((state) => state.investment);
  const { detail } = useSelector((state) => state.eba);

  const disabledDate = (current) => {
    // disable before eba start date and after current month
    const ebaStartDate = dayjs(detail?.start_date);
    const afterCurrentMonth = current && current > dayjs().endOf('month');

    return current && (current < ebaStartDate || afterCurrentMonth);
  };

  useEffect(() => {
    dispatch(Eba.getDetailEba(ebaId));
  }, []);

  useEffect(() => {
    dispatch(Report.GetPaymentDateExternal(ebaId));
    dispatch(Report.GetPrePaymentExternal(ebaId));
    dispatch(Investment.getClass({ ebaId }));
  }, [ebaId]);

  useEffect(() => {
    external_payment_date.find((element) => {
      if (element.next_payment.eba_class === selectedClass) {
        setSelectedDate(element.next_payment.tanggal_pembayaran);
      }
    });
    classEba.find((grade) => {
      if (grade.name === selectedClass) {
        setSelectedGrade(grade.id62);
      }
    });
  }, [selectedClass]);

  const initialValue = {
    tanggal_efektif: '',
    tanggal_masa_penawaran_umum_awal: '',
    jenis_tagihan: '',
    dividen_rp: '',
    dividen_non_rp: '',
    dividen_percentage: '',
    jumlah_total_nilai_tagihan_awal_non_rp: '',
    total_nilai_agunan_awal_rp: '',
    total_nilai_agunan_awal_non_rp: '',
    nilai_tagihan_sampai_akhir_periode_laporan_non_rp: '',
    nilai_agunan_sampai_akhir_periode_laporan_rp: '',
    nilai_agunan_sampai_akhir_periode_laporan_non_rp: '',
    persentase_rerata_tertimbang_nilai_tagihan: '',
    akumulasi_nilai_tagihan_dihapus_buku_rp: '',
    akumulasi_nilai_tagihan_dihapus_buku_non_rp: '',
    akumulasi_nilai_pemulihan_hapus_buku_rp: '',
    akumulasi_nilai_pemulihan_hapus_buku_non_rp: '',
    akumulasi_tagihan_bunga_tertagih_non_rp: '',
    akumulasi_tagihan_denda_rp: '',
    akumulasi_tagihan_denda_non_rp: '',
    akumulasi_tagihan_pokok_non_rp: '',
    akumulasi_total_tagihan_non_rp: '',
    jumlah_debitur_terlambat: '',
    persentase_debitur_terlambat: '',
    akumulasi_nilai_tagihan_bunga_debitur_terlambat_rp: '',
    akumulasi_nilai_tagihan_bunga_debitur_terlambat_non_rp: '',
    akumulasi_nilai_tagihan_pokok_debitur_terlambat_rp: '',
    akumulasi_nilai_tagihan_pokok_debitur_terlambat_non_rp: '',
    persentase_nilai_tagihan_bunga_terlambat: '',
    persentase_nilai_tagihan_pokok_terlambat: '',
    // tanggal_pembayaran: '',
    akumulasi_bunga_non_rp: '',
    akumulasi_pokok_non_rp: '',
    akumulasi_hasil_investasi_non_rp: '',
    akumulasi_koleksi_denda_rp: '',
    akumulasi_koleksi_denda_non_rp: '',
    akumulasi_pembayaran_biaya_non_rp: '',
    total_hasil_investasi_non_rp: '',
  };

  const submitForm = (e) => {
    const payload = {
      eba_id62: ebaId,
      grade_id62: selectedGrade,
      date: dayjs(e.date).format('YYYY-MM-DD'),
      tanggal_efektif: e.tanggal_efektif ? dayjs(e.tanggal_efektif).format('YYYY-MM-DD') : '',
      tanggal_masa_penawaran_umum_awal: e.tanggal_masa_penawaran_umum_awal
        ? dayjs(e.tanggal_masa_penawaran_umum_awal).format('YYYY-MM-DD')
        : '',
      jenis_tagihan: e.jenis_tagihan,
      dividen_rp: e.dividen_rp,
      dividen_non_rp: e.dividen_non_rp,
      dividen_percentage: e.dividen_percentage,
      jumlah_total_nilai_tagihan_awal_non_rp: e.jumlah_total_nilai_tagihan_awal_non_rp,
      total_nilai_agunan_awal_rp: e.total_nilai_agunan_awal_rp,
      total_nilai_agunan_awal_non_rp: e.total_nilai_agunan_awal_non_rp,
      nilai_tagihan_sampai_akhir_periode_laporan_non_rp:
        e.nilai_tagihan_sampai_akhir_periode_laporan_non_rp,
      nilai_agunan_sampai_akhir_periode_laporan_rp: e.nilai_agunan_sampai_akhir_periode_laporan_rp,
      nilai_agunan_sampai_akhir_periode_laporan_non_rp:
        e.nilai_agunan_sampai_akhir_periode_laporan_non_rp,
      persentase_rerata_tertimbang_nilai_tagihan: e.persentase_rerata_tertimbang_nilai_tagihan,
      akumulasi_nilai_pembayaran_dipercepat_non_rp: parseInt(
        external_prepayment?.total_akumulasi.toString().replace(',', '')
      ),
      akumulasi_nilai_tagihan_dihapus_buku_rp: e.akumulasi_nilai_tagihan_dihapus_buku_rp,
      akumulasi_nilai_tagihan_dihapus_buku_non_rp: e.akumulasi_nilai_tagihan_dihapus_buku_non_rp,
      akumulasi_nilai_pemulihan_hapus_buku_rp: e.akumulasi_nilai_pemulihan_hapus_buku_rp,
      akumulasi_nilai_pemulihan_hapus_buku_non_rp: e.akumulasi_nilai_pemulihan_hapus_buku_non_rp,
      akumulasi_tagihan_bunga_tertagih_non_rp: e.akumulasi_tagihan_bunga_tertagih_non_rp,
      akumulasi_tagihan_denda_rp: e.akumulasi_tagihan_denda_rp,
      akumulasi_tagihan_denda_non_rp: e.akumulasi_tagihan_denda_non_rp,
      akumulasi_tagihan_pokok_non_rp: e.akumulasi_tagihan_pokok_non_rp,
      akumulasi_total_tagihan_non_rp: e.akumulasi_total_tagihan_non_rp,
      jumlah_debitur_terlambat: e.jumlah_debitur_terlambat,
      persentase_debitur_terlambat: e.persentase_debitur_terlambat,
      akumulasi_nilai_tagihan_bunga_debitur_terlambat_rp:
        e.akumulasi_nilai_tagihan_bunga_debitur_terlambat_rp,
      akumulasi_nilai_tagihan_bunga_debitur_terlambat_non_rp:
        e.akumulasi_nilai_tagihan_bunga_debitur_terlambat_non_rp,
      akumulasi_nilai_tagihan_pokok_debitur_terlambat_rp:
        e.akumulasi_nilai_tagihan_pokok_debitur_terlambat_rp,
      akumulasi_nilai_tagihan_pokok_debitur_terlambat_non_rp:
        e.akumulasi_nilai_tagihan_pokok_debitur_terlambat_non_rp,
      persentase_nilai_tagihan_bunga_terlambat: e.persentase_nilai_tagihan_bunga_terlambat,
      persentase_nilai_tagihan_pokok_terlambat: e.persentase_nilai_tagihan_pokok_terlambat,
      tanggal_pembayaran: selectedDate,
      akumulasi_bunga_non_rp: e.akumulasi_bunga_non_rp,
      akumulasi_pokok_non_rp: e.akumulasi_pokok_non_rp,
      akumulasi_hasil_investasi_non_rp: e.akumulasi_hasil_investasi_non_rp,
      akumulasi_koleksi_denda_rp: e.akumulasi_koleksi_denda_rp,
      akumulasi_koleksi_denda_non_rp: e.akumulasi_koleksi_denda_non_rp,
      akumulasi_pembayaran_biaya_non_rp: e.akumulasi_pembayaran_biaya_non_rp,
      total_hasil_investasi_non_rp: e.total_hasil_investasi_non_rp,
    };
    dispatch(Report.CreateExternalReport(payload));
  };

  const dateChanged = async (date) => {
    const payload = {
      ebaId,
      period: dayjs(date).format('YYYY-MM'),
    };
    dispatch(Report.GetReportExternalDetail(payload))
      .unwrap()
      .then((res) => {
        const lists = res?.data?.results;

        if (lists.length > 0) {
          const data = {
            ...lists[0].informasi_pembayaran,
            ...lists[0].informasi_tagihan,
            ...lists[0].informasi_umum,
            ...lists[0].investor,
            ...lists[0].profile,
          };

          for (const key in initialValue) {
            if (key === 'tanggal_efektif' || key === 'tanggal_masa_penawaran_umum_awal') {
              form.setFieldValue(`${key}`, data[key] ? dayjs(data[key]) : '');
            } else {
              form.setFieldValue(`${key}`, data[key]);
            }
          }
        }
      });
  };

  return (
    <>
      <Form
        form={form}
        requiredMark={false}
        initialValues={initialValue}
        onFinish={submitForm}
        layout="vertical"
        id="myForm"
        className="mt-3">
        <div className="bg-white p-4 rounded-[10px] mb-6 flex flex-col gap-6">
          {/* Profile ===================== */}
          <div id="profile" className="border-b-1 border-[#F1F3F6]">
            <Form.Item
              label="Pilih Bulan"
              name="date"
              rules={[
                {
                  required: true,
                  message: 'Field tidak boleh kosong',
                },
              ]}>
              <DatePicker
                placeholder="Pilih Bulan"
                picker="month"
                disabledDate={disabledDate}
                onChange={dateChanged}
              />
            </Form.Item>
            <p className="text-base font-bold text-[#E70A09] mb-6">Profile</p>
            <div className="grid grid-cols-2 gap-4">
              <Form.Item
                label="Tanggal Efektif"
                name="tanggal_efektif"
                dependencies={['end_date', 'start_date']}>
                <DatePicker
                  className="custom-input rounded py-[18px] bg-white px-[24px] w-full"
                  placeholder="dd/MM/YYYY"
                  showToday={false}
                />
              </Form.Item>
              <Form.Item
                label="Tanggal Masa Penawaran Umum"
                name="tanggal_masa_penawaran_umum_awal"
                dependencies={['end_date', 'start_date']}>
                <DatePicker
                  className="custom-input rounded py-[18px] bg-white px-[24px] w-full"
                  placeholder="dd/MM/YYYY"
                  showToday={false}
                />
              </Form.Item>
            </div>
            <Form.Item label="Jenis Tagihan" name="jenis_tagihan">
              <CustomInput
                placeholder="Masukan jenis tagihan"
                type="text"
                className="py-[18px] px-[24px]"
              />
            </Form.Item>
          </div>

          {/* Informasi Umum ===================== */}
          <div id="Information" className="border-b-1 border-[#F1F3F6]">
            <p className="text-base font-bold text-[#E70A09] mb-6">Informasi Umum</p>
            <div className="grid grid-cols-3 gap-4">
              <Form.Item label="Dividen" name="dividen_rp">
                <CustomInputNumber
                  min={0}
                  placeholder="Dividen Rp"
                  className="py-[14px] px-[24px] w-full"
                  maxLength={19}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
              <Form.Item label=" " name="dividen_non_rp">
                <CustomInputNumber
                  min={0}
                  placeholder="Dividen Non-Rp"
                  className="py-[14px] px-[24px] w-full"
                  maxLength={19}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
              <Form.Item label=" " name="dividen_percentage">
                <CustomInput placeholder="Dividen %" type="text" className="py-[18px] px-[24px]" />
              </Form.Item>
            </div>
            <Form.Item
              label="Jumlah Total Nilai Tagihan Awal (Non-Rp)"
              name="jumlah_total_nilai_tagihan_awal_non_rp">
              <CustomInputNumber
                min={0}
                placeholder="Masukan jumlah total nilai tagihan awal"
                className="py-[14px] px-[24px] w-full"
                maxLength={19}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <div className="grid grid-cols-2 gap-4">
              <Form.Item label="Total Nilai Agunan Awal" name="total_nilai_agunan_awal_rp">
                <CustomInputNumber
                  min={0}
                  placeholder="Masukan Nilai Agunan Awal (Rp)"
                  className="py-[14px] px-[24px] w-full"
                  maxLength={19}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
              <Form.Item label=" " name="total_nilai_agunan_awal_non_rp">
                <CustomInputNumber
                  min={0}
                  placeholder="Masukan Nilai Agunan Awal (Non-Rp)"
                  className="py-[14px] px-[24px] w-full"
                  maxLength={19}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </div>
            <Form.Item
              label="Nilai Tagihan Sampai Dengan Akhir Periode Laporan (Non-Rp)"
              name="nilai_tagihan_sampai_akhir_periode_laporan_non_rp">
              <CustomInputNumber
                min={0}
                placeholder="Masukan nilai tagihan sampai dengan akhir periode laporan"
                className="py-[14px] px-[24px] w-full"
                maxLength={19}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <div className="grid grid-cols-2 gap-4">
              <Form.Item
                label="Nilai Agunan Sampai Dengan Akhir Periode Laporan"
                name="nilai_agunan_sampai_akhir_periode_laporan_rp">
                <CustomInputNumber
                  min={0}
                  placeholder="Masukan nilai agunan sampai dengan akhir periode laporan (Rp)"
                  className="py-[14px] px-[24px] w-full"
                  maxLength={19}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
              <Form.Item label=" " name="nilai_agunan_sampai_akhir_periode_laporan_non_rp">
                <CustomInputNumber
                  min={0}
                  placeholder="Masukan nilai agunan sampai dengan akhir periode laporan (Non-Rp)"
                  className="py-[14px] px-[24px] w-full"
                  maxLength={19}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </div>
            <Form.Item
              label="Persentase Rata-Rata Tertimbang Nilai Tagihan Terhadap Nilai Agunan Sampai Dengan Akhir Periode Laporan"
              name="persentase_rerata_tertimbang_nilai_tagihan">
              <CustomInput
                placeholder="Masukan persentase rata-rata tertimbang nilai tagihan terhadap nilai agunan"
                type="text"
                className="py-[18px] px-[24px]"
              />
            </Form.Item>
            {/* <Form.Item
              label="Akumulasi Nilai Pembayaran Dipercepat (Prepayment) Sampai Dengan Akhir Periode Laporan"
              name="akumulasi_nilai_pembayaran_dipercepat">
              <CustomInput
                placeholder="Masukan akumulasi nilai pembayaran dipercepat (Non-Rp)"
                type="text"
                className="py-[18px] px-[24px]"
              />
            </Form.Item> */}
            <div>
              Akumulasi Nilai Tagihan Yang Dihapus Buku Sampai Dengan Akhir Periode Laporan
              <div className="grid grid-cols-2 gap-4 pt-2">
                <Form.Item name="akumulasi_nilai_tagihan_dihapus_buku_rp">
                  <CustomInputNumber
                    min={0}
                    placeholder="Masukan akumulasi nilai tagihan yang dihapus buku (Rp)"
                    className="py-[14px] px-[24px] w-full"
                    maxLength={19}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item name="akumulasi_nilai_tagihan_dihapus_buku_non_rp">
                  <CustomInputNumber
                    min={0}
                    placeholder="Masukan akumulasi nilai pemulihan hapus buku (Non-Rp)"
                    className="py-[14px] px-[24px] w-full"
                    maxLength={19}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </div>
            </div>
            <div>
              Akumulasi Nilai Pemulihan Hapus Buku Sampai Dengan Akhir Periode Laporan
              <div className="grid grid-cols-2 gap-4 pt-2">
                <Form.Item name="akumulasi_nilai_pemulihan_hapus_buku_rp">
                  <CustomInputNumber
                    min={0}
                    placeholder="Masukan akumulasi nilai pemulihan hapus buku (Rp)"
                    className="py-[14px] px-[24px] w-full"
                    maxLength={19}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item name="akumulasi_nilai_pemulihan_hapus_buku_non_rp">
                  <CustomInputNumber
                    min={0}
                    placeholder="Masukan akumulasi nilai pemulihan hapus buku (Non-Rp)"
                    className="py-[14px] px-[24px] w-full"
                    maxLength={19}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* Informasi Tagihan ============ */}
          <div id="profile" className="border-b-1 border-[#F1F3F6]">
            <Form.Item
              label="Pilih Senior Tranches"
              name="class"
              rules={[
                {
                  required: true,
                  message: 'Field tidak boleh kosong',
                },
              ]}>
              <Select
                placeholder="Pilih Senior Tranches"
                options={classEba?.map((kelas) => ({
                  label: kelas.name,
                  value: kelas.name,
                }))}
                className="flex items-center bg-white border h-[48px] rounded-lg"
                onChange={setSelectedClass}
              />
            </Form.Item>
            <p className="text-base font-bold text-[#E70A09] mb-6">Informasi Tagihan</p>
            <Form.Item
              label="Akumulasi Tagihan Bunga Yang Tertagih Sampai Dengan akhir Periode Laporan (Non-Rp)"
              name="akumulasi_tagihan_bunga_tertagih_non_rp">
              <CustomInputNumber
                min={0}
                placeholder="Masukan nilai akumulasi tagihan bunga yang tertagih (Non-Rp)"
                className="py-[14px] px-[24px] w-full"
                maxLength={19}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <div>
              Akumulasi Tagihan Denda Yang Tertagih Sampai Dengan Akhir Periode Laporan
              <div className="grid grid-cols-2 gap-4 pt-2">
                <Form.Item name="akumulasi_tagihan_denda_rp">
                  <CustomInputNumber
                    min={0}
                    placeholder="Masukan nilai akumulasi denda bunga yang tertagih (Rp)"
                    className="py-[14px] px-[24px] w-full"
                    maxLength={19}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item name="akumulasi_tagihan_denda_non_rp">
                  <CustomInputNumber
                    min={0}
                    placeholder="Masukan nilai akumulasi denda bunga yang tertagih (Non-Rp)"
                    className="py-[14px] px-[24px] w-full"
                    maxLength={19}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item
              label="Akumulasi Tagihan Pokok Yang Tertagih Sampai Dengan Akhir Periode Laporan	(Non-Rp)"
              name="akumulasi_tagihan_pokok_non_rp">
              <CustomInputNumber
                min={0}
                placeholder="Masukan nilai akumulasi tagihan pokok yang tertagih (Non-Rp)"
                className="py-[14px] px-[24px] w-full"
                maxLength={19}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              label="Akumulasi Total Tagihan Yang Tertagih Sampai Dengan Akhir Periode Laporan (Non-Rp)"
              name="akumulasi_total_tagihan_non_rp">
              <CustomInputNumber
                min={0}
                placeholder="Masukan nilai akumulasi total tagihan  yang tertagih (Non-Rp)"
                className="py-[14px] px-[24px] w-full"
                maxLength={19}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              label="Jumlah Debitur yang terlambat sampai dengan akhir periode pelaporan (Pihak)"
              name="jumlah_debitur_terlambat">
              <CustomInputNumber
                min={0}
                placeholder="Masukan jumlah debitur yang terlambat"
                className="py-[14px] px-[24px] w-full"
                maxLength={19}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              label="Persentase Jumlah Debitur yang terlambat terhadap Total Jumlah Debitur sampai dengan akhir periode pelaporan"
              name="persentase_debitur_terlambat">
              <CustomInput
                placeholder="Masukan persentase jumlah debitur yang terlambat"
                type="text"
                className="py-[18px] px-[24px]"
              />
            </Form.Item>
            <div>
              <p className="text-sm mb-2">
                Akumulasi Nilai Tagihan Bunga Debitur yang terlambat sampai dengan akhir Periode
                Laporan
              </p>
              <div className="grid grid-cols-2 gap-4">
                <Form.Item name="akumulasi_nilai_tagihan_bunga_debitur_terlambat_rp">
                  <CustomInputNumber
                    min={0}
                    placeholder="Masukan nilai tagihan bunga debitur (Rp)"
                    className="py-[14px] px-[24px] w-full"
                    maxLength={19}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item name="akumulasi_nilai_tagihan_bunga_debitur_terlambat_non_rp">
                  <CustomInputNumber
                    min={0}
                    placeholder="Masukan nilai tagihan bunga debitur (Non-Rp)"
                    className="py-[14px] px-[24px] w-full"
                    maxLength={19}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </div>
            </div>
            <div>
              <p className="text-sm mb-2">
                Akumulasi Nilai Tagihan Pokok Debitur yang terlambat sampai dengan akhir Periode
                Laporan
              </p>
              <div className="grid grid-cols-2 gap-4">
                <Form.Item name="akumulasi_nilai_tagihan_pokok_debitur_terlambat_rp">
                  <CustomInputNumber
                    min={0}
                    placeholder="Masukan nilai tagihan pokok debitur tagihan yang terlambat (Rp)"
                    className="py-[14px] px-[24px] w-full"
                    maxLength={19}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item name="akumulasi_nilai_tagihan_pokok_debitur_terlambat_non_rp">
                  <CustomInputNumber
                    min={0}
                    placeholder="Masukan nilai tagihan pokok debitur tagihan yang terlambat (Non-Rp)"
                    className="py-[14px] px-[24px] w-full"
                    maxLength={19}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item
              label="Persentase Nilai Tagihan Bunga yang terlambat terhadap Total Nilai Tagihan Bunga sampai dengan akhir periode pelaporan (%)"
              name="persentase_nilai_tagihan_bunga_terlambat">
              <CustomInput
                placeholder="Masukan persentase nilai tagihan bunga yang terlambat"
                type="text"
                className="py-[18px] px-[24px]"
              />
            </Form.Item>
            <Form.Item
              label="Persentase Nilai Tagihan Pokok yang terlambat terhadap Total Nilai Tagihan Pokok sampai dengan akhir periode pelaporan (%)"
              name="persentase_nilai_tagihan_pokok_terlambat">
              <CustomInput
                placeholder="Masukan persentase nilai tagihan pokok yang terlambat"
                type="text"
                className="py-[18px] px-[24px]"
              />
            </Form.Item>
          </div>

          {/* Informasi Pembayaran ===================== */}
          <div id="Informasi Pembayaran" className="m-0">
            <p className="text-base font-bold text-[#E70A09] mb-6">Informasi Pembayaran</p>
            {/* <Form.Item
              label="Pilih kelas"
              name="tanggal_pembayaran"
              dependencies={['end_date', 'start_date']}>
              <Select
                placeholder="Pilih Senior Tranches"
                className="flex items-center bg-white border h-[48px] rounded-lg"
                options={external_payment_date.map((payment) => ({
                  label: payment.next_payment.eba_class,
                  value: payment.next_payment.tanggal_pembayaran,
                }))}
              />
            </Form.Item> */}
            <Form.Item
              label="Akumulasi Bunga / Dividen dibayarkan kepada pemegang EBA (Non-Rp) 	"
              name="akumulasi_bunga_non_rp">
              <CustomInputNumber
                min={0}
                placeholder="Masukan nilai akumulasi bunga yang dibayarkan kepada pemegang EBA"
                className="py-[14px] px-[24px] w-full"
                maxLength={19}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              label="Akumulasi Pokok dibayarkan kepada pemegang EBA	(Non-Rp)"
              name="akumulasi_pokok_non_rp">
              <CustomInputNumber
                min={0}
                placeholder="Masukan nilai akumulasi pokok yang dibayarkan kepada pemegang EBA "
                className="py-[14px] px-[24px] w-full"
                maxLength={19}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              label="Akumulasi Hasil Investasi dibayarkan kepada pemegang EBA (Non-Rp)"
              name="akumulasi_hasil_investasi_non_rp">
              <CustomInputNumber
                min={0}
                placeholder="Masukan nilai akumulasi hasil investasi yang dibayarkan kepada pemegang EBA"
                className="py-[14px] px-[24px] w-full"
                maxLength={19}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <div className="grid grid-cols-2 gap-4">
              <Form.Item
                label="Akumulasi koleksi denda EBA dibayarkan kepada pemegang EBA	"
                name="akumulasi_koleksi_denda_rp">
                <CustomInputNumber
                  min={0}
                  placeholder="Masukan nilai akumulasi denda EBA dibayarkan kepada pemegang EBA (Rp)"
                  className="py-[14px] px-[24px] w-full"
                  maxLength={19}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
              <Form.Item label=" " name="akumulasi_koleksi_denda_non_rp">
                <CustomInputNumber
                  min={0}
                  placeholder="Masukan nilai akumulasi denda EBA dibayarkan kepada pemegang EBA (Non-Rp)"
                  className="py-[14px] px-[24px] w-full"
                  maxLength={19}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </div>
            <Form.Item
              label="Akumulasi Pembayaran Biaya-Biaya (non-Rp)"
              name="akumulasi_pembayaran_biaya_non_rp">
              <CustomInputNumber
                min={0}
                placeholder="Masukan nilai akumulasi pembayaran biaya-biaya"
                className="py-[14px] px-[24px] w-full"
                maxLength={19}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              label="Total Hasil Investasi, Denda, Bunga/Dividen dan Pokok yang dibayarkan setelah dikurangi Biaya-Biaya (non-Rp)	"
              name="total_hasil_investasi_non_rp">
              <CustomInputNumber
                min={0}
                placeholder="Masukan total hasil investasi "
                className="py-[14px] px-[24px] w-full"
                maxLength={19}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex justify-end">
          <CustomButton type="primary" form="myForm" htmlType="submit">
            Input Reporting
          </CustomButton>
        </div>
      </Form>
    </>
  );
};

export default AddExternalReport;
