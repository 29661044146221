import ManagementProduct from './managementProduct';
import CreateProductAndSimulation from './create-product';
import UploadAsset from './upload-asset/UploadAsset';
import EquityBuffer from './equityBuffer';
import AssetClass from './assetClass';
import AssetClassDetail from './assetClassDetail';
import Cost from './cost';
import CostSummary from './costSummary';
import ReInvestmentPage from './reInvestment';
import DetailFeedback from './detailFeedback';
import InitialCost from './InitialCost';
import AddInvestor from './addInvestor';
import ReinvestmentSummary from './reinvestmentSummary';

export default {
  ManagementProduct,
  CreateProductAndSimulation,
  UploadAsset,
  EquityBuffer,
  AssetClass,
  AssetClassDetail,
  Cost,
  ReInvestmentPage,
  DetailFeedback,
  InitialCost,
  AddInvestor,
  ReinvestmentSummary,
  CostSummary,
};
