import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

// Get List :

const listReportInternal = createAsyncThunk(
  'get/listReportInternal',
  async (body, { rejectWithValue }) => {
    const { limit = 10, offset = 0, id = '', dateFilter } = body;
    try {
      return await API.get({
        url: `report/internal/date/?limit=${limit}&offset=${offset}&report=${id}&eba_id62=${id}${
          dateFilter === undefined ? '' : `&year=${dateFilter}`
        }`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const listReportExternal = createAsyncThunk(
  'get/listReportExternal',
  async (body, { rejectWithValue }) => {
    const { limit = 10, offset = 0, id = '', dateFilter } = body;
    try {
      return await API.get({
        url: `report/external/date/?limit=${limit}&offset=${offset}&report=${id}&eba_id62=${id}${
          dateFilter === undefined ? '' : `&year=${dateFilter}`
        }`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Detail :

const ReportInternalDetail = createAsyncThunk(
  'get/detailReportInternal',
  async (body, { rejectWithValue }) => {
    const { limit = 10, offset = 0, id = '', dateFilter } = body;
    try {
      return await API.get({
        url: `report/internal/?limit=${limit}&offset=${offset}&report=${id}&eba_id62=${id}${
          dateFilter === undefined ? '' : `&period_date=${dateFilter}`
        }`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Detail :

const ReportInternalLogHistory = createAsyncThunk(
  'get/ReportInternalLogHistory',
  async (body, { rejectWithValue }) => {
    const { limit = 10, offset = 0, id = '', dateFilter } = body;
    try {
      return await API.get({
        url: `report/internal/log_history/?limit=${limit}&offset=${offset}&report=${id}&eba_id62=${id}${
          dateFilter === undefined ? '' : `&period_date=${dateFilter}`
        }`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Create Reporting Internal

const CreateInternalReport = createAsyncThunk(
  'post/CreateInternalReport',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({ url: `report/internal/create-ibpa/`, payload });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Create Reporting External

const CreateExternalReport = createAsyncThunk(
  'post/CreateExternalReport',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({ url: `report/external/`, payload });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const GetReportExternalDetail = createAsyncThunk(
  'get/ReportExternalDetail',
  async (body, { rejectWithValue }) => {
    const { period = '', ebaId = '', limit = 10, offset = 0 } = body;
    try {
      return await API.get({
        url: `report/external/?limit=${limit}&offset=${offset}&eba_id62=${ebaId}&period_date=${period}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const ReportExternalLogHistory = createAsyncThunk(
  'get/ReportExternalLogHistory',
  async (body, { rejectWithValue }) => {
    const { limit = 10, offset = 0, ebaId = '', period = '' } = body;
    try {
      return await API.get({
        url: `report/external/log_history/?limit=${limit}&offset=${offset}&eba_id62=${ebaId}&period_date=${period}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const DeleteReportInternal = createAsyncThunk(
  'delete/reportInternal',
  async (id, { rejectWithValue }) => {
    try {
      return await API.deleteRequest({ url: `report/internal/${id}/` });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const ExportExcelInternal = createAsyncThunk(
  'get/exportReportInternal',
  async (payload, { rejectWithValue }) => {
    const { report, ebaId, period_date, period } = payload;
    try {
      return API.get({
        url: `report/export_excel/?report=${report}&eba_id62=${ebaId}&period_date=${period_date}${
          period ? `&period=${period}` : ''
        }`,
        isDownload: true,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const ExportExcelExternal = createAsyncThunk(
  'get/exportReportInternal',
  async (payload, { rejectWithValue }) => {
    const { report, ebaId, period_date, period } = payload;
    try {
      return API.get({
        url: `report/export_excel/?report=${report}&eba_id62=${ebaId}&period_date=${period_date}${
          period ? `&period=${period}` : ''
        }`,
        isDownload: true,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const GetPaymentDateExternal = createAsyncThunk(
  'get/reportExternalDate',
  async (ebaId, { rejectWithValue }) => {
    try {
      return API.get({ url: `report/external/payment_date/?eba_id62=${ebaId}` });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const GetPrePaymentExternal = createAsyncThunk(
  'get/reportExternalPrepayment',
  async (ebaId, { rejectWithValue }) => {
    try {
      return API.get({ url: `report/external/prepayment/?eba_id62=${ebaId}` });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default {
  listReportInternal,
  listReportExternal,
  ReportInternalDetail,
  ReportInternalLogHistory,
  CreateInternalReport,
  CreateExternalReport,
  GetReportExternalDetail,
  ReportExternalLogHistory,
  DeleteReportInternal,
  ExportExcelInternal,
  ExportExcelExternal,
  GetPaymentDateExternal,
  GetPrePaymentExternal,
};
