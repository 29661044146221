import { Checkbox, Form, Modal, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Format } from '../../../helper';
import { Cost } from '../../../service';
import { CustomButton, CustomInput } from '../../_shared';
import { setIsBulkUpdating } from '../../../store/cost';

export default function CostUpdate({ open, onClose, record }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');

  const handleModalClose = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (open) {
      setAmount(record?.amount);
      form.setFieldValue('period', record?.period);
      form.setFieldValue('percentage', parseInt(record?.percentage).toString() || '');
      form.setFieldValue('is_paid', record?.is_paid);
    }
  }, [open]);

  const onCalculate = (value) => {
    if (value !== '') {
      const item = form.getFieldValue('period');
      const payload = {
        cost_id62: record?.cost?.cost_id62,
        period: item,
        percent: parseFloat(value),
      };

      dispatch(Cost.calculateAmount(payload))
        .unwrap()
        .then((res) => {
          const amount = res?.data?.result || 0;
          setAmount(amount);
        });
    }
  };

  const onSubmit = (e) => {
    const payload = {
      period: e?.period,
      percentage: e?.percentage,
      is_paid: e?.is_paid,
      amount: amount,
    };

    // set isBulkUpdating to false to let notification showing
    setIsBulkUpdating(false);
    dispatch(Cost.updateCostDetail({ id: record?.id62, payload })).then(() => {
      dispatch(Cost.getCostDetail({ id: ebaId, limit: 0, offset: 0, page: 1 }));
      handleModalClose();
    });
  };

  return (
    <Modal
      title={<h3 className="text-xl font-bold">Informasi Biaya Detail</h3>}
      open={open}
      onCancel={() => handleModalClose()}
      destroyOnClose={true}
      centered
      footer={[
        <CustomButton key="back" type="secondary" onClick={() => handleModalClose()}>
          Batal
        </CustomButton>,
        <CustomButton key="submit" type="primary" form="myForm" htmlType="submit">
          Simpan
        </CustomButton>,
      ]}
      width={'50%'}>
      <Form id="myForm" layout="vertical" form={form} requiredMark={false} onFinish={onSubmit}>
        <div className="flex flex-column items-center gap-3 mb-5">
          <div className="flex items-center justify-evenly w-full">
            <Typography.Paragraph className="text-base text-center w-1/3">
              Periode
            </Typography.Paragraph>
            <Typography.Paragraph className="text-base text-center w-1/3">
              Amount
            </Typography.Paragraph>
            <Typography.Paragraph className="text-base text-center w-1/3">
              Sudah Dibayar
            </Typography.Paragraph>
          </div>
        </div>
        <div className="flex flex-column items-center gap-x-2.5 mb-5">
          <div className="flex items-baseline justify-evenly w-full gap-3">
            <Form.Item
              className="text-center w-1/3"
              name="period"
              rules={[
                {
                  required: true,
                  message: 'Field tidak boleh kosong',
                },
              ]}>
              <CustomInput type="text" className="py-[18px] px-[24px]" />
            </Form.Item>
            <div className="flex justify-start items-center gap-5 w-1/3">
              <Form.Item
                className="text-center w-1/3"
                name="percentage"
                rules={[
                  {
                    required: true,
                    message: 'Field tidak boleh kosong',
                  },
                ]}>
                <CustomInput
                  type="text"
                  className="py-[18px] px-[24px]"
                  suffix={<span>%</span>}
                  onChange={(e) => onCalculate(e.target.value)}
                />
              </Form.Item>
              <Typography.Paragraph className="text-base font-bold" style={{ marginTop: '-25px' }}>
                Rp.{Format.numberFormat(amount)}
              </Typography.Paragraph>
            </div>
            <Form.Item name="is_paid" valuePropName="checked" className="text-center w-1/3">
              <Checkbox>Ya</Checkbox>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
