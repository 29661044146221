import { DatePicker, Form, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Util } from '../../../helper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomInput, Page } from '../../../components';
import { CustomButton, CustomInputNumber } from '../../../components/_shared';
import { Eba } from '../../../service';

export default function CreateSimulation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSupervisor } = Util.role();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id') || null;
  const ebaName = searchParams.get('eba-name');
  const isSimulation = searchParams.get('is_simulation') || true;

  const initialValues = {
    name: '',
    issuance_value: '',
    basic_asset_price: '',
    first_payment_date: '',
    start_date: '',
    end_date: '',
    servicer: '',
    provider: '',
    related: '',
  };

  const breadcrumbs = [
    { name: 'Simulasi Produk', href: '/simulation' },
    {
      name: ebaId ? 'Edit Produk' : 'Draft Produk',
      href: ebaId ? null : '/management-product/?status=1',
      isHide: isSupervisor,
    },
  ];

  const onSubmit = (e) => {
    if (ebaId) {
      const payload = {
        name: e.name,
        issuance_value: e.issuance_value,
        first_payment_date: dayjs(e.first_payment_date?.$d).format('YYYY-MM-DD'),
        start_date: dayjs(e.start_date?.$d).format('YYYY-MM-DD'),
        end_date: dayjs(e.end_date?.$d).format('YYYY-MM-DD'),
        servicer_name: e.servicer,
        facility_provider: e.provider,
        other_related_parties: e.related,
        is_simulation: isSimulation,
      };

      dispatch(Eba.editEba({ payload: payload, id: ebaId }))
        .unwrap()
        .then((res) => {
          if (res.status_code === 200) {
            form.resetFields();
            navigate(
              `/management-product/upload-assets?page=management-product&eba-name=${res?.data?.name}&eba-id=${ebaId}&is_simulation=true`
            );
          }
        });
    } else {
      const payload = {
        name: e.name,
        issuance_value: e.issuance_value,
        first_payment_date: dayjs(e.first_payment_date?.$d).format('YYYY-MM-DD'),
        start_date: dayjs(e.start_date?.$d).format('YYYY-MM-DD'),
        end_date: dayjs(e.end_date?.$d).format('YYYY-MM-DD'),
        servicer: e.servicer,
        provider: e.provider,
        related: e.related,
        servicer_name: e.servicer,
        facility_provider: e.provider,
        other_related_parties: e.related,
        is_simulation: isSimulation,
      };

      dispatch(Eba.createEba(payload))
        .unwrap()
        .then((res) => {
          if (res.status_code === 201) {
            form.resetFields();
            navigate(
              `/management-product/upload-assets?page=management-product&eba-name=${res?.data?.name}&eba-id=${res?.data?.id62}&is_simulation=true`
            );
          }
        });
    }
  };

  React.useEffect(() => {
    if (ebaId) {
      dispatch(Eba.getDetailEba(ebaId))
        .unwrap()
        .then((res) => {
          form.setFieldValue('name', res?.data?.name);
          form.setFieldValue('issuance_value', res?.data?.issuance_value);
          form.setFieldValue('first_payment_date', dayjs(res?.data?.first_payment_date));
          form.setFieldValue('start_date', dayjs(res?.data?.start_date));
          form.setFieldValue('end_date', dayjs(res?.data?.end_date));
          form.setFieldValue('servicer', res?.data?.servicer_name);
          form.setFieldValue('provider', res?.data?.facility_provider);
          form.setFieldValue('related', res?.data?.other_related_parties);
        });
    }
  }, [ebaId]);

  const disabledStartDate = (date) => {
    const end = form.getFieldValue('end_date');
    if (!end) return;
    return dayjs(date) >= dayjs(end);
  };

  const disabledEndDate = (date) => {
    const start = form.getFieldValue('start_date');
    if (!start) return;
    return dayjs(date) < dayjs(start);
  };

  const disabledDate = (date) => {
    const end = form.getFieldValue('end_date');
    const start = form.getFieldValue('start_date');
    if (!end || !start) return;
    return dayjs(date) < dayjs(start) || dayjs(date) >= dayjs(end);
  };

  return (
    <Page title="Input Produk" breadcrumbs={breadcrumbs}>
      <Typography.Paragraph className="font-bold text-3xl">
        {ebaId ? 'Edit Simulasi Produk' : 'Buat Simulasi Produk'}
      </Typography.Paragraph>
      <Form
        requiredMark={false}
        initialValues={initialValues}
        layout="vertical"
        className="mt-3"
        form={form}
        onFinish={onSubmit}>
        <div className="grid grid-cols-3 gap-4">
          <Form.Item
            label="Nama Simulasi"
            name="name"
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
            ]}>
            <CustomInput
              placeholder="Tuliskan nama simulasi"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
          <Form.Item
            label="Nilai Penerbitan"
            name="issuance_value"
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject();
                  }
                  if (isNaN(value)) {
                    return Promise.reject('The input is not a number');
                  }
                  return Promise.resolve();
                },
              }),
            ]}>
            <CustomInputNumber
              placeholder="Contoh : 1.3000.000.000,00"
              className="py-[14px] px-[24px] w-full"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            label="Tanggal Pembayaran Pertama"
            name="first_payment_date"
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
            ]}>
            <DatePicker
              className="custom-input rounded py-[18px] bg-white px-[24px] w-full"
              disabledDate={disabledDate}
              placeholder="dd/MM/YYYY"
            />
          </Form.Item>
          <Form.Item label="Nama Servicer" name="servicer">
            <CustomInput
              placeholder="Masukan nama servicer"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
          <Form.Item label="Sarana Peningkatan Kredit" name="provider">
            <CustomInput
              placeholder="Masukan sarana peningkatan kredit"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
          <Form.Item label="Nama Pihak Terkait" name="related">
            <CustomInput
              placeholder="Masukan nama pihak terkait"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="ant-form-item-row">
            <div className="ant-form-item-label">
              <label htmlFor="role">Periode Waktu</label>
            </div>
            <div className="ant-form-control-input-content flex items-center justify-between gap-3">
              <Form.Item
                className="w-full"
                name="start_date"
                rules={[
                  {
                    required: true,
                    message: 'Field tidak boleh kosong',
                  },
                ]}>
                <DatePicker
                  className="custom-input rounded py-[18px] bg-white px-[24px] w-full"
                  disabledDate={disabledStartDate}
                  placeholder="dd/MM/YYYY"
                />
              </Form.Item>
              <span className="text-sm" style={{ marginTop: '-24px' }}>
                Sampai
              </span>
              <Form.Item
                className="w-full"
                name="end_date"
                rules={[
                  {
                    required: true,
                    message: 'Field tidak boleh kosong',
                  },
                ]}>
                <DatePicker
                  className="custom-input rounded py-[18px] bg-white px-[24px] w-full"
                  disabledDate={disabledEndDate}
                  placeholder="dd/MM/YYYY"
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="flex flex-row-reverse">
          {isSupervisor ? (
            <>
              <div className="flex flex-row-reverse mt-5 gap-5">
                <CustomButton
                  type="primary"
                  onClick={() => {
                    navigate(
                      `/management-product/upload-assets?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}&is_simulation=true`
                    );
                  }}>
                  Next
                </CustomButton>
                <CustomButton type="primary-ghost" htmlType="submit">
                  Edit
                </CustomButton>
              </div>
            </>
          ) : (
            <CustomButton type="primary" htmlType="submit">
              Simpan
            </CustomButton>
          )}
        </div>
      </Form>
    </Page>
  );
}
