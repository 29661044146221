import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PoolOfAsset } from '../../service';
import Table from '../table';
import { CustomInput } from '../_shared';
import CustomTabs from '../_shared/custom-tabs';
import { columnsDebitur } from './tableConfig';
import Title from './title';

const Record = ({ approvalId }) => {
  const dispatch = useDispatch();
  const { record, loading, loadingDownload } = useSelector((state) => state.poa);

  const [page, setPage] = useState({ limit: 10, offset: 0, current: 1 });
  const [tabClick, setTabClick] = useState(1);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const payload = {
      approvalId,
      limit: page.limit,
      offset: page.offset,
      active: tabClick === 1,
      search: search,
    };
    dispatch(PoolOfAsset.record(payload));
  }, [page, tabClick, search]);

  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
  };

  const Debitur = ({ dataSource }) => {
    return (
      <Table.TableGlobal
        columns={columnsDebitur}
        dataSource={dataSource}
        pageSize={page.limit}
        totalData={record?.count}
        scroll={{ x: 1000 }}
        loading={loading}
        onChangePagination={paginationChange}
        current={page.current}
      />
    );
  };

  const tabsItem = [
    {
      label: 'Debitur Aktif',
      children: <Debitur dataSource={record?.results} />,
    },
    {
      label: 'Debitur Tidak Aktif',
      children: <Debitur dataSource={record?.results} />,
    },
  ];

  const onTabClick = (e) => {
    setTabClick(e);
  };

  const onSearch = _.debounce((e) => {
    setSearch(e);
  }, 500);

  const onClickDownload = () => {
    dispatch(PoolOfAsset.downloadRecord(approvalId));
  };

  return (
    <Fragment>
      <Title title="Record" onClickDownload={onClickDownload} loading={loadingDownload} />
      <CustomInput
        placeholder="Cari"
        className="h-[48px] mb-10"
        onChange={(e) => onSearch(e.target.value)}
      />
      <CustomTabs tabsItem={tabsItem} onTabClick={onTabClick} />
    </Fragment>
  );
};

export default Record;
