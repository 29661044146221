const KEY = {
  DASHBOARD: 'dashboard',
  MANAGEMENT_PRODUCT: 'management-product',
  EBA_INFO: 'management-product/eba-info',
  EQUITY_BUFFER: 'management-product/equity-buffer',
  ASSET_CLASS: 'management-product/asset-class',
  UPLOAD_ASSETS: 'management-product/upload-assets',
  COST: 'management-product/cost',
  COST_SUMMARY: 'management-product/cost-summary',
  FEEDBACK_PRODUCT: 'management-product/detail-feedback',
  RE_INVESTMENT: 'management-product/re-investment',
  MANAGEMENT_USER: 'management-user',
  MANAGEMENT_EBA: 'management-eba',
  MANAGEMENT_ASSETS: 'management-asset',
  ASSETS: 'assets',
  UPLOAD_DATA_ORIGINATOR: 'assets/upload-data-originator',
  UPDATE_DATA: 'assets/update-data',
  REQUEST_ACQUISITION: 'assets/request-acquisition',
  FEEDBACK_ACQUISITION: 'assets/feedback-acquisition',
  PREVIEW_ACQUISITION: 'preview-data-acquisition',
  DETAIL_ACQUISITION: 'detail-data-acquisition',
  SUMMARY_ACQUISITION_ORIGINATOR: 'summary-acquisition-originator',
  DETAIL_ACQUISITION_ORIGINATOR: 'detail-data-acquisition',
  COST_EBA: 'cost-eba',
  POOL_OF_ASSET: 'pool-of-asset',
  REPORTS: 'reports',
  REPORT_INTERNAL: 'reports/reports-internal',
  REPORT_EXTERNAL: 'reports/reports-external',
  LOGBOOK: 'logbook',
  SIMULATION: 'simulation',
};

const LABEL = {
  DASHBOARD: 'Dashboard',
  MANAGEMENT_PRODUCT: 'Manajemen Produk',
  MANAGEMENT_USER: 'Management User',
  MANAGEMENT_ASSETS: 'Management Asset',
  ASSETS: 'Assets',
  COST_EBA: 'Cash Waterfall',
  REPORTS: 'Reporting',
  LOGBOOK: 'Logbook',
  SIMULATION: 'Simulasi Produk',
  EBA_INFO: 'EBA Info',
  EQUITY_BUFFER: 'Equity Tranche',
  ASSET_CLASS: 'Senior Tranches',
  COST: 'Biaya',
  RE_INVESTMENT: 'Re-Investment',
  UPLOAD_ASSETS: 'Upload Asset',
};

export default { KEY, LABEL };
