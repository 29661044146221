import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const getList = createAsyncThunk('get/reinvestment', async ({ ebaId }, { rejectWithValue }) => {
  try {
    return await API.get({
      url: `re-investment/?eba_id62=${ebaId}`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getDetail = createAsyncThunk('get/detail', async ({ id62 }, { rejectWithValue }) => {
  try {
    return await API.get({
      url: `re-investment/${id62}/`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const deleteDetail = createAsyncThunk('delete/detail', async ({ id }, { rejectWithValue }) => {
  try {
    return await API.deleteRequest({
      url: `re-investment/${id}/`,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

const CreateReinvestment = createAsyncThunk(
  'post/re-investment',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({ url: `re-investment/`, payload });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const UpdateReinvestment = createAsyncThunk(
  'put/updateReinvestment',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      return await API.put({
        url: `re-investment/${id}/`,
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const withdrawReinvestment = createAsyncThunk(
  'post/withdrawReinvestment',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({ url: `re-investment/withdrawal/`, payload });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default {
  getList,
  getDetail,
  deleteDetail,
  CreateReinvestment,
  UpdateReinvestment,
  withdrawReinvestment,
};
