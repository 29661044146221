import { Typography } from 'antd';
import React, { useEffect } from 'react';
import { CustomTable, Page } from '../../components';
import { costDetailColumn } from './components/tableConfig';
import { CustomButton } from '../../components/_shared';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Cost } from '../../service';
import usePage from '../../hooks/usePage';
import { Util } from '../../helper';
import { MdFileDownload } from 'react-icons/md';

export default function InitialCost() {
  const { isProduct } = usePage();
  const { isAdmin, isSupervisor } = Util.role();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { items, itemTotal, loading, paginationSync } = useSelector((state) => state.cost);

  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  const ebaName = searchParams.get('eba-name');
  const period = searchParams.get('period');
  const name = searchParams.get('name');
  const isSimulation = searchParams.get('is_simulation');
  const status = searchParams.get('status');

  const breadcrumbs = [
    {
      name: `${isSimulation === 'true' ? 'Simulasi Produk' : 'Management Produk'}`,
      href: '/management-product',
    },
    {
      name: 'Draft Produk',
      href: '/management-product/?status=1',
      isHide: isProduct || isSupervisor,
    },
    { name: 'Management Asset', href: null, isHide: !isProduct },
    {
      name: `Biaya  ${ebaName}`,
      // eslint-disable-next-line prettier/prettier
      href: `/management-product/cost?page=${isProduct ? '' : 'management-'}product&eba-id=${ebaId}&eba-name=${ebaName}`,
    },
    { name: 'Detail Biaya', href: null },
  ];

  const fetchData = ({ limit, offset, page = 1 }) => {
    dispatch(Cost.getCostDetailByMonth({ id: ebaId, period: period, limit, offset, page: page }));
  };

  const paginationChange = (page, pageSize) => {
    fetchData({ limit: 5, offset: (page - 1) * pageSize, page });
  };

  useEffect(() => {
    if (ebaId !== null) {
      fetchData({ limit: 5, offset: 0 });
    }
  }, []);

  const showAction = () => {
    if (isAdmin) {
      return true;
    }
    if (isSupervisor) {
      return isProduct && status != '0';
    }
  };

  const { loadingDownload } = useSelector((state) => state.report);

  const downloadExcel = () => {
    dispatch(
      Cost.DownloadCostDetail({
        ebaId: ebaId,
        period: period,
        ebaName: ebaName,
      })
    );
  };

  return (
    <Page
      title="Biaya Awal"
      breadcrumbs={breadcrumbs}
      updated={items?.length > 0 ? items[items?.length - 1].updated_at : ''}>
      <div className="flex justify-between items-center">
        <div>
          <Typography.Paragraph className="font-bold text-3xl">Biaya {name}</Typography.Paragraph>
          <Typography.Paragraph className="text-base">
            Dibawah ini merupakan informasi detail mengenai cost yang {!isProduct && 'akan'}{' '}
            dikeluarkan pada periode tertentu
          </Typography.Paragraph>
        </div>
        <CustomButton
          type="primary"
          onClick={downloadExcel}
          loading={loadingDownload}
          className="
            group
            justify-center
            items-center
            flex
            space-x-2">
          <MdFileDownload size={20} className="text-white group-hover:text-primary" />
          Download Excel
        </CustomButton>
      </div>
      <div className="mb-5 mt-5">
        <CustomTable.TableGlobal
          dataSource={items}
          columns={costDetailColumn(showAction())}
          loading={loading}
          keyRow="amount"
          pagination={{
            pageSize: 5,
            onChange: paginationChange,
            current: paginationSync?.page,
            total: itemTotal,
            showSizeChanger: true,
          }}
        />
      </div>
      <div className="flex flex-row-reverse items-center gap-5">
        <CustomButton
          type="primary-ghost"
          onClick={() =>
            navigate(
              // eslint-disable-next-line prettier/prettier
              `/management-product/cost?page=${isProduct ? '' : 'management-'}product&eba-id=${ebaId}&eba-name=${ebaName}${isSimulation === 'true' ? '&is_simulation=true' : ''}`
            )
          }>
          Kembali
        </CustomButton>
      </div>
    </Page>
  );
}
