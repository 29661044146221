import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Util } from '../../helper';
import { PeriodicPayment } from '../../service';

const initialState = {
  upload: {},
  appropiate: {},
  detailSummary: {},
  inappropiate: {},
  unupdated: {},
  history: {},
  summary: {},
  summaryPeriodic: [],
  summaryUpdateData: {
    loss: 0,
    loss_count: 0,
    cash_inflow: 0,
  },
  loading: false,
  loadingDetailSummary: false,
  download: {},
  loadingDownload: false,
};

export const periodicSlice = createSlice({
  name: 'periodic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // upload periodic payment
    builder.addCase(PeriodicPayment.periodicUpload.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(PeriodicPayment.periodicUpload.fulfilled, (state, action) => {
      state.loading = false;
      state.upload = action.payload.data;
    });
    builder.addCase(PeriodicPayment.periodicUpload.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Upload Data',
        description:
          action?.payload?.periodic_payment?.toString() ||
          action?.payload?.message?.id ||
          action?.payload?.file_id62?.toString(),
      });
      state.loading = false;
    });

    // appropiate periodic payment
    builder.addCase(PeriodicPayment.periodicSummary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(PeriodicPayment.periodicSummary.fulfilled, (state, action) => {
      state.loading = false;
      state.appropiate = action.payload.data;
    });
    builder.addCase(PeriodicPayment.periodicSummary.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Dapatkan Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // inappropiate periodic payment
    builder.addCase(PeriodicPayment.periodicFail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(PeriodicPayment.periodicFail.fulfilled, (state, action) => {
      state.loading = false;
      state.inappropiate = action.payload.data;
    });
    builder.addCase(PeriodicPayment.periodicFail.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Dapatkan Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // Summary Periodic
    builder.addCase(PeriodicPayment.summaryPeriodicPayment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(PeriodicPayment.summaryPeriodicPayment.fulfilled, (state, action) => {
      state.loading = false;
      state.summary = action.payload.data;
      state.summaryUpdateData = {
        loss: action.payload?.data?.loss,
        loss_count: action.payload?.data?.loss_count,
        cash_inflow: action.payload?.data?.cash_inflow,
      };
      state.summaryPeriodic = action.payload?.data?.result;
    });
    builder.addCase(PeriodicPayment.summaryPeriodicPayment.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed to Get Data',
        description: Util.errorHandle(action.payload),
      });
      state.loading = false;
      state.summaryPeriodic = [];
      state.summary = {};
    });

    // Summary Periodic
    builder.addCase(PeriodicPayment.historyPeriodicPayment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(PeriodicPayment.historyPeriodicPayment.fulfilled, (state, action) => {
      state.history = action.payload?.data;
      state.loading = false;
    });
    builder.addCase(PeriodicPayment.historyPeriodicPayment.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed to Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // appropiate periodic detail
    builder.addCase(PeriodicPayment.periodicSummaryDetail.pending, (state) => {
      state.loadingDetailSummary = true;
    });
    builder.addCase(PeriodicPayment.periodicSummaryDetail.fulfilled, (state, action) => {
      state.loadingDetailSummary = false;
      state.detailSummary = action.payload.data;
    });
    builder.addCase(PeriodicPayment.periodicSummaryDetail.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Dapatkan Data',
        description: action?.payload?.message?.id,
      });
      state.loadingDetailSummary = false;
    });

    // unupdated data
    builder.addCase(PeriodicPayment.unupdatedPeriodicPayment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(PeriodicPayment.unupdatedPeriodicPayment.fulfilled, (state, action) => {
      state.loading = false;
      state.unupdated = action.payload.data;
    });
    builder.addCase(PeriodicPayment.unupdatedPeriodicPayment.rejected, (state) => {
      state.loading = false;
    });

    // Download
    builder.addCase(PeriodicPayment.DownloadExcel.pending, (state) => {
      state.loadingDownload = true;
    });
    builder.addCase(PeriodicPayment.DownloadExcel.fulfilled, (state, action) => {
      state.loadingDownload = false;
      state.download = action.payload.data;
    });
    builder.addCase(PeriodicPayment.DownloadExcel.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Download Data',
        description: action?.payload?.message?.id,
      });
      state.loadingDownload = false;
    });
  },
});

export default periodicSlice.reducer;
