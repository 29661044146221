import React from 'react';
import PropTypes from 'prop-types';
import { CustomTable } from '..';
import { CustomInput } from '../_shared';
import { DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

function UserLogData({
  search,
  items,
  total,
  columns,
  loading,
  paginationSync,
  setSearch,
  fetchData,
  setTimestamps,
  timeStamps, // Add this to receive the timestamps
}) {
  const convertTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const [date, time] = timestamp.split(' ');
    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}T${time}`;
  };

  const paginationChange = (page, pageSize) => {
    const [timestamp_from, timestamp_to] = timeStamps; // Destructure timestamps
    fetchData({
      search,
      limit: 5,
      offset: (page - 1) * pageSize,
      page,
      timestamp_from: convertTimestamp(timestamp_from) || '',
      timestamp_to: convertTimestamp(timestamp_to) || '',
    });
  };

  const handleKeboardEnter = (e) => {
    if (e.key === 'Enter') {
      setSearch(e.target.value);
    }
  };

  return (
    <>
      <div className="flex space-x-4">
        <CustomInput
          prefix={<SearchOutlined />}
          className="p-3 mt-2 mb-5"
          placeholder="Search (ID, Nama User. Peran, Aktivitas Terakhir)"
          onKeyUp={handleKeboardEnter}
        />
        <DatePicker.RangePicker
          className="h-[47.5px] mt-[8px] mb-[20px]"
          showTime
          format={'DD/MM/YYYY HH:mm:ss'}
          onChange={(value, dateString) => {
            setTimestamps(dateString);
          }}
          placeholder={['Timestamp Awal', 'Timestamp Akhir']}
        />
      </div>
      <CustomTable.TableGlobal
        dataSource={items}
        columns={columns}
        loading={loading}
        pagination={{
          pageSize: 5,
          onChange: paginationChange,
          current: paginationSync?.page,
          total: total,
          showSizeChanger: false,
        }}
        keyRow="id"
      />
    </>
  );
}

UserLogData.propTypes = {
  search: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  paginationSync: PropTypes.object.isRequired,
  setSearch: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  setTimestamps: PropTypes.func.isRequired,
  timeStamps: PropTypes.array.isRequired, // Add this to PropTypes
};

export default UserLogData;
