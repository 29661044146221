import React, { useEffect } from 'react';
import { Form, Modal } from 'antd';
import { CustomButton, CustomInput, CustomInputNumber, CustomNotification } from '../../_shared';
import { Investment } from '../../../service';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

export default function AssetClassDetailForm({ item, open, onClose }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();
  const gradeId = searchParam.get('grade-id');

  useEffect(() => {
    // ========= USING API =========
    if (open) {
      dispatch(Investment.getInvestorByID({ id: item.id62 }))
        .then((res) => {
          form.setFieldValue('name', res?.payload?.data?.name);
          form.setFieldValue('sid', res?.payload?.data?.sid);
          form.setFieldValue('identity_number', res?.payload?.data?.identity_number);
          form.setFieldValue('npwp', res?.payload?.data?.npwp);
          form.setFieldValue('passport', res?.payload?.data?.passport);
          form.setFieldValue('account_bank_name', res?.payload?.data?.account_bank_name);
          form.setFieldValue('account_bank_code', res?.payload?.data?.account_bank_code);
          form.setFieldValue('amount', res?.payload?.data?.amount);
          form.setFieldValue('account_status', res?.payload?.data?.account_status);
          form.setFieldValue('balance_status', res?.payload?.data?.balance_status);
          form.setFieldValue('percentage', res?.payload?.data?.percentage);
        })
        .catch(() => {});
    }
  }, [item, open]);

  const refetch = () => {
    dispatch(Investment.investorList({ gradeId: gradeId }));
  };

  const onSubmit = (e) => {
    const body = {
      id: item.id62,
      payload: {
        name: e.name,
        sid: e.sid,
        identity_number: e.identity_number,
        passport: e.passport,
        npwp: e.npwp,
        account_bank_name: e.account_bank_name,
        account_bank_code: e.account_bank_code,
        amount: e.amount,
        account_status: e.account_status,
        balance_status: e.balance_status,
        percentage: e.percentage,
      },
    };
    dispatch(Investment.UpdateInvestorDetail(body))
      .unwrap()
      .then((res) => {
        refetch();
        CustomNotification({
          type: 'success',
          message: 'Edit Success',
          description: res?.message?.id,
        });
      });
    onClose();
  };

  return (
    <Modal
      title="Edit Investor"
      open={open}
      onCancel={() => onClose()}
      destroyOnClose={true}
      footer={[
        <CustomButton key="back" type="secondary" onClick={() => onClose()}>
          Cancel
        </CustomButton>,
        <CustomButton key="submit" type="primary" form="myForm" htmlType="submit">
          Simpan
        </CustomButton>,
      ]}
      width={'50%'}>
      <Form id="myForm" layout="vertical" form={form} requiredMark={false} onFinish={onSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <Form.Item label="Name" name="name">
            <CustomInput placeholder="Name" type="text" className="py-[18px] px-[24px]" />
          </Form.Item>
          <Form.Item
            label="Single Investor Identification"
            name="sid"
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
            ]}>
            <CustomInput
              placeholder="Single Investor Identification"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
          <Form.Item
            label="Identity Number"
            name="identity_number"
            validateFirst
            rules={[
              {
                max: 16,
                message: 'Maksimal 16 karakter',
              },
              {
                pattern: /^\d+$/,
                message: 'Hanya masukkan angka',
              },
            ]}>
            <CustomInput
              placeholder="Identity Number"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
          <Form.Item
            label="NPWP"
            name="npwp"
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
            ]}>
            <CustomInput placeholder="NPWP" type="text" className="py-[18px] px-[24px]" />
          </Form.Item>
          <Form.Item label="Passport" name="passport">
            <CustomInput placeholder="Passport" type="text" className="py-[18px] px-[24px]" />
          </Form.Item>
          <Form.Item
            label="Account Bank Name"
            name="account_bank_name"
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
            ]}>
            <CustomInput placeholder="Account Bank Name" className="py-[14px] px-[24px] w-full" />
          </Form.Item>
          <Form.Item
            label="Account Bank Code"
            name="account_bank_code"
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
            ]}>
            <CustomInput placeholder="Account Bank Code" className="py-[14px] px-[24px] w-full" />
          </Form.Item>
          <Form.Item
            label="Amount"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
            ]}>
            <CustomInputNumber
              placeholder="Amount"
              className="py-[14px] px-[24px] w-full"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            label="Account Status"
            name="account_status"
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
            ]}>
            <CustomInput placeholder="Account Status" className="py-[14px] px-[24px] w-full" />
          </Form.Item>
          <Form.Item
            label="Balance Status"
            name="balance_status"
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
            ]}>
            <CustomInput placeholder="Balance Status" className="py-[14px] px-[24px] w-full" />
          </Form.Item>
          <Form.Item
            label="Percentage"
            name="percentage"
            validateFirst
            rules={[
              {
                required: true,
                message: 'Field tidak boleh kosong',
              },
              () => ({
                validator(_, value) {
                  if (!Number(value) && value === 0) {
                    return Promise.reject(new Error('Hanya masukkan angka'));
                  }
                  if (value < 1) {
                    return Promise.reject(new Error(`Minimal 1% `));
                  }
                  if (value > 100) {
                    return Promise.reject(new Error(`Maksimal 100%`));
                  }
                  return Promise.resolve();
                },
              }),
            ]}>
            <CustomInput placeholder="Percentage" className="py-[14px] px-[24px] w-full" />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
