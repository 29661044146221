import { Typography } from 'antd';

const StatusColor = ({ status }) => {
  let className = 'bg-green_bg text-green_text';
  switch (status.value) {
    case 0:
    case 1:
      className = 'bg-blue_bg text-blue_text';
      break;
    case 2:
    case 4:
    case 6:
      className = 'bg-[#FFF2F2] text-primary';
      break;

    default:
      break;
  }
  return (
    <div className={`py-3 px-5 rounded-[50px] w-[240px] ${className}`}>
      <Typography.Text className={`font-bold ${className}`}>{status.text}</Typography.Text>
    </div>
  );
};

export default StatusColor;
