import React, { useEffect } from 'react';
import Table from '../../table';
import {
  columnsProfileLogExternal,
  columnsInfoUmumLogExternal,
  columnsInfoTagihLogExternal,
  columnsInfoTambahanLogExternal,
  columnsInvestor,
} from './columns';
import { CustomTabs } from '../..';
import { useDispatch, useSelector } from 'react-redux';
import { Report } from '../../../service';

const LogHistoryExternal = ({ ebaId, period }) => {
  const dispatch = useDispatch();
  const { external_log_history } = useSelector((state) => state.report);
  useEffect(() => {
    const body = {
      limit: 10,
      offset: 0,
      ebaId: ebaId,
      period: period,
    };
    dispatch(Report.ReportExternalLogHistory(body));
  }, []);

  const tabsItem = [
    {
      key: 0,
      label: 'Profile',
      children: (
        <Table.TableGlobal
          columns={columnsProfileLogExternal}
          dataSource={external_log_history}
          className="table-preview-data"
          bordered
          keyRow="profile"
        />
      ),
    },
    {
      key: 1,
      label: 'Informasi Umum',
      children: (
        <Table.TableGlobal
          columns={columnsInfoUmumLogExternal}
          dataSource={external_log_history}
          className="table-preview-data"
          bordered
          keyRow="informasi_umum"
          scroll={{
            x: 6500,
          }}
        />
      ),
    },
    {
      key: 2,
      label: 'Informasi Tagihan',
      children: (
        <Table.TableGlobal
          columns={columnsInfoTagihLogExternal}
          dataSource={external_log_history}
          className="table-preview-data"
          bordered
          keyRow="informasi_tagihan"
          scroll={{
            x: 1700,
          }}
        />
      ),
    },
    {
      key: 3,
      label: 'Informasi Tambahan',
      children: (
        <Table.TableGlobal
          columns={columnsInfoTambahanLogExternal}
          dataSource={external_log_history}
          className="table-preview-data"
          bordered
          keyRow="informasi_pembayaran"
          scroll={{
            x: 1200,
          }}
        />
      ),
    },
    {
      key: 4,
      label: 'Investor',
      children: (
        <Table.TableGlobal
          columns={columnsInvestor}
          dataSource={external_log_history}
          className="table-preview-data"
          scroll={{ x: 1200 }}
          bordered
        />
      ),
    },
  ];

  return (
    <>
      <CustomTabs tabsItem={tabsItem} />
    </>
  );
};

export default LogHistoryExternal;
