import { Date, Util } from '../../../helper';

export const columnsInformasiPembayaran = [
  {
    title: 'Kode EBA',
    dataIndex: 'informasi_pembayaran',
    align: 'center',
    fixed: true,
    width: 180,
    render: (record) => record?.kode_eba,
  },
  {
    title: 'Kelas EBA',
    dataIndex: 'informasi_pembayaran',
    align: 'center',
    width: 180,
    render: (record) => record?.kelas_eba,
  },
  {
    title: 'Akumulasi Hasil Investasi dan Denda sampai dengan akhir periode laporan',
    dataIndex: 'informasi_pembayaran',
    align: 'center',
    width: 180,
    render: (record) => Util.renderNumber(record?.akumulasi_hasil_investasi_dan_denda),
  },
  {
    title: 'Tanggal Pembayaran Bunga/Dividen',
    dataIndex: 'informasi_pembayaran',
    align: 'center',
    width: 180,
    render: (record) => Date.twoDigitWithDash(record.tanggal_pembayaran),
  },
  {
    title: 'Akumulasi Bunga/Dividen dibayarkan kepada pemegang EBA',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_pembayaran',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_bunga_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_pembayaran',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_bunga_non_rp),
      },
    ],
  },
  {
    title: 'Akumulasi Pokok dibayarkan kepada pemegang EBA ',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_pembayaran',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_pokok_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_pembayaran',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_pokok_non_rp),
      },
    ],
  },
  {
    title: 'Akumulasi Hasil Investasi dibayarkan kepada pemegang EBA',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_pembayaran',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_hasil_investasi_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_pembayaran',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_hasil_investasi_non_rp),
      },
    ],
  },
  {
    title: 'Akumulasi koleksi denda EBA dibayarkan kepada pemegang EBA',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_pembayaran',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_koleksi_denda_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_pembayaran',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_koleksi_denda_non_rp),
      },
    ],
  },

  {
    title: 'Akumulasi Pembayaran Biaya-Biaya',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_pembayaran',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_pembayaran_biaya_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_pembayaran',
        align: 'center',
        width: 180,
        render: (record) => Util.renderNumber(record?.akumulasi_pembayaran_biaya_non_rp),
      },
    ],
  },
  {
    title:
      'Total Hasil Investasi, Denda, Bunga/Dividen dan Pokok yang dibayarkan setelah dikurangi Biaya-Biaya',
    children: [
      {
        title: 'Rp',
        dataIndex: 'informasi_pembayaran',
        align: 'center',
        width: 280,
        render: (record) => Util.renderNumber(record?.total_hasil_investasi_rp),
      },
      {
        title: 'Non Rp',
        dataIndex: 'informasi_pembayaran',
        align: 'center',
        width: 280,
        render: (record) => Util.renderNumber(record?.total_hasil_investasi_non_rp),
      },
    ],
  },
];
