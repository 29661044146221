import { Tooltip, Typography } from 'antd';
import { Cookie, Format } from '.';
import { Constant } from '../const';

const validateFileType = (file, allowedType) => {
  if (!allowedType) return true;
  if (file.type) {
    return allowedType.includes(file.type);
  }
};

const role = () => {
  const user = Cookie.getCookie('user');
  const isAdmin = user.role === Constant.ADMIN;
  const isOriginator = user.role === Constant.ORIGINATOR;
  const isSupervisor = user.role === Constant.SUPERVISOR;

  return { isAdmin, isOriginator, isSupervisor };
};

function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i == 0) return bytes + ' ' + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
}

const renderNumber = (record, config) => (
  <Typography.Paragraph className="text-right">
    {isNaN(record) ? record : Format.numberFormat(Math.round(record))}
    {''}
    {config?.suffix}
  </Typography.Paragraph>
);

const tooltipYear = (month) => {
  const year = (month / 12).toFixed(0);
  const monthOver = month % 12;
  return (
    <Tooltip title={`${year} Tahun ${monthOver} Bulan`}>
      <span>{Number(month / 12).toFixed(1)}</span>
    </Tooltip>
  );
};

const errorHandle = (error) => {
  if (typeof error === 'string') return 'System Error';
  if (error?.message) return error.message?.id;
  if (error.status_code >= 500) {
    return `System Error (${error.status_code})`;
  }
  let err = [];
  Object.values(error).forEach((res) => {
    err.push(res);
  });

  return err.toString();
};

const scrollToElement = (id) => {
  setTimeout(() => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  }, 300);
};

const containsDuplicates = (array) => array.length !== new Set(array).size;

var SI_SYMBOL = ['', 'rb', 'jt', 'M', 'T', 'P', 'E'];

function abbreviateNumber(number) {
  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}

export default {
  validateFileType,
  bytesToSize,
  renderNumber,
  tooltipYear,
  role,
  errorHandle,
  scrollToElement,
  containsDuplicates,
  abbreviateNumber,
};
