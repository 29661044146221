import { Avatar, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import CustomButton from '../../components/_shared/custom-button';
import { Cookie } from '../../helper';
import { useAuth } from '../../hooks/useAuth';
import { User } from '../../service';

const Profile = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initField = [
    { name: 'Name', value: user?.full_name },
    { name: 'Email', value: user?.email },
    { name: 'Password', value: '********' },
    { name: 'Role', value: user?.role },
  ];

  const [field, setField] = useState(initField);
  const [picture, setPicture] = useState('');

  const onClickEdit = () => {
    navigate('/edit-profile');
  };

  useEffect(() => {
    dispatch(User.userProfile({ id: user.user_id62 }))
      .unwrap()
      .then(({ data }) => {
        const updateField = [
          { name: 'Name', value: data?.full_name },
          { name: 'Email', value: data?.email },
          { name: 'Password', value: '********' },
          { name: 'Role', value: data?.role },
        ];
        Cookie.setCookie('user', { ...user, picture: data.picture });
        setField(updateField);
        setPicture(data.picture);
      });
  }, []);

  return (
    <Page title="Profile">
      <div className="rounded-lg overflow-hidden max-w-[75vw]">
        <div className="bg-gradient-to-r from-primary to-[#833C3C] h-52"></div>
        <div className="relative bg-white p-12">
          <div className="flex absolute top-[-50px] w-[90%] justify-between">
            <Avatar
              src={picture}
              size={120}
              shape="square"
              style={{
                backgroundColor: '#00a2ae',
                verticalAlign: 'middle',
                width: '120px',
                height: '120px',
                fontSize: '90px',
                borderRadius: '20px',
              }}>
              {user?.full_name[0]}
            </Avatar>
            <div className="flex flex-col justify-end ">
              <CustomButton type="primary-ghost" onClick={onClickEdit}>
                Edit Profile
              </CustomButton>
            </div>
          </div>
          <div className=" mt-12">
            {field.map((res, i) => (
              <div key={i} className="flex py-4">
                <Typography.Paragraph className="font-bold w-8 sm:w-[100px]">
                  {res.name}
                </Typography.Paragraph>
                <Typography.Paragraph
                  // eslint-disable-next-line prettier/prettier
                  className={`w-full max-w-5xl pb-4 ${
                    field.length !== i + 1 ? 'border-b-1 border-b-gray' : ''
                  }`}>
                  {res.value}
                </Typography.Paragraph>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Profile;
