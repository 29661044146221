import { Typography, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AssetsComponent, CustomTable, Page } from '../../components';
import { Report } from '../../service';
import { columns } from './components/tableConfig';
import { ReportComponent } from '../../components';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const breadcrumbs = [
  { name: 'Management Produk', href: '/management-product' },
  { name: 'Reporting', href: null },
];
const initPage = { limit: 10, offset: 0, current: 1 };

const Reports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [DateFilter, setDateFilter] = useState('');

  const { internal_list, external_list, loading } = useSelector((state) => state.report);

  const [click, setClick] = useState('reports-internal');
  const [page, setPage] = useState(initPage);
  const [isAdd, setIsAdd] = useState(false);
  const [addType, setAddType] = useState('internal');

  const ebaName = searchParams.get('eba-name');
  const ebaId = searchParams.get('eba-id');

  const listCard = [
    { id: 'reports-internal', title: 'Reporting Internal', description: 'Laporan Internal' },
    { id: 'reports-external', title: 'Reporting Eksternal', description: 'Laporan OJK' },
    { id: 'add-report', title: 'Tambah Reporting', description: 'Tambah Reporting' },
  ];

  useEffect(() => {
    const payload = {
      limit: page.limit,
      offset: page.offset,
      id: ebaId,
      dateFilter: DateFilter,
    };
    if (click === listCard[0].id) {
      dispatch(Report.listReportInternal(payload));
      setIsAdd(false);
    } else if (click === listCard[2].id) {
      setIsAdd(true);
    } else {
      dispatch(Report.listReportExternal(payload));
      setIsAdd(false);
    }
  }, [click, page, DateFilter]);

  const onClickCard = (id) => {
    setPage(initPage);
    setClick(id);
  };

  const onClickDetail = (e) => {
    location.search.includes('date')
      ? navigate(
          `/reports/${click}?page=product&eba-id=${ebaId}&eba-name=${ebaName}&status=0&date=${e}`
        )
      : navigate(`/reports/${click + location.search}&date=${e}`);
  };

  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
  };

  const onChangeRadio = (e) => {
    setAddType(e.target.value);
  };

  const exportExcel = (date) => {
    let payload;
    if (click === 'reports-internal') {
      payload = {
        report: 'internal',
        ebaId: ebaId,
        period_date: date,
      };
      dispatch(Report.ExportExcelInternal(payload));
    } else {
      payload = {
        report: 'external',
        ebaId: ebaId,
        period_date: date,
      };
      dispatch(Report.ExportExcelExternal(payload));
    }
  };

  return (
    <Page title="Reports" breadcrumbs={breadcrumbs}>
      <div className="flex justify-between items-center">
        <div>
          <Typography.Paragraph className="font-bold text-3xl">Reporting</Typography.Paragraph>
          <Typography.Paragraph className="font-bold text-xl mt-2">
            {ebaName ? ebaName : null}
          </Typography.Paragraph>
        </div>
        <div className=" flex flex-row items-center gap-4">
          <Typography.Paragraph className="font-bold text-base">Tahun:</Typography.Paragraph>
          <DatePicker
            className="h-full"
            picker="year"
            onChange={(dateString) => {
              dateString ? setDateFilter(dayjs(dateString).format('YYYY')) : setDateFilter('');
              setPage({ ...page, current: 1, offset: 0 });
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-5 my-6">
        {listCard.map((res) => (
          <AssetsComponent.Card
            key={res.id}
            title={res.title}
            description={res.description}
            onClick={() => onClickCard(res.id)}
            isClick={res.id === click}
          />
        ))}
      </div>
      {!isAdd ? (
        <>
          <CustomTable.TableGlobal
            loading={loading}
            dataSource={
              click === 'reports-internal' ? internal_list?.results : external_list?.results
            }
            totalData={click === 'reports-internal' ? internal_list?.count : external_list?.count}
            columns={columns(onClickDetail, click, exportExcel)}
            onChangePagination={paginationChange}
            current={page.current}
            rowKey={(record) =>
              click === 'reports-internal' ? record?.report_date?.start_date : record?.date
            }
          />
        </>
      ) : (
        <>
          <div className="flex flex-col gap-4 mb-6">
            <Typography.Paragraph>Tipe Reporting</Typography.Paragraph>
            <Radio.Group onChange={onChangeRadio} value={addType}>
              <Radio value="internal">Reporting Internal</Radio>
              <Radio value="eksternal">Reporting OJK</Radio>
            </Radio.Group>
          </div>
          {addType === 'internal' ? (
            <ReportComponent.AddInternalReport />
          ) : (
            <ReportComponent.AddExternalReport ebaId={ebaId} />
          )}
        </>
      )}
    </Page>
  );
};

export default Reports;
