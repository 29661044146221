import { Table as ATable, Typography } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Format } from '../../helper';
import { Cashflow } from '../../service';
import Table from '../table';
import { columnsCombinationAcquisition } from './tableConfig';

const round = (value) => Math.round(value);

const SummaryData = ({ totalData, currentPage, data }) => {
  const lastPage = Math.ceil(totalData / 12);
  return (
    currentPage === lastPage && (
      <ATable.Summary.Row className="bg-[#D5D5D5] bg-opacity-40">
        <ATable.Summary.Cell index={0}>Total</ATable.Summary.Cell>
        <ATable.Summary.Cell index={2} align="right">
          <Typography.Text>
            {Format.numberFormat(round(data.total_cashflow_acquisition))}
          </Typography.Text>
        </ATable.Summary.Cell>
        <ATable.Summary.Cell index={1} align="right">
          <Typography.Text>{Format.numberFormat(round(data.total_cashflow_sehat))}</Typography.Text>
        </ATable.Summary.Cell>
        <ATable.Summary.Cell index={3} align="right">
          <Typography.Text>{Format.numberFormat(round(data.total_sum_cashflow))}</Typography.Text>
        </ATable.Summary.Cell>
        <ATable.Summary.Cell index={4} align="right">
          <Typography.Text>{Format.numberFormat(round(data.total_projection))}</Typography.Text>
        </ATable.Summary.Cell>
        <ATable.Summary.Cell index={5} align="right">
          <Typography.Text>{Format.numberFormat(round(data.total_difference))}</Typography.Text>
        </ATable.Summary.Cell>
      </ATable.Summary.Row>
    )
  );
};

const CombinationAcquisition = ({ ebaId }) => {
  const dispatch = useDispatch();
  const { combination } = useSelector((state) => state.cashflow);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(Cashflow.cashflowCombination({ ebaId }));
  }, []);

  const onChangePagination = (page) => {
    setCurrentPage(page);
    document.getElementById('tab-comparation-cashflow').scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <Fragment>
      <div className="mt-5">
        <Table.TableGlobal
          columns={columnsCombinationAcquisition}
          dataSource={combination?.results}
          pageSize={12}
          keyRow="month"
          totalData={combination?.count}
          onChangePagination={onChangePagination}
          summary={() => (
            <SummaryData
              data={combination}
              totalData={combination?.count}
              currentPage={currentPage}
            />
          )}
        />
      </div>
    </Fragment>
  );
};

export default CombinationAcquisition;
