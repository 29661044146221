import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/components/table.css';

function SimpleTable({ headers, children, className }) {
  return (
    <table
      border={2}
      className={
        className
          ? `simple-table border-collapse border ${className}`
          : 'simple-table border-collapse border'
      }>
      <thead>
        {headers.map((item, child) => (
          <tr key={child}>
            {item.map(
              (sub, child2) =>
                sub && (
                  <th
                    key={child2}
                    {...(sub.rowSpan && { rowSpan: sub.rowSpan })}
                    {...(sub.colSpan && { colSpan: sub.colSpan })}
                    {...(sub.width && { style: { width: sub.width } })}
                    className={sub.className}>
                    {sub.text}
                  </th>
                )
            )}
          </tr>
        ))}
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}

SimpleTable.propTypes = {
  headers: PropTypes.array.isRequired,
  children: PropTypes.node,
};

export default SimpleTable;
