import React from 'react';
import { Util } from '../../../helper';
import CustomButton from '../../_shared/custom-button';

const AcquisitionAction = ({ onClickDetail, record }) => {
  const { isAdmin, isOriginator } = Util.role();
  let isReject = false;
  switch (record?.status?.value) {
    case 2:
    case 4:
    case 6:
      isReject = true;
      break;

    default:
      break;
  }
  const text =
    record?.status?.text === 'Request Data Akuisisi' && isOriginator
      ? 'Upload Data'
      : 'Lihat Detail';
  const textAction = isAdmin && isReject ? 'Perbaiki' : text;
  return (
    <div className="flex justify-center space-x-3">
      <CustomButton type="primary-ghost" onClick={() => onClickDetail(record)}>
        {textAction}
      </CustomButton>
    </div>
  );
};

export default AcquisitionAction;
