import { Tooltip } from 'antd';
import { Date, Util } from '../../../helper';

const getDetailYear = (month) => {
  const year = (month / 12).toFixed(0);
  const monthOver = month % 12;
  return (
    <Tooltip title={`${year} Tahun ${monthOver} Bulan`}>
      <span>{Number(month / 12).toFixed(1)}</span>
    </Tooltip>
  );
};

const columns = [
  {
    title: 'Nomor Pinjaman',
    dataIndex: 'loan_number',
    key: 'loan_number',
    width: 180,
    className: 'text-center',
    align: 'center',
  },
  {
    title: 'Tanggal Kredit Awal',
    dataIndex: 'credit_start_date',
    key: 'credit_start_date',
    width: 180,
    className: 'text-center',
    align: 'center',
    render: (record) => Date.twoDigitWithDash(record),
  },
  {
    title: 'Tanggal Jatuh Tempo',
    dataIndex: ' credit_due_date',
    key: 'credit_due_date',
    width: 180,
    className: 'text-center',
    align: 'center',
    render: (record) => Date.twoDigitWithDash(record),
  },
  {
    title: 'Angsuran Pokok (Rp)',
    dataIndex: 'principal_installments',
    key: 'principal_installments',
    width: 200,
    className: 'text-center',
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Angsuran Bunga (Rp)',
    dataIndex: 'interest_installments',
    key: 'interest_installments',
    width: 180,
    className: 'text-center',
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Total Angsuran (Rp)',
    dataIndex: 'total_installment_payment',
    key: 'total_installment_payment',
    width: 180,
    className: 'text-center',
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Bunga (%)',
    dataIndex: 'interest_rate_type',
    width: 150,
    className: 'text-center',
    align: 'center',
    render: (record) => record || '0%',
  },
  {
    title: 'Tenor (Tahun)',
    dataIndex: 'tenor',
    key: 'tenor',
    width: 150,
    className: 'text-center',
    align: 'center',
    render: (record) => getDetailYear(record),
  },
];

export { columns };
