import { Format } from '../../../helper';

const ruleName = [
  {
    required: true,
    message: 'Field tidak boleh kosong',
  },
];

const rulePercentInvest = [
  ...ruleName,
  ({ getFieldValue }) => ({
    validator(_, value) {
      let hundred = 0;
      const fieldValue = getFieldValue('fields');
      fieldValue?.forEach((res) => {
        hundred += Number(res?.percentage_invest);
      });
      hundred = Number(hundred).toFixed(2);
      if (!Number(value) && value === 0) {
        return Promise.reject(new Error(`Hanya masukkan angka`));
      }
      if (value > 100) {
        return Promise.reject(new Error(`Maksimal 100%`));
      }
      if (value < 1) {
        return Promise.reject(new Error(`Minimal 1%`));
      }
      if (hundred > 100.0) {
        return Promise.reject(new Error(`Total persentase saat ini ${hundred}% lebih dari 100%`));
      }
      return Promise.resolve();
    },
  }),
];

const rulePeriod = [
  ...ruleName,
  {
    pattern: /^\d+$/,
    message: 'Hanya masukkan angka',
  },
  () => ({
    validator(_, value) {
      if (value < 1) {
        return Promise.reject(new Error(`Minimal 1 periode`));
      }
      return Promise.resolve();
    },
  }),
];

const ruleInvestAmount = (amountBuffer, isUpdate, current) => [
  ...ruleName,
  ({ getFieldValue }) => ({
    validator(_, value) {
      let amount = 0;
      const fieldValue = getFieldValue('fields');
      fieldValue?.forEach((res) => {
        amount += Number(res?.amount_invest);
      });
      const bufferLeft = isUpdate ? Number(current) + Number(amountBuffer) : amountBuffer;
      const update = isUpdate && Number(value) > Number(amountBuffer) + Number(current);

      if (update || (!isUpdate && Number(value) > Number(amountBuffer))) {
        return Promise.reject(
          new Error(`Melebihi nilai Equity Tranche tersisa ${Format.numberFormat(bufferLeft)}`)
        );
      }

      if (update || (!isUpdate && Number(amount) > Number(amountBuffer))) {
        return Promise.reject(
          new Error(
            `Total melebihi nilai Equity Tranche tersisa ${Format.numberFormat(bufferLeft)}`
          )
        );
      }
      return Promise.resolve();
    },
  }),
];

export default {
  ruleName,
  rulePercentInvest,
  rulePeriod,
  ruleInvestAmount,
};
