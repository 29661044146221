import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTable } from '..';
import { loggingDetail } from '../../screens/management-user/components/tableConfig';
import { UserLog } from '../../service';
import { CustomButton } from '../_shared';

export default function UserLogAction({ id }) {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const { itemDetails, itemDetailTotal, loadingDetail, paginationSyncDetail } = useSelector(
    (state) => state.userLog
  );

  const fetchUserLogDetail = ({ limit, offset, page }) => {
    dispatch(UserLog.userLogDetailList({ id, limit, offset, page }));
  };

  useEffect(() => {
    if (open) {
      fetchUserLogDetail({ limit: 5, offset: 0, page: 1 });
    }
  }, [open]);

  // eslint-disable-next-line no-unused-vars
  const paginationChange = (page, pageSize) => {
    fetchUserLogDetail({ limit: 5, offset: (page - 1) * pageSize, page });
  };

  return (
    <div className="flex">
      <CustomButton type="primary-ghost" onClick={() => setOpen(true)}>
        Lihat Detail
      </CustomButton>
      <Modal
        title={<h3 className="text-xl font-bold">Logging Activity</h3>}
        open={open}
        onCancel={() => setOpen(false)}
        centered
        footer={null}
        width="75%">
        <CustomTable.TableGlobal
          // dataSource={dummyData}
          dataSource={itemDetails}
          columns={loggingDetail.column}
          loading={loadingDetail}
          pagination={{
            pageSize: 5,
            onChange: paginationChange,
            current: paginationSyncDetail?.page,
            total: itemDetailTotal,
            showSizeChanger: false,
          }}
          keyRow="id"
          // scroll={{ x: 2000 }}
        />
      </Modal>
    </div>
  );
}
