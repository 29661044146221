import { Table } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRowKeys } from '../../store/aquisition';
import { columnsAcquisition } from './columns';

const TableAcquisition = ({ keyRow = 'id62', setPage, page, status, ...props }) => {
  const dispatch = useDispatch();
  const { submissionList, loading, selectedRowKeys, selectedRow } = useSelector(
    (state) => state.aquisition
  );

  const statusSubmit = [3, 5, 7, 8];
  const onSelectRow = (record, selected) => {
    if (statusSubmit.includes(Number(status))) return; // can't select if admin was submit
    let addSelected = [];
    let rowSelected = {
      id62: record?.id62,
      loan_number: record.loan_number,
      total_installment_payment: record.total_installment_payment,
    };

    if (selected) {
      // add selected row
      addSelected = [...new Set([...selectedRowKeys, record?.id62])];
    } else {
      // remove selected row
      addSelected = selectedRowKeys?.filter((element) => element !== record?.id62);
    }

    dispatch(setSelectedRowKeys({ key: addSelected, row: rowSelected }));
  };

  const addAll = (row) => {
    let add = [];
    if (selectedRow?.length === 0) return row;
    const ids = new Set(selectedRow.map((s) => s.id62));
    add = [...selectedRow, ...row.filter((r) => !ids.has(r.id62))];
    return add;
  };

  const onSelectAll = (selected) => {
    if (statusSubmit.includes(Number(status))) return; // can't select if admin was submit
    let rowKey = [];
    let row = [];
    submissionList?.results?.map((el) => {
      rowKey.push(el.id62);
      row.push({
        id62: el?.id62,
        loan_number: el.loan_number,
        total_installment_payment: el.total_installment_payment,
      });
    });
    if (selected) {
      // add selected row
      rowKey = [...new Set([...selectedRowKeys, ...rowKey])];
      row = addAll(row);
    } else {
      // remove selected row
      rowKey = selectedRowKeys?.filter((element) => rowKey.indexOf(element) < 0);
      row = selectedRow?.filter((sr) => !row?.find((r) => r.id62 === sr.id62));
    }

    dispatch(setSelectedRowKeys({ key: rowKey, row: row, type: 'all' }));
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectRow,
    onSelectAll: onSelectAll,
  };

  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
  };

  const statusReject = [1, 2, 4, 6];

  const TableCallback = useCallback(() => {
    return (
      <Table
        {...props}
        rowSelection={rowSelection}
        dataSource={submissionList?.results}
        columns={columnsAcquisition}
        rowKey={(record) => record[keyRow]}
        loading={loading}
        scroll={{ x: 1000 }}
        rowClassName={(record) => {
          const found = selectedRow?.find((element) => element?.id62 === record?.id62);
          if (found?.id62 && record.is_picked) return 'bg-[#FFF1F1]';
          if (record.is_picked && statusReject.includes(Number(status))) {
            return 'bg-[#FFF1F1] reject-hover';
          }
          if (found) return 'bg-[#d1ffd3]';
        }}
        pagination={{
          pageSize: 10,
          onChange: paginationChange,
          total: submissionList?.count,
          showSizeChanger: false,
          current: page.current,
        }}
      />
    );
  }, [selectedRowKeys, submissionList]);

  return <TableCallback />;
};

export default TableAcquisition;
