import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as AntdTabs } from 'antd';
import '../../../styles/components/tabs.css';

function tabs({ items, defaultActiveKey, ...props }) {
  return (
    <AntdTabs
      defaultActiveKey={defaultActiveKey}
      items={items.map((item, key) => {
        return {
          label: item?.title,
          key,
          children: item?.children,
        };
      })}
      {...props}
    />
  );
}

tabs.propTypes = {
  items: PropTypes.array.isRequired,
  defaultActiveKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

tabs.defaultProps = {
  defaultActiveKey: '0',
};

export default React.memo(tabs);
