import { Tooltip, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AssetsComponent, ManagementProductComponent, Page } from '../../../components';
import {
  ApproveDeclineModal,
  CustomButton,
  CustomNotification,
  UpdatedTime,
} from '../../../components/_shared';
import { Constant } from '../../../const';
import { Util } from '../../../helper';
import usePage from '../../../hooks/usePage';
import { earningPercentageColumn, uploadAssetsColumn } from '../components/tableConfig';

export default function UploadAsset() {
  const { isSupervisor, isAdmin } = Util.role();
  const navigate = useNavigate();
  const { isProduct } = usePage();

  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  const isSimulation = searchParams.get('is_simulation');
  const status = searchParams.get('status');
  const ebaName = searchParams.get('eba-name') || null;

  const { items, itemTotal, paginationSync } = useSelector((state) => state.aquisition);
  const { revenuePeriod, isCovered } = useSelector((state) => state.revenue);
  const { loading: loadingSend } = useSelector((state) => state.reference);
  const { ebaPeriodDiff } = useSelector((state) => state.eba);

  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [activeCard, setActiveCard] = useState(0);
  const [loadingupload, setLoadingUpload] = useState(0);
  const [percentProcess, setPercentProcess] = useState('');

  const breadcrumbs = [
    {
      id: 1,
      name: `${isSimulation === 'true' ? 'Simulasi Produk' : 'Management Produk'}`,
      href: '/management-product',
    },
    {
      name: 'Draft Produk',
      href: '/management-product/?status=1',
      isHide: isProduct || isSupervisor,
    },
    { name: 'Management Asset', href: null, isHide: !isProduct },
    { id: 2, name: 'Upload Asset', href: null },
  ];

  const cardItems = [
    { id: 0, title: 'Upload Assets', description: ' ' },
    { id: 1, title: 'Earning Percentage (EBA Portion)', description: ' ' },
  ];

  const handleSubmit = () => {
    const simulation = isSimulation == 'true' ? '&is_simulation=true' : '';
    if (!activeCard) {
      setActiveCard(1);
    } else {
      if (items.length > 0 && revenuePeriod.length > 0) {
        navigate(
          // eslint-disable-next-line prettier/prettier
          `/management-product/asset-class?page=management-product&eba-name=${ebaName}&eba-id=${
            ebaId + simulation
          }&status=${status}`
        );
      } else {
        CustomNotification({
          type: Constant.WARNING,
          message: 'Anda belum upload asset atau atur earning percentage',
        });
      }
    }
  };

  const onClickCard = (item) => {
    if (loadingSend) return;
    if (activeCard === 0 && items?.length === 0) {
      CustomNotification({
        type: Constant.WARNING,
        message: 'Anda belum upload asset',
      });
      return;
    }

    setActiveCard(item.id);
  };

  const SaveButton = useCallback(() => {
    let disabled = true;
    let tooltip = '';
    if (!activeCard) {
      // upload asset
      disabled = items?.length === 0;
      tooltip = 'Anda belum upload asset';
    } else {
      disabled = false;
      // earning percentage
      if (!isCovered) {
        disabled = true;
        tooltip = `Periode terakhir earning percentage belum sesuai periode terakhir eba ${ebaPeriodDiff}`;
      }

      if (revenuePeriod.length === 0) {
        disabled = true;
        tooltip = 'Anda belum atur earning percentage';
      }
      if (itemTotal === 0) {
        disabled = true;
        tooltip = 'Anda belum upload asset';
      }
    }
    return (
      <div className="flex flex-row-reverse mt-5">
        <Tooltip title={disabled ? tooltip : ''} placement="topRight">
          <div>
            <CustomButton
              type="primary"
              onClick={() => handleSubmit()}
              disabled={disabled}
              {...(disabled && { style: { pointerEvents: 'none' } })}>
              {!activeCard ? 'Selanjutnya' : 'Simpan'}
            </CustomButton>
          </div>
        </Tooltip>
      </div>
    );
  }, [itemTotal, revenuePeriod, activeCard]);

  const Updated = () => {
    const updateUpload = items?.length > 0 ? items[items?.length - 1].updated_at : '';
    const updateEarning =
      revenuePeriod?.length > 0 ? revenuePeriod[revenuePeriod?.length - 1].updated_at : '';
    const updated = !activeCard ? updateUpload : updateEarning;

    return <UpdatedTime updated={ebaId ? updated : ''} />;
  };

  return (
    <Page
      title="Upload Asset"
      breadcrumbs={breadcrumbs}
      loading={loadingupload}
      textSpin={
        <>
          <p>Data sedang diproses</p>
          <p>{percentProcess}</p>
        </>
      }>
      <Typography.Paragraph className="font-bold text-3xl">Upload Assets</Typography.Paragraph>
      <Typography.Paragraph className="font-bold text-xl mt-2">
        {ebaName ? ebaName : null}
      </Typography.Paragraph>
      <div className="grid grid-cols-2 gap-5 my-6">
        {cardItems.map((item) => (
          <AssetsComponent.Card
            title={item.title}
            description={item.description}
            key={item.id}
            onClick={() => onClickCard(item)}
            isClick={item.id === activeCard}
          />
        ))}
      </div>
      <Updated />
      {!activeCard ? (
        <ManagementProductComponent.UploadAsset
          columns={uploadAssetsColumn(paginationSync?.page || 1)}
          setLoadingUpload={setLoadingUpload}
          setPercentProcess={setPercentProcess}
          status={status}
          items={ebaId ? items : []}
        />
      ) : (
        <ManagementProductComponent.EarningPercentage
          column={earningPercentageColumn(
            (isAdmin && status != '0') || (isSupervisor && isSimulation === 'true')
          )}
          ebaId={ebaId}
          items={ebaId ? revenuePeriod : []}
          isProduct={isProduct}
          isSimulation={isSimulation === 'true'}
          status={status}
        />
      )}
      {isAdmin && !isProduct && <SaveButton />}
      {isSupervisor &&
        !isProduct &&
        (isSimulation === 'true' ? (
          <>
            <div className="flex flex-row-reverse mt-5 gap-5">
              <CustomButton
                type="primary"
                onClick={() => {
                  navigate(
                    `/management-product/asset-class?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}&is_simulation=true`
                  );
                }}>
                Next
              </CustomButton>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row-reverse mt-5 gap-5">
              <CustomButton
                type="primary"
                onClick={() => {
                  setOpen(true);
                  setType('approve');
                }}>
                Approve
              </CustomButton>
              <CustomButton
                type="primary-ghost"
                onClick={() => {
                  setOpen(true);
                  setType('decline');
                }}>
                Decline
              </CustomButton>
            </div>
            <ApproveDeclineModal
              type={type}
              open={open}
              onClose={() => setOpen(false)}
              ebaId={ebaId}
              page={'Upload Asset'}
              navigate={`/management-product/asset-class?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}&status=${status}`}
            />
          </>
        ))}
    </Page>
  );
}
