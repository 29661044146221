import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useMemo, useState } from 'react';

const CustomTabs = ({ tabsItem, onTabClick, defaultActiveKey, ...props }) => {
  const [clickKey, setClick] = useState(props?.activeKey || 1);

  const items = useMemo(() => {
    const item = [];
    tabsItem?.forEach((res, i) => {
      const active = clickKey === i + 1;
      const clicked = active ? 'text-primary' : `text-grey`;
      item.push({
        ...res,
        label: <p className={`px-3 font-bold hover:text-primary ${clicked}`}>{res.label}</p>,
        key: i + 1,
        children: res.children,
        disabled: res?.disabled || false,
      });
    });

    return item;
  }, [clickKey, tabsItem, props?.activeKey]);

  const tabClick = (e) => {
    setClick(Number(e));
    onTabClick(e);
  };

  return (
    <Tabs onTabClick={tabClick} defaultActiveKey={defaultActiveKey} items={items} {...props} />
  );
};

export default memo(CustomTabs);

CustomTabs.defaultProps = {
  tabsItem: [],
  defaultActiveKey: '1',
  onTabClick: () => {},
};

CustomTabs.propTypes = {
  tabsItem: PropTypes.arrayOf(PropTypes.object),
  onTabClick: PropTypes.func,
  defaultActiveKey: PropTypes.string,
  props: PropTypes.any,
};
