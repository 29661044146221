import { CustomButton } from '../../../components/';
import { Date } from '../../../helper';

const columns = (clickDetail, click, exportExcel = () => {}) => [
  {
    title: 'Month',
    dataIndex: click === 'reports-internal' ? 'report_date' : 'date',
    render: (record) => Date.monthYear(click === 'reports-internal' ? record?.start_date : record),
  },
  {
    title: 'Last Updated',
    dataIndex: 'last_updated',
    render: () => Date.lastUpdate('2023-07-24 13:58'),
  },
  {
    title: <div className="text-center">Action</div>,
    key: 'action',
    width: 200,
    render: (record) => (
      <div className="flex flex-row gap-2">
        <CustomButton
          type="primary-ghost"
          style={{ borderRadius: '10px' }}
          size="small"
          onClick={() =>
            clickDetail(click === 'reports-internal' ? record.report_date.start_date : record.date)
          }>
          Lihat Detail
        </CustomButton>
        <CustomButton
          type="primary-ghost"
          size="small"
          style={{ borderRadius: '10px' }}
          onClick={() =>
            exportExcel(click === 'reports-internal' ? record.report_date.start_date : record.date)
          }>
          Export Excel
        </CustomButton>
      </div>
    ),
  },
];

export { columns };
