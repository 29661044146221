import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const getDetailFeedback = createAsyncThunk(
  'get/notes/',
  async ({ id62, approval }, { rejectWithValue }) => {
    try {
      return await API.get({ url: `notes/?eba_id62=${id62}&approval=${approval}` });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default { getDetailFeedback };
