/* eslint-disable no-unused-vars */
import { Typography } from 'antd';
import React from 'react';
import { Format } from '../../../helper';

const TotalData = ({
  consistent_count,
  incomplete_count,
  inconsistent_count,
  transactionDate,
  totalUpload,
  appropiateData = [],
}) => {
  const data = [
    { title: 'Total Data Yang Diupload', value: totalUpload },
    { title: 'Data Sesuai', value: consistent_count },
    { title: 'Data Tidak Sesuai', value: inconsistent_count },
    { title: 'Data Tidak Ditemukan', value: incomplete_count },
  ];
  return (
    <div>
      {/* <div className="flex items-center space-x-2">
        <RiCalendar2Fill className="text-primary" size={20} />
        <Typography.Paragraph className="text-primary text-base font-medium">
          Tanggal Transaksi: {transactionDate ? Date.fullDate(transactionDate) : '-'}
        </Typography.Paragraph>
      </div> */}
      <div className="flex space-x-5 mt-5">
        {data.map((res) => (
          <div
            key={res.title}
            className="flex-1 bg-white p-4 rounded-10 border border-grey_bg space-y-2">
            <Typography.Paragraph className="font-bold">{res.title}</Typography.Paragraph>
            <Typography.Paragraph className="text-primary text-2xl">
              {Format.numberFormat(res.value)}
            </Typography.Paragraph>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TotalData;
