import React from 'react';
import Table from '../../table';
import { columnsInvestor } from './columns';

const tableInvestor = ({ page, setPage, item }) => {
  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
  };

  return (
    <Table.TableGlobal
      columns={columnsInvestor}
      dataSource={item}
      totalData={item?.length}
      className="table-preview-data"
      scroll={{ x: 2000 }}
      bordered
      onChangePagination={paginationChange}
      current={page.current}
      keyRow="investor"
    />
  );
};

export default tableInvestor;
