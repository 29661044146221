import { Card, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomTable, ManagementProductComponent, Page } from '../../components';
import { ApproveDeclineModal, CustomButton, CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Format, Util } from '../../helper';
import usePage from '../../hooks/usePage';
import { Buffer, Eba, Reinvestment } from '../../service';
import { reInvestmentColumn } from './components/tableConfig';
import { MdInfoOutline } from 'react-icons/md';

export default function ReInvestment() {
  const { isProduct } = usePage();
  const { isSupervisor, isAdmin } = Util.role();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  // const pageShow = searchParams.get('page');
  const isSimulation = searchParams.get('is_simulation') || false;
  const ebaName = searchParams.get('eba-name');

  const { list, trigger, loading } = useSelector((state) => state.reinvestment);
  const { data } = useSelector((state) => state.buffer);

  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [openForm, setOpenForm] = useState(false);
  const [publish, setPublish] = useState(false);
  const [page, setPage] = useState(1);

  const breadcrumbs = [
    {
      name: `${isSimulation === 'true' ? 'Simulasi Produk' : 'Management Produk'}`,
      href: `${isSimulation === 'true' ? '/simulation' : '/management-produk'}`,
    },
    {
      name: 'Draft Produk',
      href: '/management-product/?status=1',
      isHide: isProduct || isSupervisor,
    },
    { name: 'Management Asset', href: null, isHide: !isProduct },

    { name: 'Re-Investment', href: null },
  ];

  const fetchData = async () => {
    await dispatch(Reinvestment.getList({ ebaId: ebaId }));
    // always fetch buffer detail after get list to avoid null data
    await dispatch(Buffer.bufferDetail({ ebaId: ebaId }));
  };

  useEffect(() => {
    fetchData();
  }, [trigger]);

  const Approving = () => {
    dispatch(Eba.AdminApproval(ebaId)).then(() =>
      navigate(isSimulation ? '/simulasi' : '/management-product/?status=2')
    );
  };

  const addDataReinvest = () => {
    if (Number(data.amount) == Number(data.reinvestment_amount)) {
      CustomNotification({
        type: Constant.WARNING,
        message: 'Tidak Bisa Input Data',
        description: 'Seluruh equity tranche telah terpakai',
      });
      return;
    }
    setOpenForm(true);
  };

  const paginationChange = (pages) => {
    setPage(pages);
  };

  const showAction = () => {
    if (isAdmin) {
      return true;
    }
    if (isSupervisor) {
      return !isProduct && isSimulation === 'true';
    }
  };

  return (
    <Page title="Re-Investment" breadcrumbs={breadcrumbs} updated={ebaId ? data?.updated_at : ''}>
      <Typography.Paragraph className="font-bold text-3xl">Re-Investment</Typography.Paragraph>
      <Typography.Paragraph className="font-bold text-xl mt-2">
        {ebaName ? ebaName : null}
      </Typography.Paragraph>
      <Typography.Paragraph className="text-base mt-2">
        Dibawah ini merupakan informasi detail mengenai nilai re-investment yang{' '}
        {!isProduct && 'akan'} dikeluarkan pada periode tertentu
      </Typography.Paragraph>
      <Card className="mt-5 mb-5">
        <div className="flex justify-between align-center">
          <div className="flex flex-start">
            <MdInfoOutline style={{ marginTop: '5px', marginRight: '1rem' }} />
            <Typography.Paragraph className="font-bold text-base">
              Posisi Nilai Equity Tranche
            </Typography.Paragraph>
          </div>
          <Typography.Paragraph className="font-bold text-base text-primary">
            Rp. {Format.numberFormat(data.amount) || 0}
          </Typography.Paragraph>
        </div>
      </Card>
      <div className="flex justify-between align-center">
        <Typography.Paragraph className="font-bold text-base">
          Detail Re-Investment
        </Typography.Paragraph>
        {isAdmin && (
          <div className="flex flex-row-reverse gap-4">
            <CustomButton
              type="primary-ghost"
              className="
            group
            justify-center
            items-center
            flex
            space-x-2"
              onClick={addDataReinvest}>
              <BsPlusCircleFill size={20} className="text-primary group-hover:text-white" />
              Input Data
            </CustomButton>
            {/* <CustomButton
              type="primary-ghost"
              onClick={() =>
                navigate(
                  // eslint-disable-next-line prettier/prettier
                  `/management-product/reinvestment-summary?eba-name=${ebaName}&page=${pageShow}&eba-id=${ebaId}${isSimulation === 'true' ? `&is_simulation=${isSimulation}` : ''
                  }`
                )
              }>
              Lihat Summary
            </CustomButton> */}
          </div>
        )}
      </div>
      <div className="mb-5 mt-5">
        <CustomTable.TableGlobal
          dataSource={list}
          columns={reInvestmentColumn(showAction())}
          loading={loading}
          keyRow="id62"
          pageSize={10}
          onChangePagination={paginationChange}
          current={page}
          scroll={{ x: 900 }}
        />
      </div>
      {isAdmin && !isProduct && (
        <div className="flex flex-row-reverse items-center gap-5">
          {isSimulation !== 'true' && (
            <CustomButton
              type="publish"
              className="bg-green_main text-white hover:text-green_main hover:bg-white hover:border-green_main"
              onClick={() => setPublish(true)}>
              Ajukan
            </CustomButton>
          )}
          <CustomButton
            type="primary"
            onClick={() =>
              isSimulation === 'true' ? Approving() : navigate('/management-produk')
            }>
            Simpan
          </CustomButton>
          <CustomButton
            type="primary-ghost"
            onClick={() =>
              navigate(
                // eslint-disable-next-line prettier/prettier
                `/management-product/cost?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}${
                  isSimulation === 'true' ? '&is_simulation=true' : ''
                }`
              )
            }>
            Kembali
          </CustomButton>
        </div>
      )}
      {isSupervisor &&
        !isProduct &&
        (isSimulation === 'true' ? (
          <>
            <div className="flex flex-row-reverse mt-5 gap-5">
              <CustomButton
                type="primary"
                onClick={() => {
                  navigate(`/simulation`);
                }}>
                Next
              </CustomButton>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row-reverse mt-5 gap-5">
              <CustomButton
                type="publish"
                className="bg-green_main text-white hover:text-green_main hover:bg-white hover:border-green_main"
                onClick={() => setPublish(true)}>
                Simpan
              </CustomButton>
              <CustomButton
                type="primary"
                onClick={() => {
                  setOpen(true);
                  setType('approve');
                }}>
                Approve
              </CustomButton>
              <CustomButton
                type="primary-ghost"
                onClick={() => {
                  setOpen(true);
                  setType('decline');
                }}>
                Decline
              </CustomButton>
            </div>
            <ApproveDeclineModal
              type={type}
              open={open}
              onClose={() => setOpen(false)}
              onApproved={() => setPublish(true)}
              ebaId={ebaId}
              page={'Reinvestment'}
            />
          </>
        ))}
      <ManagementProductComponent.ReInvestmentForm
        buffer={data.amount}
        open={openForm}
        onClose={() => setOpenForm(false)}
      />
      <ManagementProductComponent.ReInvestmentPublish
        open={publish}
        onApprove={Approving}
        onClose={() => setPublish(false)}
      />
    </Page>
  );
}
