import { Typography } from 'antd';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { CustomButton, ManagementProductComponent } from '../../../components';
import { Date, Format } from '../../../helper';

const statusColor = (status) => {
  let className = 'bg-green_bg text-green_text';
  switch (status.value) {
    case 0:
      className = 'bg-blue_bg text-blue_text';
      break;
    case 3:
      className = 'bg-[#FFF2F2] text-primary';
      break;

    default:
      break;
  }
  return (
    <Typography.Text className={`py-3 px-5 font-bold rounded-[50px] w-[240px] ${className}`}>
      {status.text}
    </Typography.Text>
  );
};

const columnsListProduct = (onClick) => [
  {
    title: 'Nama Produk',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    className: 'font-bold',
  },
  {
    title: 'Bulan Awal',
    dataIndex: 'start_date',
    key: 'start_date',
    align: 'center',
    className: 'font-bold',
    render: Date.twoDigitWithDash,
  },
  {
    title: 'Bulan Akhir',
    dataIndex: 'end_date',
    key: 'end_date',
    align: 'center',
    className: 'font-bold',
    render: Date.twoDigitWithDash,
  },
  {
    title: 'Last Updated',
    dataIndex: 'updated_at',
    className: 'font-bold',
    render: Date.lastUpdate,
  },
  {
    title: 'Aksi',
    key: 'action',
    width: 200,
    align: 'center',
    render: (_, record) => (
      <CustomButton type="primary-ghost" onClick={() => onClick(record)}>
        Lihat Detail
      </CustomButton>
    ),
  },
];

const columnsDraftProduct = [
  {
    title: 'Nama Produk',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    className: 'font-bold',
    width: 180,
  },
  {
    title: 'Bulan Awal',
    dataIndex: 'start_date',
    key: 'start_date',
    align: 'center',
    width: 180,
    className: 'font-bold',
    render: Date.twoDigitWithDash,
  },
  {
    title: 'Bulan Akhir',
    dataIndex: 'end_date',
    key: 'end_date',
    align: 'center',
    width: 180,
    className: 'font-bold',
    render: Date.twoDigitWithDash,
  },
  {
    title: 'Aksi',
    key: 'action',
    align: 'center',
    width: 100,
    render: () => <ManagementProductComponent.DraftAction />,
  },
];

const columnsFeedbackList = (onClick) => [
  {
    title: 'Nama Produk',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: 180,
    className: 'font-bold',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: 180,
    render: (record) => statusColor(record),
  },
  {
    title: 'Aksi',
    key: 'action',
    align: 'center',
    width: 100,
    render: (record) => (
      <CustomButton type="primary-ghost" onClick={() => onClick(record)}>
        Lihat Detail
      </CustomButton>
    ),
  },
];

const columnsFeedbackProduct = [
  {
    title: 'Nama Halaman',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: 180,
    className: 'font-bold',
  },
  {
    title: 'Feedback',
    dataIndex: 'feedback',
    key: 'feedback',
    align: 'center',
    className: 'font-bold',
    width: 280,
    render: (record) => record || '-',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: 180,
    render: (record) => statusColor(record),
  },
  {
    title: 'Aksi',
    key: 'action',
    align: 'center',
    width: 100,
    render: () => <CustomButton type="primary-ghost">Lihat Detail</CustomButton>,
  },
];

const dataDraft = [
  {
    buffer_percent: '0.00 %',
    end_date: '2026-01-01',
    id62: 'C',
    is_simulation: false,
    name: 'KIK EBA 44',
    start_date: '2023-01-17',
  },
  {
    buffer_percent: '0.00 %',
    end_date: '2026-01-01',
    id62: 'C',
    is_simulation: false,
    name: 'KIK EBA 55',
    start_date: '2023-01-17',
  },
];

const dataFeedback = [
  { name: 'EBA Info', feedback: 'Mantap', status: { value: 1, text: 'Disetujui' } },
  { name: 'Asset', feedback: '', status: { value: 0, text: 'Diajukan' } },
  { name: 'Cash Waterfall', feedback: 'Kurang itu', status: { value: 3, text: 'Ditolak' } },
];

const dataFeedbackList = [
  { name: 'KIK EBA Z', status: { value: 0, text: 'Diajukan' } },
  { name: 'KIK EBA C', status: { value: 1, text: 'Disetujui' } },
  { name: 'KIK EBA M', status: { value: 3, text: 'Ditolak' } },
];

const data = [{ name: 'KIK EBA 1', description: 'Kredit Pensiunan', parameter: '10 Januari 2019' }];

const uploadAssetsColumn = [
  {
    title: 'No Pinjaman',
    dataIndex: 'loan_number',
    key: 'loan_number',
  },
  {
    title: 'Nama',
    dataIndex: 'debtor_name',
    key: 'debtor_name',
  },
  {
    title: 'Nilai',
    dataIndex: 'total_installment_payment',
    key: 'total_installment_payment',
    render: (record) => 'Rp.' + Format.numberFormat(record) + ',00',
  },
  {
    title: 'Tanggal Lunas',
    dataIndex: 'credit_due_date',
    key: 'credit_due_date',
    render: Date.twoDigitWithDash,
  },
  {
    title: 'Aksi',
    key: 'action',
    render: () => <CustomButton type="primary-ghost">Lihat Detail</CustomButton>,
  },
];

const uploadAssetsData = [
  {
    number: '1000012',
    name: 'John Doe',
    amount: 'Rp. 120.000,00',
    date: '26 November 2026',
  },
  {
    number: '1000013',
    name: 'Mark Z',
    amount: 'Rp. 120.000,00',
    date: '26 November 2026',
  },
];

const earningPercentageColumn = [
  [
    {
      text: 'Persentase',
      className: 'border',
    },
    {
      text: 'Periode Mulai',
      className: 'border',
    },
    {
      text: 'Periode Selesai',
      className: 'border',
    },
  ],
];

const earningPercentageData = [
  {
    percentage: '60%',
    period_start: '1',
    period_end: '69',
  },
  {
    percentage: '99%',
    period_start: '60',
    period_end: '120',
  },
];

const equityBufferColumn = [
  {
    title: 'Tanggal',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Deskripsi',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Debit',
    dataIndex: 'debit',
    key: 'debit',
  },
  {
    title: 'Kredit',
    dataIndex: 'credit',
    key: 'credit',
  },
  {
    title: 'Saldo',
    dataIndex: 'balance',
    key: 'balance',
  },
];

const equityBufferData = [
  {
    date: '26 November 2026',
    description: 'Penambahan Ekuitas B',
    debit: '-',
    credit: 'Rp. 1.300.000.000.000',
    balance: 'Rp. 1.300.000.000.000',
  },
];

const assetClassColumn = [
  {
    title: 'Senior Tranches',
    dataIndex: 'class',
    key: 'class',
  },
  {
    title: 'Bulan Awal',
    dataIndex: 'start_period',
    key: 'start_period',
  },
  {
    title: 'Bulan Akhir',
    dataIndex: 'end_period',
    key: 'end_period',
  },
  {
    title: 'AUM Value',
    dataIndex: 'aum_value',
    key: 'aum_value',
  },
  {
    title: 'Aksi',
    key: 'action',
    render: () => <ManagementProductComponent.AssetClassAction />,
  },
];

const assetClassData = [
  {
    class: 'A1',
    start_period: 'Penambahan Ekuitas B',
    end_period: 'John Doe',
    aum_value: '500 B',
  },
];

const assetClassDetailColumn = [
  {
    title: 'Kode EBA',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Single Investor Identification',
    dataIndex: 'identification',
    key: 'identification',
  },
  {
    title: 'Nama Investor',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Jenis Investor',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Keterangan Investor',
    dataIndex: 'note',
    key: 'note',
  },
  {
    title: 'Total Investasi Awal',
    dataIndex: 'first_invest',
    key: 'first_invest',
  },
  {
    title: 'Total Investasi Akhir',
    dataIndex: 'last_invest',
    key: 'last_invest',
  },
  {
    title: 'Aksi',
    key: 'action',
    render: () => (
      <>
        <div className="flex gap-5">
          <BiPencil style={{ cursor: 'pointer' }} />
          <BiTrash style={{ cursor: 'pointer' }} />
        </div>
      </>
    ),
  },
];

const assetClassDetailData = [
  {
    code: 'BBKK01',
    identification: 'IBD2502',
    name: 'PT. Bank Bukopin TBK',
    type: 'IB',
    note: 'Bank',
    first_invest: 'IDR 119.600.000,00',
    last_invest: 'IDR 119.600.000,00',
  },
  {
    code: 'BBKK01',
    identification: 'IBD2502',
    name: 'PT. Bank Bukopin TBK',
    type: 'IB',
    note: 'Bank',
    first_invest: 'IDR 119.600.000,00',
    last_invest: 'IDR 119.600.000,00',
  },
];

const logRatingColumn = [
  [
    {
      text: 'Rating',
      className: 'border',
    },
    {
      text: 'Periode Biling',
      className: 'border',
    },
    {
      text: 'Rating Date',
      className: 'border',
    },
    {
      text: 'Rating Agency',
      className: 'border',
    },
  ],
];

const logRatingData = [
  {
    id: 1,
    rating: 'AAA',
    rating_period: '3',
    rating_date: '12 Januari 2022',
    ratting_given_by: 'John Doe',
  },
  {
    id: 2,
    rating: 'AA',
    rating_period: '3',
    rating_date: '12 Januari 2022',
    ratting_given_by: 'John Doe',
  },
];

const costColumn = [
  {
    title: 'Bulan',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Biaya Per Bulan',
    dataIndex: 'exprenses_per_month',
    key: 'exprenses_per_month',
  },
  {
    title: 'Aksi',
    key: 'action',
    width: '15%',
    align: 'center',
    render: (record) => <ManagementProductComponent.CostAction name={record?.date} />,
  },
];

const constData = [
  {
    id: 1,
    date: 'November 2022',
    exprenses_per_month: 'IDR 120.000.000,00',
  },
  {
    id: 2,
    date: 'Oktober 2022',
    exprenses_per_month: 'IDR 120.000.000,00',
  },
  {
    id: 3,
    date: 'September 2022',
    exprenses_per_month: 'IDR 120.000.000,00',
  },
  {
    id: 4,
    date: 'Agustus 2022',
    exprenses_per_month: 'IDR 120.000.000,00',
  },
  {
    id: 5,
    date: 'Juli 2022',
    exprenses_per_month: 'IDR 120.000.000,00',
  },
];

const costDetailColumn = [
  {
    title: 'Nama Biaya',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Periode Biaya',
    dataIndex: 'period',
    key: 'period',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
  },
  {
    title: 'Aksi',
    key: 'action',
    width: '10%',
    align: 'center',
    render: () => <ManagementProductComponent.CostDetailAction />,
  },
];

const costDetailData = [
  {
    name: 'Biaya 1',
    period: 'Per Bulan',
    total: 'IDR 119.600.000,00',
  },
  {
    name: 'Biaya 2',
    period: 'Per Kuartal',
    total: 'IDR 119.600.000,00',
  },
  {
    name: 'Biaya 3',
    period: 'Per Tahun',
    total: 'IDR 119.600.000,00',
  },
  {
    name: 'Biaya 4',
    period: 'Kustom',
    total: 'IDR 119.600.000,00',
  },
];

const reInvestmentColumn = [
  {
    title: 'Nama Investasi',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Persentasi Investasi',
    dataIndex: 'percent',
    key: 'percent',
    align: 'center',
  },
  {
    title: 'Nilai Investasi',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
  },
  {
    title: 'Aksi',
    key: 'action',
    width: '10%',
    align: 'center',
    render: () => <ManagementProductComponent.ReInvestmentAction />,
  },
];

const reInvestmentData = [
  {
    name: 'Investasi 1',
    percent: '60%',
    amount: 'IDR 780.000.000,00',
  },
  {
    name: 'Investasi 2',
    percent: '5%',
    amount: 'IDR 65.500.000,00',
  },
];

export {
  columnsListProduct,
  data,
  uploadAssetsColumn,
  uploadAssetsData,
  earningPercentageColumn,
  earningPercentageData,
  equityBufferColumn,
  equityBufferData,
  assetClassColumn,
  assetClassData,
  assetClassDetailColumn,
  assetClassDetailData,
  logRatingColumn,
  logRatingData,
  columnsDraftProduct,
  columnsFeedbackProduct,
  dataDraft,
  dataFeedback,
  columnsFeedbackList,
  dataFeedbackList,
  costColumn,
  constData,
  costDetailColumn,
  costDetailData,
  reInvestmentColumn,
  reInvestmentData,
};
