import { Typography, Modal } from 'antd';
import React from 'react';
import { CustomButton } from '../../_shared';
import { Util } from '../../../helper';

export default function ReInvestmentPublish({ open, onClose, onApprove }) {
  const { isAdmin } = Util.role();
  return (
    <Modal
      open={open}
      footer={null}
      onCancel={() => {
        onClose();
      }}
      destroyOnClose={true}
      centered
      width={'486px'}>
      <div className="flex flex-col p-6 gap-6 justify-center items-center">
        {/* <img width={80} src="/icons/popup/warningPopup.svg" alt="warning" /> */}
        <Typography.Paragraph className="font-bold text-base text-center w-[354px]">
          {isAdmin
            ? 'Apakah Anda yakin ingin mengajukan ke supervisor?'
            : 'Apakah anda yakin ingin menyimpan permintaan produk ini?'}
        </Typography.Paragraph>
        <div className="flex flex-row-reverse gap-5 mt-5">
          <CustomButton type="primary" onClick={onApprove}>
            Setuju
          </CustomButton>
          <CustomButton onClick={() => onClose()} type="primary-ghost">
            Batalkan
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
}
