import React from 'react';
import { SimpleTable } from '../../_shared';
import { Util } from '../../../helper';
import { Modal } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Report } from '../../../service';

// Import Icon
import { BiTrash } from 'react-icons/bi';
import { ExclamationCircleFilled } from '@ant-design/icons';

const { confirm } = Modal;

const TableReportInternal = ({
  internalDetail = [],
  loading = false,
  columns = [],
  ibpaChildren = [],
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const tableHeader = [
    [
      {
        text: 'Monthly report',
        className: 'border rounded-tl-10',
        rowSpan: 2,
      },
      {
        text: 'Total AUM',
        className: 'border',
        colSpan: columns?.length,
      },
      {
        text: 'Tanggal Pelunasan Bertahap',
        className: 'border',
        colSpan: columns?.length,
      },
      {
        text: 'Indikasi Pelunasan Bertahap',
        className: 'border',
        colSpan: columns?.length,
      },
      {
        text: 'Indikasi Imbah Hasil',
        className: 'border',
        colSpan: columns?.length,
      },
      {
        text: 'Total Imbah Hasil',
        className: 'border',
        colSpan: columns?.length,
      },
      {
        text: 'Tanggal',
        className: 'border',
        rowSpan: 2,
      },
      ...ibpaChildren,
      {
        text: 'Jumlah Debitur',
        className: 'border',
        rowSpan: 2,
      },
      {
        text: 'Jumlah Sehat',
        className: 'border',
        rowSpan: 2,
      },
      {
        text: 'Jumlah Meninggal',
        className: 'border',
        rowSpan: 2,
      },
      {
        text: 'Jumlah Pengumpulan',
        className: 'border',
        rowSpan: 2,
      },
      {
        text: 'Rata-rata Jatuh Tempo',
        className: 'border',
        rowSpan: 2,
      },
      {
        text: 'NPL',
        className: 'border',
        rowSpan: 2,
      },
      {
        text: 'Jumlah Debitur Awal',
        className: 'border',
        rowSpan: 2,
      },
      {
        text: 'Jumlah Debitur per Masing-masing Bulan',
        className: 'border',
        rowSpan: 2,
      },
      {
        text: 'Jumlah Debitur Meninggal per Bulan',
        className: 'border',
        rowSpan: 2,
      },
      {
        text: 'Akumulasi Debitur',
        className: 'border',
        rowSpan: 2,
      },
      {
        text: 'Persentase Debitur',
        className: 'border',
        rowSpan: 2,
      },
      {
        text: 'Jumlah Debitur Sehat per Masing-masing Bulan',
        className: 'border',
        rowSpan: 2,
      },
      {
        text: 'Jumlah Lunas',
        className: 'border',
        rowSpan: 2,
      },
    ],
    [...columns, ...columns, ...columns, ...columns, ...columns],
  ];

  const deleteConfirm = (id) => {
    confirm({
      title: <h3 className="text-xl font-bold">Apakah Anda yakin ingin menghapus IBPA ini?</h3>,
      icon: <ExclamationCircleFilled />,
      centered: true,
      width: '50%',
      okText: 'Ya',
      okButtonProps: {
        className: 'custom-button custom-button-primary',
        style: { height: '48px' },
      },
      cancelText: 'Batalkan',
      cancelButtonProps: {
        className: 'custom-button custom-button-primary-ghost',
        style: { height: '48px' },
      },
      onOk() {
        dispatch(Report.DeleteReportInternal(id))
          .unwrap()
          .then(() => {
            navigate(`/reports?${location.search}`);
          });
      },
    });
  };

  return (
    <>
      {!loading ? (
        <div className="w-[400%]">
          <SimpleTable headers={tableHeader}>
            {internalDetail.length > 0 &&
              internalDetail.map((item, key) => (
                <tr key={key}>
                  {/* Monthly Report */}
                  <td className="border p-2 text-center">{item.laporan_bulanan}</td>
                  {/* Total Aum */}
                  {item.total_aum.aum.map((aum, index) => (
                    <td key={index} className="border p-2 text-center">
                      {Util.renderNumber(aum.total_aum)}
                    </td>
                  ))}
                  {!item.total_aum.aum.length && <td className="border p-2 text-center">-</td>}

                  {/* Payment */}
                  {item.payment.map((aum, index) => (
                    <td key={index} className="border p-2 text-center">
                      {aum.tanggal_pelunasan_bertahap}
                    </td>
                  ))}
                  {!item.payment.length && <td className="border p-2 text-center">-</td>}

                  {item.payment.map((aum, index) => (
                    <td key={index} className="border p-2 text-center">
                      {Util.renderNumber(aum.indikasi_pelunasan_bertahap)}
                    </td>
                  ))}
                  {!item.payment.length && <td className="border p-2 text-center">-</td>}

                  {item.payment.map((aum, index) => (
                    <td key={index} className="border p-2 text-center">
                      {Util.renderNumber(aum.indikasi_imbal_hasil)}
                    </td>
                  ))}
                  {!item.payment.length && <td className="border p-2 text-center">-</td>}

                  {item.payment.map((aum, index) => (
                    <td key={index} className="border p-2 text-center">
                      {Util.renderNumber(aum.total_imbal_hasil)}
                    </td>
                  ))}
                  {!item.payment.length && <td className="border p-2 text-center">-</td>}

                  {/* tanggal */}
                  {/* {item.ibpa.map((ibpa, index) => (
                    <td key={index} className="border p-2 text-center">
                      {ibpa.tanggal || '-'}
                    </td>
                  ))} */}
                  {item.ibpa[0]?.tanggal ? (
                    <td className="border p-2 text-center">{item.ibpa[0].tanggal}</td>
                  ) : (
                    <>
                      <td className="border p-2 text-center">-</td>
                      {ibpaChildren.map((_, index) => (
                        <td className="border p-2 text-center" key={index}>
                          -
                        </td>
                      ))}
                    </>
                  )}

                  {/* harga pasar wajar (ibpa) */}
                  {item.ibpa.map((ibpa, index) => (
                    <td id={ibpa.ibpa} key={index} className="border p-2 text-center">
                      {ibpa.ibpa ? (
                        <div className="flex justify-center items-center gap-5">
                          {ibpa.ibpa}
                          <BiTrash
                            style={{ cursor: 'pointer' }}
                            onClick={() => deleteConfirm(ibpa.id62)}
                          />
                        </div>
                      ) : (
                        '-'
                      )}
                    </td>
                  ))}

                  {/* debitur */}
                  <td className="border p-2 text-center">
                    {Util.renderNumber(item.debtor.jumlah_debitur)}
                  </td>
                  <td className="border p-2 text-center">
                    {Util.renderNumber(item.debtor.jumlah_sehat)}
                  </td>
                  <td className="border p-2 text-center">
                    {Util.renderNumber(item.debtor.jumlah_meninggal)}
                  </td>
                  <td className="border p-2 text-center">
                    {Util.renderNumber(item.debtor.jumlah_pengumpulan)}
                  </td>
                  <td className="border p-2 text-center">
                    {Util.renderNumber(item.debtor.rata2_jatuh_tempo)}
                  </td>
                  <td className="border p-2 text-center">
                    {Util.renderNumber(item.debtor.npl, { suffix: '%' })}
                  </td>
                  <td className="border p-2 text-center">
                    {Util.renderNumber(item.debtor.jumlah_debitur_awal)}
                  </td>
                  <td className="border p-2 text-center">
                    {Util.renderNumber(item.debtor.jumlah_debitur_per_masing2_bulan)}
                  </td>
                  <td className="border p-2 text-center">
                    {Util.renderNumber(item.debtor.jumlah_debitur_meninggal_per_masing2_bulan)}
                  </td>
                  <td className="border p-2 text-center">
                    {Util.renderNumber(item.debtor.akumulasi_debitur_meninggal)}
                  </td>
                  <td className="border p-2 text-center">
                    {item.debtor.persentase_debitur_meninggal || '-'}
                  </td>
                  <td className="border p-2 text-center">
                    {Util.renderNumber(item.debtor.jumlah_debitur_sehat_per_masing2_bulan)}
                  </td>
                  <td className="border p-2 text-center">
                    {Util.renderNumber(item.debtor.jumlah_lunas)}
                  </td>
                </tr>
              ))}
          </SimpleTable>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default TableReportInternal;
