/* eslint-disable no-unused-vars */
import { ExclamationCircleFilled } from '@ant-design/icons';
import { DatePicker, Modal, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TbDownload, TbFileDollar } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { AssetsComponent, ManagementEbaComponent } from '..';
import { Date, Format, Util } from '../../helper';
import { summaryHeader, summaryInflow } from '../../screens/management-eba/components/tableConfig';
import { Aquisition, Cashflow, PeriodicPayment } from '../../service';
import { CustomButton, CustomNotification, SimpleTable, Tabs } from '../_shared';
import { Constant } from '../../const';

const UpdateData = () => {
  const { isSupervisor } = Util.role();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  const isPublish = searchParams.get('is_publish');
  const fileId = searchParams.get('file-id');
  const firstPayment = searchParams.get('first_payment');
  const ebaName = searchParams.get('eba-name');
  const { confirm } = Modal;

  const dispatch = useDispatch();
  const { summaryPeriodic, summaryUpdateData, summary } = useSelector((state) => state.periodic);

  console.log(summaryPeriodic, 'periodic');

  const { detail } = useSelector((state) => state.eba);
  const { loss } = useSelector((state) => state.cashflow);
  const month = summary?.period_date ? Date.monthYear(summary?.period_date) : '';

  const [filterDate, setFilterDate] = useState('');
  const [loadingDownload, setLoadingDownload] = useState(false);

  const cardItems = [
    {
      // eslint-disable-next-line prettier/prettier
      title: `Cashflow Hilang Sampai ${dayjs(detail?.end_date).year()} : Rp ${
        Format.numberFormat(Math.ceil(summaryUpdateData?.loss)) || 0
      }`,
      description: 'lorem ipsum',
    },
    {
      title: `Cashflow Meninggal Dunia : Rp ${Format.numberFormat(Math.ceil(summary?.loss_die))}`,
      description: 'lorem ipsum',
    },
    {
      title: `Cashflow Lunas Dipercepat : Rp ${Format.numberFormat(
        Math.ceil(summary?.loss_paidoff)
      )}`,
      description: 'lorem ipsum',
    },
  ];

  const handleChange = (_, dateString) => {
    setFilterDate(dateString);
    dispatch(
      PeriodicPayment.summaryPeriodicPayment({
        ebaId,
        month: dateString,
        isPublish,
        fileId: '',
      })
    );
  };

  const fetchCashflowLoss = (year) => {
    dispatch(Cashflow.cashflowLoss({ ebaId, year }));
  };

  const tabItems = [
    {
      title: 'Sebelum Akuisisi',
      children: (
        <ManagementEbaComponent.ComparationCashflow items={loss} fetchItems={fetchCashflowLoss} />
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      PeriodicPayment.summaryPeriodicPayment({
        ebaId,
        month: filterDate,
        isPublish,
        fileId,
      })
    );
    fetchCashflowLoss(moment().format('YYYY'));
  }, []);

  const disabledDate = (date) => {
    const currnt = dayjs(date);
    return currnt < dayjs(firstPayment);
  };

  const showConfirm = () => {
    confirm({
      title: <h3 className="text-xl font-bold">Apakah Anda yakin ingin request akuisisi?</h3>,
      icon: <ExclamationCircleFilled />,
      centered: true,
      width: '30%',
      okText: 'Yes',
      okButtonProps: {
        className: 'custom-button custom-button-primary',
        style: { height: '48px' },
      },
      cancelText: 'Cancel',
      cancelButtonProps: {
        className: 'custom-button custom-button-secondary',
        style: { height: '48px' },
      },
      onOk() {
        if (!summaryUpdateData?.loss && !summary?.loss_die && !summary?.loss_paidoff) {
          CustomNotification({
            type: Constant.WARNING,
            message: 'Tidak dapat melakukan request akuisisi',
            description: 'Semua status lancar',
          });
          return;
        }

        const payload = {
          eba_id62: ebaId,
          date: summary?.transaction_date,
          cost: summaryUpdateData?.loss,
          lost_debtor: summaryUpdateData?.loss_count,
        };

        dispatch(Aquisition.acquisitionAdminRequest(payload))
          .unwrap()
          .catch((err) => {
            err?.msg?.map((res) => {
              CustomNotification({ type: Constant.ERROR, description: res });
            });
          })
          .then(() => {
            navigate(`/assets?page=product&eba-id=${ebaId}&eba-name=${ebaName}`);
          });
      },
      onCancel() {
        console.log('cancel');
      },
    });
  };

  const roundAmount = (amount) => {
    return Math.round(amount);
  };

  const downloadSummary = () => {
    const payload = {
      ebaId: detail.id62,
      ebaName: detail.name,
      periodDate: Date.periodUpdate(summary?.period_date),
      isPublish: true,
      fileId: fileId,
    };
    setLoadingDownload(true);
    dispatch(PeriodicPayment.periodicDownloadExcel(payload))
      .unwrap()
      .finally(() => {
        setLoadingDownload(false);
      });
  };

  const TdTable = ({ objKey }) => {
    return (
      <td className="border p-2 text-bold text-right">
        {summaryPeriodic.length > 0 &&
          Format.numberFormat(
            roundAmount(
              summaryPeriodic.reduce((a, b) => ({
                [objKey]: Number(a[objKey]) + Number(b[objKey]),
              }))?.[objKey]
            )
          )}
      </td>
    );
  };

  const TdItem = ({ objKey }) => (
    <td className="border p-2 text-right">{Format.numberFormat(roundAmount(objKey))}</td>
  );

  return (
    <>
      <div className="flex justify-between items-center gap-5 my-6">
        <h4 className="text-2xl font-bold">{month}</h4>
        <div className="flex justify-between items-center gap-5 my-5">
          <Space>
            <Typography.Paragraph className="font-bold text-sm">Filter :</Typography.Paragraph>
            <DatePicker
              onChange={handleChange}
              picker="month"
              className="border h-[48px] bg-white rounded-lg flex items-center w-[200px]"
              disabledDate={disabledDate}
            />
          </Space>
          <CustomButton
            loading={loadingDownload}
            type="primary"
            onClick={downloadSummary}
            className="flex items-center justify-start gap-2">
            <TbDownload size={20} />
            <span>Download Summary</span>
          </CustomButton>
          {!isSupervisor && (
            <CustomButton
              type="primary"
              onClick={showConfirm}
              className="flex items-center justify-start gap-2">
              <TbFileDollar size={20} />
              <span>Request Akuisisi</span>
            </CustomButton>
          )}
        </div>
      </div>
      <div className="grid grid-cols-3 gap-5 my-6">
        {cardItems.map((item, key) => (
          <AssetsComponent.Card title={item.title} key={key} cursor="" />
        ))}
      </div>
      <div className="flex justify-between items-center gap-5 my-6">
        <h4 className="text-basel font-bold">Summary Sebelum Pembayaran Bulan {month}</h4>
      </div>
      <div className="mb-5">
        <SimpleTable headers={summaryHeader}>
          {summaryPeriodic.length > 0 &&
            summaryPeriodic.map((item, key) => (
              <tr key={key} style={{ backgroundColor: '#fff' }}>
                <td className="border p-2 text-center">{item.description}</td>
                <td className="border p-2 text-center">{Format.numberFormat(item.debtor)}</td>
                <TdItem objKey={item.principal_bank} />
                <TdItem objKey={item.interest_installments_bank} />
                <TdItem objKey={item.total_installment_payment_bank} />
                <TdItem objKey={item.principal} />
                <TdItem objKey={item.interest_installments} />
                <TdItem objKey={item.total_installment_payment} />
              </tr>
            ))}
          <tr>
            <td className="border p-2 text-bold text-center">Sub Total</td>
            <td className="border p-2 text-bold text-center">
              {summaryPeriodic.length > 0 &&
                summaryPeriodic.reduce((a, b) => ({ debtor: a.debtor + b.debtor }))?.debtor}
            </td>
            <TdTable objKey="principal_bank" />
            <TdTable objKey="interest_installments_bank" />
            <TdTable objKey="total_installment_payment_bank" />
            <TdTable objKey="principal" />
            <TdTable objKey="interest_installments" />
            <TdTable objKey="total_installment_payment" />
          </tr>
        </SimpleTable>
      </div>
      <div className="mb-5">
        <SimpleTable headers={summaryInflow}>
          <tr style={{ backgroundColor: '#FFF' }}>
            <td className="border p-2 text-center">
              {summary?.period_date ? Date.monthYear(summary?.period_date) : '-'}
            </td>
            <TdItem objKey={summary?.cash_inflow} />
          </tr>
        </SimpleTable>
      </div>
      <h4 className="text-2xl font-bold mt-5 mb-3">Summary Cashflow yang hilang</h4>
      <h4 className="text-2xl font-bold">Perbandingan Cashflow</h4>
      <Tabs items={tabItems} />
    </>
  );
};

export default UpdateData;
