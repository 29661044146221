import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { BsBoxSeam } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ManagementEbaComponent, Page } from '../../components';
import { AcquisitionDataSummary } from '../../components/_shared';
import CustomTabs from '../../components/_shared/custom-tabs';
import { PageContent } from '../../const';
import { Date, Format, Util } from '../../helper';
import { Eba, Aquisition } from '../../service';

const ManagementEba = () => {
  const { isAdmin, isSupervisor } = Util.role();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  const approvalId = searchParams.get('approval-id');
  const status = searchParams.get('status');
  const show = searchParams.get('show');
  const lossCashflow = searchParams.get('loss-cashflow');
  const installmentTotal = searchParams.get('installment-total');
  const installmentTotalDie = searchParams.get('installment-total-die');
  const installmentTotalPaidoff = searchParams.get('installment-total-paidoff');
  const tabSelected = searchParams.get('tab');

  const { detail } = useSelector((state) => state.eba);
  const ebaName = detail?.name;

  const breadcrumbs = [
    { name: 'Management Produk', href: '/management-product' },
    {
      name: ebaName,
      href: `/assets?page=${PageContent.PRODUCT}&eba-id=${ebaId}&eba-name=${ebaName}`,
    },
    { name: 'Pengelolaan EBA', href: null },
  ];

  const [clickKey, setClickKey] = useState(tabSelected);

  useEffect(() => {
    dispatch(Eba.getDetailEba(ebaId));
  }, []);

  useEffect(() => {
    dispatch(
      Aquisition.acquisitionApprovalSummary({
        approvalId,
        ebaId,
      })
    );
  }, []);

  const SummaryShow = () => {
    const statusApproval = [3, 5, 7, 8].includes(Number(status));
    const summaryAcquisition = (
      <AcquisitionDataSummary
        title="Data Akuisisi Baru Diajukan"
        status={status}
        hasStaticDate={true}
      />
    );

    const dataAcquisition = (
      <ManagementEbaComponent.AquisitionData
        searchParams={searchParams}
        lossCashflow={lossCashflow}
        installmentTotal={installmentTotal}
      />
    );

    if (isSupervisor) return summaryAcquisition;
    if (isAdmin && statusApproval) return summaryAcquisition;
    return dataAcquisition;
  };

  const tabsItem = () => {
    return [
      {
        label: 'Update Data',
        key: '1',
        children: <ManagementEbaComponent.UpdateData />,
        disabled: show === 'acquisition',
      },
      {
        label: 'Data Akuisisi',
        key: '2',
        disabled: show === 'updated',
        children: <SummaryShow />,
      },
    ];
  };

  const cashflowLoss = [
    { title: 'Total Angsuran per Bulan Debitur Hilang', value: installmentTotal },
    {
      title: 'Total Angsuran Debitur Meninggal Dunia',
      value: installmentTotalDie,
    },
    { title: 'Total Angsuran Debitur Lunas Dipercepat', value: installmentTotalPaidoff },
  ];

  const onTabClick = (e) => {
    setClickKey(e);
  };

  return (
    <Page title="Pengelolaan EBA" breadcrumbs={breadcrumbs}>
      <div className="flex items-center space-x-3 mb-3">
        <div
          className="
            flex
            items-center
            justify-center
          bg-primary
            rounded-[50px]
            w-[48px]
            h-[48px]
              ">
          <BsBoxSeam size={20} color="#ffffff" className="font-bold" />
        </div>
        <Typography.Paragraph className="font-bold text-3xl">Pengelolaan EBA</Typography.Paragraph>
      </div>
      <Typography.Paragraph className="text-grey">
        Diperbarui {Date.parseTimeAgo(detail?.updated_at)}
      </Typography.Paragraph>
      {clickKey == 2 && isAdmin && (
        <div className="grid grid-cols-1 gap-2">
          {cashflowLoss?.map((res, i) => (
            <div
              key={i}
              className={`flex flex-1 justify-between p-5 rounded-lg border-1 border-[#F1F3F6] ${
                res.title ? 'bg-white' : ''
              }`}>
              {res.title && (
                <>
                  <Typography.Text className={`text-base font-bold ${i !== 0 ? ' pl-8' : ''}`}>
                    {res.title}
                  </Typography.Text>
                  <Typography.Text className="text-primary text-base font-bold">
                    Rp {Format.numberFormat(res.value)}
                  </Typography.Text>
                </>
              )}
            </div>
          ))}
        </div>
      )}
      <div>
        <CustomTabs onTabClick={onTabClick} defaultActiveKey={clickKey} items={tabsItem()} />
      </div>
    </Page>
  );
};

export default ManagementEba;
