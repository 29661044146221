import { Form, Input, Typography } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Page } from '../../components';
import { CustomButton, CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Login } from '../../service';

const CreateNewPassword = ({ isAuth = false }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const otp = searchParams.get('otp');

  const dispatch = useDispatch();

  const initValue = {
    newPassword: '',
    confirmPassword: '',
  };

  const onSubmit = (value) => {
    const body = {
      password: value.newPassword,
      forgot_token: otp,
    };

    dispatch(Login.setNewPassword(body))
      .unwrap()
      .then(() => {
        if (isAuth) {
          navigate('/edit-profile/change-password');
        } else {
          navigate('/sign-in');
        }
      })
      .catch(({ data }) => {
        const pass = data?.pasword || data?.password || [];
        pass?.map((res) => {
          CustomNotification({ type: Constant.ERROR, message: 'Failed', description: res });
        });
      });
  };

  const FormCreatePassword = () => (
    <Form layout="vertical" initialValues={initValue} requiredMark={false} onFinish={onSubmit}>
      <div className={`${isAuth ? 'w-full' : 'w-[360px]'} flex flex-col`}>
        <Typography.Text className="font-bold text-[40px] ">Create New Password</Typography.Text>
        <Form.Item
          label="New Password"
          name="newPassword"
          className="mt-16"
          validateFirst={true}
          rules={[
            {
              required: true,
              message: 'Field tidak boleh kosong',
            },
            { min: 8, message: 'Minimal length is 8 character' },
          ]}>
          <Input.Password
            placeholder="**********"
            className="custom-input mt-2 py-[18px] px-[24px]"
          />
        </Form.Item>

        <Form.Item
          label="Confirm New Password"
          name="confirmPassword"
          className="mb-1"
          validateFirst
          dependencies={['newPassword']}
          rules={[
            {
              required: true,
              message: 'Field tidak boleh kosong',
            },
            { min: 8, message: 'Minimal length is 8 character' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              },
            }),
          ]}>
          <Input.Password
            placeholder="**********"
            className="custom-input mt-2 py-[18px] px-[24px]"
          />
        </Form.Item>

        <CustomButton type="primary" className="mt-20" htmlType="submit">
          Create New Password
        </CustomButton>
      </div>
    </Form>
  );
  return (
    <Page title="Create Password">
      {isAuth ? (
        <FormCreatePassword />
      ) : (
        <div className="flex justify-end bg-sign-in bg-no-repeat bg-cover h-[100vh]">
          <div className="flex w-full max-w-[580px] h-full max-md:rounded-none bg-white items-center justify-center rounded-l-3xl">
            <FormCreatePassword />
          </div>
        </div>
      )}
    </Page>
  );
};

export default CreateNewPassword;
