import React from 'react';
import CreateProduct from './create-product';
import CreateSimulation from './create-simulation';
import { useSearchParams } from 'react-router-dom';

export default function CreateProductAndSimulation() {
  const [searchParams] = useSearchParams();
  const is_simulation = searchParams.get('is_simulation');
  return is_simulation === 'true' ? <CreateSimulation /> : <CreateProduct />;
}
