import { Typography } from 'antd';
import React, { useState } from 'react';
import { IoIosSend, IoMdArrowRoundBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EntryDataComponent, Page } from '../../components';
import { CustomNotification, CustomModalConfirmation } from '../../components/_shared';
import CustomButton from '../../components/_shared/custom-button';
import CustomTabs from '../../components/_shared/custom-tabs';
import { Constant } from '../../const';
import { Date, Util } from '../../helper';
import { PeriodicPayment } from '../../service';
import TotalData from './components/totalData';
import { MdFileDownload } from 'react-icons/md';

const UpdateData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idEba = searchParams.get('eba-id');
  const ebaName = searchParams.get('eba-name');
  const fileId = searchParams.get('file-id');
  const isPublish = searchParams.get('is_publish');

  const { summary, loadingDownload } = useSelector((state) => state.periodic);

  const [tabClick, setTabClick] = useState(1);
  const [loadingSend, setLoadingSend] = useState(false);
  const [isModalConfirmShow, setIsModalConfirmShow] = useState(false);

  const breadcrumbs = [
    { name: 'Management Produk', href: '/management-product' },
    { name: ebaName, href: `/assets?page=product&eba-id=${idEba}&eba-name=${ebaName}` },
    {
      name: 'Update Data',
      href: `/assets/upload-data-originator?page=product&eba-id=${idEba}&eba-name=${ebaName}`,
      isHide: isPublish == 'true',
    },
    {
      name: 'Masukkan Data',
      href: null,
    },
  ];

  const tabItems = [
    {
      label: 'Data Sesuai',
      children: (
        <EntryDataComponent.AppropiateData fileId={fileId} ebaId={idEba} isPublish={isPublish} />
      ),
    },
    {
      label: 'Data Tidak Ditemukan',
      children: (
        <EntryDataComponent.InappropiateData fileId={fileId} ebaId={idEba} tabClick={tabClick} />
      ),
    },
    // {
    //   label: 'Data Hilang',
    //   children: <EntryDataComponent.LostData idEba={idEba} />,
    // },
    {
      label: 'Data Tidak Sesuai',
      children: (
        <EntryDataComponent.NotCompletedData fileId={fileId} ebaId={idEba} tabClick={tabClick} />
      ),
    },
  ];

  const onTabClick = (e) => {
    setTabClick(e);
    Util.scrollToElement('tab-update-data');
  };

  const sendPeriodicSummary = () => {
    setLoadingSend(true);
    dispatch(PeriodicPayment.periodicSummarySend(fileId))
      .unwrap()
      .then(() => {
        CustomNotification({
          type: Constant.SUCCESS,
          message: 'Sukses Kirim Data',
        });
        navigate(`/assets?page=product&eba-id=${idEba}`);
        setIsModalConfirmShow(false);
        setLoadingSend(false);
      })
      .catch((err) => {
        CustomNotification({
          type: Constant.ERROR,
          message: 'Gagal Kirim Data',
          description: Util.errorHandle(err),
        });
        setIsModalConfirmShow(false);
        setLoadingSend(false);
      });
  };

  const onClickSend = () => {
    if (summary?.not_updateds > 0) {
      CustomNotification({ message: 'Masih ada data yang belum diupdate', type: Constant.WARNING });
      return;
    }
    setIsModalConfirmShow(true);
  };

  const onClickBack = () => {
    navigate(-1, { replace: true });
  };

  const onClickExportExcel = () => {
    const payload = {
      ebaId: idEba,
      ebaName: ebaName,
      periodDate: Date.periodUpdate(summary?.period_date),
      isPublish: isPublish == 'true',
      fileId: fileId,
    };
    dispatch(PeriodicPayment.periodicDownloadExcel(payload));
  };

  return (
    <Page title="Update Data" breadcrumbs={breadcrumbs}>
      <div className="flex justify-between items-center">
        <div className="flex space-x-3 items-center">
          <IoMdArrowRoundBack size={30} className="cursor-pointer" onClick={onClickBack} />
          <Typography.Paragraph className="font-bold text-3xl">Masukkan Data</Typography.Paragraph>
        </div>
        <div className="flex space-x-2">
          {tabClick == 1 && isPublish == 'false' && (
            <CustomButton
              onClick={onClickSend}
              loading={loadingSend}
              type="primary"
              className="
            group
            flex
            justify-center
            items-center
            space-x-2">
              <IoIosSend size={20} className="text-white group-hover:text-primary" />
              <span>Kirim Data</span>
            </CustomButton>
          )}
        </div>
      </div>
      <TotalData
        transactionDate={summary?.transaction_date}
        appropiateData={summary?.result}
        totalUpload={summary?.uploaded_count}
        consistent_count={summary.consistent_count}
        incomplete_count={summary.incomplete_count}
        inconsistent_count={summary.inconsistent_count}
      />
      <div className="flex justify-end">
        <CustomButton
          type="primary"
          onClick={onClickExportExcel}
          loading={loadingDownload}
          className="
            group
            justify-center
            items-center
            flex
            space-x-2 mt-5">
          <MdFileDownload size={20} className="text-white group-hover:text-primary" />
          Download Excel
        </CustomButton>
      </div>
      <CustomTabs
        id="tab-update-data"
        tabsItem={tabItems}
        onTabClick={onTabClick}
        className="mt-5"
      />
      <CustomModalConfirmation
        open={isModalConfirmShow}
        title="Apakah anda yakin dengan data diatas? Data yang sudah dikirim tidak dapat diubah"
        onConfirm={sendPeriodicSummary}
        onClose={() => setIsModalConfirmShow(false)}
      />
    </Page>
  );
};

export default UpdateData;
