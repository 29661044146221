import moment from 'moment';
import 'moment/locale/id';

const monthYear = (date) => {
  if (!date) return '-';
  return moment(date).format('MMMM YYYY');
};

const fullDate = (date) => {
  if (!date) return '-';
  return moment(date).format('DD MMMM YYYY');
};

const fullDateWithDash = (date) => {
  if (!date) return '-';
  return moment(date).format('DD-MMMM-YYYY');
};

const twoDigitWithDash = (date) => {
  if (!date) return '-';
  return moment(date).format('YYYY-MM-DD');
};

const fullDateFromUnix = (unix) => {
  if (!unix) return '-';
  return moment.unix(unix / 1000).format('DD MMMM YYYY');
};

const logDate = (date) => {
  if (!date) return '-';
  return moment(date).format('DD/MM/YYYY HH:mm:ss');
};

const parseTimeAgo = (date) => {
  if (!date) return '-';
  return moment(date).fromNow();
};

const yearMonthDay = (date) => {
  if (!date) return '-';
  return moment(date).format('YYYY MMMM DD');
};

const lastUpdate = (date) => {
  if (!date) return '-';
  return moment(date).format('YYYY-MM-DD HH:mm');
};

const periodUpdate = (date) => {
  if (!date) return '-';
  return moment(date).format('YYYY-MM');
};

const convertIdToEn = (month) => {
  const dictionary = {
    Januari: 'January',
    Februari: 'February',
    Maret: 'March',
    April: 'April',
    Mei: 'May',
    Juni: 'June',
    Juli: 'July',
    Agustus: 'August',
    September: 'September',
    Oktober: 'October',
    November: 'November',
    Desember: 'December',
  };

  return dictionary[month];
};

export default {
  convertIdToEn,
  monthYear,
  fullDate,
  fullDateFromUnix,
  logDate,
  fullDateWithDash,
  twoDigitWithDash,
  parseTimeAgo,
  yearMonthDay,
  lastUpdate,
  periodUpdate,
};
