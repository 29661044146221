import { Tooltip } from 'antd';
import Typography from 'antd/es/typography/Typography';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeFilter, setFilter } from '../../store/pool-of-asset';
import FilterInputType from './FilterInputType';

export const type = {
  TEXT: 'text',
  SELECT: 'select',
  NUMBER: 'number',
  DIVIDER: 'divider',
};

const FilterPoolOfAsset = () => {
  const dispatch = useDispatch();

  const { filter } = useSelector((state) => state.poa);

  const onChangeNumber = _.debounce((e, data) => {
    const payload = { id: data.id, name: data.name, data: e || '' };
    dispatch(setFilter(payload));
    if (!e) dispatch(removeFilter(data.id));
  }, 500);

  const FieldFilter = ({ fieldName = '', children, tooltip }) => (
    <div className="px-5 space-y-2">
      <Tooltip title={tooltip}>
        <Typography.Paragraph className="font-bold text-black">{fieldName}</Typography.Paragraph>
      </Tooltip>
      {children}
    </div>
  );

  const filterList = [
    {
      id: 'dbr_max',
      name: 'DBR Max',
      type: type.NUMBER,
      value: filter.dbr_max,
      onChange: onChangeNumber,
      tooltip: 'Perbandingan pendapatan bersih dengan cicilan utang perbulan',
    },
    {
      id: 'dbr_min',
      name: 'DBR Min',
      type: type.NUMBER,
      value: filter.dbr_min,
      onChange: onChangeNumber,
      tooltip: 'Perbandingan pendapatan bersih dengan cicilan utang perbulan',
    },
    {
      id: 'max',
      name: 'Saldo Pinjaman Max',
      type: type.NUMBER,
      value: filter.max,
      onChange: onChangeNumber,
    },
    {
      id: 'min',
      name: 'Saldo Pinjaman Min',
      type: type.NUMBER,
      value: filter.min,
      onChange: onChangeNumber,
    },
    {
      id: 'tenor_max',
      name: 'Tenor (Bulan) Max',
      type: type.NUMBER,
      value: filter.tenor_max,
      onChange: onChangeNumber,
      tooltip: 'Jangka waktu',
    },
    {
      id: 'tenor_min',
      name: 'Tenor (Bulan) Min',
      type: type.NUMBER,
      value: filter.tenor_min,
      onChange: onChangeNumber,
      tooltip: 'Jangka waktu',
    },
  ];

  return (
    <div className="rounded-2xl h-fit overflow-x-hidden border-grey_bg border-2 mx-5">
      <div className="bg-grey_bg px-5 py-3">
        <Typography.Paragraph className="font-bold text-base">Filter</Typography.Paragraph>
      </div>
      <div className="space-y-5 py-5">
        {filterList.map((res, i) => (
          <FieldFilter fieldName={res.name} key={i} tooltip={res?.tooltip}>
            <FilterInputType
              typeFilter={res.type}
              options={res.optionSelect}
              data={res}
              value={res?.value}
              onChange={res?.onChange}
            />
          </FieldFilter>
        ))}
      </div>
    </div>
  );
};

export default FilterPoolOfAsset;
