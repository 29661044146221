import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Util } from '../../helper';
import { PeriodicPayment } from '../../service';
import Table from '../table';
import { columnsHistoryUpdate } from './components/columns';

const DataUpdate = ({ navigate, idEba, ebaName, tabSelected, cardSelected, pageContent }) => {
  const { isOriginator } = Util.role();
  const dispatch = useDispatch();
  const { history, loading } = useSelector((state) => state.periodic);

  const [page, setPage] = useState({ limit: 10, offset: 0, current: 1 });

  useEffect(() => {
    const payload = {
      ebaId: idEba,
      limit: page.limit,
      offset: page.offset,
      isArchive: cardSelected === 2,
    };
    if (idEba) dispatch(PeriodicPayment.historyPeriodicPayment(payload));
  }, [page, cardSelected, idEba]);

  const onClickDetail = (record) => {
    const period = moment(record.period).format('YYYY-MM-DD');
    const firstPayment = moment(record.eba.first_payment_date).format('YYYY-MM');
    const date = moment(record?.updated_at).format('YYYY-MM-');
    const showData = 'updated';
    let query = `/assets/update-data?page=${pageContent}&eba-id=${idEba}&eba-name=${ebaName}&file-id=${record.file.id62}&is_publish=true&tab=${tabSelected}&period=${period}&first_payment=${firstPayment}&show=${showData}`;
    if (!isOriginator) {
      query = `/management-eba?page=${pageContent}&eba-id=${idEba}&eba-name=${ebaName}&file-id=${record.file.id62}&is_publish=true&is-update=true&updated=${date}&eba-name=${ebaName}&tab=${tabSelected}&period=${period}&first_payment=${firstPayment}&show=${showData}`;
    }
    navigate(query);
  };

  const paginationChange = (pages, pageSize) => {
    setPage({ ...page, offset: (pages - 1) * pageSize, current: pages });
  };

  return (
    <Table.TableGlobal
      dataSource={history?.results}
      columns={columnsHistoryUpdate(onClickDetail)}
      totalData={history?.count}
      loading={loading}
      current={page.current}
      onChangePagination={paginationChange}
      scroll={{ x: 900 }}
    />
  );
};

export default DataUpdate;
