import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Page } from '../../components';
import { DatePicker, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { TiWarningOutline } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { Format, Util } from '../../helper';
import { Eba } from '../../service';
import Graph from './reinvestment-graph/graph';
import usePage from '../../hooks/usePage';

const ReinvestmentSummary = () => {
  const dispatch = useDispatch();
  const { isSupervisor } = Util.role();
  const { isProduct } = usePage();
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  const isSimulation = searchParams.get('is_simulation');

  const { summary, detail } = useSelector((state) => state.eba);

  const [startDate, setStartDate] = React.useState(
    dayjs(detail?.first_payment_date).add(2, 'day').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = React.useState(
    dayjs(detail?.first_payment_date).add(1, 'year').format('YYYY-MM-DD')
  );
  const [status, setStatus] = React.useState(false);

  React.useEffect(() => {
    dispatch(Eba.getDetailEba(ebaId));
  }, [ebaId]);

  React.useEffect(() => {
    const range = dayjs(startDate).add(1, 'month').format('YYYY-MM-DD') === endDate;
    setStatus(range);
  }, [startDate, endDate]);

  React.useEffect(() => {
    if (startDate && endDate) {
      dispatch(
        Eba.getSummaryEba({
          ebaId: ebaId,
          is_simulation: isSimulation,
          startDate: startDate,
          endDate: endDate,
        })
      );
    }
  }, [ebaId, isSimulation, startDate, endDate]);

  const breadcrumbs = [
    {
      name: `${isSimulation === 'true' ? 'Simulasi Produk' : 'Management Produk'}`,
      href: `${isSimulation === 'true' ? '/simulation' : '/management-produk'}`,
    },
    {
      name: 'Draft Produk',
      href: '/management-product/?status=1',
      isHide: isSupervisor || isProduct,
    },
    { name: 'Management Asset', href: null, isHide: !isProduct },
    { name: 'Re-Investment', href: null, goBack: true },
    { name: 'Summary', href: null },
  ];

  const disabledDate = (date) => {
    const currnt = dayjs(date);
    const firstPayment = dayjs(detail?.first_payment_date);
    const endDateEba = dayjs(detail?.end_date);
    return currnt < firstPayment || currnt >= endDateEba;
  };

  const rangeChange = (date) => {
    date !== null ? setStartDate(dayjs(date[0]?.$d).format('YYYY-MM-DD')) : setStartDate(null);
    date !== null ? setEndDate(dayjs(date[1]?.$d).format('YYYY-MM-DD')) : setEndDate(null);
  };

  return (
    <Page title="Reinvestment Summary" breadcrumbs={breadcrumbs}>
      <Typography.Paragraph className="font-bold text-2xl mb-6">
        Investasi - {summary?.results?.eba?.name}
      </Typography.Paragraph>
      <>
        <div className="my-6 flex gap-4 flex-row">
          <div className="bg-white gap-4 p-3 w-1/2 flex flex-col flex-grow rounded-md">
            <Typography.Paragraph className="font-semibold text-base">
              Persentase Investasi
            </Typography.Paragraph>
            <Typography.Paragraph className="font-semibold text-2xl text-primary">
              {summary?.results?.investment?.total_percent} %
            </Typography.Paragraph>
          </div>
          <div className="bg-white gap-4 p-3 w-1/2 flex flex-col flex-grow rounded-md">
            <Typography.Paragraph className="font-semibold text-base">
              Nilai Investasi
            </Typography.Paragraph>
            <Typography.Paragraph className="font-semibold text-2xl text-primary">
              {Format.numberFormat(Math.round(summary?.results?.investment?.total_amount))}
            </Typography.Paragraph>
          </div>
        </div>

        <div className="w-full box-border max-h-[400px] rounded-xl px-6 pt-6 bg-white">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <Tooltip
                title="Filter minimal berjarak 2 bulan"
                placement="topLeft"
                defaultOpen={status}
                className="relative">
                <TiWarningOutline
                  size={18}
                  className="
                absolute
                top-[-14px]
                left-[-14px] "
                  color="#fdcc00"
                />
                <Typography.Paragraph className="font-bold text-2xl">
                  Proyeksi Keuangan
                </Typography.Paragraph>
              </Tooltip>
              {/* <Typography.Paragraph className="font-medium text-xs">{year}</Typography.Paragraph> */}
            </div>
            <div className="flex flex-row gap-2 items-center justify-end">
              <DatePicker.RangePicker
                picker="month"
                onChange={(e) => rangeChange(e)}
                format="YYYY-MM-DD"
                disabledDate={disabledDate}
                status={status && 'warning'}
              />
            </div>
          </div>
          <div className="h-[300px]">
            <Graph item={summary?.results?.financial_projections} />
          </div>
        </div>
      </>
    </Page>
  );
};

export default ReinvestmentSummary;
