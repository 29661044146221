import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Param } from '../../service';

const initialState = {
  bei: [],
  loading: false,
};

export const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create bei
    builder.addCase(Param.createBei.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Param.createBei.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Sukses Create',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Param.createBei.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Create',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // get bei
    builder.addCase(Param.getBei.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Param.getBei.fulfilled, (state, action) => {
      state.loading = false;
      state.bei = action.payload.data;
    });
    builder.addCase(Param.getBei.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // put bei
    builder.addCase(Param.updateBei.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Param.updateBei.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Sukses Ubah',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Param.updateBei.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Ubah',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });

    // delete bei
    builder.addCase(Param.deleteBei.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Param.deleteBei.fulfilled, (state, action) => {
      state.loading = false;
      CustomNotification({
        type: Constant.SUCCESS,
        message: 'Sukses Hapus',
        description: action?.payload?.message?.id,
      });
    });
    builder.addCase(Param.deleteBei.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Gagal Hapus',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });
  },
});

export default noteSlice.reducer;
