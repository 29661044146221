import React from 'react';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { ManagementProductComponent } from '../..';
import { useDispatch } from 'react-redux';
import { Revenue } from '../../../service';
import { useSearchParams } from 'react-router-dom';
import { CustomNotification } from '../../_shared';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';

export default function EarningPercentageAction({ item, setCurrent, setTotal }) {
  const { confirm } = Modal;
  const [open, setOpen] = React.useState(false);
  const [searchParam] = useSearchParams();
  const ebaId = searchParam.get('eba-id');
  const dispatch = useDispatch();

  const showConfirm = () => {
    confirm({
      title: (
        <h3 className="text-xl font-bold">Apakah Anda yakin ingin menghapus nilai earning ini?</h3>
      ),
      icon: <ExclamationCircleFilled />,
      centered: true,
      width: '30%',
      okText: 'Delete',
      okButtonProps: {
        className: 'custom-button custom-button-primary',
        style: { height: '48px' },
      },
      cancelText: 'Cancel',
      cancelButtonProps: {
        className: 'custom-button custom-button-secondary',
        style: { height: '48px' },
      },
      onOk() {
        dispatch(Revenue.deleteRevenuePeriod(item?.id62))
          .unwrap()
          .then((res) => {
            dispatch(Revenue.revenuePeriod({ ebaId: ebaId }));
            CustomNotification({
              type: 'success',
              message: 'Success Delete Percentage',
              description: res.message?.id,
            });
          });
        Modal.destroyAll();
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  return (
    <>
      <div className="flex justify-center gap-5">
        <BiPencil style={{ cursor: 'pointer' }} onClick={() => setOpen(true)} />
        <BiTrash style={{ cursor: 'pointer' }} onClick={showConfirm} />
      </div>
      <ManagementProductComponent.EarningPercentageForm
        open={open}
        onClose={() => setOpen(false)}
        id62={item?.id62}
        isUpdate={true}
        ebaId={item?.eba}
        record={item}
        setCurrent={setCurrent}
        setTotal={setTotal}
      />
    </>
  );
}
