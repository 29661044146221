import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Waterfall } from '../../service';
import { Constant } from '../../const';

const initialState = {
  data: [],
  loading: false,
  rerender: false,
};

export const userSlice = createSlice({
  name: 'waterfall',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get cash waterfall
    builder.addCase(Waterfall.cashWaterfall.pending, (state) => {
      state.loading = true;
      state.rerender = !state.rerender;
    });
    builder.addCase(Waterfall.cashWaterfall.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.loading = false;
      state.rerender = !state.rerender;
    });
    builder.addCase(Waterfall.cashWaterfall.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'failed get data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
      state.rerender = !state.rerender;
    });
  },
});

export default userSlice.reducer;
