import { Typography } from 'antd';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Page, CustomTable } from '../../components';
import { columnsFeedbackAcquisition, dummyFeedback } from './columns';

const FeedbackAcquisition = () => {
  const [searchParams] = useSearchParams();
  const ebaName = searchParams.get('eba-name');
  const ebaId = searchParams.get('eba-id');

  const breadcrumbs = [
    { name: 'Management Produk', href: '/management-product' },
    { name: ebaName, href: `/assets?page=product&eba-id=${ebaId}` },
    { name: 'Feedback Data Akuisisi', href: null },
  ];
  return (
    <Page title="Feedback Akuisisi" breadcrumbs={breadcrumbs}>
      <div>
        <Typography.Paragraph className="font-bold text-3xl">
          Feedback Data Akusisi
        </Typography.Paragraph>
        <CustomTable.TableGlobal dataSource={dummyFeedback} columns={columnsFeedbackAcquisition} />
      </div>
    </Page>
  );
};

export default FeedbackAcquisition;
