const required = [
  {
    required: true,
    message: 'Field tidak boleh kosong',
  },
];

const rulesPeriodStart = [...required, { pattern: /^[0-9]*$/, message: 'Hanya masukkan angka' }];

const rulesPeriodEnd = (ebaPeriodDiff) => [
  ...required,
  { pattern: /^[0-9]*$/, message: 'Hanya masukkan angka' },
  () => ({
    validator(_, value) {
      if (Number(value) > ebaPeriodDiff) {
        return Promise.reject(`Melebihi periode maksimal eba ${ebaPeriodDiff}`);
      }

      return Promise.resolve();
    },
  }),
];

const rulesPercent = [
  ...required,
  () => ({
    validator(_, value) {
      if (!Number(value)) {
        return Promise.reject('Hanya masukkan angka');
      }
      if (value > 100) {
        return Promise.reject(new Error(`Maksimal 100%`));
      }
      if (value < 1) {
        return Promise.reject(new Error(`Minimal 1%`));
      }
      return Promise.resolve();
    },
  }),
];

export default { rulesPeriodStart, rulesPeriodEnd, rulesPercent };
