import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssetsComponent, ManagementUserComponent, Page } from '../../components';
import { Tabs } from '../../components/_shared';
import { Util } from '../../helper';
import { User, UserLog } from '../../service';
import { logging, user, userSPV } from './components/tableConfig';

const ManagementUser = () => {
  const [activeCard, setActiveCard] = useState(0);
  const [groups, setGroup] = useState('internal');
  const [search, setSearch] = useState('');
  const [timeStamps, setTimestamps] = useState(['', '']);
  const { isSupervisor } = Util.role();

  const dispatch = useDispatch();

  const userModule = useSelector((state) => state.user);
  const userLogModule = useSelector((state) => state.userLog);

  const fetchUser = ({ limit, offset, group, page }) => {
    dispatch(User.userList({ limit, offset, group, page }));
  };

  const fetchUserLog = ({ search, limit, offset, page, timestamp_from, timestamp_to }) => {
    dispatch(UserLog.userLogList({ search, limit, offset, page, timestamp_from, timestamp_to }));
  };

  const convertTimestamp = (timestamp) => {
    if (!timestamp) return '';

    // change DD/MM/YYYY HH:mm:ss to YYYY-MM-DDTHH:mm:ss
    const [date, time] = timestamp.split(' ');
    const [day, month, year] = date.split('/');

    return `${year}-${month}-${day}T${time}`;
  };

  useEffect(() => {
    fetchUser({ limit: 5, offset: 0, group: groups, page: 1 });
    fetchUserLog({
      search: '',
      limit: 5,
      offset: 0,
      page: 1,
      timestamp_from: '',
      timestamp_to: '',
    });
  }, []);

  const handleTabChange = (data) => {
    if (data === 0) {
      fetchUser({ limit: 5, offset: 0, group: 'internal', page: 1 });
      setGroup('internal');
    } else {
      fetchUser({ limit: 5, offset: 0, group: 'originator', page: 1 });
      setGroup('originator');
    }
  };

  const handleCardChange = (key) => {
    setActiveCard(key);
    if (key === 0) {
      fetchUser({ limit: 5, offset: 0, group: 'internal', page: 1 });
      setGroup('internal');
    } else {
      const [timestamp_from, timestamp_to] = timeStamps;
      fetchUserLog({ search: '', limit: 5, offset: 0, page: 1, timestamp_from, timestamp_to });
    }
  };

  useEffect(() => {
    const [timestamp_from, timestamp_to] = timeStamps;
    fetchUserLog({
      search: search,
      limit: 5,
      offset: 0,
      page: 1,
      timestamp_from: convertTimestamp(timestamp_from),
      timestamp_to: convertTimestamp(timestamp_to),
    });
  }, [search, timeStamps]);

  const cardItems = [
    { id: 0, title: 'Data User', description: 'Data pengguna yang sudah ditambahkan' },
    { id: 1, title: 'Logging User', description: 'Pencatatan aktivitas setiap pengguna' },
  ];

  const tabItems = [
    {
      title: 'Internal',
      children: (
        <ManagementUserComponent.UserData
          items={userModule.items}
          total={userModule.itemTotal}
          columns={isSupervisor ? userSPV.column : user.column}
          loading={userModule.loading}
          paginationSync={userModule?.paginationSync}
          fetchData={fetchUser}
          group={groups}
        />
      ),
    },
    {
      title: 'Originator',
      children: (
        <ManagementUserComponent.UserData
          items={userModule.items}
          total={userModule.itemTotal}
          columns={isSupervisor ? userSPV.column : user.column}
          loading={userModule.loading}
          paginationSync={userModule?.paginationSync}
          fetchData={fetchUser}
          group={groups}
        />
      ),
    },
  ];

  return (
    <Page title="Management User">
      <Typography.Paragraph className="font-bold text-3xl">Management User</Typography.Paragraph>
      <div className="grid grid-cols-2 gap-5 my-6">
        {cardItems.map((item) => (
          <AssetsComponent.Card
            title={item.title}
            description={item.description}
            key={item.id}
            onClick={() => handleCardChange(item.id)}
            isClick={item.id === activeCard}
          />
        ))}
      </div>
      <div>
        {!activeCard ? (
          <Tabs items={tabItems} onChange={handleTabChange} />
        ) : (
          <ManagementUserComponent.UserLogData
            items={userLogModule.items}
            total={userLogModule.itemTotal}
            columns={logging.column}
            loading={userLogModule.loading}
            fetchData={fetchUserLog}
            paginationSync={userLogModule.paginationSync}
            search={search}
            setSearch={setSearch}
            timeStamps={timeStamps}
            setTimestamps={setTimestamps}
          />
        )}
      </div>
    </Page>
  );
};

export default ManagementUser;
