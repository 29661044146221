import { message } from 'antd';
import { Constant } from '../../../const';

const CustomMessage = ({
  type = Constant.SUCCESS || Constant.INFO || Constant.WARNING || Constant.ERROR,
  messages = '',
  duration = 3,
  onClose,
}) => {
  message.open({
    type: type,
    content: messages,
    duration: duration,
    onClose: onClose,
  });
};

export default CustomMessage;
