import { Modal, Result, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';
import { MdWarning } from 'react-icons/md';
import CustomButton from '../custom-button';

const ModalApprove = ({
  openModal,
  isApprove,
  notes,
  setNotes,
  onClickCancel,
  onClickSend,
  loading,
}) => {
  return (
    <Modal open={openModal} footer={null} closable={false}>
      <Result
        icon={
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center bg-primary w-[60px] h-[60px] rounded-[50px]">
              <MdWarning color="white" size={36} />
            </div>
          </div>
        }
        title={
          <Typography.Paragraph className="font-bold text-base">{`Apakah anda yakin ${
            isApprove ? 'ingin' : 'tidak'
          } menyetujui permintaan ini?`}</Typography.Paragraph>
        }
        extra={
          <div className="space-y-5">
            <div>
              <Typography.Paragraph className="text-left font-bold">Catatan</Typography.Paragraph>
              <TextArea
                rows={4}
                className="custom-input"
                placeholder="Masukkan catatan"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
            <div className="space-x-4">
              <CustomButton
                type="primary-ghost"
                key="cancel"
                onClick={onClickCancel}
                className="w-24">
                Batalkan
              </CustomButton>
              <CustomButton
                type="primary"
                key="send"
                onClick={onClickSend}
                className="w-24"
                loading={loading}>
                Kirim
              </CustomButton>
            </div>
          </div>
        }
      />
    </Modal>
  );
};

export default ModalApprove;
