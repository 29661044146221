import dayjs from 'dayjs';
// eslint-disable-next-line no-useless-escape
let domainRegex = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/);

const fieldRequired = [
  {
    required: true,
    message: 'Field tidak boleh kosong',
  },
];

const rulesEmailDomain = [
  ...fieldRequired,
  () => ({
    validator(_, value) {
      const notSymbol = [];
      const domainNotValid = [];
      value?.map((res) => {
        if (res[0] !== '@') {
          notSymbol.push(res);
        } else {
          if (!domainRegex.test(res.substring(1))) {
            domainNotValid.push(res);
          }
        }
      });
      if (notSymbol.length > 0) {
        return Promise.reject(
          new Error(`Domain diawali dengan simbol '@'. Perbaiki domain berikut ${notSymbol}`)
        );
      }
      if (domainNotValid.length > 0) {
        return Promise.reject(
          new Error(`Domain tidak sesuai. Perbaiki domain berikut ${domainNotValid}`)
        );
      }
      return Promise.resolve();
    },
  }),
];

const rulesDateBetweenPeriod = [
  ...fieldRequired,
  ({ getFieldValue }) => ({
    validator(_, value) {
      const current = dayjs(value);
      const end = getFieldValue('end_date');
      const start = getFieldValue('start_date');
      const endDate = dayjs(end);
      const startDate = dayjs(start);

      if (current > endDate && end) {
        return Promise.reject(new Error(`Melebihi periode terakhir`));
      }
      if (current < startDate && start) {
        return Promise.reject(new Error(`Kurang dari periode pertama`));
      }
      return Promise.resolve();
    },
  }),
];

const rulesIssurance = [
  ...fieldRequired,
  () => ({
    validator(_, value) {
      if (isNaN(value)) {
        return Promise.reject('Hanya masukkan angka');
      }
      return Promise.resolve();
    },
  }),
];

const rulesFirstPeriod = [
  ...fieldRequired,
  ({ getFieldValue }) => ({
    validator(_, value) {
      const current = dayjs(value);
      const end = getFieldValue('end_date');
      const endDate = dayjs(end);
      const diff = endDate.diff(current, 'month');

      if (diff < 1 && end) {
        return Promise.reject(new Error(`Periode kurang dari 1 bulan`));
      }
      return Promise.resolve();
    },
  }),
];

const rulesLastPeriod = [
  ...fieldRequired,
  ({ getFieldValue }) => ({
    validator(_, value) {
      const current = dayjs(value);
      const start = getFieldValue('start_date');
      const startDate = dayjs(start);
      const diff = current.diff(startDate, 'month');

      if (diff < 1 && start) {
        return Promise.reject(new Error(`Periode kurang dari 1 bulan`));
      }
      return Promise.resolve();
    },
  }),
];

export default {
  rulesDateBetweenPeriod,
  fieldRequired,
  rulesIssurance,
  rulesFirstPeriod,
  rulesLastPeriod,
  rulesEmailDomain,
};
