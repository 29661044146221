import { Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomTable, ManagementProductComponent, Page } from '../../components';
import { ApproveDeclineModal, CustomButton } from '../../components/_shared';
import { Util } from '../../helper';
import usePage from '../../hooks/usePage';
import { Cost, Eba, Investment, Transaction } from '../../service';
import { costColumn } from './components/tableConfig';

export default function CostPage() {
  const { isProduct } = usePage();
  const { isSupervisor, isAdmin } = Util.role();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  const isSimulation = searchParams.get('is_simulation');
  const status = searchParams.get('status');
  const ebaName = searchParams.get('eba-name');
  const page = searchParams.get('page') || 'management-product';

  const { items, itemTotal, loading } = useSelector((state) => state.cost);
  const { allClassComplete } = useSelector((state) => state.investment);

  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [budget, setBudget] = useState(0);
  const [hasBuffer, setHasBuffer] = useState(false);
  const [openAddData, setOpenAddData] = useState(false);
  const [openBei, setOpenBei] = useState(false);

  const breadcrumbs = [
    {
      name: `${isSimulation === 'true' ? 'Simulasi Produk' : 'Management Produk'}`,
      href: '/management-product',
    },
    {
      name: 'Draft Produk',
      href: '/management-product/?status=1',
      isHide: isProduct || isSupervisor,
    },
    {
      name: 'Management Asset',
      href: `/management-product/eba-info?page=${page}&eba-id=${ebaId}&eba-name=${ebaName}&status=${status}`,
      isHide: !isProduct,
    },
    { name: 'Biaya Berjalan', href: null },
  ];

  const paginationChange = (page, pageSize) => {
    fetchData({ limit: 10, offset: (page - 1) * pageSize, page: page });
  };

  const fetchData = ({ limit, offset, page = 1 }) => {
    dispatch(Cost.getCostDashboard({ id: ebaId, limit, offset, page }));
  };

  useEffect(() => {
    fetchData({ limit: 10, offset: 0 });
    dispatch(Eba.getDetailEba(ebaId))
      .unwrap()
      .then((res) => {
        setBudget(res?.data?.outstanding_amount);
      });
    dispatch(Transaction.getTransaction({ ebaId: ebaId, limit: 5, offset: 0, page: 1 }))
      .unwrap()
      .then((res) => {
        setHasBuffer(res?.data?.data.length > 0);
      });
    dispatch(Investment.getClass({ ebaId: ebaId, limit: 100, offset: 0, page: 1 }));
  }, [ebaId]);

  const disabledAddData = () => {
    let disabled = true;
    if (Number(budget) === 0) {
      return disabled;
    }
    return hasBuffer && !allClassComplete;
  };

  const ButtonSelanjutnya = ({ disabled, redirect }) => (
    <Tooltip title={disabled ? 'Anda belum menambahkan biaya' : ''} placement="topRight">
      <div>
        <CustomButton
          type="primary"
          disabled={disabled}
          {...(disabled && { style: { pointerEvents: 'none' } })}
          onClick={() =>
            redirect(
              // eslint-disable-next-line prettier/prettier
              `/management-product/re-investment?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}&status=${status}&is_simulation=${
                isSimulation === 'true' ? 'true' : 'false'
              }`
            )
          }>
          Selanjutnya
        </CustomButton>
      </div>
    </Tooltip>
  );

  const ButtonAdd = ({ disabled }) => (
    <Tooltip
      title={
        disabled ? 'Senior Tranches belum diatur, tidak memiliki buffer atau budget belum 0' : ''
      }
      placement="topRight">
      <div>
        <CustomButton
          type="primary-ghost"
          className="
            group
            justify-center
            items-center
            flex
            space-x-2"
          disabled={disabled}
          onClick={() => setOpenAddData(true)}>
          <BsPlusCircleFill
            size={20}
            className={disabled ? 'text-grey' : 'text-primary group-hover:text-white'}
          />
          Input Data
        </CustomButton>
      </div>
    </Tooltip>
  );

  return (
    <Page title="Biaya Berjalan" breadcrumbs={breadcrumbs}>
      <Typography.Paragraph className="font-bold text-3xl">Biaya Berjalan</Typography.Paragraph>
      <Typography.Paragraph className="font-bold text-xl mt-2">
        {ebaName ? ebaName : null}
      </Typography.Paragraph>
      <div className="flex justify-between items-center gap-5 my-6">
        <Typography.Paragraph className="text-base">
          Dibawah ini merupakan informasi detail mengenai cost yang {!isProduct && 'akan'}{' '}
          dikeluarkan pada periode tertentu
        </Typography.Paragraph>

        <div className="flex space-x-3">
          <CustomButton
            type="primary-ghost"
            onClick={() =>
              navigate(
                `/management-product/cost-summary?page=${page}&eba-name=${ebaName}&eba-id=${ebaId}&status=${status}`
              )
            }>
            Summary Cost
          </CustomButton>
          {isAdmin && (
            <CustomButton
              type="primary-ghost"
              className="
            group
            justify-center
            items-center
            flex
            space-x-2"
              // disabled={disabled}
              onClick={() => setOpenBei(true)}>
              Atur BEI Parameter
            </CustomButton>
          )}
          {!isSupervisor && <ButtonAdd disabled={disabledAddData()} />}
        </div>
      </div>
      <div className="mb-5 mt-5">
        <CustomTable.TableGlobal
          keyRow="date"
          dataSource={items}
          columns={costColumn}
          loading={loading}
          pagination={{
            pageSize: 10,
            onChange: paginationChange,
            showSizeChanger: false,
            total: itemTotal,
          }}
        />
      </div>
      {isAdmin && !isProduct && (
        <div className="flex flex-row-reverse items-center gap-5">
          <ButtonSelanjutnya disabled={items?.length === 0} redirect={navigate} />
          <CustomButton
            type="primary-ghost"
            onClick={() =>
              navigate(
                `/management-product/asset-class?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}&status=${status}`
              )
            }>
            Kembali
          </CustomButton>
        </div>
      )}
      {isSupervisor &&
        !isProduct &&
        (isSimulation === 'true' ? (
          <>
            <div className="flex flex-row-reverse mt-5 gap-5">
              <CustomButton
                type="primary"
                onClick={() => {
                  navigate(
                    `/management-product/re-investment?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}&is_simulation=true`
                  );
                }}>
                Next
              </CustomButton>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row-reverse mt-5 gap-5">
              <CustomButton
                type="primary"
                onClick={() => {
                  setOpen(true);
                  setType('approve');
                }}>
                Approve
              </CustomButton>
              <CustomButton
                type="primary-ghost"
                onClick={() => {
                  setOpen(true);
                  setType('decline');
                }}>
                Decline
              </CustomButton>
            </div>
            <ApproveDeclineModal
              type={type}
              open={open}
              onClose={() => setOpen(false)}
              ebaId={ebaId}
              page={'Biaya'}
              navigate={`/management-product/re-investment?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}&status=${status}`}
            />
          </>
        ))}
      <ManagementProductComponent.BeiSet ebaId={ebaId} open={openBei} setOpen={setOpenBei} />
      <ManagementProductComponent.CostForm
        open={openAddData}
        onClose={() => setOpenAddData(false)}
        ebaId={ebaId}
      />
    </Page>
  );
}
