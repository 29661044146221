import { Select, Typography } from 'antd';
import React, { Fragment, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ApproveDeclineModal, CustomButton } from '../../../components/_shared';
import { Date, Format, Util } from '../../../helper';
import usePage from '../../../hooks/usePage';
import { Eba } from '../../../service';
import { EditOutlined, LoadingOutlined, SaveOutlined } from '@ant-design/icons';

export default function CreateProductSupervisor() {
  const { isProduct } = usePage();
  const { isAdmin } = Util.role();
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  const ebaName = searchParams.get('eba-name');

  const dispatch = useDispatch();
  const { detail } = useSelector((state) => state.eba);

  const [open, setOpen] = useState(false);
  const [isEditEmail, setEditEmail] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [emails, setEmails] = useState([]);
  const [type, setType] = useState('');

  const options = (mail) => {
    return mail?.map((res) => {
      return { label: res, value: res };
    });
  };

  const FieldInfo = useCallback(
    ({ title, value }) => (
      <div className="flex">
        <Typography.Text className="text-base w-1/2 p-4 border-1 border-grey_bg">
          {title}
        </Typography.Text>
        <Typography.Text className="text-base w-1/2 p-4 border-1 border-grey_bg">
          {value}
        </Typography.Text>
      </div>
    ),
    [detail]
  );

  const onChangeEmail = (e) => {
    setEmails(e);
  };

  const onClickSaveEmail = () => {
    if (emails.length === 0 || detail?.email?.length === emails.length) {
      // no changes
      setEditEmail(false);
      return;
    }
    setLoadingEmail(true);

    const payload = {
      email: emails,
    };
    dispatch(Eba.editEba({ id: ebaId, payload }))
      .unwrap()
      .then(() => {
        setEditEmail(false);
        dispatch(Eba.getDetailEba(ebaId));
        setLoadingEmail(false);
      })
      .catch(() => {
        setEditEmail(false);
        setLoadingEmail(false);
      });
  };

  return (
    <>
      <div className="bg-white rounded-lg mt-3">
        <FieldInfo title="Nama" value={detail.name} />
        <FieldInfo title="Penerbit" value={detail.publisher} />
        <FieldInfo title="Kreditur Awal & Agen Pengumpul" value={detail.initial_creditor} />
        <FieldInfo title="Bank Kustodian" value={detail.custodian_bank} />
        <FieldInfo title="Pencatatan" value={detail.registration} />
        <FieldInfo title="Amortisasi" value={detail.amortization} />
        <FieldInfo title="Asset Dasar Investasi" value={detail.basic_asset} />
        <FieldInfo title="Nilai Penerbitan" value={Format.numberFormat(detail.issuance_value)} />
        <FieldInfo
          title="Tanggal Arus Kas Pertama dari Originator"
          value={Date.twoDigitWithDash(detail.first_payment_date)}
        />
        <FieldInfo
          title="Tanggal Arus Kas Terakhir dari Originator"
          value={Date.twoDigitWithDash(detail.final_cashflow_date)}
        />
        <FieldInfo
          title="Periode Bunga"
          value={`${Date.twoDigitWithDash(detail.start_date)} s/d ${Date.twoDigitWithDash(
            detail.end_date
          )}`}
        />
        <div className="flex">
          <Typography.Text className="text-base w-1/2 p-4 border-1 border-grey_bg">
            Domain Email Perusahaan
          </Typography.Text>
          <div className="flex w-1/2 px-3 items-center">
            {isEditEmail ? (
              <Fragment>
                <Select
                  className="select-mail select-mail-product py-2 w-full bg-white"
                  mode="tags"
                  placeholder="@domainemail.com"
                  bordered={false}
                  autoFocus
                  defaultValue={detail?.email}
                  options={options(detail?.email)}
                  onChange={onChangeEmail}
                />
                {!loadingEmail ? (
                  <SaveOutlined role="button" onClick={onClickSaveEmail} />
                ) : (
                  <LoadingOutlined />
                )}
              </Fragment>
            ) : (
              <Fragment>
                <Typography.Text className="text-base w-full py-4 ">
                  {detail?.email?.length > 0 &&
                    detail?.email.map((item, key) => (
                      <span key={key}>
                        {' '}
                        {key !== 0 && ' - '}
                        {item}
                      </span>
                    ))}
                  {detail?.email?.length === 0 && <span>-</span>}
                </Typography.Text>
                {isAdmin && <EditOutlined role="button" onClick={() => setEditEmail(true)} />}
              </Fragment>
            )}
          </div>
        </div>
        {detail.servicer_name && <FieldInfo title="Nama Servicer" value={detail.servicer_name} />}
        {detail.facility_provider && (
          <FieldInfo title="Sarana Peningkatan Kredit" value={detail.facility_provider} />
        )}
        {detail.other_related_parties && (
          <FieldInfo title="Nama Pihak Terkait" value={detail.other_related_parties} />
        )}
      </div>
      {!isProduct && (
        <div className="flex flex-row-reverse gap-5 mt-5">
          <CustomButton
            type="primary"
            onClick={() => {
              setOpen(true);
              setType('approve');
            }}>
            Approve
          </CustomButton>
          <CustomButton
            type="primary-ghost"
            onClick={() => {
              setOpen(true);
              setType('decline');
            }}>
            Decline
          </CustomButton>
        </div>
      )}
      <ApproveDeclineModal
        type={type}
        open={open}
        onClose={() => setOpen(false)}
        ebaId={ebaId}
        page={'Eba Info'}
        navigate={`/management-product/upload-assets?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}`}
      />
    </>
  );
}
