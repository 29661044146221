import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomTable } from '../..';
import { Aquisition } from '../../../service';
import { passColumns } from '../tableConfig';
import { Alert } from 'antd';

const PassInsepection = ({ fileId }) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState({ limit: 10, offset: 0 });
  const [current, setCurrent] = useState(1);
  const [submission, setSubmission] = useState({});
  const [loading, setLoading] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);
  const [percentProcess, setPercentProcess] = useState('');
  const [error, setError] = useState({ isError: false, notes: '' });

  useEffect(() => {
    setLoading(true);
    dispatch(
      Aquisition.aquisitionSubmission({
        isPass: 'True',
        fileId: fileId,
        limit: page.limit,
        offset: page.offset,
      })
    )
      .unwrap()
      .then(({ data }) => {
        if (data?.status === 1) {
          // if data still process
          const percent = (data?.count / data?.count_total) * 100;
          setPercentProcess(percent.toFixed(1) + '%');
          setError({ notes: '', isError: false });

          setTimeout(() => {
            setIsRefetch(!isRefetch);
          }, 3000);
        } else if (data?.status === 2) {
          // there is error when upload data
          setError({ notes: data?.notes, isError: true });
        } else {
          setError({ notes: '', isError: false });
          setLoading(false);
          setSubmission(data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page, isRefetch]);

  const paginationChange = (pages, pageSize) => {
    setCurrent(pages);
    setPage({ ...page, offset: (pages - 1) * pageSize });
  };
  return error.isError ? (
    <Alert message="Error upload" description={error.notes} type="error" showIcon />
  ) : (
    <CustomTable.TableGlobal
      dataSource={submission?.results}
      columns={passColumns}
      loading={loading}
      tipLoading={percentProcess}
      pagination={{
        pageSize: 10,
        onChange: paginationChange,
        current,
        total: submission?.count,
        showSizeChanger: false,
      }}
    />
  );
};

export default PassInsepection;
