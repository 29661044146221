import { Util } from '../../helper';

const columnsAfterAcquisition = [
  { title: 'Bulan', dataIndex: 'month', key: 'month' },
  {
    title: 'Total cashflow hilang(Rp)',
    dataIndex: 'before_acquisition',
    key: 'before_acquisition',
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Total cashflow diakuisisi(Rp)',
    dataIndex: 'after_acquisition',
    key: 'after_acquisition',
    align: 'center',
    render: Util.renderNumber,
  },
  {
    title: 'Selisih(Rp)',
    dataIndex: 'subtraction',
    key: 'subtraction',
    align: 'right',
    render: Util.renderNumber,
  },
];

const columnsBeforeAcquisition = [
  { title: 'Bulan', dataIndex: 'month', key: 'month' },
  {
    title: 'Cashflow yang hilang(Rp)',
    dataIndex: 'total_loss',
    key: 'total_loss',
    align: 'right',
    render: Util.renderNumber,
  },
];

const columnsCombinationAcquisition = [
  { title: 'Bulan', dataIndex: 'month', key: 'month' },
  {
    title: 'Cashflow Akuisisi(Rp)',
    dataIndex: 'cashflow_acquisition',
    key: 'cashflow_acquisition',
    render: Util.renderNumber,
  },
  {
    title: 'Cashflow Sehat(Rp)',
    dataIndex: 'cashflow_sehat',
    key: 'cashflow_sehat',
    render: Util.renderNumber,
  },
  {
    title: 'Total Cashflow Akuisisi + Sehat(Rp)',
    dataIndex: 'sum_cashflow',
    key: 'sum_cashflow',
    render: Util.renderNumber,
  },
  {
    title: 'Proyeksi Awal(Rp)',
    dataIndex: 'projection',
    key: 'projection',
    render: Util.renderNumber,
  },
  { title: 'Selisih(Rp)', dataIndex: 'difference', key: 'difference', render: Util.renderNumber },
];

export { columnsAfterAcquisition, columnsBeforeAcquisition, columnsCombinationAcquisition };
