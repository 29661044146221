import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const aquisitionList = createAsyncThunk(
  'get/aquisitionList',
  async ({ limit, offset, ebaId }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `acquisition/?limit=${limit}&offset=${offset}&eba_id62=${ebaId}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const aquisitionApproval = createAsyncThunk(
  'get/aquisitionApproval',
  async ({ idEba, limit, offset, isArchive = '', status = '' }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `acquisition/approval/?eba_id62=${idEba}&limit=${limit}&offset=${offset}&archive=${isArchive}&status_value=${status}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const aquisitionApprovalByAdmin = createAsyncThunk(
  'post/aquisitionApprovalByAdmin',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({ url: `acquisition/approval/by-admin/`, payload });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const aquisitionApprovalBySupervisor = createAsyncThunk(
  'post/aquisitionApprovalBySupervisor',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({ url: `acquisition/approval/by-supervisor/`, payload });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const aquisitionApprovalByOriginator = createAsyncThunk(
  'post/aquisitionApprovalBySupervisor',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({ url: `acquisition/approval/by-originator/`, payload });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const aquisitionSubmissionNewData = createAsyncThunk(
  'post/aquisitionSubmissionNewData',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({ url: 'acquisition/submission/upload-new-data/', payload });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const aquisitionSubmission = createAsyncThunk(
  'get/aquisitionSubmission',
  async (payload, { rejectWithValue }) => {
    const {
      search = '',
      isPass = '',
      loanNumber = '',
      limit = 10,
      offset = 0,
      fileId = '',
      sortBy = '',
    } = payload;
    try {
      return await API.get({
        url: `acquisition/submission/?is_pass=${isPass}&file_id62=${fileId}&search=${search}&loan_number=${loanNumber}&limit=${limit}&offset=${offset}&${sortBy}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const aquisitionSubmissionRecommendation = createAsyncThunk(
  'get/aquisitionSubmissionRecommendation',
  async (payload, { rejectWithValue }) => {
    const { search = '', priority = '', limit = '', offset = '', fileId = '' } = payload;
    try {
      return await API.get({
        url: `acquisition/submission/recommendation/?file_id62=${fileId}&search=${search}&priority=${priority}&limit=${limit}&offset=${offset}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const aquisitionDownloadExcel = createAsyncThunk(
  'get/aquisitionDownloadExcel',
  async (payload, { rejectWithValue }) => {
    const {
      search = '',
      isPass = '',
      loanNumber = '',
      limit = '',
      offset = '',
      fileId = '',
      fileName,
    } = payload;
    try {
      return await API.get({
        url: `acquisition/submission/export-excel/?is_pass=${isPass}&file_id62=${fileId}&search=${search}&loan_number=${loanNumber}&limit=${limit}&offset=${offset}`,
        isDownload: true,
        fileName: fileName,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const aquisitionDownloadFormatExcel = createAsyncThunk(
  'get/aquisitionDownloadFormatExcel',
  async (_, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `acquisition/submission/format-excel/`,
        isDownload: true,
        fileName: 'Format_Data_Akuisisi',
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const aquisitionAssetDownloadFormatExcel = createAsyncThunk(
  'get/aquisitionAssetDownloadFormatExcel',
  async (_, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `acquisition/format-excel/`,
        isDownload: true,
        fileName: 'Format_Upload_Asset',
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const aquisitionDeleteFile = createAsyncThunk(
  'delete/aquisitionDeleteFile',
  async (idFile, { rejectWithValue }) => {
    try {
      return await API.deleteRequest({
        url: `acquisition/submission/upload-new-data/${idFile}/`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const aquisitionSendDataUpload = createAsyncThunk(
  'post/aquisitionSendDataUpload',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({
        url: `acquisition/submission/send/`,
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getTotalDiffAcquistion = createAsyncThunk(
  'get/getTotalDiffAcquistion',
  async (query, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `acquisition/submission/total-diff-acquisition/?${query}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const acquisitionAdminRequest = createAsyncThunk(
  'post/acquisitionAdminRequest',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({
        url: `acquisition/admin-request/`,
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const acquisitionSummary = createAsyncThunk(
  'post/acquisitionSummary',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({
        url: `acquisition/approval/summary/calculate-npv/`,
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const acquisitionApprovalSummary = createAsyncThunk(
  'get/acquisitionApprovalSummary',
  async (payload, { rejectWithValue }) => {
    const { ebaId, approvalId } = payload;
    try {
      return await API.get({
        url: `acquisition/approval/summary/?eba_id62=${ebaId}&approval_id62=${approvalId}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const acquisitionDownloadDetailApprovalSummary = createAsyncThunk(
  'get/acquisitionDownloadDetailApprovalSummary',
  async ({ approvalId, ebaId }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `acquisition/approval/summary/export-excel/?approval_id62=${approvalId}&eba_id62=${ebaId}`,
        isDownload: true,
        fileName: 'Detail_Data_Pengajuan_Akuisisi',
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const acquisitionDownloadDetailApprovalSummaryAcquisition = createAsyncThunk(
  'get/acquisitionDownloadDetailApprovalSummaryAcquisition',
  async (id62, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `acquisition/approval/${id62}/export-excel/`,
        isDownload: true,
        fileName: 'Detail_Data_Pengajuan_Akuisisi',
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const acquisitionDownloadApprovalSummaryAcquisition = createAsyncThunk(
  'get/acquisitionDownloadApprovalSummaryAcquisition',
  async (id62, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `acquisition/approval/${id62}/export-excel/`,
        isDownload: true,
        fileName: 'Summary_Setelah_Akuisisi',
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const acquisitionApprovalSummaryDetail = createAsyncThunk(
  'get/acquisitionApprovalSummaryDetail',
  async ({ approvalId, offset }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `acquisition/approval/${approvalId}/asset/?offset=${offset}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const acquisitionUpload = createAsyncThunk(
  'post/acquisitionUpload',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({
        url: `acquisition/upload/`,
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const aquisitionSummaryList = createAsyncThunk(
  'post/acquisitionSummaryList',
  async (ebaId, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `acquisition/summary/?eba_id62=${ebaId}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const aquisitionSummaryDelete = createAsyncThunk(
  'delete/acquisitionSummary',
  async (fileId, { rejectWithValue }) => {
    try {
      return await API.deleteRequest({
        url: `acquisition/upload/${fileId}/`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const acquisitionExportExcel = createAsyncThunk(
  'get/acquisitionExportExcel',
  async (ebaId, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `acquisition/upload/${ebaId}/export-excel/`,
        isDownload: true,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default {
  aquisitionList,
  aquisitionApproval,
  aquisitionSubmissionNewData,
  aquisitionSubmission,
  aquisitionSubmissionRecommendation,
  aquisitionDownloadExcel,
  aquisitionDeleteFile,
  aquisitionDownloadFormatExcel,
  aquisitionSendDataUpload,
  aquisitionApprovalByAdmin,
  acquisitionAdminRequest,
  acquisitionSummary,
  acquisitionUpload,
  acquisitionApprovalSummary,
  acquisitionDownloadDetailApprovalSummary,
  acquisitionDownloadDetailApprovalSummaryAcquisition,
  acquisitionDownloadApprovalSummaryAcquisition,
  acquisitionApprovalSummaryDetail,
  aquisitionApprovalBySupervisor,
  aquisitionApprovalByOriginator,
  aquisitionSummaryList,
  aquisitionSummaryDelete,
  acquisitionExportExcel,
  aquisitionAssetDownloadFormatExcel,
  getTotalDiffAcquistion,
};
