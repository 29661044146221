const numberFormat = (value = 0) => {
  if (!Number(value)) return 0;
  return new Intl.NumberFormat('id-ID', {
    currency: 'IDR',
  }).format(Number(value));
};

const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = '';
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export default { numberFormat, getBase64 };
