import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../helper';

const revenuePeriod = createAsyncThunk(
  'get/revenuePeriod',
  async ({ ebaId, limit = 5, offset = 0 }, { rejectWithValue }) => {
    try {
      return await API.get({
        url: `revenue/period/?eba_id62=${ebaId}&limit=${limit}&offset=${offset}`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createRevenuePeriod = createAsyncThunk(
  'post/createRevenuePeriod',
  async (payload, { rejectWithValue }) => {
    try {
      return await API.post({
        url: 'revenue/period/',
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const editRevenuePeriod = createAsyncThunk(
  'put/editRevenuePeriod',
  async ({ payload, id62 }, { rejectWithValue }) => {
    try {
      return await API.put({
        url: `revenue/period/${id62}/`,
        payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const deleteRevenuePeriod = createAsyncThunk(
  'delete/deleteRevenuePeriod',
  async (id62, { rejectWithValue }) => {
    try {
      return await API.deleteRequest({
        url: `revenue/period/${id62}/`,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default {
  revenuePeriod,
  createRevenuePeriod,
  editRevenuePeriod,
  deleteRevenuePeriod,
};
