import { Card, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { CustomTable, ManagementProductComponent, Page } from '../../components';
import { equityBufferColumnSimulation } from './components/tableConfig';
import { CustomButton, ApproveDeclineModal } from '../../components/_shared';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Transaction } from '../../service';
import { Format, Util } from '../../helper';
import usePage from '../../hooks/usePage';

export default function EquityBuffer() {
  const navigate = useNavigate();
  const { isProduct } = usePage();
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');
  const isSimulation = searchParams.get('is_simulation');
  const dispatch = useDispatch();
  const { isSupervisor, isAdmin } = Util.role();
  const status = searchParams.get('status');
  const ebaName = searchParams.get('eba-name') || null;

  const { items, itemTotal, summary, loading, paginationSync } = useSelector(
    (state) => state.transaction
  );

  const [open, setOpen] = useState(false);
  const [openApproval, setOpenApproval] = useState(false);
  const [type, setType] = useState('');

  const breadcrumbs = [
    {
      name: `${isSimulation === 'true' ? 'Simulasi Produk' : 'Management Produk'}`,
      href: '/management-product',
    },
    {
      name: 'Draft Produk',
      href: '/management-product/?status=1',
      isHide: isProduct || isSupervisor,
    },
    { name: 'Management Asset', href: null, isHide: !isProduct },
    { name: 'Equity Tranche', href: null },
  ];

  const fetchData = ({ limit = 5, offset = 0, page = 1 }) => {
    dispatch(Transaction.getTransaction({ ebaId: ebaId, limit, offset, page }));
  };

  useEffect(() => {
    fetchData({ limit: 5, offset: 0, page: 1 });
  }, [ebaId]);

  const paginationChange = (page, pageSize) => {
    fetchData({ limit: 5, offset: (page - 1) * pageSize, page });
  };

  const round = (v) => Math.round(v);

  // const ButtonAddBuffer = ({ disabled }) => (
  //   <Tooltip
  //     title={disabled ? 'Anda sudah menambahkan initial buffer' : ''}
  //     placement="bottomRight">
  //     <div>
  //       <CustomButton
  //         type="primary-ghost"
  //         className="group justify-center items-center flex space-x-2"
  //         onClick={() => setOpen(true)}
  //         disabled={disabled}
  //         {...(disabled && { style: { pointerEvents: 'none' } })}>
  //         <BsPlusCircleFill
  //           size={20}
  //           className={itemTotal > 0 ? 'text-grey' : 'text-primary group-hover:text-white'}
  //         />
  //         Input Data
  //       </CustomButton>
  //     </div>
  //   </Tooltip>
  // );

  return (
    <Page
      title="Equity Tranche"
      breadcrumbs={breadcrumbs}
      updated={items?.length > 0 ? items[items?.length - 1].updated_at : ''}>
      <Typography.Paragraph className="font-bold text-3xl">Equity Tranche</Typography.Paragraph>
      <Typography.Paragraph className="font-bold text-xl mt-2">
        {ebaName || ''}
      </Typography.Paragraph>
      <div className="flex justify-between items-center gap-5 my-6">
        {!isProduct && (
          <Typography.Paragraph className="text-base">
            Silahkan tambahkan equity tranche yang diinginkan
          </Typography.Paragraph>
        )}
        {/* {isAdmin && !isProduct && <ButtonAddBuffer disabled={itemTotal > 0} />} */}
      </div>
      <div className="flex justify-between items-center gap-3 mb-3">
        <Card className="p-1.5 w-full">
          <div className="flex flex-col">
            <Typography.Text className="text-base">Beginning Balance</Typography.Text>
            <Typography.Text className="text-primary text-2xl">
              Rp. {Format.numberFormat(round(summary?.credit))}
            </Typography.Text>
          </div>
        </Card>
        <Card className="p-1.5 w-full">
          <div className="flex flex-col">
            <Typography.Text className="text-base">Cash Inflow / Outflow</Typography.Text>
            <Typography.Text className="text-primary text-2xl">
              Rp. {Format.numberFormat(round(summary?.debit))}
            </Typography.Text>
          </div>
        </Card>
        <Card className="p-1.5 w-full">
          <div className="flex flex-col">
            <Typography.Text className="text-base">Ending Balance</Typography.Text>
            <Typography.Text className="text-primary text-2xl">
              Rp. {Format.numberFormat(round(summary?.buffer))}
            </Typography.Text>
          </div>
        </Card>
      </div>
      <div>
        <CustomTable.TableGlobal
          dataSource={items}
          columns={equityBufferColumnSimulation(!isProduct)}
          // columns={isSimulation ? equityBufferColumnSimulation : equityBufferColumn}
          loading={loading}
          pagination={{
            pageSize: 5,
            onChange: paginationChange,
            current: paginationSync?.page,
            total: itemTotal,
            showSizeChanger: false,
          }}
        />
      </div>
      {isAdmin && !isProduct && (
        <>
          <div className="flex flex-row-reverse mt-3">
            <CustomButton
              type="primary"
              onClick={() =>
                navigate(
                  // eslint-disable-next-line prettier/prettier
                  `/management-product/cost?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}&status=${status}&is_simulation=${
                    isSimulation == 'true' ? 'true' : 'false'
                  }`
                )
              }>
              Simpan
            </CustomButton>
          </div>
          <ManagementProductComponent.EquityBufferForm open={open} onClose={() => setOpen(false)} />
        </>
      )}
      {isSupervisor &&
        !isProduct &&
        (isSimulation === 'true' ? (
          <>
            <div className="flex flex-row-reverse mt-5 gap-5">
              <CustomButton
                type="primary"
                onClick={() => {
                  navigate(
                    `/management-product/cost?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}&is_simulation=true`
                  );
                }}>
                Next
              </CustomButton>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row-reverse mt-5 gap-5">
              <CustomButton
                type="primary"
                onClick={() => {
                  setOpenApproval(true);
                  setType('approve');
                }}>
                Approve
              </CustomButton>
              <CustomButton
                type="primary-ghost"
                onClick={() => {
                  setOpenApproval(true);
                  setType('decline');
                }}>
                Decline
              </CustomButton>
            </div>
            <ApproveDeclineModal
              type={type}
              open={openApproval}
              onClose={() => setOpenApproval(false)}
              ebaId={ebaId}
              page={'Ekuitas Buffer'}
              navigate={`/management-product/cost?page=management-product&eba-name=${ebaName}&eba-id=${ebaId}&status=${status}`}
            />
          </>
        ))}
    </Page>
  );
}
