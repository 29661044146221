import { DatePicker, Form, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomInput, ManagementProductComponent, Page } from '../../../components';
import { CustomButton, CustomInputNumber } from '../../../components/_shared';
import { Util } from '../../../helper';
import usePage from '../../../hooks/usePage';
import { Eba } from '../../../service';
import rules from './rules';

export default function CreateProduct({ is_simulation }) {
  const { isAdmin, isSupervisor } = Util.role();
  const { isProduct } = usePage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const isSimulation = is_simulation || false;
  const [searchParams] = useSearchParams();
  const ebaId = searchParams.get('eba-id');

  const { detail } = useSelector((state) => state.eba);

  const [status, setStatus] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    if (ebaId !== null) {
      setLoading(true);
      dispatch(Eba.getDetailEba(ebaId))
        .unwrap()
        .then((res) => {
          setLoading(false);
          form.setFieldValue('name', res?.data?.name);
          form.setFieldValue('publisher', res?.data?.publisher);
          form.setFieldValue('initial_creditor', res?.data?.initial_creditor);
          form.setFieldValue('custodian_bank', res?.data?.custodian_bank);
          form.setFieldValue('registration', res?.data?.registration);
          form.setFieldValue('amortization', res?.data?.amortization);
          form.setFieldValue('basic_asset', res?.data?.basic_asset);
          form.setFieldValue('issuance_value', res?.data?.issuance_value);
          form.setFieldValue('start_date', dayjs(res?.data?.start_date));
          form.setFieldValue('end_date', dayjs(res?.data?.end_date));
          form.setFieldValue('final_cashflow_date', dayjs(res?.data?.final_cashflow_date));
          form.setFieldValue('first_payment_date', dayjs(res?.data?.first_payment_date));
          form.setFieldValue('email', res?.data?.email);
          form.setFieldValue('servicer', res?.data?.servicer_name);
          form.setFieldValue('provider', res?.data?.facility_provider);
          form.setFieldValue('related', res?.data?.other_related_parties);
          setStatus(res?.data?.status?.value);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [ebaId]);

  const breadcrumbs = [
    { name: 'Management Produk', href: '/management-product' },
    {
      name: 'Draft Produk',
      href: '/management-product/?status=1',
      isHide: isProduct || isSupervisor,
    },
    { name: 'Management Asset', href: null, isHide: !isProduct },
    { name: 'Eba Info', href: null },
  ];

  const onSubmit = (e) => {
    setLoadingSubmit(true);
    const payload = {
      name: e.name,
      publisher: e.publisher,
      initial_creditor: e.initial_creditor,
      custodian_bank: e.custodian_bank,
      registration: e.registration,
      amortization: e.amortization,
      basic_asset: e.basic_asset,
      issuance_value: e.issuance_value,
      start_date: dayjs(e.start_date?.$d).format('YYYY-MM-DD'),
      end_date: dayjs(e.end_date?.$d).format('YYYY-MM-DD'),
      final_cashflow_date: dayjs(e.final_cashflow_date?.$d).format('YYYY-MM-DD'),
      first_payment_date: dayjs(e.first_payment_date?.$d).format('YYYY-MM-DD'),
      is_simulation: isSimulation,
      email: e.email,
      servicer_name: e.servicer,
      facility_provider: e.provider,
      other_related_parties: e.related,
    };

    if (ebaId !== null) {
      dispatch(Eba.editEba({ id: ebaId, payload }))
        .unwrap()
        .then((res) => {
          form.resetFields();
          navigate(
            `/management-product/upload-assets?page=management-product&eba-name=${res?.data?.name}&eba-id=${ebaId}&status=${status}`
          );
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    } else {
      dispatch(Eba.createEba(payload))
        .unwrap()
        .then((res) => {
          if (res.status_code === 201) {
            form.resetFields();
            navigate(
              // eslint-disable-next-line prettier/prettier
              `/management-product/upload-assets?page=management-product&eba-name=${
                res?.data?.name
              }&eba-id=${res?.data?.id62}&status=${1}`
            );
          }
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    }
  };

  const disabledStartDate = (date) => {
    const end = form.getFieldValue('end_date');
    if (!end) return;
    return dayjs(date) >= dayjs(end);
  };

  const disabledEndDate = (date) => {
    const start = form.getFieldValue('start_date');
    if (!start) return;
    return dayjs(date) < dayjs(start);
  };

  const disabledDate = (date) => {
    const end = form.getFieldValue('end_date');
    const start = form.getFieldValue('start_date');
    if (!end || !start) return;
    return dayjs(date) < dayjs(start) || dayjs(date) >= dayjs(end);
  };

  const footerLastCashflow = () => {
    const end = form.getFieldValue('end_date');
    const click = () => {
      form.setFieldValue('final_cashflow_date', dayjs(end));
    };
    return (
      end && (
        <div className="flex justify-center">
          <CustomButton type="link" onClick={click}>
            Periode Terakhir
          </CustomButton>
        </div>
      )
    );
  };

  const FormCreateProduct = useCallback(
    () => (
      <Form requiredMark={false} layout="vertical" className="mt-3" form={form} onFinish={onSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            label={
              <p>
                Nama Produk <span style={{ color: 'red' }}>*</span>
              </p>
            }
            name="name"
            rules={rules.fieldRequired}>
            <CustomInput
              placeholder="Tuliskan nama produk"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
          <Form.Item
            label={
              <p>
                Domain Email Perusahaan (Originator) <span style={{ color: 'red' }}>*</span>
              </p>
            }
            name="email"
            key="domain"
            validateFirst
            rules={rules.rulesEmailDomain}>
            <Select
              className="select-mail rounded custom-input overflow-hidden h-[60px]"
              mode="tags"
              placeholder="@domainemail.com"
            />
          </Form.Item>
          <Form.Item
            label={
              <p>
                Penerbit <span style={{ color: 'red' }}>*</span>
              </p>
            }
            name="publisher"
            rules={rules.fieldRequired}>
            <CustomInput
              placeholder="Tuliskan nama penerbit"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
          <Form.Item
            label={
              <p>
                Kreditur Awal & Agen Pengumpul <span style={{ color: 'red' }}>*</span>
              </p>
            }
            name="initial_creditor"
            rules={rules.fieldRequired}>
            <CustomInput
              placeholder="Tuliskan kreditur awal & agen pengumpul"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
          <Form.Item
            label={
              <p>
                Bank Kustodian <span style={{ color: 'red' }}>*</span>
              </p>
            }
            name="custodian_bank"
            rules={rules.fieldRequired}>
            <CustomInput
              placeholder="Tuliskan nama bank kustodian"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
          <Form.Item
            label={
              <p>
                Pencatatan <span style={{ color: 'red' }}>*</span>
              </p>
            }
            name="registration"
            rules={rules.fieldRequired}>
            <CustomInput
              placeholder="Tuliskan pencatatan"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
          <Form.Item
            label={
              <p>
                Periode Pembayaran <span style={{ color: 'red' }}>*</span>
              </p>
            }
            name="amortization"
            rules={rules.fieldRequired}>
            <CustomInput
              placeholder="Monthly, Quarterly, Semi-Annualy, Annualy, etc"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
          <Form.Item
            label={
              <p>
                Aset Dasar Investasi <span style={{ color: 'red' }}>*</span>
              </p>
            }
            name="basic_asset"
            rules={rules.fieldRequired}>
            <CustomInput
              placeholder="Masukan aset dasar investasi"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
          <Form.Item
            label={
              <p>
                Nilai Penerbitan <span style={{ color: 'red' }}>*</span>
              </p>
            }
            name="issuance_value"
            rules={rules.rulesIssurance}
            validateFirst>
            <CustomInputNumber
              min={0}
              placeholder="Tuliskan nilai penerbitan"
              className="py-[14px] px-[24px] w-full"
              maxLength={19}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item label="Nama Servicer" name="servicer">
            <CustomInput
              placeholder="Masukan nama servicer"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
          <Form.Item
            className="w-full"
            name="start_date"
            dependencies={['end_date']}
            rules={rules.rulesFirstPeriod}
            label={
              <p>
                Tanggal Penerbitan <span style={{ color: 'red' }}>*</span>
              </p>
            }>
            <DatePicker
              className="custom-input rounded py-[18px] bg-white px-[24px] w-full"
              placeholder="dd/MM/YYYY"
              disabledDate={disabledStartDate}
            />
          </Form.Item>
          <Form.Item
            className="w-full"
            name="end_date"
            dependencies={['start_date', 'final_cashflow_date']}
            rules={rules.rulesLastPeriod}
            label={
              <p>
                Tanggal Jatuh Tempo <span style={{ color: 'red' }}>*</span>
              </p>
            }>
            <DatePicker
              className="custom-input rounded py-[18px] bg-white px-[24px] w-full"
              placeholder="dd/MM/YYYY"
              disabledDate={disabledEndDate}
            />
          </Form.Item>
          <Form.Item
            label={
              <p>
                Tanggal Arus Kas Pertama dari Originator <span style={{ color: 'red' }}>*</span>
              </p>
            }
            name="first_payment_date"
            dependencies={['start_date', 'end_date']}
            rules={rules.rulesDateBetweenPeriod}
            tooltip="Minimal satu bulan dari periode awal">
            <DatePicker
              className="custom-input rounded py-[18px] bg-white px-[24px] w-full"
              placeholder="dd/MM/YYYY"
              disabledDate={disabledDate}
            />
          </Form.Item>
          <Form.Item
            label={
              <p>
                Tanggal Arus Kas Terakhir dari Originator <span style={{ color: 'red' }}>*</span>
              </p>
            }
            name="final_cashflow_date"
            dependencies={['end_date', 'start_date']}
            tooltip="Diantara periode awal dan periode akhir"
            rules={rules.rulesDateBetweenPeriod}>
            <DatePicker
              className="custom-input rounded py-[18px] bg-white px-[24px] w-full"
              placeholder="dd/MM/YYYY"
              disabledDate={disabledDate}
              renderExtraFooter={footerLastCashflow}
              showToday={false}
            />
          </Form.Item>
          <Form.Item label="Sarana Peningkatan Kredit" name="provider">
            <CustomInput
              placeholder="Masukan saranan peningkatan kredit"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
          <Form.Item label="Nama Pihak Terkait" name="related">
            <CustomInput
              placeholder="Masukan nama pihak terkait"
              type="text"
              className="py-[18px] px-[24px]"
            />
          </Form.Item>
        </div>
        {!isProduct && (
          <div className="flex flex-row-reverse">
            <CustomButton type="primary" htmlType="submit" loading={loadingSubmit}>
              Simpan
            </CustomButton>
          </div>
        )}
      </Form>
    ),
    []
  );

  const ShowContent = () => {
    if (isAdmin && !isProduct) return <FormCreateProduct />;
    return <ManagementProductComponent.CreateProductSupervisor />;
  };

  return (
    <Page
      title="Eba Info"
      breadcrumbs={breadcrumbs}
      loading={loading}
      updated={ebaId !== null ? detail?.updated_at : ''}>
      <Typography.Paragraph className="font-bold text-3xl">Informasi EBA</Typography.Paragraph>
      <Typography.Paragraph className="text-base">
        Dibawah ini merupakan informasi mengenai spesifikasi produk EBA{' '}
        {isProduct ? '' : 'yang akan ditambahkan'}:
      </Typography.Paragraph>
      <ShowContent />
    </Page>
  );
}
