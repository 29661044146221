import Typography from 'antd/es/typography/Typography';
import React from 'react';
import { MdFileDownload } from 'react-icons/md';
import CustomButton from '../_shared/custom-button';

const Title = ({ title, onClickDownload, loading }) => {
  return (
    <div className="flex justify-between my-10">
      <Typography.Text className="font-bold text-3xl">{title}</Typography.Text>
      <CustomButton
        type="primary"
        onClick={onClickDownload}
        loading={loading}
        className="
            group 
            flex 
            justify-center 
            items-center 
            space-x-2">
        <MdFileDownload size={20} className="text-white group-hover:text-primary" />
        <Typography.Text className="font-bold text-white group-hover:text-primary">
          Unduh Excel
        </Typography.Text>
      </CustomButton>
    </div>
  );
};

export default Title;
