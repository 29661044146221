import { createSlice } from '@reduxjs/toolkit';
import { CustomNotification } from '../../components/_shared';
import { Constant } from '../../const';
import { Reference } from '../../service';

const initialState = {
  upload: [],
  loading: false,
  file: {},
  fileBase64: '',
};

export const referenceSlice = createSlice({
  name: 'reference',
  initialState,
  reducers: {
    setFileUpload: (state, action) => {
      state.file = action.payload;
    },
    setFileBase64: (state, action) => {
      state.fileBase64 = action.payload;
    },
    removeFileUpload: (state) => {
      state.file = {};
    },
  },
  extraReducers: (builder) => {
    // login
    builder.addCase(Reference.chunkUpload.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Reference.chunkUpload.fulfilled, (state, action) => {
      state.loading = false;
      state.upload = action.payload.data;
    });
    builder.addCase(Reference.chunkUpload.rejected, (state, action) => {
      CustomNotification({
        type: Constant.ERROR,
        message: 'Failed Get Data',
        description: action?.payload?.message?.id,
      });
      state.loading = false;
    });
  },
});

export const { setFileUpload, setFileBase64, removeFileUpload } = referenceSlice.actions;
export default referenceSlice.reducer;
