import { Typography, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { MdFileDownload, MdSearch } from 'react-icons/md';
// import { AiOutlineClockCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Page, ReportComponent } from '../../components';
import { CustomButton, CustomInput, CustomTabs } from '../../components/_shared';
import { Report } from '../../service';

const ReportsExternal = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const ebaName = searchParams.get('eba-name');
  const period = searchParams.get('date');
  const ebaId = searchParams.get('eba-id');

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState({ limit: 10, offset: 0, current: 1 });

  const { loadingDownload, external_detail } = useSelector((state) => state.report);

  const breadcrumbs = [
    { name: 'Management Produk', href: '/management-product' },
    { name: `Reporting ${ebaName}`, href: `/reports${location.search}` },
    { name: 'Reporting External', href: null },
  ];

  const tabsItem = [
    {
      key: 0,
      label: 'Profile',
      children: (
        <ReportComponent.TableReportProfile page={page} setPage={setPage} item={external_detail} />
      ),
    },
    {
      key: 1,
      label: 'Informasi Umum',
      children: (
        <ReportComponent.TableInformasiUmum page={page} setPage={setPage} item={external_detail} />
      ),
    },
    {
      key: 2,
      label: 'Informasi Tagihan',
      children: (
        <ReportComponent.TableInformasiTagihan
          page={page}
          setPage={setPage}
          item={external_detail}
        />
      ),
    },
    {
      key: 3,
      label: 'Informasi Pembayaran',
      children: (
        <ReportComponent.TableInformasiPembayaran
          page={page}
          setPage={setPage}
          item={external_detail}
        />
      ),
    },
    {
      key: 4,
      label: 'Investor',
      children: (
        <ReportComponent.TableInvestor page={page} setPage={setPage} item={external_detail} />
      ),
    },
  ];

  const downloadExcel = () => {
    const payload = {
      report: 'external',
      ebaId: ebaId,
      period_date: period,
      period: 'monthly',
    };
    dispatch(Report.ExportExcelExternal(payload));
  };

  useEffect(() => {
    const body = {
      period: period,
      ebaId: ebaId,
      limit: page.limit,
      offset: page.offset,
    };
    dispatch(Report.GetReportExternalDetail(body));
  }, [page]);

  return (
    <Page title="Reporting Eksternal" breadcrumbs={breadcrumbs}>
      <div className="flex justify-between items-center my-6">
        <div>
          <Typography.Paragraph className="font-bold text-3xl">
            Reporting - Otoritas Jasa Keuangan
          </Typography.Paragraph>
          <Typography.Paragraph className="font-bold text-xl mt-2">
            {dayjs(period).format('MMMM YYYY')}
          </Typography.Paragraph>
        </div>
        <div className="flex flex-row-reverse gap-4">
          <CustomButton
            type="primary"
            onClick={downloadExcel}
            loading={loadingDownload}
            className="
            group
            justify-center
            items-center
            flex
            space-x-2">
            <MdFileDownload size={20} className="text-white group-hover:text-primary" />
            Download Excel
          </CustomButton>
          {/* <CustomButton
            type="primary-ghost"
            onClick={() => setOpen(true)}
            loading={loadingDownload}
            className="
            group
            justify-center
            items-center
            flex
            space-x-2">
            <AiOutlineClockCircle size={20} className="text-primary group-hover:text-white" />
            Log History
          </CustomButton> */}
        </div>
      </div>
      <CustomInput placeholder="Search" prefix={<MdSearch />} className="my-5 h-[48px]" />
      <CustomTabs tabsItem={tabsItem} />

      <Modal
        title="Log History - Reporting OJK"
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        width="85%">
        <ReportComponent.LogHistoryExternal ebaId={ebaId} period={period} />
      </Modal>
    </Page>
  );
};

export default ReportsExternal;
